import { GET_SETTLEMENT_REPORT } from "../services/apiUrls";
import { post } from "../services/service";

export const fetchAllSettlementDetails = async (locationId, tenantId, data) => {
  let response = await post(
    GET_SETTLEMENT_REPORT.replace("{tenantId}", tenantId).replace(
      "{locationId}",
      locationId
    ),
    data
  );
  return response;
};
