import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  GET_CAMERA_READING,
  VEHICLE_ENTRY,
  // ADD_CASH_COLLECT_DATA,
} from "../../../../../services/apiUrls";
import QRCode from "qrcode";
import { get, post } from "../../../../../services/service";
import {
  // allotSlot,
  fetchActiveSlots,
  fetchAllSlotsWithoutFloor,
  // fetchOccupiedAndAvailableSlots,
} from "../../../../../api/slots";
import { fetchActiveFloors } from "../../../../../api/floors";
import { fetchAllParkingTypes } from "../../../../../api/parkingTypes";
import { fetchAllBaseFees } from "../../../../../api/baseFees";

export function useEntryDetails() {
  const userId = localStorage.getItem("userId");
  const fullName = localStorage.getItem("fullname");
  const params = useParams();
  let tenantId = localStorage.getItem("tenantId");
  let locationId = localStorage.getItem("locationId");

  const [parkingTypes, setParkingTypes] = useState([]);
  const [parkingTypeId, setParkingTypeId] = useState("");
  const [parkingAmount, setParkingAmount] = useState("");

  let vehicleTypeId = params.vehicleId;

  const [isBaseFeeDirty, setIsBaseFeeDirty] = useState(false);
  const [slotCount, setSlotCount] = useState("");

  // const today = new Date();
  // const originalDate = moment(today);
  // const formattedDate = originalDate.format("YYYY-MM-DD");

  const [toast, setToast] = useState({ show: false, type: "", messsege: "" });
  const [toastForNoSlot, setToastForNoSlot] = useState({
    show: false,
    type: "",
    messsege: "",
  });

  const [entryDetails, setEntryDetails] = useState({});
  const [activeSlots, setActiveSlots] = useState([]);
  const [activeFloors, setActiveFloors] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [formValues, setFormValues] = useState({
    vrn: "",
    gateId: params.gateId,
    // parkingAmount: 0,
    paymentMode: "CASH",
    floorId: "",
    slotId: "",
    paymentStatus: "INITIATED",
    tenantId: tenantId,
    locationId: locationId,
    entryById: localStorage.getItem("userId"),
    vehicleImgUrl: "",
    vehicleId: params.vehicleId,
  });

  const [activefloorId, setActivefloorId] = useState("");
  const [qr, setQr] = useState("");
  const [showPopUp, setShowPopUp] = useState(false);

  // Get All parking Type
  const getParkingType = async () => {
    let response = await fetchAllParkingTypes(locationId, tenantId);
    setParkingTypes(response);
    if (response) {
      findRegular(response);
    }
    return response;
  };

  const findRegular = (response) => {
    const selectedParkingType = response?.find((val) => {
      return !val.deleted && val.name.trim().toLowerCase() === "regular";
    });
    setParkingTypeId(selectedParkingType?.id);
  };

  //Get All Basefee
  const getBaseFee = async () => {
    let response = await fetchAllBaseFees(locationId, tenantId);
    findBaseFee(response);
    return response;
  };

  const findBaseFee = (response) => {
    const selectedBaseFee = response.find(
      (val) =>
        val?.vehicleType?.id === params.vehicleId &&
        val.parkingType.id === parkingTypeId
    );
    setParkingAmount(selectedBaseFee?.amount);
    if (selectedBaseFee === undefined) {
      setIsBaseFeeDirty(true);
    }
  };

  // Get Slot counts
  const getSlotCounts = async () => {
    let response = await fetchAllSlotsWithoutFloor(
      tenantId,
      locationId,
      vehicleTypeId,
      parkingTypeId
    );
    if (response) {
      setSlotCount(response);
    }
  };

  const handlePopUp = () => {
    setShowPopUp(!showPopUp);
  };

  const GenerateQRcode = (vrn) => {
    QRCode.toDataURL(vrn)
      .then((url) => {
        setQr(url);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleParkingType = (e) => {
    setParkingTypeId(e.target.id);
  };

  /*eslint-disable */
  const handlePaymentMode = (name) => {
    setFormValues({ ...formValues, paymentMode: name });
    if (name == "UPI") {
      setPymentModes({ ...paymentModes, cash: false, upi: true, card: false });
    } else if (name == "CASH") {
      setPymentModes({ ...paymentModes, cash: true, upi: false, card: false });
    } else if (name == "CARD") {
      setPymentModes({ ...paymentModes, cash: false, upi: false, card: false });
    }
  };

  const handleChange = (e) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
  };

  const handleFloorChange = (e) => {
    setActivefloorId(e.target.value);
  };

  const handleEntryClear = () => {
    setFormValues({ ...formValues, vrn: "" });
  };

  // const addDataToCashCollectiontable = async (parkingId) => {
  //   const data = {
  //     vrn: formValues.vrn.toUpperCase(),
  //     parking: {
  //       id: parkingId,
  //     },
  //     parkingAmount: parkingAmount,
  //     date: formattedDate,
  //     paymentMode: formValues.paymentMode,
  //     tenantId: formValues.tenantId,
  //     locationId: formValues.locationId,
  //     entryById: userId,
  //     transactionType: "CREDIT",
  //     gateId: params.gateId,
  //     gateType: "ENTRY",
  //     vehicleType: {
  //       id: vehicleTypeId,
  //     },
  //     complimentary: false,
  //     mobileNo: "",
  //   };
  //   const response = await post(
  //     ADD_CASH_COLLECT_DATA.replace("{tenantId}", tenantId).replace(
  //       "{locationId}",
  //       locationId
  //     ),
  //     data
  //   );
  //   return response;
  // };

  // const handleSlotAllot = async () => {
  //   let response = await allotSlot(
  //     activefloorId,
  //     locationId,
  //     formValues.slotId
  //   );
  //   if (response) {
  //     getSlotCounts();
  //   }
  //   return response;
  // };

  const handleEntrySubmit = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    const data = {
      vrn: formValues.vrn.toUpperCase(),
      parkingType: {
        id: parkingTypeId,
      },
      vehicleType: {
        id: vehicleTypeId,
      },
      entryById: userId,
      entryBoothId: formValues.gateId,
      parkingAmount: parkingAmount,
      paymentMode: formValues.paymentMode,
      paymentStatus: formValues.paymentStatus,
      tenantId: tenantId,
      locationId: locationId,
      slotId: formValues.slotId,
      complimentary: false,
      mobileNo: "",
      gateId: formValues.gateId,
      transactionType: "CREDIT",
      name: fullName,
      gateType: "ENTRY"
    };

    let entry = await post(
      VEHICLE_ENTRY.replace("{tenantId}", tenantId).replace(
        "{locationId}",
        locationId
      ),
      data
    );
    setEntryDetails(entry);
    if (
      entry?.id !== "" &&
      entry?.id !== null &&
      entry?.response?.status !== 500
    ) {
      // getActiveSlots();
      // addDataToCashCollectiontable(entry?.id);
      GenerateQRcode(entry?.vrn);
      handlePopUp();
      handleEntryClear();
      setIsLoading(false);
      setToast({
        ...toast,
        show: true,
        type: "success",
        messsege: "Vehicle Entry Successfull",
      });
      // handleSlotAllot();
      getBaseFee();
      if (activeSlots.length === 0) {
        setIsLoading(false);
        setToastForNoSlot({
          ...toastForNoSlot,
          show: true,
          type: "error",
          messsege: "No slot available",
        });
      }
    } else if (entry?.id == null) {
      setIsLoading(false);
      setToast({
        ...toast,
        show: true,
        type: "error",
        messsege: "Vehicle on premise",
      });
    } else if (entry?.response?.status === 500) {
      setIsLoading(false);
      setToast({
        ...toast,
        show: true,
        type: "error",
        messsege: "Vehicle Entry Failed",
      });
    } else {
      setIsLoading(false);
      setToast({
        ...toast,
        show: true,
        type: "error",
        messsege: "Error",
      });
    }
  };

  const getCameraReading = async () => {
    const response = await get(
      GET_CAMERA_READING.replace("{tenantId}", tenantId).replace(
        "{locationId}",
        locationId
      )
    );
    setFormValues({
      ...formValues,
      vrn: response.vrn,
      vehicleImgUrl: response.imageUrl,
    });
    return response;
  };

  const getActiveSlots = async () => {
    let response = await fetchActiveSlots(
      // activefloorId,
      locationId,
      vehicleTypeId,
      parkingTypeId
    );
    if (response) {
      setFormValues({ ...formValues, slotId: response[0]?.id });
    }
    setActiveSlots(response);
    return response;
  };

  const getActiveFloors = async () => {
    let response = await fetchActiveFloors(locationId, tenantId);
    if (response) {
      setActivefloorId(response[0]?.id);
    }
    setActiveFloors(response);
    return response;
  };

  /* eslint-disable */
  useEffect(() => {
    if (toast.show === true) {
      const interval = setInterval(() => {
        setToast({ ...toast, show: false });
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [toast.show]);

  useEffect(() => {
    getActiveFloors();
    getParkingType();
    getBaseFee();
  }, []);

  useEffect(() => {
    getBaseFee();
  }, [vehicleTypeId, isBaseFeeDirty, parkingTypeId]);

  useEffect(() => {
    if (parkingTypeId && vehicleTypeId) {
      getSlotCounts();
      getActiveSlots();
    }
  }, [parkingTypeId, vehicleTypeId, entryDetails]);
  /*eslint-enable */

  return {
    qr,
    toast,
    slotCount,
    showPopUp,
    formValues,
    isLoading,
    activeSlots,
    activeFloors,
    activefloorId,
    parkingTypes,
    parkingTypeId,
    entryDetails,
    parkingAmount,
    toastForNoSlot,
    handlePopUp,
    handleChange,
    handleFloorChange,
    handleEntrySubmit,
    handlePaymentMode,
    handleEntryClear,
    handleParkingType,
    getCameraReading,
  };
}
