import moment from "moment";
import { useEffect, useState } from "react";
import { get, post } from "../../../services/service";
import {
  GET_CASH_COLLECT_REPORT_DATA,
  GET_ACTIVE_GATES,
  // GET_CASH_COLLECT_DETAIL_URL,
  GET_ALL_PAYMENT_MODE_DATA,
} from "../../../services/apiUrls";
import { fetchActiveEmployees } from "../../../api/users";
import { useParams } from "react-router";
import dayjs from "dayjs";
import { useLocation } from "react-router-dom";
import { fetchCashCollectDetailsViewWithPagination } from "../../../api/cashCollect";

export function useCashCollectReport() {
  const userSelectedDate = useLocation();

  let convertedDate = moment(userSelectedDate.state?.date).format("YYYY-MM-DD");

  const [paymentModeData, setPaymentModeData] = useState([]);

  const [cashCollectDetailsReportloader, setCashCollectDetailsReportloader] =
    useState(false);

  const [cashCollectLoader, setCashCollectLoader] = useState(false);

  const params = useParams();

  const [formValue, setFormValue] = useState({
    gateId: "",
    gate: "entry",
    gateForDetailReport: "entry",
    gateIdForDetailReport: "",
    toDate: convertedDate,
    fromDate: convertedDate,
    paymentMode:
      params?.id === "CASH" ? "CASH" : params?.id === "CARD" ? "CARD" : "UPI",
  });

  const [currentPage, setCurrentPage] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  const elementsPerPage = 100;
  let pageNumbers = Math.ceil(totalElements / elementsPerPage);
  const [employees, setEmployees] = useState();

  const [cashCollectData, setCashCollectData] = useState([]);
  const [cashCollectDetailData, setCashCollectDetailData] = useState([]);

  const [search, setSearch] = useState("");

  const [activeGates, setActiveGates] = useState([]);

  let entryById = localStorage.getItem("userId");
  let tenantId = localStorage.getItem("tenantId");
  let locationId = localStorage.getItem("locationId");

  const [fromDateTime, setFromDateTime] = useState(
    dayjs().set("hour", 0).set("minute", 0).set("second", 0)
  );

  const [toDateTime, setToDateTime] = useState(
    dayjs().set("hour", 23).set("minute", 59).set("second", 0)
  );

  const dayjsObject = dayjs(userSelectedDate.state?.date)
    .set("hour", 23)
    .set("minute", 59)
    .set("second", 0);

  const dayjsObjectForFromDate = dayjs(userSelectedDate.state?.date)
    .set("hour", 0)
    .set("minute", 0)
    .set("second", 0);

  const fromDateFromNavigate = new Date(dayjsObject);
  let convertedFromDateFromNavigate =
    moment(fromDateFromNavigate).format("YYYY-MM-DD");

  const handleFromDateTimeChange = (newValue) => {
    setFromDateTime(newValue);
    const fromDate = new Date(newValue);
    let convertedFromdate = moment(fromDate).format("YYYY-MM-DD");
    setFormValue({ ...formValue, fromDate: convertedFromdate });
  };

  const handleToDateTimeChange = (newValue) => {
    setToDateTime(newValue);
    const toDate = new Date(newValue);
    let convertedTodate = moment(toDate).format("YYYY-MM-DD");
    setFormValue({ ...formValue, toDate: convertedTodate });
  };

  const [fromDateTimeForDetailsReport, setFromDateTimeForDetailsReport] =
    useState(
      dayjsObjectForFromDate
      // ? dayjsObject
      // : dayjs().set("hour", 0).set("minute", 0).set("second", 0)
    );

  const [toDateTimeForDetailsReport, setToDateTimeForDetailsReport] = useState(
    dayjsObject
    // ? dayjsObject
    // : dayjs().set("hour", 23).set("minute", 59).set("second", 0)
  );

  const handleFromDateTimeChangeForDetailsReport = (newValue) => {
    setFromDateTimeForDetailsReport(newValue);
    const fromDate = new Date(newValue);
    let convertedFromdate = moment(fromDate).format("YYYY-MM-DD");
    setFormValue({ ...formValue, fromDate: convertedFromdate });
  };

  const handleToDateTimeChangeForDetailsReport = (newValue) => {
    setToDateTimeForDetailsReport(newValue);
    const toDate = new Date(newValue);
    let convertedTodate = moment(toDate).format("YYYY-MM-DD");
    setFormValue({ ...formValue, toDate: convertedTodate });
  };

  const [value, setValue] = useState([]);

  // useEffect(() => {
  //   let value = {
  //     userId: "aedfcaae-604f-48db-b31c-fc0b8a904c96",
  //     name: "deepthi",
  //   };
  //   setValue(value);  
  // }, []);

  const handleOnChange = (event, newValue) => {
    // userSelectedDate.state?.userId
    setValue(newValue);
  };

  const handleSearchChange = async (e) => {
    setSearch(e.target.value);
  };

  const handleInputChange = (e) => {
    setFormValue({
      ...formValue,
      [e.target.name]: e.target.value,
    });
  };

  const handleClear = () => {
    setFormValue({
      gateId: "",
      gate: "entry",
      toDate: convertedDate,
      fromDate: convertedDate,
    });
    setValue([]);
  };

  const getCashCollectDetailsViewWithPagination = async (
    fromDate,
    toDate,
    e
  ) => {
    setCashCollectDetailsReportloader(true);
    if (e.target.name === "today") {
      setFromDateTimeForDetailsReport(
        dayjs().set("hour", 0).set("minute", 0).set("second", 0)
      );
      setToDateTimeForDetailsReport(
        dayjs().set("hour", 23).set("minute", 59).set("second", 0)
      );
    } else if (e.target.name === "yesterday") {
      setFromDateTimeForDetailsReport(
        dayjs()
          .subtract(1, "day")
          .set("hour", 0)
          .set("minute", 0)
          .set("second", 0)
      );
      setToDateTimeForDetailsReport(
        dayjs()
          .subtract(1, "day")
          .set("hour", 23)
          .set("minute", 59)
          .set("second", 0)
      );
    } else if (e.target.name === "week") {
      setFromDateTimeForDetailsReport(
        dayjs()
          .subtract(7, "day")
          .set("hour", 0)
          .set("minute", 0)
          .set("second", 0)
      );
      setToDateTimeForDetailsReport(
        dayjs().set("hour", 23).set("minute", 59).set("second", 0)
      );
    } else if (e.target.name === "tendays") {
      setFromDateTimeForDetailsReport(
        dayjs()
          .subtract(10, "day")
          .set("hour", 0)
          .set("minute", 0)
          .set("second", 0)
      );
      setToDateTimeForDetailsReport(
        dayjs().set("hour", 23).set("minute", 59).set("second", 0)
      );
    } else if (e.target.name === "month") {
      setFromDateTimeForDetailsReport(
        dayjs()
          .subtract(30, "day")
          .set("hour", 0)
          .set("minute", 0)
          .set("second", 0)
      );
      setToDateTimeForDetailsReport(
        dayjs().set("hour", 23).set("minute", 59).set("second", 0)
      );
    }

    let bodyData = {
      fromDate: fromDate,
      toDate: toDate,
      tenantId: tenantId,
      locationId: locationId,
      paymentMode: formValue.paymentMode,
    };

    let response = await fetchCashCollectDetailsViewWithPagination(
      locationId,
      tenantId,
      currentPage,
      bodyData
    );
    if (response) {
      setTotalElements(response?.totalElements);
      setCashCollectDetailsReportloader(false);
      setCashCollectDetailData(response?.content);
    }
    return response;
  };

  const getCashCollectDetailsViewWithPaginationWithoutEvent = async (
    fromDate,
    toDate
  ) => {
    let bodyData = {
      fromDate: fromDate,
      toDate: toDate,
      tenantId: tenantId,
      locationId: locationId,
      paymentMode: formValue.paymentMode,
      gateId: formValue?.gateIdForDetailReport,
      entryById: userSelectedDate.state?.userId,
    };
    setCashCollectDetailsReportloader(true);
    let response = await fetchCashCollectDetailsViewWithPagination(
      locationId,
      tenantId,
      currentPage,
      bodyData
    );
    if (response) {
      setTotalElements(response?.totalElements);
      setCashCollectDetailsReportloader(false);
      setCashCollectDetailData(response?.content);
    }
    return response;
  };

  const handleGateChange = (e) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
    getActiveGates(e.target.value);
  };

  const FilterCashCollectData = cashCollectData?.cashCollectDetails?.filter(
    (item) =>
      item?.total === parseInt(search) ||
      item?.refund === parseInt(search) ||
      item?.netAmount === parseInt(search) ||
      item?.gate?.name?.toLowerCase().includes(search.toLowerCase()) ||
      item?.paymentMode.toLowerCase().includes(search.toLowerCase()) ||
      item?.entryBy?.name?.toLowerCase().includes(search.toLowerCase())
  );

  const FilterCashCollecDetailtData =
    cashCollectDetailData?.length === 0 ||
    cashCollectDetailData?.length === undefined
      ? []
      : cashCollectDetailData?.filter(
          (item) =>
            item?.parkingDto?.parkingAmount === parseInt(search) ||
            item?.gate?.name?.toLowerCase().includes(search?.toLowerCase()) ||
            item?.parkingDto?.vehicleType?.name
              ?.toLowerCase()
              .includes(search?.toLowerCase()) ||
            item?.parkingDto?.vrn
              ?.toLowerCase()
              .includes(search?.toLowerCase()) ||
            item?.parkingDto?.paymentStatus
              ?.toLowerCase()
              .includes(search?.toLowerCase())
        );

  const currentDate = new Date().toLocaleDateString();
  const date = moment(currentDate).format("YYYY-MM-DD");

  var yesterday1 = new Date();
  yesterday1.setDate(yesterday1.getDate() - 1);
  const yesterday = moment(yesterday1).format("YYYY-MM-DD");

  var oneWeek = new Date();
  oneWeek.setDate(oneWeek.getDate() - 6);
  const week = moment(oneWeek).format("YYYY-MM-DD");

  var TenDay = new Date();
  TenDay.setDate(TenDay.getDate() - 9);
  const tenDays = moment(TenDay).format("YYYY-MM-DD");

  var oneMonth = new Date();
  oneMonth.setDate(oneMonth.getDate() - 29);
  const month = moment(oneMonth).format("YYYY-MM-DD");

  const getCashCollect = async () => {
    setCashCollectLoader(true);
    const url = `${GET_CASH_COLLECT_REPORT_DATA.replace(
      "{tenantId}",
      tenantId
    ).replace("{locationId}", locationId)}`;
    const data = {
      toDate: formValue.toDate,
      tenantId: tenantId,
      fromDate: formValue.fromDate,
      locationId: locationId,
      gateId: formValue.gateId,
      entryById: value?.userId,
    };
    const resp = await post(url, data);
    if (resp && resp.entryById !== "") {
      setCashCollectLoader(false);
      setCashCollectData(resp);
    }
  };

  const getAllActivePaymentModeData = async () => {
    const url = GET_ALL_PAYMENT_MODE_DATA;
    const resp = await get(url);
    if (resp) {
      setPaymentModeData(resp);
      // setFormValue({ ...formValue, paymentMode: resp[0]?.paymentMode });
    }
  };

  const getAllActiveEmployeeName = async () => {
    let response = await fetchActiveEmployees(tenantId);
    if (response) {
      setEmployees(response);
    }
  };

  // const getCashCollectDetailReport = async (fromDate, toDate, e) => {
  //   setCashCollectDetailsReportloader(true);
  //   if (e.target.name === "today") {
  //     setFromDateTimeForDetailsReport(
  //       dayjs().set("hour", 0).set("minute", 0).set("second", 0)
  //     );
  //     setToDateTimeForDetailsReport(
  //       dayjs().set("hour", 23).set("minute", 59).set("second", 0)
  //     );
  //   } else if (e.target.name === "yesterday") {
  //     setFromDateTimeForDetailsReport(
  //       dayjs()
  //         .subtract(1, "day")
  //         .set("hour", 0)
  //         .set("minute", 0)
  //         .set("second", 0)
  //     );
  //     setToDateTimeForDetailsReport(
  //       dayjs()
  //         .subtract(1, "day")
  //         .set("hour", 23)
  //         .set("minute", 59)
  //         .set("second", 0)
  //     );
  //   } else if (e.target.name === "week") {
  //     setFromDateTimeForDetailsReport(
  //       dayjs()
  //         .subtract(7, "day")
  //         .set("hour", 0)
  //         .set("minute", 0)
  //         .set("second", 0)
  //     );
  //     setToDateTimeForDetailsReport(
  //       dayjs().set("hour", 23).set("minute", 59).set("second", 0)
  //     );
  //   } else if (e.target.name === "tendays") {
  //     setFromDateTimeForDetailsReport(
  //       dayjs()
  //         .subtract(10, "day")
  //         .set("hour", 0)
  //         .set("minute", 0)
  //         .set("second", 0)
  //     );
  //     setToDateTimeForDetailsReport(
  //       dayjs().set("hour", 23).set("minute", 59).set("second", 0)
  //     );
  //   } else if (e.target.name === "month") {
  //     setFromDateTimeForDetailsReport(
  //       dayjs()
  //         .subtract(30, "day")
  //         .set("hour", 0)
  //         .set("minute", 0)
  //         .set("second", 0)
  //     );
  //     setToDateTimeForDetailsReport(
  //       dayjs().set("hour", 23).set("minute", 59).set("second", 0)
  //     );
  //   }
  //   const url = `${GET_CASH_COLLECT_DETAIL_URL.replace(
  //     "{tenantId}",
  //     tenantId
  //   ).replace("{locationId}", locationId)}`;
  //   const data = {
  //     fromDate: fromDate,
  //     toDate: toDate,
  //     tenantId: tenantId,
  //     locationId: locationId,
  //     paymentMode: formValue?.paymentMode,
  //     gateId: formValue?.gateIdForDetailReport,
  //     entryById: value?.userId,
  //   };
  //   const resp = await post(url, data);
  //   if (resp) {
  //     setCashCollectDetailsReportloader(false);
  //     setCashCollectDetailData(resp);
  //   }
  // };

  // const getCashCollectDetailReportWithoutEvent = async (fromDate, toDate) => {
  //   setCashCollectDetailsReportloader(true);
  //   const url = `${GET_CASH_COLLECT_DETAIL_URL.replace(
  //     "{tenantId}",
  //     tenantId
  //   ).replace("{locationId}", locationId)}`;
  //   const data = {
  //     fromDate: fromDate,
  //     toDate: toDate,
  //     tenantId: tenantId,
  //     locationId: locationId,
  //     paymentMode: formValue?.paymentMode,
  //     gateId: formValue?.gateIdForDetailReport,
  //     entryById: value?.userId,
  //   };
  //   const resp = await post(url, data);
  //   if (resp) {
  //     setCashCollectDetailsReportloader(false);
  //     setCashCollectDetailData(resp);
  //   }
  // };

  const getCashCollectReportByDateButton = async (fromDate, toDate, e) => {
    setCashCollectLoader(true);
    if (e.target.name === "today") {
      setFromDateTime(dayjs().set("hour", 0).set("minute", 0).set("second", 0));
      setToDateTime(dayjs().set("hour", 23).set("minute", 59).set("second", 0));
    } else if (e.target.name === "yesterday") {
      setFromDateTime(
        dayjs()
          .subtract(1, "day")
          .set("hour", 0)
          .set("minute", 0)
          .set("second", 0)
      );
      setToDateTime(
        dayjs()
          .subtract(1, "day")
          .set("hour", 23)
          .set("minute", 59)
          .set("second", 0)
      );
    } else if (e.target.name === "week") {
      setFromDateTime(
        dayjs()
          .subtract(7, "day")
          .set("hour", 0)
          .set("minute", 0)
          .set("second", 0)
      );
      setToDateTime(dayjs().set("hour", 23).set("minute", 59).set("second", 0));
    } else if (e.target.name === "tendays") {
      setFromDateTime(
        dayjs()
          .subtract(10, "day")
          .set("hour", 0)
          .set("minute", 0)
          .set("second", 0)
      );
      setToDateTime(dayjs().set("hour", 23).set("minute", 59).set("second", 0));
    } else if (e.target.name === "month") {
      setFromDateTime(
        dayjs()
          .subtract(30, "day")
          .set("hour", 0)
          .set("minute", 0)
          .set("second", 0)
      );
      setToDateTime(dayjs().set("hour", 23).set("minute", 59).set("second", 0));
    }
    const url = `${GET_CASH_COLLECT_REPORT_DATA.replace(
      "{tenantId}",
      tenantId
    ).replace("{locationId}", locationId)}`;
    const data = {
      fromDate: fromDate,
      toDate: toDate,
      tenantId: tenantId,
      locationId: locationId,
      paymentMode: formValue?.paymentMode,
      gateId: formValue?.gateIdForDetailReport,
      entryById: value?.userId,
    };
    const resp = await post(url, data);
    if (resp) {
      setCashCollectLoader(false);
      setCashCollectData(resp);
    }
  };

  /*eslint-disable */
  const getActiveGates = async (gate) => {
    let booth;
    if (gate == "entry") {
      booth = await get(
        GET_ACTIVE_GATES.replace("{locationId}", locationId).replace(
          "{type}",
          "ENTRY"
        )
      );
    } else if (gate == "exit") {
      booth = await get(
        GET_ACTIVE_GATES.replace("{locationId}", locationId).replace(
          "{type}",
          "EXIT"
        )
      );
    }
    setActiveGates(booth);
  };
  useEffect(() => {
    getActiveGates(formValue.gate);
  }, []);
  /*eslint-enable */

  /*eslint-disable */
  useEffect(() => {
    if (formValue.fromDate && formValue.toDate) {
      getCashCollect(entryById);
    }
  }, [formValue.fromDate, formValue.toDate, formValue.gateId, value]);

  useEffect(() => {
    if (formValue.fromDate && formValue.toDate && formValue.paymentMode) {
      // getCashCollectDetailReportWithoutEvent(
      //   convertedFromDateFromNavigate
      //     ? convertedFromDateFromNavigate
      //     : formValue?.fromDate,
      //   convertedFromDateFromNavigate
      //     ? convertedFromDateFromNavigate
      //     : formValue?.toDate
      // );
      if (
        userSelectedDate?.pathname === "/cashcollect/view/CASH" ||
        userSelectedDate?.pathname === "/cashcollect/view/UPI" ||
        userSelectedDate?.pathname === "/cashcollect/view/CARD"
      ) {
        getCashCollectDetailsViewWithPaginationWithoutEvent(
          convertedFromDateFromNavigate
            ? convertedFromDateFromNavigate
            : formValue?.fromDate,
          convertedFromDateFromNavigate
            ? convertedFromDateFromNavigate
            : formValue?.toDate
        );
      }
    }
  }, [
    formValue.toDate,
    formValue.fromDate,
    formValue.paymentMode,
    currentPage,
    formValue.gateIdForDetailReport,
  ]);

  useEffect(() => {
    getAllActivePaymentModeData();
    getAllActiveEmployeeName();
  }, []);

  /*eslint-enable */

  return {
    date,
    week,
    value,
    month,
    params,
    tenDays,
    yesterday,
    formValue,
    employees,
    currentPage,
    activeGates,
    toDateTime,
    dayjsObject,
    pageNumbers,
    fromDateTime,
    cashCollectData,
    paymentModeData,
    cashCollectLoader,
    FilterCashCollectData,
    toDateTimeForDetailsReport,
    FilterCashCollecDetailtData,
    fromDateTimeForDetailsReport,
    cashCollectDetailsReportloader,
    handleClear,
    setFormValue,
    handleOnChange,
    setCurrentPage,
    handleGateChange,
    handleInputChange,
    handleSearchChange,
    handleToDateTimeChange,
    handleFromDateTimeChange,
    getCashCollectDetailsViewWithPagination,
    // getCashCollectDetailReport,
    getCashCollectReportByDateButton,
    handleFromDateTimeChangeForDetailsReport,
    handleToDateTimeChangeForDetailsReport,
  };
}
