import React from "react";
import {
  Box,
  Button,
  Dialog,
  Checkbox,
  TextField,
  DialogTitle,
  DialogContent,
  FormControlLabel,
  Grid,
} from "@mui/material";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  input: {
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
});

const ExtraChargesForm = ({
  open,
  formValue,
  isDisabled,
  handleClose,
  handleCheckBox,
  handleInputChange,
  extraChargesHandleSubmit,
}) => {
  const classes = useStyles();

  return (
    <>
      <Dialog fullWidth open={open} onClose={handleClose}>
        <Box
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"space-between"}
        >
          <DialogTitle className="text-[#312C41] !text-[16px] !font-semibold">
            Extra Charges
          </DialogTitle>

          <CancelRoundedIcon
            onClick={handleClose}
            className="bg-[white] !text-[#344767] !text-[1.7rem] !outline-none cursor-pointer p-4"
          />
        </Box>

        <DialogContent>
          <Grid
            container
            spacing={4}
            paddingLeft={2}
            paddingRight={2}
            paddingBottom={2}
          >
            <Grid item xs={12} md={6} direction="row" spacing={2}>
              <TextField
                required
                fullWidth
                name="name"
                type="text"
                id="outlined-required"
                label="Name"
                size="small"
                value={formValue?.name}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                required
                fullWidth
                name="amount"
                type="number"
                id="outlined-required"
                label="Amount"
                size="small"
                className={classes.input}
                InputLabelProps={{ shrink: true }}
                value={formValue?.amount}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} md={6} spacing={2}>
              <TextField
                required
                fullWidth
                name="remarks"
                type="text"
                id="outlined-required"
                label="remarks"
                size="small"
                value={formValue?.remarks}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    defaultChecked
                    sx={{ "& .MuiSvgIcon-root": { color: "#2F2A42" } }}
                  />
                }
                onChange={handleCheckBox}
                name="active"
                label="active"
                checked={formValue?.active === true ? true : false}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                fullWidth
                disabled={!isDisabled}
                onClick={extraChargesHandleSubmit}
                sx={{
                  textTransform: "none",
                  background: !isDisabled
                    ? "gray"
                    : "linear-gradient(135deg, #3A416F 0%, #141727 100%)",
                  color: "white",
                  "&:hover": {},
                }}
              >
                {formValue.id ? "Update" : "Ok"}
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ExtraChargesForm;
