import {
  GET_TRANSACTION_REPORT,
  GET_TRNASACTION_REPORT_WITH_PAGINATION,
} from "../services/apiUrls";
import { get } from "../services/service";

export const fetchTransactionReportWithPagination = async (
  tenantId,
  locationId,
  twoWheelerId,
  fourWheelerId,
  fromDate,
  toDate,
  currentPage
) => {
  try {
    let params = {
      page: currentPage,
      size: 100,
    };
    let response = await get(
      `
      ${GET_TRNASACTION_REPORT_WITH_PAGINATION.replace(
        "{tenantId}",
        tenantId
      ).replace(
        "{locationId}",
        locationId
      )}/${twoWheelerId}/${fourWheelerId}?fromDate=${fromDate}&toDate=${toDate}`,
      params
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const fetchTransactionTeport = async (
  fromDate,
  toDate,
  tenantId,
  locationId
) => {
  let response = await get(
    `${GET_TRANSACTION_REPORT.replace("{tenantId}", tenantId).replace(
      "{locationId}",
      locationId
    )}?fromDate=${fromDate}&toDate=${toDate}`
  );
  return response;
};
