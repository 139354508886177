import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Box, Divider, Drawer, IconButton } from "@mui/material";
import logo from "../../../assets/images/dashboard-logo.svg";
import HambergerManu from "../../../assets/images/hamburgermenu.svg";
import NavSection from "./NavSection";
import SimpleBar from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";
import useResponsive from "../../hooks/useResponsive";

const NAV_WIDTH = 260;

Nav.propTypes = {
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func,
};

export default function Nav({
  openNav,
  onCloseNav,
  setIsTrue,
  isTrue,
  // open,
  // setOpen,
}) {
  const isDesktop = useResponsive("up", "lg");

  const renderContent = (
    <SimpleBar className="max-h-[100vh]">
      <Box className="m-auto py-[0.7rem]">
        <Box className="flex justify-center items-center gap-3">
          <IconButton
            onClick={() => {
              setIsTrue(!isTrue);
              // setOpen(!open);
            }}
            aria-label="hambergermenu"
          >
            <img src={HambergerManu} width={30} height="auto" alt="menu" />
          </IconButton>
          <Link to="/home">
            <img src={logo} width={150} height="auto" alt="logo" />
          </Link>
        </Box>
      </Box>

      <Divider
        variant="middle"
        className="bg-gradient-to-r from-[rgba(0, 0, 0, 0.4)] to-[rgba(0, 0, 0, 0)] h-[1px]"
      />

      <NavSection />

      <Box sx={{ flexGrow: 1 }} />
    </SimpleBar>
  );

  return (
    <>
      <Box
        component="nav"
        sx={{
          flexShrink: { lg: 0 },
          width: { lg: isTrue ? NAV_WIDTH : 0 },
        }}
      >
        {isDesktop ? (
          <Drawer
            open={isTrue}
            variant="persistent"
            PaperProps={{
              sx: {
                width: NAV_WIDTH,
                bgcolor: "#f2f2f2",
                border: "none",
              },
            }}
          >
            {renderContent}
          </Drawer>
        ) : (
          <Drawer
            open={openNav}
            onClose={onCloseNav}
            ModalProps={{
              keepMounted: true,
            }}
            PaperProps={{
              sx: { width: NAV_WIDTH },
            }}
          >
            {renderContent}
          </Drawer>
        )}
      </Box>
    </>
  );
}
