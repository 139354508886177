import { useEffect } from "react";
import { useState } from "react";
import {
  VEHICLE_TYPE,
  PUT_VEHICLE_REPORT,
} from "../../../services/apiUrls";
import { get, post, Put } from "../../../services/service";

export function useStaffonBoard() {
  const [staffData, setStaffData] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);

  const [open, setOpen] = useState(false);
  const [searchValue, setSearchvalue] = useState("");
  const [buttonHandle, setButtonHandle] = useState("");

  let tenantId = localStorage.getItem("tenantId");
  let locationId = localStorage.getItem("locationId");

  const isDisabled =
    selectedRow?.name !== "" && selectedRow?.description !== "";

  const fetchStaffonBoard = async () => {
    const url = `${VEHICLE_TYPE.replace("{tenantId}", tenantId).replace(
      "{locationId}",
      locationId
    )}`;
    const resp = await get(url);
    setStaffData(resp);
  };

  const handleChange = (event) => {
    setSelectedRow({ ...selectedRow, [event.target.name]: event.target.value });
  };

  const handleClickOpen = () => {
    setOpen(true);
    setButtonHandle("Add");
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSearchChange = (e) => {
    setSearchvalue(e.target.value);
  };

  const handleSave = async () => {
    const url = `${VEHICLE_TYPE.replace("{tenantId}", tenantId).replace(
      "{locationId}",
      locationId
    )}`;
    const data = {
      name: selectedRow.vehicletype,
      description: selectedRow.description,
      tenantId: localStorage.getItem("tenantId"),
      locationId: localStorage.getItem("locationId"),
    };
    const resp = await post(url, data);
    if (resp && resp.id !== "") {
      fetchStaffonBoard();
    }
    setOpen(false);
  };

  const handleEdit = (row) => {
    setSelectedRow(row);
    setOpen(true);
    setButtonHandle("Edit");
  };

  const handleSaveEdit = async () => {
    const url = `${PUT_VEHICLE_REPORT.replace("{tenantId}", tenantId).replace(
      "{locationId}",
      locationId
    )}`;
    const data = {
      id: selectedRow.key,
      name: selectedRow.vehicletype,
      description: selectedRow.description,
      tenantId: localStorage.getItem("tenantId"),
      locationId: localStorage.getItem("locationId"),
    };
    const resp = await Put(url, data);
    if (resp && resp.id !== "") {
      fetchStaffonBoard();
    }
    setOpen(false);
  };

  const toggleStatus = (id) => {
    const updatedData = staffData.map((row) =>
      row.id === id
        ? { ...row, status: row.status === "ACTIVE" ? "INACTIVE" : "ACTIVE" }
        : row
    );
    setStaffData(updatedData);
  };

  const columns = [
    {
      field: "id",
      headerName: "NO",
      headerClassName: "super-app-theme--header",
      flex: 1,
    },
    {
      field: "firstname",
      headerName: "FIRST NAME",
      headerClassName: "super-app-theme--header",
      flex: 1,
    },

    {
      field: "lastname",
      headerName: "LAST NAME",
      headerClassName: "super-app-theme--header",
      flex: 1,
    },
    {
      field: "email",
      headerName: "EMAIL",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "password",
      headerName: "PASSWORD",
      headerClassName: "super-app-theme--header",
      flex: 1,
    },
    {
      field: "usertype",
      headerName: "USERTYPE",
      headerClassName: "super-app-theme--header",
      flex: 1,
    },
    {
      field: "location",
      headerName: "LOCATION",
      headerClassName: "super-app-theme--header",
      flex: 1,
    },
  ];

  const rows = staffData.map((rowData, index) => ({
    key: rowData.id,
    id: index + 1,
    vehicletype: rowData.name,
    description: rowData.description,
    status: rowData.status,
  }));

 /*eslint-disable */
  useEffect(() => {
    fetchStaffonBoard();
  }, []);
   /*eslint-enable */

  return {
    vehicleData: staffData,
    selectedRow,
    open,
    searchValue,
    buttonHandle,
    isDisabled,
    fetchStaffonBoard,
    handleChange,
    handleClickOpen,
    handleClose,
    handleSearchChange,
    handleSave,
    handleEdit,
    handleSaveEdit,
    toggleStatus,
    columns,
    rows,
  };
}
