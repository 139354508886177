import React from "react";
import { makeStyles } from "@mui/styles";
import { Button, Grid, TextField, Typography } from "@mui/material";

const useStyles = makeStyles({
  input: {
    marginTop: "1rem",
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
});

const LateEarlyRelaxationTail = ({
  setStep,
  formValue,
  handleInputChange,
  isDisabledForRelaxation,
}) => {
  const classes = useStyles();

  return (
    <>
      <Grid container gap={3}>
        <Grid item xs={12} sm={5} md={4} lg={4}>
          <Typography className="!mt-2 !mb-2" variant="subtitle2">
            Daily Late Minutes
          </Typography>
          <TextField
            fullWidth
            size="small"
            type="number"
            name="lateMinutes"
            className={classes.input}
            onChange={handleInputChange}
            value={parseInt(formValue.lateMinutes)}
            placeholder="Enter Relaxation on daily of late minutes"
          />
        </Grid>
        <Grid item xs={12} sm={5} md={4} lg={4}>
          <Typography className="!mt-2 !mb-2" variant="subtitle2">
            Daily Early Minutes
          </Typography>
          <TextField
            fullWidth
            size="small"
            type="number"
            name="earlyMinutes"
            className={classes.input}
            onChange={handleInputChange}
            value={formValue.earlyMinutes}
            placeholder="Enter Relaxation on daily basis of early minutes"
          />
        </Grid>
      </Grid>
      <Grid container mt={24} className="flex justify-between">
        <Grid item>
          <Button
            variant="outlined"
            onClick={() => setStep(0)}
            sx={{ width: "220px", color: "#344767" }}
          >
            Back
          </Button>
        </Grid>
        <Grid item className="flex justify-center">
          <Button
            disabled={!isDisabledForRelaxation}
            onClick={() => setStep(2)}
            sx={{
              width: "220px",
              background: !isDisabledForRelaxation
                ? "gray"
                : "linear-gradient(135deg, #3A416F 0%, #141727 100%)",
              color: "white",
              "&:hover": {},
            }}
          >
            Next
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default LateEarlyRelaxationTail;
