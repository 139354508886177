import React from "react";
import { Button, Checkbox, FormControlLabel, Grid } from "@mui/material";

const WorkingDaysTail = ({
  setStep,
  isChecked,
  checkedValues,
  handleCheckboxChange,
  handleItemCheckboxChange,
  isDisabledForWorkingDetails,
}) => {
  return (
    <>
      <Grid container>
        <Grid item mb={2}>
          <FormControlLabel
            control={
              <Checkbox
                sx={{
                  "& .MuiSvgIcon-root": { color: "#576782" },
                }}
              />
            }
            onChange={handleCheckboxChange}
            name="checkAll"
            value="all"
            label="Select All"
            checked={isChecked}
          />
        </Grid>
      </Grid>

      <Grid container gap={3}>
        {checkedValues?.map((value, key) => {
          return (
            <Grid item key={key}>
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{ "& .MuiSvgIcon-root": { color: "#576782" } }}
                  />
                }
                onChange={(e) => handleItemCheckboxChange(key, e)}
                name={value.name}
                label={value.name}
                value={value.value}
                checked={value.checked}
              />
            </Grid>
          );
        })}
      </Grid>

      <Grid container mt={11} className="flex justify-between">
        <Grid item>
          <Button
            variant="outlined"
            onClick={() => setStep(1)}
            sx={{ width: "220px", color: "#344767" }}
          >
            Back
          </Button>
        </Grid>
        <Grid item className="flex justify-center">
          <Button
            disabled={isDisabledForWorkingDetails}
            onClick={() => setStep(3)}
            sx={{
              width: "220px",
              background: isDisabledForWorkingDetails
                ? "gray"
                : "linear-gradient(135deg, #3A416F 0%, #141727 100%)",
              color: "white",
              "&:hover": {},
            }}
          >
            Next
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default WorkingDaysTail;
