import React from "react";
import { Container, Typography, Box, Grid } from "@mui/material";
import car from "../../../../assets/images/car_entry_exit.png";
import bgImg from "../../../../assets/images/sLOTS_bg.svg";
import useAvailableSlot from "../../hooks/useAvailableSlot";

const AvailableSlotsForCars = () => {
  const { carsAvailbleSlots } = useAvailableSlot();

  return (
    <>
      <div
        className="background-fixed"
        style={{ backgroundImage: `url(${bgImg})` }}
      >
        <Container
          xs={12}
          md={12}
          sm={12}
          fullWidth
          sx={{
            height: "100vh",
            alignItems: "center",
            display: "flex",
          }}
        >
          <Box sx={{ mx: "auto" }}>
            <Box>
              <Typography
                textAlign="center"
                sx={{ fontSize: "50px", fontWeight: "700", color: "white" }}
              >
                AVAILABLE SLOTS
              </Typography>
            </Box>
            <Box>
              <Typography
                textAlign="center"
                sx={{
                  fontSize: "250px",
                  fontWeight: "700",
                  color: "white",
                  mt: -5,
                  mb: -8,
                }}
              >
                {carsAvailbleSlots?.response?.available}
              </Typography>
            </Box>
            <Grid>
              <img style={{ width: "100%" }} src={car} alt="/" />
            </Grid>
          </Box>
        </Container>
      </div>
    </>
  );
};

export default AvailableSlotsForCars;
