import React from "react";
import { Search } from "@mui/icons-material";
import MainLayout from "../../../containers/MainLayout";
import FeeMasterTable from "../components/FeeMasterTable";
import AddBoxRoundedIcon from "@mui/icons-material/AddBoxRounded";
import {
  Alert,
  Box,
  Button,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import { useSlot } from "../hooks/useSlot";
import SlotMasterForm from "../components/SlotMasterForm";

function SlotPage() {
  const {
    toast,
    open,
    // floors,
    columns,
    locations,
    formValue,
    isDisabled,
    errorMessage,
    parkingTypeData,
    // activeFloorId,
    vehicleTypeData,
    slotMasterFilterData,
    handleClose,
    handleSubmit,
    handleCheckBox,
    handleClickOpen,
    findDuplicateSlot,
    handleInputChange,
    handleSearchChange,
    // handlefloorInputChange,
  } = useSlot();

  return (
    <MainLayout>
      <Typography className="text-[#252F40] !mb-2">
        <Link to="/dashboard" className="no-underline">
          <span className="text-[#8C8C8C]">Masters / </span>
        </Link>
        Slot
      </Typography>

      <Box
        sx={{
          boxShadow: " 0px 5px 15px -2px rgba(0, 0, 0, 0.12)",
          borderRadius: "2rem",
          width: "100%",
        }}
      >
        <Box className="flex justify-between px-3 py-2 items-center bg-[white]">
          <Box display="flex" p={1} alignItems="center">
            <TextField
              label="Search"
              onChange={handleSearchChange}
              size="small"
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
          </Box>

          <Button
            variant="contained"
            onClick={handleClickOpen}
            className="!bg-[#3A416F] gap-2"
          >
            Add <AddBoxRoundedIcon />
          </Button>

          <SlotMasterForm
            open={open}
            formValue={formValue}
            isDisabled={isDisabled}
            handleClose={handleClose}
            errorMessage={errorMessage}
            handleSubmit={handleSubmit}
            handleCheckBox={handleCheckBox}
            parkingTypeData={parkingTypeData}
            vehicleTypeData={vehicleTypeData}
            handleInputChange={handleInputChange}
            findDuplicateSlot={findDuplicateSlot}
          />
        </Box>

        <Grid container className="!bg-[white] px-5 py-3" gap={2}>
          <Grid item xs={12} sm={5} md={3} lg={3}>
            <FormControl fullWidth size="small">
              <InputLabel size="small" required>
                Locations
              </InputLabel>
              <Select
                labelId="locations"
                label="Locations *"
                name="locationId"
                value={formValue?.locationId}
                onChange={handleInputChange}
                defaultValue={formValue?.locationId}
              >
                {locations?.map((obj) => {
                  if (obj.deleted === false) {
                    return (
                      <MenuItem value={obj.id} key={obj.i}>
                        {obj.name}
                      </MenuItem>
                    );
                  } else {
                    return null;
                  }
                })}
              </Select>
            </FormControl>
          </Grid>
          {/* <Grid item xs={12} sm={5} md={3} lg={3}>
            <FormControl fullWidth size="small">
              <InputLabel size="small" required>
                Floors
              </InputLabel>
              <Select
                labelId="floorId"
                label="floorId *"
                name="floorId"
                value={activeFloorId}
                onChange={handlefloorInputChange}
                defaultValue={activeFloorId}
              >
                {floors?.map((obj, i) => {
                  if (obj.deleted === false) {
                    return (
                      <MenuItem value={obj.id} key={obj.i}>
                        {obj.name}
                      </MenuItem>
                    );
                  } else {
                    return null;
                  }
                })}
              </Select>
            </FormControl>
          </Grid> */}
        </Grid>

        <Snackbar open={toast.show} autoHideDuration={800} onClose={false} className="!text-[red]">
          <Alert severity={toast.type} sx={{ width: "100%" }}>
            {toast?.messsege}
          </Alert>
        </Snackbar>

        <Snackbar open={toast.show} autoHideDuration={6000}>
          <Alert
            severity="success"
            sx={{ width: "100%" }}
          >
            This is a success message!
          </Alert>
        </Snackbar>

        <FeeMasterTable
          columns={columns}
          feeMasterFilterData={slotMasterFilterData}
        />
      </Box>
    </MainLayout>
  );
}

export default SlotPage;
