import { VEHICLE_TYPE } from "../services/apiUrls";
import { get } from "../services/service";

export const fetchVehicleTypes = async (tenantId, locationId) => {
  let response = await get(
    VEHICLE_TYPE.replace("{tenantId}", tenantId).replace(
      "{locationId}",
      locationId
    )
  );
  return response;
};
