import { useEffect, useState } from "react";
import { GET_USER_RECEIPT_PRINT } from "../../services/apiUrls";
import moment from "moment";
import { get } from "../../services/service";

export function useHeader() {
  const [time, setTime] = useState(new Date());
  const date = new Date();
  const options = { day: "numeric", month: "long", year: "numeric" };
  const currentDate = date.toLocaleDateString("en-US", options);

  const presentDate = moment(date.toLocaleDateString()).format("YYYY/MM/DD");

  const tenantId = localStorage.getItem("tenantId");

  const locationId = localStorage.getItem("locationId");

  const userId = localStorage.getItem("userId");

  const [receiptPopUp, setReceiptPopUp] = useState(false);

  const [receiptData, setReceiptData] = useState([]);

  const receiptHandleClose = () => {
    setReceiptPopUp(!receiptPopUp);
  };

  useEffect(() => {
    const timerID = setInterval(() => {
      setTime(new Date());
    }, 1000);

    return () => {
      clearInterval(timerID);
    };
  }, []);

  const currentTime = time.toLocaleTimeString();

  // GET USER RECEIPT
  const getUserReceipt = async () => {
    try {
      setReceiptPopUp(true);
      const resp = await get(
        `${GET_USER_RECEIPT_PRINT.replace("{tenantId}", tenantId)
          .replace("{location}", locationId)
          .replace("{userId}", userId)}/?fromDate=${presentDate}`
      ).then((res) => {
        setReceiptData(res);
        return res;
      });
      return resp;
    } catch (err) {
      return err;
    }
  };

  return {
    currentTime,
    currentDate,
    receiptPopUp,
    receiptHandleClose,
    getUserReceipt,
    receiptData,
    presentDate,
  };
}
