import { useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import { Box, Button, Typography } from "@mui/material";
import { VEHICLE_TYPE, GET_PARKING_TYPES } from "../../../services/apiUrls";
import { get } from "../../../services/service";
import {
  addSlot,
  deleteSlot,
  fetchAllSlots,
  fetchSlot,
  restoreSlot,
  updateSlot,
} from "../../../api/slots";
import { fetchEntityLocation } from "../../../api/locations";
import { fetchActiveFloors } from "../../../api/floors";
import useIsEqual from "./useIsEqual";

export function useSlot() {
  const [open, setOpen] = useState(false);
  const [slotMaster, setSlotMaster] = useState([]);

  const [parkingTypeData, setParkingTypeData] = useState();
  const [vehicleTypeData, setVehicleTypeData] = useState();

  const [toast, setToast] = useState({ show: false, type: "", messsege: "" });

  const [search, setSearch] = useState("");

  const [locations, setLocation] = useState([]);
  const [floors, setFloors] = useState([]);

  const [errorMessage, setErrorMessage] = useState("");

  const [formValue, setFormValue] = useState({
    id: "",
    name: "",
    alotted: false,
    locationId: localStorage.getItem("locationId"),
    areaId: "",
    floorId: "",
    parkingTypeId: "",
    description: "",
    vehicleTypeId: "",
  });

  const [activeFloorId, setActiveFloorId] = useState("");

  let tenantId = localStorage.getItem("tenantId");
  let locationId = localStorage.getItem("locationId");

  const { isEqual, isSpace } = useIsEqual();

  const columns = [
    {
      field: "No.",
      headerName: "NO.",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "name",
      headerName: "NAME",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "description",
      headerName: "DESCRIPTION",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    // {
    //   field: "vehicleType",
    //   headerName: "Vehicle Type",
    //   flex: 1,
    //   headerClassName: "super-app-theme--header",
    //   valueGetter: (params) => params.row.vehicleTypeId,
    // },
    // {
    //   field: "parkingType",
    //   headerName: "Parking Type",
    //   flex: 1,
    //   headerClassName: "super-app-theme--header",
    //   valueGetter: (params) => params.row.parkingTypeId,
    // },
    {
      field: "deleted",
      headerName: "STATUS",
      flex: 1,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <Box>
            {params.row.deleted === false ? (
              <Button
                variant="contained"
                size="small"
                color="success"
                sx={{ width: "2rem" }}
                onClick={async () => {
                  let response = await deleteSlot(
                    params.id,
                    activeFloorId,
                    locationId
                  );
                  if (response) {
                    getAllSlots();
                  }
                }}
              >
                <Typography sx={{ fontSize: "10px" }}>Active</Typography>
              </Button>
            ) : (
              <Button
                variant="contained"
                size="small"
                color="error"
                sx={{ width: "2rem" }}
                onClick={async () => {
                  let response = await restoreSlot(
                    params.id,
                    activeFloorId,
                    locationId
                  );
                  if (response) {
                    getAllSlots();
                  }
                }}
              >
                <Typography sx={{ fontSize: "10px" }}>Inactive</Typography>
              </Button>
            )}
          </Box>
        );
      },
    },
    {
      field: "action",
      headerName: "ACTION",
      flex: 1,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <EditIcon
            sx={{ cursor: "pointer" }}
            onClick={() => {
              setOpen(true);
              window.scrollTo(0, 0);
              getSlot(params.id);
            }}
          />
        );
      },
    },
  ];

  const handleInputChange = (e) => {
    setErrorMessage("");
    let convertedValue = isSpace(e.target.value);
    setFormValue({ ...formValue, [e.target.name]: convertedValue });
  };

  const handlefloorInputChange = (e) => {
    setActiveFloorId(e.target.value);
  };

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const handleCheckBox = (e) => {
    if (e.target.checked) {
      setFormValue({
        ...formValue,
        [e.target.name]: true,
      });
    } else {
      setFormValue({
        ...formValue,
        [e.target.name]: false,
      });
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
    handleClear();
    setErrorMessage("");
  };

  const handleClose = () => {
    setOpen(false);
    handleClear();
  };

  const handleClear = () => {
    setFormValue({
      ...formValue,
      id: "",
      name: "",
      alotted: false,
      locationId: localStorage.getItem("locationId"),
      areaId: "",
      floorId: "",
      parkingTypeId: "",
      description: "",
      vehicleTypeId: "",
    });
  };

  const isDisabled =
    formValue?.name !== "" &&
    formValue?.description !== "" &&
    formValue?.parkingTypeId !== "" &&
    formValue?.vehicleTypeId !== "";

  const getSlot = async (id) => {
    let response = await fetchSlot(activeFloorId, locationId, id);
    if (response && response?.id !== "") {
      setFormValue({
        ...formValue,
        id: response?.id,
        name: response?.name,
        description: response?.description,
        parkingTypeId: response?.parkingTypeId,
        vehicleTypeId: response?.vehicleTypeId,
      });
    }
  };

  const findDuplicateSlot = () => {
    const isDuplicate = slotMaster.map((slot) => {
      let isEqualName = isEqual(slot.name, formValue.name);
      let isEqualVehicleType = isEqual(
        slot.vehicleTypeId,
        formValue.vehicleTypeId
      );
      let isEqualParkingTypeId = isEqual(
        slot.parkingTypeId,
        formValue.parkingTypeId
      );

      return isEqualName && isEqualParkingTypeId && isEqualVehicleType;
    });

    const finalResult = isDuplicate.includes(true);

    if (finalResult) {
      setErrorMessage("Slot already exists");
    } else {
      handleSubmit();
    }
  };

  const getAllSlots = async () => {
    let response = await fetchAllSlots(locationId);
    if (response && response?.[0]?.id !== " ") {
      setSlotMaster(response);
    }
  };

  const getActiveFloors = async () => {
    let response = await fetchActiveFloors(locationId, tenantId);
    if (response) {
      setActiveFloorId(response[0]?.id);
    }
    setFloors(response);
    return response;
  };

  const getAllParkingType = async () => {
    const resp = await get(
      GET_PARKING_TYPES.replace("{tenantId}", tenantId).replace(
        "{locationId}",
        locationId
      )
    );
    if (resp && resp?.id !== "") {
      setParkingTypeData(resp);
    }
  };

  const getAllVehicleType = async () => {
    const resp = await get(
      VEHICLE_TYPE.replace("{tenantId}", tenantId).replace(
        "{locationId}",
        locationId
      )
    );
    if (resp && resp?.id !== " ") {
      setVehicleTypeData(resp);
    }
  };

  const getEntityLocations = async () => {
    let response = await fetchEntityLocation(tenantId);
    setLocation(response);
    return response;
  };

  const handleSubmit = async () => {
    if (!formValue.id) {
      const data = {
        name: formValue.name,
        description: formValue.description,
        // floorId: activeFloorId,
        locationId: formValue?.locationId,
        tenantId: tenantId,
        vehicleTypeId: formValue.vehicleTypeId,
        parkingTypeId: formValue.parkingTypeId,
        alotted: formValue.alotted,
      };

      let response = await addSlot(locationId, data);

      if (response?.id !== "") {
        setToast({
          ...toast,
          show: true,
          type: "success",
          messsege: "Slot Added Successfull",
        });
        handleClose();
        getAllSlots();
      }
    } else {
      const data = {
        id: formValue.id,
        name: formValue.name,
        description: formValue.description,
        // floorId: activeFloorId,
        locationId: formValue?.locationId,
        tenantId: tenantId,
        vehicleTypeId: formValue.vehicleTypeId,
        parkingTypeId: formValue.parkingTypeId,
        alotted: formValue.alotted,
      };
      let response = await updateSlot(locationId, data);

      if (response && response.id !== "") {
        setToast({
          ...toast,
          show: true,
          type: "success",
          messsege: "Slot Updated Successfull",
        });
        handleClear();
        handleClose();
        getAllSlots();
      }
    }
  };

  const slotMasterFilterData = slotMaster?.filter(
    (item) => item?.name.toLowerCase().includes(search.toLowerCase())
    // item?.description === parseInt(search) ||
    // item.parkingType.name.toLowerCase().includes(search.toLowerCase()) ||
    // item.vehicleType.name.toLowerCase().includes(search.toLowerCase())
  );

  /*eslint-disable */
  useEffect(() => {
    getAllParkingType();
    getAllVehicleType();
    getEntityLocations();
    getActiveFloors();
    getAllSlots();
  }, []);

  // useEffect(() => {
  //   if (activeFloorId) {
  //     getAllSlots();
  //   }
  // }, [activeFloorId]);
  /*eslint-disable */

  return {
    open,
    toast,
    columns,
    // areas,
    floors,
    formValue,
    locations,
    isDisabled,
    errorMessage,
    activeFloorId,
    parkingTypeData,
    vehicleTypeData,
    slotMasterFilterData,
    handleClose,
    handleSubmit,
    handleCheckBox,
    handleClickOpen,
    handleInputChange,
    findDuplicateSlot,
    handleSearchChange,
    handlefloorInputChange,
  };
}
