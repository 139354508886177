import { Box, Button, Typography } from "@mui/material";
import React, { useState } from "react";
import EditIcon from "@mui/icons-material/Edit";


export const useShiftMaster = () => {
  const [showForm, setShowForm] = useState(false);


  const handleFormPopup = () => {
    setShowForm(!showForm);
  };

  const columns = [
    {
      field: "No.",
      headerName: "NO.",
      width: 65,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "username",
      headerName: "USER NAME",
      flex: 1,
      headerClassName: "super-app-theme--header",
    //   valueGetter: (params) => params.row.vehicleType?.name,
    },
    {
      field: "shiftname",
      headerName: "SHIFT NAME",
      flex: 1,
      headerClassName: "super-app-theme--header",
    //   valueGetter: (params) => params.row.parkingType?.name,
    },

    {
      field: "description",
      headerName: "DESCRIPTION",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "deleted",
      headerName: "STATUS",
      flex: 1,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <Box>
            <Button
              onClick={() => {
                // setTableRowDetails(params);
              }}
            >
              {params.row.deleted === "true" ? (
                <Button
                  variant="contained"
                  size="small"
                  color="success"
                  sx={{ width: "2rem" }}
                >
                  <Typography sx={{ fontSize: "10px" }}>Active</Typography>
                </Button>
              ) : (
                <Button
                  variant="contained"
                  size="small"
                  color="error"
                  sx={{ width: "2rem" }}
                >
                  <Typography sx={{ fontSize: "10px" }}>Inactive</Typography>
                </Button>
              )}
            </Button>
          </Box>
        );
      },
    },
    {
      field: "action",
      headerName: "ACTION",
      flex: 1,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <EditIcon
            sx={{ cursor: "pointer" }}
            onClick={() => {
            //   setOpen(true);
              window.scrollTo(0, 0);
            //   getFeeMasterById(params.id);
            }}
          />
        );
      },
    },
  ];
  return { showForm, handleFormPopup,columns };
};
