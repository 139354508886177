import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { post } from "../../../services/service";
import {
  GET_TRNASACTION_VEHICLE_DATA,
  TRNASACTION_REPORT_SEARCH,
} from "../../../services/apiUrls";
import moment from "moment";
import useConvertTime from "./useConvertTime";
import dayjs from "dayjs";
import { fetchTransactionTeport } from "../../../api/transaction";

export function useTransactionPage() {
  const navigate = useNavigate();
  const { state } = useLocation();

  const [loading, setLoading] = useState(true);

  var today = new Date();
  today.setDate(today.getDate());

  // const todayConvertedWith12 = moment(today).format("YYYY/MM/DD 12:00:00");
  // const todayConvertedWith11 = moment(today).format("YYYY/MM/DD 23:59:00");
  const todayConverted = moment().format("YYYY-MM-DD");

  const [formValues, setFormValues] = useState({
    fromDate: todayConverted,
    fromDateForApi: moment(todayConverted).format("YYYY/MM/DD"),
    toDate: todayConverted,
    toDateForApi: moment(todayConverted).format("YYYY/MM/DD"),
  });

  console.log("formValues", formValues);

  const [search, setSearch] = useState(null);
  const [transactionDeatils, setTransactionDetails] = useState([]);
  const [totalTransactions, setTotalTransactions] = useState([]);
  const [transactionDetailsByDate, setTransactionDetailsByDate] = useState([]);

  let tenantId = localStorage.getItem("tenantId");
  let locationId = localStorage.getItem("locationId");

  const currentDate = new Date().toLocaleDateString();
  const currentDateConverted = moment(currentDate).format("YYYY/MM/DD");

  var yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  const yesterdayConverted = moment(yesterday).format("YYYY/MM/DD");

  var oneWeek = new Date();
  oneWeek.setDate(oneWeek.getDate() - 6);
  const week = moment(oneWeek).format("YYYY/MM/DD");

  var TenDay = new Date();
  TenDay.setDate(TenDay.getDate() - 9);
  const TenDays = moment(TenDay).format("YYYY/MM/DD");

  var oneMonth = new Date();
  oneMonth.setDate(oneMonth.getDate() - 29);
  const month = moment(oneMonth).format("YYYY/MM/DD");

  const [fromDateTime, setFromDateTime] = useState(
    dayjs().set("hour", 0).set("minute", 0).set("second", 0)
  );

  const [toDateTime, setToDateTime] = useState(
    dayjs().set("hour", 23).set("minute", 59).set("second", 0)
  );

  const handleFromDateTimeChange = (newValue) => {
    setFromDateTime(newValue);
    const fromDate = new Date(newValue).toLocaleDateString();
    const fromDate1 = moment(fromDate).format("YYYY/MM/DD 12:00:00");
    const fromDate2 = moment(fromDate).format("YYYY/MM/DD");
    setFormValues({
      ...formValues,
      fromDate: fromDate1,
      fromDateForApi: fromDate2,
    });
  };

  // const handleToDateTimeChange = (newValue) => {
  //   console.log('newValue', newValue)
  //   setToDateTime(newValue);
  //   const toDate = new Date(newValue).toLocaleDateString();
  //   console.log('swarna', toDate)
  //   const toDate2 = moment(toDate).format("YYYY/MM/DD");
  //   const toDate1 = moment(toDate).format("YYYY/MM/DD 00:00:00");
  //   console.log('toDate1', toDate1)
  //   console.log('toDate2', toDate2)
  //   setFormValues({ ...formValues, toDate: toDate1, toDateForApi: toDate2 });
  // };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    //eslint-disable-next-line
    if (name == "fromDate") {
      setFormValues((prevState) => ({
        ...prevState,
        [name]: value,
        fromDateForApi: moment(value).format("YYYY/MM/DD"),
      }));
    } else {
      setFormValues((prevState) => ({
        ...prevState,
        [name]: value,
        toDateForApi: moment(value).format("YYYY/MM/DD"),
      }));
    }
  };

  const { convertPremiseTimeStamp } = useConvertTime();

  const [totalElementsFordetailReport, setTotalElementsFordetailReport] =
    useState(0);
  const elementsPerPageForDetailReport = 100;
  let pageNumbersForDetailReport = Math.ceil(
    totalElementsFordetailReport / elementsPerPageForDetailReport
  );

  const [currentPageForDeatilReport, setCurrentPageForDeatilReport] =
    useState(0);

  const columns = [
    {
      field: "No",
      headerName: "NO.",
      width: 65,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "entryBy",
      headerName: "ENTRY BY",
      flex: 1,
      headerClassName: "super-app-theme--header",
      valueGetter: (params) => params?.row?.entryBy?.name,
    },
    {
      field: "entry",
      headerName: "ENTRY GATE",
      flex: 1,
      headerClassName: "super-app-theme--header",
      valueGetter: (params) => params?.row?.entryBooth?.name,
    },
    {
      field: "vehicletype",
      headerName: "VEHICLE TYPE",
      flex: 1,
      headerClassName: "super-app-theme--header",
      valueGetter: (params) => params.row.vehicleType?.name,
    },
    {
      field: "vrn",
      headerName: "VRN NO",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "entryTime",
      headerName: "ENTRY TIME",
      flex: 1,
      headerClassName: "super-app-theme--header",
      valueGetter: (params) =>
        moment(params?.row?.entryTime).format("HH:mm:ss"),
    },
    {
      field: "exitTime",
      headerName: "EXIT TIME",
      flex: 1,
      headerClassName: "super-app-theme--header",
      valueGetter: (params) =>
        params?.row?.exitTime
          ? moment(params?.row?.exitTime).format("HH:mm:ss")
          : " --",
    },
    {
      field: "timeOnPremises",
      headerName: "DURATION",
      flex: 1,
      headerClassName: "super-app-theme--header",
      valueGetter: ({ value }) => convertPremiseTimeStamp(value),
    },
    {
      field: "exitBy",
      headerName: "EXIT BY",
      flex: 1,
      headerClassName: "super-app-theme--header",
      valueGetter: (params) => params?.row?.exitBy?.name,
    },
    {
      field: "exit",
      headerName: "EXIT GATE",
      flex: 1,
      headerClassName: "super-app-theme--header",
      valueGetter: (params) => params?.row?.exitBooth?.name,
    },
    {
      field: "parkingType",
      headerName: "PARKING TYPE",
      flex: 1,
      headerClassName: "super-app-theme--header",
      valueGetter: (params) => params?.row?.parkingType?.name,
    },
    // {
    //   field: "paymentMode",
    //   headerName: "Payment Mode",
    //   flex: 1,
    //   headerClassName: "super-app-theme--header",
    // },
    {
      field: "parkingAmount",
      headerName: "AMOUNT",
      flex: 1,
      align: "right",
      headerAlign: "right",
      headerClassName: "super-app-theme--header",
    },
    {
      field: "status",
      headerName: "STATUS",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
  ];

  const handleSearchChange = async (e) => {
    setSearch(e.target.value);
    let formDate = {
      tenantId: tenantId,
      locationId: locationId,
      date: state?.date,
      vrn: e.target.value,
    };

    const url = `${TRNASACTION_REPORT_SEARCH.replace(
      "{tenantId}",
      tenantId
    ).replace("{locationId}", locationId)}`;

    const response = await post(url, formDate);
    setTransactionDetailsByDate(response);
    return response;
  };

  // const getTransactionReportData = async (fromDate, toDate, e) => {
  //   setLoading(true);
  //   if (e.target.name === "today") {
  //     setFromDateTime(dayjs().set("hour", 0).set("minute", 0).set("second", 0));
  //     setToDateTime(dayjs().set("hour", 23).set("minute", 59).set("second", 0));
  //   } else if (e.target.name === "yesterday") {
  //     setFromDateTime(
  //       dayjs()
  //         .subtract(1, "day")
  //         .set("hour", 0)
  //         .set("minute", 0)
  //         .set("second", 0)
  //     );
  //     setToDateTime(
  //       dayjs()
  //         .subtract(1, "day")
  //         .set("hour", 23)
  //         .set("minute", 59)
  //         .set("second", 0)
  //     );
  //   } else if (e.target.name === "week") {
  //     setFromDateTime(
  //       dayjs()
  //         .subtract(7, "day")
  //         .set("hour", 0)
  //         .set("minute", 0)
  //         .set("second", 0)
  //     );
  //     setToDateTime(dayjs().set("hour", 23).set("minute", 59).set("second", 0));
  //   } else if (e.target.name === "tendays") {
  //     setFromDateTime(
  //       dayjs()
  //         .subtract(10, "day")
  //         .set("hour", 0)
  //         .set("minute", 0)
  //         .set("second", 0)
  //     );
  //     setToDateTime(dayjs().set("hour", 23).set("minute", 59).set("second", 0));
  //   } else if (e.target.name === "month") {
  //     setFromDateTime(
  //       dayjs()
  //         .subtract(30, "day")
  //         .set("hour", 0)
  //         .set("minute", 0)
  //         .set("second", 0)
  //     );
  //     setToDateTime(dayjs().set("hour", 23).set("minute", 59).set("second", 0));
  //   }
  //   const url = `${GET_ALL_TRANSACTION_DATA.replace(
  //     "{tenantId}",
  //     tenantId
  //   ).replace(
  //     "{locationId}",
  //     locationId
  //   )}/${twoWheelerId}/${fourWheelerId}?fromDate=${fromDate}&toDate=${toDate}`;
  //   const resp = await get(url);
  //   if (resp && resp !== "") {
  //     setLoading(false);
  //     setTransactionDetails(resp);
  //   }
  // };

  // const getTransactionDetails = async (fromDate, toDate) => {
  //   setLoading(true);
  //   const url = `${GET_ALL_TRANSACTION_DATA.replace(
  //     "{tenantId}",
  //     tenantId
  //   ).replace(
  //     "{locationId}",
  //     locationId
  //   )}/${twoWheelerId}/${fourWheelerId}?fromDate=${fromDate}&toDate=${toDate}`;
  //   const resp = await get(url);
  //   if (resp && resp !== "") {
  //     setLoading(false);
  //     setTransactionDetails(resp);
  //   }
  // };

  const getTransactionDetailsByDate = async (date) => {
    setLoading(true);
    let formDate = {
      tenantId: tenantId,
      locationId: locationId,
      date: date,
    };
    const url = `${GET_TRNASACTION_VEHICLE_DATA.replace(
      "{tenantId}",
      tenantId
    ).replace(
      "{locationId}",
      locationId
    )}?page=${currentPageForDeatilReport}&size=${100}`;

    const resp = await post(url, formDate);
    if (resp && resp?.id !== "") {
      setLoading(false);
      setTransactionDetailsByDate(resp?.content?.content);
      setTotalElementsFordetailReport(resp?.totalElements);
    }
  };

  // const getTransactionReportWithPagination = async (fromDate, toDate) => {
  //   let response = await fetchTransactionReportWithPagination(
  //     tenantId,
  //     locationId,
  //     twoWheelerId,
  //     fourWheelerId,
  //     fromDate,
  //     toDate,
  //     currentPage
  //   );
  //   if (response && response !== "") {
  //     setLoading(false);
  //     setTransactionDetails(response?.content);
  //     setTotalElements(totalElements);
  //   }
  //   return response;
  // };

  const getTransactionReport = async (e) => {
    e?.preventDefault();
    let response = await fetchTransactionTeport(
      formValues?.fromDateForApi,
      formValues?.toDateForApi,
      tenantId,
      locationId
    );

    const groupedData = response?.[0]?.detailReport?.reduce((acc, obj) => {
      const dateString = obj.date.join("-");
      if (!acc[dateString]) {
        acc[dateString] = {
          ...obj,
          date: moment(dateString, "YYYY-MM-DD").format("DD/MM/YYYY"),
        };
      } else {
        acc[dateString].date = moment(dateString, "YYYY-MM-DD").format(
          "DD/MM/YYYY"
        );
        acc[dateString].totalVehicle += obj.totalVehicle;
        acc[dateString].totalAmount += obj.totalAmount;
        acc[dateString].fourWheelerAmount += obj.fourWheelerAmount;
        acc[dateString].fourWheelerCount += obj.fourWheelerCount;
        acc[dateString].fourWheelerRefund += obj.fourWheelerRefund;
        acc[dateString].twoWheelerRefund += obj.twoWheelerRefund;
        acc[dateString].fourWheelerLessThanFifteen +=
          obj.fourWheelerLessThanFifteen;
        acc[dateString].twoWheelerAmount += obj.twoWheelerAmount;
        acc[dateString].twoWheelerCount += obj.twoWheelerCount;
        acc[dateString].twoWheelerLessThanFifteen +=
          obj.twoWheelerLessThanFifteen;
        acc[dateString].vehicleOnPremises += obj.vehicleOnPremises;
      }
      return acc;
    }, {});

    const result = Object?.values(groupedData);

    setTransactionDetails(response);
    setTotalTransactions(result);
    return response;
  };

  const getTransactionReportByDate = async (e, fromDate, toDate) => {
    e.preventDefault();
    if (e.target.name === "today") {
      setFromDateTime(dayjs().set("hour", 0).set("minute", 0).set("second", 0));
      setToDateTime(dayjs().set("hour", 23).set("minute", 59).set("second", 0));
      setFormValues({
        ...formValues,
        fromDate: moment(fromDate).format("YYYY-MM-DD"),
        toDate: moment(toDate).format("YYYY-MM-DD"),
        fromDateForApi: fromDate,
        toDateForApi: toDate,
      });
    } else if (e.target.name === "yesterday") {
      setFromDateTime(
        dayjs()
          .subtract(1, "day")
          .set("hour", 0)
          .set("minute", 0)
          .set("second", 0)
      );
      setToDateTime(
        dayjs()
          .subtract(1, "day")
          .set("hour", 23)
          .set("minute", 59)
          .set("second", 0)
      );
      setFormValues({
        ...formValues,
        fromDate: moment(fromDate).format("YYYY-MM-DD"),
        toDate: moment(toDate).format("YYYY-MM-DD"),
        toDateForApi: toDate,
        fromDateForApi: fromDate,
      });
    } else if (e.target.name === "week") {
      setFromDateTime(
        dayjs()
          .subtract(7, "day")
          .set("hour", 0)
          .set("minute", 0)
          .set("second", 0)
      );
      setToDateTime(dayjs().set("hour", 23).set("minute", 59).set("second", 0));
      setFormValues({
        ...formValues,
        toDateForApi: toDate,
        fromDateForApi: fromDate,
        fromDate: moment(fromDate).format("YYYY-MM-DD"),
        toDate: moment(toDate).format("YYYY-MM-DD"),
      });
    } else if (e.target.name === "tendays") {
      setFromDateTime(
        dayjs()
          .subtract(10, "day")
          .set("hour", 0)
          .set("minute", 0)
          .set("second", 0)
      );
      setToDateTime(dayjs().set("hour", 23).set("minute", 59).set("second", 0));
      setFormValues({
        ...formValues,
        toDateForApi: toDate,
        fromDateForApi: fromDate,
        fromDate: moment(fromDate).format("YYYY-MM-DD"),
        toDate: moment(toDate).format("YYYY-MM-DD"),
      });
    } else if (e.target.name === "month") {
      setFromDateTime(
        dayjs()
          .subtract(30, "day")
          .set("hour", 0)
          .set("minute", 0)
          .set("second", 0)
      );
      setToDateTime(dayjs().set("hour", 23).set("minute", 59).set("second", 0));
      setFormValues({
        ...formValues,
        toDateForApi: toDate,
        fromDateForApi: fromDate,
        fromDate: moment(fromDate).format("YYYY-MM-DD"),
        toDate: moment(toDate).format("YYYY-MM-DD"),
      });
    }
    let response = await fetchTransactionTeport(
      fromDate,
      toDate,
      tenantId,
      locationId
    );
    const groupedData = response?.[0]?.detailReport?.reduce((acc, obj) => {
      const dateString = obj.date.join("-");
      if (!acc[dateString]) {
        acc[dateString] = {
          ...obj,
          date: moment(dateString, "YYYY-MM-DD").format("DD/MM/YYYY"),
        };
      } else {
        acc[dateString].date = moment(dateString, "YYYY-MM-DD").format(
          "DD/MM/YYYY"
        );
        acc[dateString].totalVehicle += obj.totalVehicle;
        acc[dateString].totalAmount += obj.totalAmount;
        acc[dateString].fourWheelerAmount += obj.fourWheelerAmount;
        acc[dateString].fourWheelerCount += obj.fourWheelerCount;
        acc[dateString].fourWheelerRefund += obj.fourWheelerRefund;
        acc[dateString].twoWheelerRefund += obj.twoWheelerRefund;
        acc[dateString].fourWheelerLessThanFifteen +=
          obj.fourWheelerLessThanFifteen;
        acc[dateString].twoWheelerAmount += obj.twoWheelerAmount;
        acc[dateString].twoWheelerCount += obj.twoWheelerCount;
        acc[dateString].twoWheelerLessThanFifteen +=
          obj.twoWheelerLessThanFifteen;
        acc[dateString].vehicleOnPremises += obj.vehicleOnPremises;
      }
      return acc;
    }, {});

    const result = Object?.values(groupedData);

    setTransactionDetails(response);
    setTotalTransactions(result);
    return response;
  };

  // const getTransactionReportWithPaginationAndDate = async (
  //   fromDate,
  //   toDate,
  //   e
  // ) => {
  //   setLoading(true);
  //   if (e.target.name === "today") {
  //     setFromDateTime(dayjs().set("hour", 0).set("minute", 0).set("second", 0));
  //     setToDateTime(dayjs().set("hour", 23).set("minute", 59).set("second", 0));
  //   } else if (e.target.name === "yesterday") {
  //     setFromDateTime(
  //       dayjs()
  //         .subtract(1, "day")
  //         .set("hour", 0)
  //         .set("minute", 0)
  //         .set("second", 0)
  //     );
  //     setToDateTime(
  //       dayjs()
  //         .subtract(1, "day")
  //         .set("hour", 23)
  //         .set("minute", 59)
  //         .set("second", 0)
  //     );
  //   } else if (e.target.name === "week") {
  //     setFromDateTime(
  //       dayjs()
  //         .subtract(7, "day")
  //         .set("hour", 0)
  //         .set("minute", 0)
  //         .set("second", 0)
  //     );
  //     setToDateTime(dayjs().set("hour", 23).set("minute", 59).set("second", 0));
  //   } else if (e.target.name === "tendays") {
  //     setFromDateTime(
  //       dayjs()
  //         .subtract(10, "day")
  //         .set("hour", 0)
  //         .set("minute", 0)
  //         .set("second", 0)
  //     );
  //     setToDateTime(dayjs().set("hour", 23).set("minute", 59).set("second", 0));
  //   } else if (e.target.name === "month") {
  //     setFromDateTime(
  //       dayjs()
  //         .subtract(30, "day")
  //         .set("hour", 0)
  //         .set("minute", 0)
  //         .set("second", 0)
  //     );
  //     setToDateTime(dayjs().set("hour", 23).set("minute", 59).set("second", 0));
  //   }

  //   let response = await fetchTransactionReportWithPagination(
  //     tenantId,
  //     locationId,
  //     twoWheelerId,
  //     fourWheelerId,
  //     fromDate,
  //     toDate,
  //     currentPage
  //   );
  //   if (response && response !== "") {
  //     setLoading(false);
  //     setTransactionDetails(response?.content);
  //   }
  //   return response;
  // };

  /*eslint-disable */
  useEffect(() => {
    getTransactionReport();
  }, []);

  useEffect(() => {
    if (state?.date || search === null) {
      getTransactionDetailsByDate(state?.date);
    }
  }, [state?.date, currentPageForDeatilReport, search]);
  /*eslint-enable */

  return {
    week,
    month,
    TenDays,
    loading,
    columns,
    formValues,
    toDateTime,
    fromDateTime,
    transactionDeatils,
    totalTransactions,
    yesterdayConverted,
    currentDateConverted,
    transactionDetailsByDate,
    pageNumbersForDetailReport,
    currentPageForDeatilReport,
    navigate,
    handleSearchChange,
    getTransactionReport,
    handleInputChange,
    handleFromDateTimeChange,
    getTransactionReportByDate,
    setCurrentPageForDeatilReport,
  };
}
