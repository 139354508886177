const useIsEqual = () => {
  const isEqual = (a, b) => {
    if (a === b) {
      return true;
    } else if (typeof a === "string" && typeof b === "string") {
      return a.toLowerCase() === b.toLowerCase();
    } else {
      return false;
    }
  };

  const isSpace = (name) => {
    // Trim first space and remove consecutive double spaces
    const processedString = name.replace(/^\s+/, "").replace(/\s{2,}/g, " ");
    return processedString;
  };

  return {
    isSpace,
    isEqual,
  };
};

export default useIsEqual;
