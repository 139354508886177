import {
  Box,
  Button,
  TextField,
  Typography,
  InputAdornment,
} from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { Search } from "@mui/icons-material";
import MainLayout from "../../../containers/MainLayout";
import GateTable from "../components/GateTable";
import AddBoxRoundedIcon from "@mui/icons-material/AddBoxRounded";
import { useGateMaster } from "../hooks/useGateMaster";
import GateForm from "../components/GateForm";

const GateMasterPage = () => {
  const {
    open,
    columns,
    loading,
    formValues,
    isDisabled,
    errorMessage,
    entryBoothFilterData,
    handleClose,
    handleOpenForm,
    findDuplicateGate,
    handleInputChange,
    handleSearchChange,
  } = useGateMaster();
  return (
    <>
      <MainLayout>
        <Typography className="text-[#252F40] !mb-2">
          <Link to="/dashboard" className="no-underline">
            <span className="text-[#8C8C8C]">Masters / </span>
          </Link>
          Gates
        </Typography>
        <Box
          sx={{
            boxShadow: " 0px 5px 15px -2px rgba(0, 0, 0, 0.12)",
            borderRadius: "2rem",
            width: "100%",
          }}
        >
          <Box className="flex justify-between px-3 py-2 items-center bg-[white]">
            <Box display="flex" p={1} alignItems="center">
              <TextField
                label="Search"
                onChange={handleSearchChange}
                size="small"
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Search />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>

            <Button
              variant="contained"
              onClick={handleOpenForm}
              className="!bg-[#3A416F] gap-2"
            >
              Add <AddBoxRoundedIcon />
            </Button>
            <GateForm
              open={open}
              formValue={formValues}
              isDisabled={isDisabled}
              errorMessage={errorMessage}
              handleClose={handleClose}
              handleInputChange={handleInputChange}
              findDuplicateGate={findDuplicateGate}
            />
          </Box>
          <GateTable
            columns={columns}
            loading={loading}
            entryBoothFilterData={entryBoothFilterData}
          />
        </Box>
      </MainLayout>
    </>
  );
};

export default GateMasterPage;
