import React from "react";
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
} from "@mui/material";
import { Stack } from "@mui/system";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";

const FloorMasterForm = ({
  open,
  selectedRow,
  buttonHandle,
  isDisabled,
  handleChange,
  handleClose,
  handleSave,
}) => {
  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"space-between"}
        >
          <DialogTitle className="text-[#312C41] !text-[16px] !font-semibold">
            {buttonHandle === "Add" ? "Add" : "Edit"}
          </DialogTitle>

          <CancelRoundedIcon
            sx={{ cursor: "pointer" }}
            onClick={handleClose}
            className="bg-[white] !text-[#344767] !text-[1.7rem] !outline-none p-4"
          />
        </Box>

        <DialogContent>
          <Stack spacing={4} paddingLeft={4} paddingRight={4} paddingBottom={2}>
            <Stack direction="row" spacing={2}>
              <TextField
                required
                name="name"
                type="text"
                id="outlined-required"
                label="Name"
                size="small"
                onChange={handleChange}
                value={selectedRow ? selectedRow.name : ""}
              />
              <TextField
                required
                name="description"
                type="text"
                id="outlined-required"
                label="Description"
                size="small"
                value={selectedRow ? selectedRow.description : ""}
                onChange={handleChange}
              />
            </Stack>

            <Button
              disabled={!isDisabled}
              onClick={handleSave}
              sx={{
                textTransform: "none",
                background: !isDisabled
                  ? "gray"
                  : "linear-gradient(135deg, #3A416F 0%, #141727 100%)",
                color: "white",
                "&:hover": {},
              }}
            >
              {buttonHandle === "Add" ? "Submit" : "Update"}
            </Button>
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default FloorMasterForm;
