import React from "react";
import {
  Box,
  Card,
  Stack,
  Alert,
  Button,
  Snackbar,
  Typography,
  FormControl,
  OutlinedInput,
  InputAdornment,
  Autocomplete,
  TextField,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
} from "@mui/material";

import cashWhite from "../../../../assets/images/Cash_Icon.svg";
import cashBlue from "../../../../assets/images/Cash_icon_normal.svg";
import upiWhite from "../../../../assets/images/UPI_Whiteout.svg";
import upiBlue from "../../../../assets/images/UPI_Og.svg";
import entry_logo from "../../../../assets/images/entry_logo1.svg";
import car from "../../../../assets/images/car_entry_exit.png";
import bike from "../../../../assets/images/bike_entry_exit.png";
import LinearProgress from "@mui/material/LinearProgress";
import { useExitDetails } from "./hooks/useExitDetails";
import { useLocation } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { Oval } from "react-loader-spinner";
import moment from "moment";

const useOutlinedTextStyle = makeStyles((theme) => ({
  root: {
    "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    height: "40px",
    fontSize: "25px !important",
    backgroundColor: "#f1f1f1",
    borderRadius: "8px !important",
    fontWeight: "800 !important",
    color: "#3a416f !important",
  },
}));

const ExitDetailsPage = () => {
  const {
    toast,
    value,
    loading,
    entryTime,
    formValues,
    isDisabled,
    paymentModes,
    premisesDate,
    handleChange,
    parkingAmount,
    handleExitClear,
    inputValue,
    // activeFloors,
    // activefloorId,
    setInputValue,
    extraCharges,
    setFormValues,
    handleExitSubmit,
    // penaltyAndRefund,
    handleInputChange,
    handlePaymentMode,
    handleAmountChange,
    activeVehicleNumber,
    getVehicleSuggestions,
    handleAutoCompleteChange,
    handleCheckBox,
  } = useExitDetails();

  const classes = useOutlinedTextStyle();
  const { state } = useLocation();

  return (
    <div className="background-fixed-with-gradiant-without-height xxs:h-screen xs:h-auto sm:h-auto md:h-screen">
      <Card className="!rounded-xl xs:mx-3 md:mx-6 lg:mx-6  xl:mx-6 mt-16">
        <div className="grid grid-cols-1 md:grid-cols-2 p-[2%]">
          <Box>
            {/* <Box className="flex justify-center text-[#344767] md:hidden">
              <Typography className="!text-[0.8rem] !text-bold">
                Parking ID:{formValues?.id}
              </Typography>
            </Box> */}
            <Box className="card-box">
              <img src={entry_logo} alt="logo" />
            </Box>
            <div className="grid grid-cols-1">
              <Box className="flex justify-center !flex-col mt-3">
                <Stack direction="column" className="!mt-4">
                  <span className="flex justify-center">
                    <Autocomplete
                      size="large"
                      name="vrn"
                      id="vrn"
                      filterOptions={(options, { inputValue }) =>
                        options.filter((option) => {
                          return option?.vrn.includes(inputValue);
                        })
                      }
                      options={activeVehicleNumber ?? []}
                      value={value}
                      inputValue={inputValue}
                      className="!rounded-lg"
                      onChange={handleAutoCompleteChange}
                      onInputChange={(event, newInputValue) => {
                        setInputValue(newInputValue.toUpperCase());
                        let newIputValueUpperCase = newInputValue.toUpperCase();
                        if (newInputValue) {
                          setFormValues({
                            ...formValues,
                            vrn: newIputValueUpperCase,
                          });
                          handleInputChange(event, newInputValue);
                          getVehicleSuggestions(newIputValueUpperCase);
                        }
                      }}
                      getOptionLabel={(option) => option.vrn ?? ""}
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          {option.vrn} -
                          <span className="text-[14px] pl-1 text-[#54B435]">
                            {moment(option.entryTime).format(
                              "DD-MM-YYYY HH:mm:ss.S"
                            )}
                          </span>
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Vehicle Number"
                          variant="filled"
                          placeholder="Enter Vehicle Number"
                          sx={{ minWidth: "300px" }}
                          onInput={(e) => {
                            e.target.value =
                              e.target.value.match(/^[A-Za-z0-9]+$/);
                          }}
                        />
                      )}
                    />

                    {/* <input
                      name="vrn"
                      value={formValues.vrn}
                      onChange={handleChange}
                      placeholder="Vehicle Number"
                      className="number-box w-[80%] uppercase placeholder:normal-case"
                      onInput={(e) => {
                        e.target.value =
                          e.target.value.match(/^[A-Za-z0-9-]+$/);
                      }}
                    /> */}
                  </span>
                  <span className="flex justify-center">
                    {loading ? (
                      <LinearProgress className="w-[300px] rounded-lg" />
                    ) : (
                      ""
                    )}
                  </span>
                </Stack>
              </Box>
              {/* <Box className="hidden md:block">
                <Typography className="flex justify-center !mt-5 text-[#344767]">
                  Parking ID
                </Typography>
                <Box className="mt-2">
                  <Typography className="flex justify-center text-[#344767] !font-bold">
                    {formValues?.id}
                  </Typography>
                </Box>
              </Box> */}
            </div>
          </Box>

          <Box>
            <Stack
              direction="row"
              className="flex justify-evenly !mt-2  xs:gap-5 sm:gap-5 lg:gap-14 xl:gap-16"
            >
              <Box className="flex justify-center items-center !flex-col gap-1 min-w-max !text-[#344767]">
                <Typography className="flex justify-center xs:!text-[10px] sm:!text-[10px] xl:!text-[14px]">
                  Parking Type
                </Typography>
                {formValues.parkingType ? (
                  <Box className="flex justify-center">
                    <Typography
                      variant="subtitle1"
                      className="bg-[#3A416F] text-[white] !rounded-xl px-2"
                    >
                      {formValues.parkingType}
                    </Typography>
                  </Box>
                ) : (
                  "---"
                )}
              </Box>

              <Box className="flex justify-center items-center !flex-col gap-1 min-w-max !text-[#344767]">
                <Typography className="flex justify-center xs:!text-[10px] sm:!text-[10px] xl:!text-[14px]">
                  Base Fee
                </Typography>
                <Typography className="flex justify-center !font-bold xs:!text-[15px] sm:!text-[15px] xl:!text-[20px]">
                  ₹{formValues?.baseFee >= 0 ? formValues?.baseFee : "---"}
                </Typography>
              </Box>

              <Box className="min-w-max !text-[#344767]">
                <Typography className="flex justify-center xs:!text-[10px] sm:!text-[10px] xl:!text-[14px]">
                  Entry Time
                </Typography>
                <Typography className="flex justify-center !mt-1 !font-bold xs:!text-[15px] sm:!text-[15px] xl:!text-[20px]">
                  {formValues?.entryTime ? entryTime : "---"}
                </Typography>
              </Box>

              <Box className="flex justify-center items-center !flex-col gap-1 min-w-max !text-[#344767]">
                <Typography className="flex justify-center xs:!text-[10px] sm:!text-[10px] xl:!text-[14px]">
                  Time on Premises
                </Typography>
                <Typography className="flex justify-center !font-bold xs:!text-[15px] sm:!text-[15px] xl:!text-[20px]">
                  {formValues?.timeOnPremises ? premisesDate : "---"}
                </Typography>
              </Box>
            </Stack>

            <Stack
              direction={{ xs: "column", sm: "column", md: "row", lg: "row" }}
              className="flex justify-center gap-4 px-16 xs:!mt-5 sm:!mt-[3rem] lg:!mt-10"
            >
              {formValues.refund > 0 ? (
                <Box className="lg:w-1/3 min-w-max !flex-col !text-[#344767]">
                  <Typography className="flex justify-center xs:!text-[10px] sm:!text-[10px] xl:!text-[14px]">
                    Refund
                  </Typography>
                  <Box className="flex justify-center !mt-2">
                    <FormControl variant="outlined" className="!w-[100px]">
                      <OutlinedInput
                        id="outlined-adornment-weight"
                        startAdornment={
                          <InputAdornment position="start">₹</InputAdornment>
                        }
                        fullWidth
                        type="tel"
                        name="refund"
                        value={formValues.refund}
                        onChange={handleChange}
                        className={classes.root}
                        sx={{
                          "& .css-1o9s3wi-MuiInputBase-input-MuiOutlinedInput-input":
                            {
                              color: "#DD2121 !important",
                            },
                        }}
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                          "aria-label": "weight",
                        }}
                        onInput={(e) => {
                          e.target.value = e.target.value.match(/^[0-9\b]+$/);
                        }}
                      />
                    </FormControl>
                  </Box>
                </Box>
              ) : (
                <Box className="lg:w-1/3 min-w-max !flex-col !text-[#344767]">
                  <Typography className="flex justify-center xs:!text-[10px] sm:!text-[10px] xl:!text-[14px]">
                    Fee To be Paid
                  </Typography>
                  <Box className="flex justify-center !mt-2">
                    <FormControl variant="outlined" className="!w-[150px]">
                      <OutlinedInput
                        id="outlined-adornment-weight"
                        startAdornment={
                          <InputAdornment position="start">₹</InputAdornment>
                        }
                        fullWidth
                        readOnly
                        type="number"
                        name="parkingAmount"
                        value={parkingAmount}
                        onChange={handleAmountChange}
                        className={classes.root}
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                          "aria-label": "weight",
                        }}
                        sx={{ borderColor: "red" }}
                        onInput={(e) => {
                          e.target.value = e.target.value.match(/^[0-9\b]+$/);
                        }}
                      />
                    </FormControl>
                  </Box>
                </Box>
              )}

              <Box className="lg:w-1/3 min-w-max  !text-[#344767] ">
                <Typography className="flex justify-center xs:!text-[10px] sm:!text-[10px] xl:!text-[14px]">
                  Extra Charges (if any)
                </Typography>
                <Box className="flex justify-center">
                  <FormControl variant="outlined" className="!mt-2 !w-[150px]">
                    <OutlinedInput
                      id="outlined-adornment-weight"
                      startAdornment={
                        <InputAdornment position="start">₹</InputAdornment>
                      }
                      fullWidth
                      type="tel"
                      name="extrafee"
                      value={formValues.extrafee}
                      onChange={handleChange}
                      onInput={(e) => {
                        e.target.value = e.target.value.match(/^[0-9\b]+$/);
                      }}
                      className={classes.root}
                      aria-describedby="outlined-weight-helper-text"
                      inputProps={{
                        "aria-label": "weight",
                      }}
                    />
                  </FormControl>
                </Box>
              </Box>

              <Box className="lg:w-1/3 min-w-max">
                <Typography className="flex justify-center xs:!text-[10px] sm:!text-[10px] xl:!text-[14px]">
                  Reason for extra charge
                </Typography>

                <FormControl fullWidth sx={{ mt: 1 }}>
                  <Select
                    // displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    className="h-[40px]"
                    value={formValues.extrafeeReasonId}
                    onChange={(e) => {
                      setFormValues({
                        ...formValues,
                        extrafeeReasonId: e.target.value,
                      });
                    }}
                  >
                    {extraCharges?.map((obj) => {
                      return <MenuItem value={obj?.id}>{obj?.name}</MenuItem>;
                    })}
                  </Select>
                </FormControl>

                <FormControlLabel
                  control={
                    <Checkbox
                      defaultChecked
                      onChange={(e) => handleCheckBox(e)}
                      sx={{ "& .MuiSvgIcon-root": { color: "#576782" } }}
                    />
                  }
                  name="shiftOutNextDay"
                  label="Complimentry"
                  checked={formValues?.complimentary === true ? true : false}
                />

                {formValues?.complimentary === true && (
                  <>
                    <Typography className="flex justify-center xs:!text-[10px] sm:!text-[10px] xl:!text-[14px]">
                      Amount
                    </Typography>
                    <Box className="flex justify-center !mt-2">
                      <FormControl variant="outlined" className="!w-[150px]">
                        <OutlinedInput
                          id="complimentry-amount"
                          startAdornment={
                            <InputAdornment position="start">₹</InputAdornment>
                          }
                          fullWidth
                          type="number"
                          name="parkingAmount"
                          value={formValues.parkingAmount}
                          onChange={handleChange}
                          className={classes.root}
                          aria-describedby="outlined-weight-helper-text"
                          inputProps={{
                            "aria-label": "weight",
                          }}
                          sx={{ borderColor: "red" }}
                          // onInput={(e) => {
                          //   e.target.value = e.target.value.match(/^[0-9\b]+$/);
                          // }}
                        />
                      </FormControl>
                    </Box>
                  </>
                )}
              </Box>
            </Stack>

            <Stack
              direction="row"
              className="flex justify-center items-end xs:!mt-6 sm:!mt-[3rem] lg:!mt-12  gap-8"
            >
              <Button
                name="UPI"
                className="button-shadow !rounded-lg gap-1 !capitalize h-[40px]"
                sx={
                  paymentModes.upi
                    ? {
                        bgcolor: paymentModes.upi ? "#3A416F" : "#ffffff",
                        color: paymentModes.upi ? "#3A416F" : "#ffffff",
                        "&:hover": {
                          backgroundColor: "#3A416F !important",
                        },
                      }
                    : {
                        bgcolor: paymentModes.upi ? "#3A416F" : "#ffffff",
                        color: paymentModes.upi ? "#ffffff" : "#ffffff",
                        "&:hover": {
                          backgroundColor: "#ffffff !important",
                        },
                      }
                }
                onClick={(e) => handlePaymentMode("UPI")}
              >
                <img
                  src={paymentModes.upi ? upiWhite : upiBlue}
                  alt="icon"
                  width="50px"
                />
              </Button>

              <Button
                name="CASH"
                className="button-shadow !rounded-lg gap-1 !capitalize hover:!bg-[#3A416F] h-[40px]"
                sx={
                  paymentModes.cash
                    ? {
                        bgcolor: paymentModes.cash ? "#3A416F" : "#ffffff",
                        color: paymentModes.cash ? "#ffffff" : "#3A416F",
                        "&:hover": {
                          backgroundColor: "#3A416F !important",
                        },
                      }
                    : {
                        bgcolor: paymentModes.cash ? "#3A416F" : "#ffffff",
                        color: paymentModes.cash ? "#ffffff" : "#3A416F",
                        "&:hover": {
                          backgroundColor: "#ffffff !important",
                        },
                      }
                }
                onClick={(e) => handlePaymentMode("CASH")}
              >
                <img
                  src={paymentModes.cash ? cashWhite : cashBlue}
                  alt="icon"
                  width="25px"
                />{" "}
                Cash
              </Button>
            </Stack>

            <div className="flex justify-center xs:flex-col-reverse sm:flex-col-reverse md:flex-row  gap-2 xs:!mt-5 sm:!mt-14 md:!mt-12 xl:!mt-16">
              <Button
                fullWidth
                size="small"
                className="flex justify-center xl:!w-[200px] !capitalize !border-[#3A416F] h-[2.5rem] !text-[#3A416F] !rounded-lg"
                variant="outlined"
                onClick={handleExitClear}
              >
                Clear
              </Button>
              <Button
                fullWidth
                size="small"
                className="!capitalize xl:!w-[200px] h-[2.5rem] !rounded-lg"
                sx={{
                  background: !isDisabled
                    ? "gray"
                    : "linear-gradient(135deg, #3A416F 0%, #141727 100%), #2F2A42",
                }}
                variant="contained"
                disabled={!isDisabled}
                onClick={handleExitSubmit}
              >
                {loading ? (
                  <Oval
                    height={20}
                    width={20}
                    color="#4fa94d"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                    ariaLabel="oval-loading"
                    secondaryColor="#4fa94d"
                    strokeWidth={2}
                    strokeWidthSecondary={2}
                  />
                ) : (
                  "Submit"
                )}
              </Button>
            </div>
          </Box>
        </div>
      </Card>

      {/* <Box className="flex justify-end mr-6 mt-3">
        <TextField
          className="!w-[200px] sm:!w-[300px] md:!w-[400px] lg:!w-[300px]"
          sx={{
            "&:after": {
              borderBottomColor: "#D9D9D9",
            },
            "& .MuiSvgIcon-root": {
              color: "#D9D9D9",
            },
          }}
          select
          label="Floor"
          fullWidth
          InputProps={{
            sx: { color: "#F8F9FA" },
          }}
          InputLabelProps={{
            sx: { color: "#F8F9FA" },
          }}
          value={activefloorId}
          // onChange={handleFloorChange}
        >
          {activeFloors?.map((obj, i) => {
            return (
              <MenuItem key={i} value={obj.id}>
                {obj.name}
              </MenuItem>
            );
          })}
        </TextField>
      </Box> */}

      <Box className="flex-col absolute left-5 bottom-50 hidden xl:block bottom-14">
        <Typography className="text-gradiant !text-[2rem] !font-black">
          Park with ease !
        </Typography>
        <Typography className="text-[#576782] !text-[17px]">
          PARKezy makes parking hassle-free.
        </Typography>
      </Box>
      <Box className="!absolute !right-0 !bottom-2 ">
        {/* eslint-disable eqeqeq */}
        <img
          src={state.vehicleId == 1 ? bike : car}
          className="w-200 h-[150px] hidden xl:block"
          alt="logo"
        />
      </Box>
      <Snackbar open={toast.show} autoHideDuration={1500}>
        <Alert severity={toast.type} sx={{ width: "100%" }}>
          {toast.messsege}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default ExitDetailsPage;
