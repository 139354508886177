import React from "react";
import { Search } from "@mui/icons-material";
import { useFeeMaster } from "../hooks/useFeeMaster";
import FeeMasterForm from "../components/FeeMasterForm";
import MainLayout from "../../../containers/MainLayout";
import FeeMasterTable from "../components/FeeMasterTable";
import AddBoxRoundedIcon from "@mui/icons-material/AddBoxRounded";
import {
  Box,
  Button,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";

function FeeMasterPage() {
  const {
    open,
    columns,
    formValue,
    isDisabled,
    handleClose,
    handleSubmit,
    handleCheckBox,
    handleClickOpen,
    parkingTypeData,
    vehicleTypeData,
    handleInputChange,
    handleSearchChange,
    feeMasterFilterData,
  } = useFeeMaster();

  return (
    <MainLayout>
      <Typography className="text-[#252F40] !mb-2">
        <Link to="/dashboard" className="no-underline">
          <span className="text-[#8C8C8C]">Masters / </span>
        </Link>
        Fee
      </Typography>
      <Box
        sx={{
          boxShadow: " 0px 5px 15px -2px rgba(0, 0, 0, 0.12)",
          borderRadius: "2rem",
          width: "100%",
        }}
      >
        <Box className="flex justify-between px-3 py-2 items-center bg-[white]">
          <Box display="flex" p={1} alignItems="center">
            <TextField
              label="Search"
              onChange={handleSearchChange}
              size="small"
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
          </Box>

          <Button
            variant="contained"
            onClick={handleClickOpen}
            className="!bg-[#3A416F] gap-2"
          >
            Add <AddBoxRoundedIcon />
          </Button>

          <FeeMasterForm
            open={open}
            formValue={formValue}
            isDisabled={isDisabled}
            handleClose={handleClose}
            handleSubmit={handleSubmit}
            handleCheckBox={handleCheckBox}
            parkingTypeData={parkingTypeData}
            vehicleTypeData={vehicleTypeData}
            handleInputChange={handleInputChange}
          />
        </Box>
        <FeeMasterTable
          columns={columns}
          feeMasterFilterData={feeMasterFilterData}
        />
      </Box>
    </MainLayout>
  );
}

export default FeeMasterPage;
