import { Button } from "@mui/material";
import React from "react";

const SecondaryButton = ({ disabled, onClick, name, variant }) => {
  return (
    <Button
      disabled={disabled}
      onClick={onClick}
      variant={variant}
      sx={{
        textTransform: "none",
        bgcolor: "#ffffff",
        color: "#141727",
        "&:hover": {
          backgroundColor: "#ffffff !important",
        },
      }}
    >
      {name}
    </Button>
  );
};

export default SecondaryButton;
