import axios from "axios";
import {
  GET_PARKING_TYPE,
  GET_COMPLIMENTARY_REPORT,
} from "../../../services/apiUrls";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import moment from "moment";

export const useComplimentaryReports = () => {
  const token = localStorage.getItem("token");
  const locationId = localStorage.getItem("locationId");
  const tenantId = localStorage.getItem("tenantId");

  const date = new Date();
  const currentDate = moment(date.toLocaleDateString()).format("YYYY-MM-DD");

  const [parkingTypeList, setParkingTypeList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [complementaryReportDetails, setComplementaryReportDetails] = useState(
    []
  );
  const [complimentaryFormValues, setComplimentaryFormValues] = useState({
    fromDate: currentDate,
    toDate: currentDate,
    parkingTypeId: "",
  });

  const columns = [
    {
      field: "No",
      headerName: "NO.",
      width: 65,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "date",
      headerName: "DATE",
      flex: 1,
      align: "center",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      valueFormatter: (params) =>
        params?.value ? moment(params?.value).format("DD/MM/YYYY") : "--",
    },
    {
      field: "vrn",
      headerName: "VRN",
      flex: 1,
      align: "center",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
    },
    {
      field: "entryBy",
      headerName: "ENTRY BY",
      flex: 1,
      align: "right",
      headerAlign: "right",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => params?.row?.entryBy?.name,
    },
    {
      field: "entryTime",
      headerName: "ENTRY TIME",
      flex: 1,
      align: "right",
      headerAlign: "right",
      headerClassName: "super-app-theme--header",
      valueFormatter: (params) =>
        moment(params?.row?.entryTime).format("hh:mm:ss A"),
      // renderCell: (params) => params?.row?.entryTime,
    },
    {
      field: "entryBooth",
      headerName: "ENTRY BOOTH",
      flex: 1,
      align: "right",
      headerAlign: "right",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => params?.row?.entryBooth?.name,
    },
    {
      field: "exitBooth",
      headerName: "EXIT BOOTH",
      flex: 1,
      align: "right",
      headerAlign: "right",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => params?.row?.exitBooth?.name,
    },
    {
      field: "exitBy",
      headerName: "EXIT BY",
      flex: 1,
      align: "center",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => params?.row?.exitBy?.name,
    },
    {
      field: "mobileNo",
      headerName: "MOBILE NUMBER",
      flex: 1,
      align: "center",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
    },
    {
      field: "parkingAmount",
      headerName: "PARKING AMOUNT",
      flex: 1,
      align: "center",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
    },
    {
      field: "parkingType",
      headerName: "PARKING TYPE",
      flex: 1,
      align: "center",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => params?.row?.parkingType?.name,
    },
    {
      field: "paymentMode",
      headerName: "PAYMENT MODE",
      flex: 1,
      align: "center",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
    },
    {
      field: "vehicleType",
      headerName: "VEHICLE TYPE",
      flex: 1,
      align: "right",
      headerAlign: "right",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => params?.row?.vehicleType?.name,
    },
  ];

  const [fromDateTime, setFromDateTime] = useState(
    dayjs().set("hour", 0).set("minute", 0).set("second", 0)
  );

  const [toDateTime, setToDateTime] = useState(
    dayjs().set("hour", 23).set("minute", 59).set("second", 0)
  );

  const handleFromDateTimeChange = (newValue) => {
    setFromDateTime(newValue);
    const fromDate = new Date(newValue).toLocaleDateString();
    const fromDate1 = moment(fromDate).format("YYYY/MM/DD 12:00:00");
    setComplimentaryFormValues({
      ...complimentaryFormValues,
      fromDate: fromDate1,
    });
  };

  const handleToDateTimeChange = (newValue) => {
    setToDateTime(newValue);
    const toDate = new Date(newValue).toLocaleDateString();
    const toDate1 = moment(toDate).format("YYYY/MM/DD 23:59:00");
    setComplimentaryFormValues({ ...complimentaryFormValues, toDate: toDate1 });
  };

  const handleInputChange = (e) => {
    setComplimentaryFormValues({
      ...complimentaryFormValues,
      [e.target.name]: e.target.value,
    });
  };

  // GET PARKING TYPE
  const getParkingType = async () => {
    try {
      const resp = await axios
        .get(
          `${GET_PARKING_TYPE}${tenantId}/location/${locationId}/parking-type`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setParkingTypeList(res.data);
          return res;
        });
      return resp;
    } catch (err) {
      setParkingTypeList([]);
      return err;
    }
  };

  // GET COMPLIMENTARY REPORT
  const getComplimentaryReport = async (bodyData) => {
    setLoading(true);
    try {
      const resp = await axios
        .post(
          `${GET_COMPLIMENTARY_REPORT.replace("{tenantId}", tenantId).replace(
            "{locationId}",
            locationId
          )}`,
          bodyData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          return res;
        });
      if (resp?.response?.status === 500) {
        setLoading(false);
        setComplementaryReportDetails([]);
      } else {
        setLoading(false);
        setComplementaryReportDetails(resp?.data);
      }
      return resp;
    } catch (err) {
      setLoading(false);
      return err;
    }
  };

  useEffect(() => {
    getParkingType();
    //eslint-disable-next-line
  }, []);

  // COMPLIMENTARY REPORTS HANDLE SUBMIT
  const complimentaryHandleSubmit = async () => {
    const bodyData = {
      fromDate: moment(complimentaryFormValues.fromDate).format("YYYY-MM-DD"),
      toDate: moment(complimentaryFormValues.toDate).format("YYYY-MM-DD"),
      tenantId: tenantId,
      locationId: locationId,
      parkingType: {
        id: complimentaryFormValues.parkingTypeId,
      },
    };

    await getComplimentaryReport(bodyData);
  };

  // COMPLIMENTARY REPORTS
  const complimentaryReportsHandleChange = (e) => {
    setComplimentaryFormValues({
      ...complimentaryFormValues,
      [e.target.name]: e.target.value,
    });
  };

  return {
    loading,
    columns,
    toDateTime,
    fromDateTime,
    parkingTypeList,
    complimentaryFormValues,
    complementaryReportDetails,
    handleInputChange,
    handleToDateTimeChange,
    handleFromDateTimeChange,
    complimentaryHandleSubmit,
    complimentaryReportsHandleChange,
  };
};
