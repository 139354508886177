import { useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import { Box, Button, Typography } from "@mui/material";
import {
  EXTRA_CHARGES_DATA_TABLE,
  CREATE_EXTRA_CHARGES,
  STATUS_INACTIVE,
  STATUS_ACTIVE,
  EXTRA_CHARGES_UPDATE,
} from "../../../services/apiUrls";
import axios from "axios";

export function useExtraCharges() {
  const [open, setOpen] = useState(false);

  const [search, setSearch] = useState("");

  const [extraChargesTableDataList, setExtraChargesTableDataList] = useState(
    []
  );

  const [toast, setToast] = useState({ show: false, type: "", messsege: "" });

  const [formValue, setFormValue] = useState({
    name: "",
    amount: "",
    remarks: "",
    id: "",
    active: "false",
  });

  const columns = [
    {
      field: "No.",
      headerName: "NO.",
      width: 65,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "name",
      headerName: "NAME",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "amount",
      headerName: "AMOUNT",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },

    {
      field: "remarks",
      headerName: "REMARKS",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "deleted",
      headerName: "STATUS",
      flex: 1,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <Box>
            <Button>
              {params?.row?.deleted === false ? (
                <Button
                  variant="contained"
                  size="small"
                  color="success"
                  sx={{ width: "2rem" }}
                  onClick={() => {
                    statusInactive(params.row.id);
                  }}
                >
                  <Typography sx={{ fontSize: "10px" }}>Active</Typography>
                </Button>
              ) : (
                <Button
                  variant="contained"
                  size="small"
                  color="error"
                  sx={{ width: "2rem" }}
                  onClick={() => {
                    statusActive(params.row.id);
                  }}
                >
                  <Typography sx={{ fontSize: "10px" }}>Inactive</Typography>
                </Button>
              )}
            </Button>
          </Box>
        );
      },
    },
    {
      field: "action",
      headerName: "ACTION",
      flex: 1,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <EditIcon
            sx={{ cursor: "pointer" }}
            onClick={() => {
              setOpen(true);
              window.scrollTo(0, 0);
              setFormValue(params.row);
            }}
          />
        );
      },
    },
  ];

  // EXTRA CHARGES DATA TABLE
  const extraChargesTableData = async () => {
    try {
      const resp = await axios
        .get(`${EXTRA_CHARGES_DATA_TABLE}`)
        .then((res) => {
          setExtraChargesTableDataList(res.data);
          return res;
        });
      return resp;
    } catch (err) {
      return err;
    }
  };

  // STATUS INACTIVE
  const statusInactive = async (id) => {
    try {
      const resp = await axios({
        method: "DELETE",
        url: `${STATUS_INACTIVE}${id}`,
      }).then((res) => {
        extraChargesTableData();
        return res;
      });
      return resp;
    } catch (err) {
      return err;
    }
  };

  // STATUS ACTIVE
  const statusActive = async (id) => {
    try {
      const resp = await axios({
        method: "DELETE",
        url: `${STATUS_ACTIVE}${id}`,
      }).then((res) => {
        extraChargesTableData();
        return res;
      });
      return resp;
    } catch (err) {
      return err;
    }
  };

  // CREATE EXTRA CHARGES
  const createExtraCharges = async (bodyData) => {
    try {
      const resp = await axios
        .post(`${CREATE_EXTRA_CHARGES}`, bodyData)
        .then((res) => {
          return res;
        });
      return resp;
    } catch (err) {
      return err;
    }
  };

  // EXTRA CHARGES UPDATE
  const extraChargesUpdate = async (bodyData) => {
    try {
      const resp = await axios({
        method: "PUT",
        url: EXTRA_CHARGES_UPDATE,
        data: bodyData,
      }).then((res) => {
        extraChargesTableData();
        return res;
      });
      return resp;
    } catch (err) {
      return err;
    }
  };

  // EXTRA CHARGES HANDLE SUBMIT
  const extraChargesHandleSubmit = async () => {
    const bodyData = {
      name: formValue.name,
      amount: formValue.amount,
      remarks: formValue.remarks,
      id: formValue.id,
      active: formValue.active,
    };

    let api_res_data;

    if (!formValue.id) {
      api_res_data = await createExtraCharges(bodyData);
    } else {
      api_res_data = await extraChargesUpdate(bodyData);
    }
    if (api_res_data?.status === 200) {
      setToast({
        ...toast,
        show: true,
        type: "success",
        messsege: "Extra Charges Added Successfully",
      });
      handleClose();
    }
  };

  useEffect(() => {
    if (toast.show === true) {
      const interval = setInterval(() => {
        setToast({ ...toast, show: false });
      }, 1000);
      return () => clearInterval(interval);
    }
    //eslint-disable-next-line
  }, [toast.show]);

  const handleInputChange = (e) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
  };

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const handleCheckBox = (e) => {
    if (e.target.checked) {
      setFormValue({
        ...formValue,
        [e.target.name]: true,
      });
    } else {
      setFormValue({
        ...formValue,
        [e.target.name]: false,
      });
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    handleClear();
  };

  const handleClear = () => {
    setFormValue({
      name: "",
      amount: "",
      remarks: "",
      id: "",
      active: "false",
    });
  };

  const isDisabled =
    formValue?.name !== "" &&
    formValue?.amount !== "" &&
    formValue?.active !== "";

  useEffect(() => {
    extraChargesTableData();
  }, []);

  return {
    open,
    search,
    columns,
    formValue,
    isDisabled,
    handleClose,
    handleCheckBox,
    handleClickOpen,
    handleInputChange,
    handleSearchChange,
    extraChargesTableDataList,
    extraChargesHandleSubmit,
    toast,
    // ExtraChargesFilterDatas
  };
}
