import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import useVehicleType from "../features/entry-exit/hooks/useVehicleType";

const RequireAuth = ({ children }) => {
  let token = localStorage.getItem("token");
  let userType = localStorage.getItem("userType");

  const { twoWheelerId, fourWheelerId } = useVehicleType();

  let location = useLocation();

  const ptotectedPathsForAdmin = [
    "/dashboard",
    "/cashcollect",
    "/cashcollect/view/CASH",
    "/cashcollect/view/UPI",
    "/cashcollect/view/CARD",
    "/transaction",
    "/transaction/view",
    "/shift-master",
    "/entrybooth",
    "/shiftmaster/assign",
    "/reports",
    "/floor",
    "/block",
    "/fee",
    "/slot",
    "/attendence",
    "/collections",
    "/vehicle",
    "/parkingtype",
    "/paymentmode",
    "/location-master",
    "/staffonboard",
    "/shiftmaster",
    "/staffonboarding",
    "/extra-charges",
    "/profile-settings",
    "/shift-type",
    `/cars-refund-report/${fourWheelerId}`,
    `/bikes-refund-report/${twoWheelerId}`,
    `/settlement-report`,
    `/cars`,
    `/bikes`,
  ];

  if (
    userType !== "ADMIN" &&
    (location?.pathname === "/dashboard" ||
      location?.pathname === "/cashcollect" ||
      location?.pathname === "/cashcollect/view/CASH" ||
      location?.pathname === "/cashcollect/view/UPI" ||
      location?.pathname === "/cashcollect/view/CARD" ||
      location?.pathname === "/transaction" ||
      location?.pathname === "/transaction/view" ||
      location?.pathname === "/shift-master" ||
      location?.pathname === "/shiftmaster/assign" ||
      location?.pathname === "/entrybooth" ||
      location?.pathname === "/reports" ||
      location?.pathname === "/floor" ||
      location?.pathname === "/block" ||
      location?.pathname === "/fee" ||
      location?.pathname === "/slot" ||
      location?.pathname === "/attendence" ||
      location?.pathname === "/collections" ||
      location?.pathname === "/vehicle" ||
      location?.pathname === "/parkingtype" ||
      location?.pathname === "/paymentmode" ||
      location?.pathname === "/location-master" ||
      location?.pathname === "/staffonboard" ||
      location?.pathname === "/shiftmaster" ||
      location?.pathname === "/staffonboarding" ||
      location?.pathname === "/extra-charges" ||
      location?.pathname === "/profile-settings" ||
      location?.pathname === "/settlement-report" ||
      location?.pathname ===
        `/cars-refund-report/d77be835-f20a-4294-a177-7791e40740db` ||
      location?.pathname === `/bikes-refund-report/${twoWheelerId}` ||
      location?.pathname === "/shift-type")
  ) {
    return <Navigate to="/" replace />;
  }

  if (
    userType !== "USER" &&
    !ptotectedPathsForAdmin.includes(location?.pathname)
  ) {
    return <Navigate to="/" replace />;
  }

  if (!token) {
    return <Navigate to="/" replace />;
  }
  return <>{children}</>;
};

export default RequireAuth;
