import { GET_BASE_FEES } from "../services/apiUrls";
import { get } from "../services/service";

export const fetchAllBaseFees = async (locationId, tenantId) => {
  let response = await get(
    GET_BASE_FEES.replace("{tenantId}", tenantId).replace(
      "{locationId}",
      locationId
    )
  );
  return response;
};
