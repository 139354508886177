import {
  Box,
  Button,
  TextField,
  Typography,
  InputAdornment,
} from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { useShift } from "./hooks/useShift";
import { Search } from "@mui/icons-material";
import ShiftForm from "./components/ShiftForm";
import ShiftTable from "./components/ShiftTable";
import MainLayout from "../../../../containers/MainLayout";
import AddBoxRoundedIcon from "@mui/icons-material/AddBoxRounded";

const ShiftPage = () => {
  const {
    step,
    open,
    setStep,
    shiftin,
    shiftout,
    formValue,
    setShiftIn,
    setShiftOut,
    handleClose,
    setFormValue,
    shiftTypeData,
    handleCheckBox,
    handleClickOpen,
    ShiftTypeColumns,
    handleInputChange,
    handleSearchChange,
    handleShiftCreateSubmit,
  } = useShift();

  return (
    <MainLayout>
      <Typography className="text-[#252F40] !mb-2">
        <Link to="/dashboard" className="no-underline">
          <span className="text-[#8C8C8C]">Masters / Shift Management / </span>
        </Link>
        Shift
      </Typography>
      <Box
        sx={{
          boxShadow: " 0px 5px 15px -2px rgba(0, 0, 0, 0.12)",
          borderRadius: "2rem",
          width: "100%",
        }}
      >
        <Box className="flex justify-between px-3 py-2 items-center bg-[white]">
          <Box display="flex" alignItems="center" m={1}>
            <Typography className="text-[#576782]">Create Shift</Typography>
          </Box>
          <Box className="flex gap-6">
            <Box alignItems="center">
              <TextField
                label="Search"
                onChange={handleSearchChange}
                size="small"
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Search />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>

            <Button
              variant="contained"
              size="small"
              onClick={handleClickOpen}
              className="!bg-[#3A416F] gap-2"
              sx={{ textTransform: "none" }}
            >
              Create <AddBoxRoundedIcon />
            </Button>
          </Box>

          <ShiftForm
            open={open}
            step={step}
            setStep={setStep}
            shiftin={shiftin}
            shiftout={shiftout}
            formValue={formValue}
            setShiftIn={setShiftIn}
            setShiftOut={setShiftOut}
            handleClose={handleClose}
            setFormValue={setFormValue}
            handleCheckBox={handleCheckBox}
            handleInputChange={handleInputChange}
            handleShiftCreateSubmit={handleShiftCreateSubmit}
          />
        </Box>
        <ShiftTable shiftTypeData={shiftTypeData} ShiftTypeColumns={ShiftTypeColumns} />
      </Box>
    </MainLayout>
  );
};

export default ShiftPage;
