import React from "react";
import { useState } from "react";
import MainLayout from "../../containers/MainLayout";
import {
  Box,
  Button,
  Container,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  InputAdornment,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Stack } from "@mui/system";
import { Search } from "@mui/icons-material";
import AddBoxRoundedIcon from "@mui/icons-material/AddBoxRounded";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";

const Reports = () => {
  const [open, setOpen] = useState(false);
  const [searchValue, setSearchvalue] = useState("");
  const [formData, setFormData] = useState({
    id: "",
    vehicletype: "",
    parkingtype: "",
    feetype: "",
    fee: "",
    description: "",
    status: "",
  });

  const [tData, setTData] = useState([
    {
      id: 1,
      vehicletype: "2 Wheeler",
      parkingtype: "Regular",
      feetype: "Base Fee",
      fee: "20",
      description: "2 Wheeler Base Fee",
      status: "ACTIVE",
    },
    {
      id: 2,
      vehicletype: "2 Wheeler",
      parkingtype: "Premium",
      feetype: "Per Hour",
      fee: "10",
      description: "2 Wheeler Hourly Fee",
      status: "ACTIVE",
    },
    {
      id: 3,
      vehicletype: "4 Wheeler",
      parkingtype: "Regular",
      feetype: "Base Fee",
      fee: "5",
      description: "4 Wheeler Hourly Fee",
      status: "INACTIVE",
    },
    {
      id: 4,
      vehicletype: "4 Wheeler",
      parkingtype: "premium",
      feetype: "Base Fee",
      fee: "5",
      description: "4 Wheeler Hourly Fee",
      status: "ACTIVE",
    },
  ]);

  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSearchChange = (e) => {
    setSearchvalue(e.target.value);
  };

  const handleSave = () => {
    const newRecord = {
      id: tData.length + 1,
      vehicletype: formData.vehicletype,
      parkingtype: formData.parkingtype,
      feetype: formData.feetype,
      fee: formData.fee,
      description: formData.description,
      status: formData.status,
    };
    setTData([...tData, newRecord]);
    setFormData({
      id: "",
      vehicletype: "",
      parkingtype: "",
      feetype: "",
      fee: "",
      description: "",
      status: "",
    });
    setOpen(false);
  };

  const toggleStatus = (id) => {
    const updatedData = tData.map((row) =>
      row.id === id
        ? { ...row, status: row.status === "ACTIVE" ? "INACTIVE" : "ACTIVE" }
        : row
    );
    setTData(updatedData);
  };

  const columns = [
    {
      field: "id",
      headerName: "NO",
      headerClassName: "super-app-theme--header",
      flex: 1,
    },
    {
      field: "vehicletype",
      headerName: "VEHICLE TYPE",
      headerClassName: "super-app-theme--header",
      flex: 1,
    },
    {
      field: "parkingtype",
      headerName: "PARKING TYPE",
      headerClassName: "super-app-theme--header",
      flex: 1,
    },
    {
      field: "feetype",
      headerName: "FEE TYPE",
      headerClassName: "super-app-theme--header",
      flex: 1,
    },
    {
      field: "fee",
      headerName: "FEE ",
      headerClassName: "super-app-theme--header",
      flex: 1,
    },

    {
      field: "description",
      headerName: "DESCRIPTION",
      headerClassName: "super-app-theme--header",
      flex: 1,
    },
    {
      field: "active",
      headerName: "STATUS",
      flex: 1,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <Box display={"flex"} paddingRight={1}>
          <Button
            onClick={() => {
              toggleStatus(params.row.id);
            }}
          >
            {params.row.status === "ACTIVE" ? (
              <Button
                variant="contained"
                size="small"
                color="success"
                sx={{ width: "2rem" }}
              >
                <Typography sx={{ fontSize: "10px" }}>Active</Typography>
              </Button>
            ) : (
              <Button
                variant="contained"
                size="small"
                color="error"
                sx={{ width: "2rem" }}
              >
                <Typography sx={{ fontSize: "10px" }}>Inactive</Typography>
              </Button>
            )}
          </Button>
        </Box>
      ),
    },
  ];

  // const activeButtton = () => {

  //     tData.status ==="ACTIVE" ? (
  //       <Button style={{ backgroundColor: "green" }}>ACTIVE</Button>
  //     ) : (
  //       <Button style={{ backgroundColor: "red" }}>INACTIVE</Button>
  //     );
  // };

  const rows = tData.map((rowData) => ({
    id: rowData.id,
    vehicletype: rowData.vehicletype,
    parkingtype: rowData.parkingtype,
    feetype: rowData.feetype,
    fee: rowData.fee,
    description: rowData.description,
    status: rowData.status,
  }));

  //   const filteredRows = tData.filter((row) =>
  //   row.description.toLowerCase().includes(searchValue.toLowerCase())
  // );

  return (
    <>
      <MainLayout>
        <Container>
          <Box
            sx={{
              boxShadow: " 0px 5px 15px -2px rgba(0, 0, 0, 0.12)",
              borderRadius: "2rem",
              width: "100%",
            }}
          >
            <Box
              backgroundColor={"white"}
              alignItems={"center"}
              className="flex justify-between px-3 py-2 "
            >
              <Box display="flex" p={1} alignItems="center">
                <TextField
                  label="Search"
                  value={searchValue}
                  onChange={handleSearchChange}
                  size="small"
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Search />
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>

              <Button
                variant="contained"
                onClick={handleClickOpen}
                sx={{
                  backgroundColor: "#3A416F",
                }}
              >
                Add <AddBoxRoundedIcon />
              </Button>

              <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <Box
                  display={"flex"}
                  flexDirection={"row"}
                  justifyContent={"space-between"}
                >
                  <DialogTitle>Fee</DialogTitle>

                  <Button onClick={handleClose}>
                    <CancelRoundedIcon
                      sx={{
                        backgroundColor: "white",
                        color: "#344767",

                        outline: "none",
                      }}
                    />{" "}
                  </Button>
                </Box>

                <DialogContent>
                  <Stack
                    spacing={4}
                    paddingLeft={4}
                    paddingRight={4}
                    paddingBottom={2}
                  >
                    <Stack direction="row" spacing={2}>
                      <TextField
                        name="id"
                        type="text"
                        id="outlined-read-only-input"
                        label="Id"
                        size="small"
                        onChange={handleChange}
                      />
                      <TextField
                        required
                        name="vehicletype"
                        type="text"
                        id="outlined-required"
                        label="Vehicle Type"
                        size="small"
                        value={formData.vehicletype}
                        onChange={handleChange}
                      />
                    </Stack>
                    <Stack direction="row" spacing={2}>
                      <TextField
                        required
                        name="parkingtype"
                        type="text"
                        id="outlined-required"
                        label="Parking type"
                        size="small"
                        value={formData.parkingtype}
                        onChange={handleChange}
                      />
                      <TextField
                        required
                        name="feetype"
                        type="text"
                        id="outlined-required"
                        label="Fee Type"
                        size="small"
                        value={formData.feetype}
                        onChange={handleChange}
                      />
                    </Stack>
                    <Stack direction="row" spacing={2}>
                      <TextField
                        required
                        name="fee"
                        type="text"
                        id="outlined-required"
                        label="Fee Amount"
                        size="small"
                        value={formData.fee}
                        onChange={handleChange}
                      />
                      <TextField
                        required
                        name="status"
                        type="text"
                        id="outlined-required"
                        label="Status"
                        size="small"
                        value={formData.status}
                        onChange={handleChange}
                      />
                    </Stack>
                    <Button
                      onClick={handleSave}
                      sx={{
                        backgroundColor: "#3A416F",
                        color: "white",
                        "&:hover": {
                          color: "white",
                          backgroundColor: "black",
                        },
                      }}
                    >
                      OK
                    </Button>
                  </Stack>
                </DialogContent>
              </Dialog>
            </Box>

            <DataGrid
              rows={rows}
              columns={columns}
              pageSize={10}
              rowsPerPage={[10]}
              sx={{
                height: 400,
                fontSize: "small",
                backgroundColor: "white",
                "& .super-app-theme--header": {
                  backgroundColor: "#576782",
                  fontWeight: "bold",
                  color: "white",
                },
              }}
            />
          </Box>
        </Container>
      </MainLayout>
    </>
  );
};

export default Reports;
