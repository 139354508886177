import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Grid,
  Box,
  Typography,
  IconButton,
  useMediaQuery,
  Badge,
  Popover,
  MenuItem,
  Divider,
} from "@mui/material";
import QueryBuilderIcon from "@mui/icons-material/QueryBuilder";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import "../../../assets/css/style.css";
import logo from "../../../assets/images/dashboard-logo.svg";
import HambergerManu from "../../../assets/images/hamburgermenu.svg";
import { useHeader } from "../../hooks/useHeader";
import { Stack } from "@mui/system";

import { useTheme } from "styled-components";
import { AccountCircle, CalendarToday, Settings } from "@mui/icons-material";
import ChangePasswordChildren from "../ChangePasswordChildren";
import PopUp from "../PopUp";
import SuccessPopupChildren from "../SuccessPopupChildren";
import { useNavigate } from "react-router-dom";

const Header = ({ setIsTrue, isTrue }) => {
  const { currentTime, currentDate } = useHeader();
  const theme = useTheme();
  const navigate = useNavigate();
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));
  const isMd = useMediaQuery(theme.breakpoints.down("md"));
  const [popOpen, setPopOpen] = useState(null);
  const [settingsOpen, setSettingsOpen] = useState(null);
  const [notificationsOpen, setNotificationsOpen] = useState(false);

  const [openChangePassword, setOpenChangePassword] = useState(false);
  const [openSuccessPopup, setOpenSuccessPopup] = useState(false);

  const handleOpenChangePassword = () => {
    setOpenChangePassword(true);
  };

  const handleCloseChangePassword = () => {
    setOpenChangePassword(false);
  };

  const handleOpenSuccessPopup = () => {
    setOpenSuccessPopup(true);
  };

  const handleCloseSuccessPopup = () => {
    setOpenSuccessPopup(false);
  };

  const changePasswordChildren = (
    <ChangePasswordChildren
      handleOpenSuccessPopup={handleOpenSuccessPopup}
      handleCloseChangePassword={handleCloseChangePassword}
    />
  );

  const successPopupChildren = <SuccessPopupChildren />;

  return (
    <>
      <AppBar className="bg-[#F8F9FA] !shadow-none ">
        <Toolbar
          className="flex justify-end"
          sx={{ backgroundColor: "#f2f2f2" }}
        >
          <Box className="flex !justify-between !w-[100%] font-inter font-bold  text-center items-center text-gray-700">
            <Grid className="flex !gap-4 items-center">
              <Box>
                <IconButton
                  onClick={() => {
                    setIsTrue(!isTrue);
                    // setOpen(!open);
                  }}
                >
                  <img
                    src={HambergerManu}
                    width={30}
                    height="auto"
                    alt="menu"
                  />
                </IconButton>
              </Box>
              <img
                src={logo}
                alt="logo"
                className="h-[2.2rem] hidden sm:block"
              />
            </Grid>

            {!isSm && !isMd ? (
              <Stack
                direction="row"
                className="flex justify-center items-start"
              ></Stack>
            ) : (
              ""
            )}

            {/* Header Section */}
            <Grid className="flex items-center gap-4">
              {/* Time */}
              <Typography className="!text-[#000000] !text-[12px]">Version 1.04</Typography>
              <Grid
                item
                className="!h-[2.2rem] flex justify-start items-center px-2 gap-2 !text-[#3A416F] !font-bold bg-[white] rounded-[5px] !font-inter"
              >
                <QueryBuilderIcon />
                <Typography
                  sx={{
                    width: "129px",
                    height: "17px",
                    left: "515px",
                    top: "15px",

                    fontStyle: "normal",
                    fontWeight: 700,
                    fontSize: "14px",
                    lineHeight: "17px",
                    display: "flex",
                    alignItems: "center",
                    textAlign: "center",
                    textTransform: "uppercase",
                    color: "#3A416F",
                  }}
                >
                  {currentTime}
                </Typography>
              </Grid>

              {/* Date */}
              <Grid
                item
                className="!h-[2.2rem] flex justify-start items-center px-2 gap-2 !text-[#3A416F] rounded-[5px]  bg-[white]"
              >
                <CalendarToday />
                <Typography
                  variant="subtitle2"
                  sx={{
                    width: "129px",
                    height: "17px",
                    left: "515px",
                    top: "15px",
                    fontStyle: "normal",
                    fontWeight: 700,
                    fontSize: "14px",
                    lineHeight: "17px",
                    display: "flex",
                    alignItems: "center",
                    textAlign: "center",
                    textTransform: "uppercase",
                    color: "#3A416F",
                  }}
                >
                  {currentDate}
                </Typography>
              </Grid>

              {!isSm && !isMd ? (
                <Box className="flex gap-1 items-center justify-center text-gray-400">
                  {/* Settings icon */}

                  <IconButton onClick={() => setSettingsOpen(true)}>
                    <Settings />
                  </IconButton>

                  {/* Notification Icon */}
                  <IconButton
                    onClick={() => setNotificationsOpen(true)}
                    sx={{ ml: -1.2 }}
                  >
                    <Badge badgeContent={0} color="error" fontSize="small">
                      <NotificationsNoneIcon />
                    </Badge>
                  </IconButton>

                  {/* Gokulam Galleria */}

                  <IconButton
                    onClick={(event) => setPopOpen(event.currentTarget)}
                  >
                    <AccountCircle />
                  </IconButton>

                  {/* Settings Popover */}
                  <Popover
                    disableScrollLock
                    open={Boolean(settingsOpen)}
                    anchorEl={settingsOpen}
                    onClose={() => setSettingsOpen(null)}
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                    transformOrigin={{ vertical: "top" }}
                    PaperProps={{
                      sx: {
                        p: 0,
                        mt: 5,
                        ml: -2.3,
                        width: 180,
                        msOverflowY: "hidden",
                        "& .MuiMenuItem-root": {
                          typography: "body2",
                          borderRadius: 0.75,
                          width: 300,
                        },
                      },
                    }}
                  >
                    <Box sx={{ my: 1.5, px: 2.5 }}>
                      <Typography
                        variant="body2"
                        sx={{ color: "text.secondary" }}
                        noWrap
                      >
                        Settings
                      </Typography>
                    </Box>

                    <Divider sx={{ borderStyle: "dashed" }} />

                    <MenuItem sx={{ m: 1 }}>Privacy Settings</MenuItem>
                    <MenuItem sx={{ m: 1 }}>Security Settings</MenuItem>
                    <MenuItem sx={{ m: 1 }}>Help</MenuItem>
                  </Popover>

                  {/* Notification popoover */}
                  <Popover
                    disableScrollLock
                    open={Boolean(notificationsOpen)}
                    anchorEl={notificationsOpen}
                    onClose={() => setNotificationsOpen(null)}
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                    transformOrigin={{ vertical: "top", horizontal: "right" }}
                    PaperProps={{
                      sx: {
                        p: 0,
                        mt: 5,
                        ml: -2.3,
                        width: 180,
                        msOverflowY: "hidden",
                        "& .MuiMenuItem-root": {
                          typography: "body2",
                          borderRadius: 0.75,
                          width: 300,
                        },
                      },
                    }}
                  >
                    <Box sx={{ my: 1.5, px: 2.5 }}>
                      <Typography
                        variant="body2"
                        sx={{ color: "text.secondary" }}
                      >
                        No notifications
                      </Typography>
                    </Box>
                  </Popover>

                  {/* Account Popover */}
                  <Popover
                    disableScrollLock
                    open={Boolean(popOpen)}
                    anchorEl={popOpen}
                    onClose={() => setPopOpen(null)}
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    transformOrigin={{ vertical: "top", horizontal: "right" }}
                    PaperProps={{
                      sx: {
                        p: 0,
                        mt: 1.5,
                        ml: 0.75,
                        width: 180,
                        msOverflowY: "hidden",
                        "& .MuiMenuItem-root": {
                          typography: "body2",
                          borderRadius: 0.75,
                          width: 300,
                        },
                      },
                    }}
                  >
                    <Box sx={{ my: 1.5, px: 2.5 }}>
                      <Typography
                        variant="body2"
                        sx={{ color: "text.secondary" }}
                        noWrap
                      >
                        {localStorage.getItem("firstName")}
                      </Typography>
                    </Box>

                    <Divider sx={{ borderStyle: "dashed" }} />
                    <MenuItem sx={{ m: 1 }} onClick={handleOpenChangePassword}>
                      Change password
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        localStorage.clear();
                        navigate("/");
                      }}
                      sx={{ m: 1 }}
                    >
                      Logout
                    </MenuItem>
                  </Popover>
                </Box>
              ) : (
                ""
              )}
            </Grid>
          </Box>
        </Toolbar>
      </AppBar>
      <PopUp
        open={openChangePassword}
        handleClose={handleCloseChangePassword}
        popUpChildren={changePasswordChildren}
      />
      <PopUp
        open={openSuccessPopup}
        handleClose={handleCloseSuccessPopup}
        popUpChildren={successPopupChildren}
      />
    </>
  );
};

export default Header;
