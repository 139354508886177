import { useEffect, useState } from "react";
import { GET_TODAY_DASHBOARD_DETAILS } from "../../../services/apiUrls";
import { get } from "../../../services/service";
import useVehicleType from "../../entry-exit/hooks/useVehicleType";
import {
  fetchMonthlyGraphByParkingType,
  fetchMonthlyGraphByVehicleType,
  fetchWeeklyGraphByParkingType,
  fetchWeeklyGraphByVehicleType,
} from "../../../api/graph";
import moment from "moment";
import { fetchParkingTypes } from "../../../api/parkingType";

export function useHomePage() {
  const tenantId = localStorage.getItem("tenantId");
  const locationId = localStorage.getItem("locationId");

  const currentYear = moment().format("YYYY");

  const [dashboardData, setDashboardData] = useState();

  const [Loading, setLoading] = useState(false);

  const { twoWheelerId, fourWheelerId } = useVehicleType();

  const [parkingTypes, setParkingTypes] = useState([]);

  const [monthlyGraphByCarDetails, setMonthlyGraphByCarDetails] = useState([]);
  const [monthlyGraphByBikeDetails, setMonthlyGraphByBikeDetails] = useState(
    []
  );

  const [weeklyGraphByCarDetails, setWeeklyGraphByCarDetails] = useState([]);
  const [weeklyGraphByBikeDetails, setWeeklyGraphByBikeDetails] = useState([]);

  const [
    monthlyGraphByParkingTypeDetails,
    setMonthlyGraphByParkingTypeDetails,
  ] = useState([]);

  const [weeklyGraphByParkingTypeDetails, setWeeklyGraphByParkingTypeDetails] =
    useState([]);

  const [parkingType, setParkingType] = useState({
    parkingTypeId: "",
    parkingTypeName: "",
  });

  const today = moment().format("YYYY-MM-DD");
  const sevenDaysBack = moment().subtract(7, "days").format("YYYY-MM-DD");

  const [formValues, setFormValues] = useState({
    fromDate: sevenDaysBack,
    toDate: today,
  });

  const sevenDaysAfter = moment(formValues.fromDate)
    .add(7, "days")
    .format("YYYY-MM-DD");

  const handleSelectOnChange = (e) => {
    const str = e.target.value;
    // eslint-disable-next-line
    const [id, name] = str.split(",");
    setParkingType({
      ...parkingType,
      parkingTypeId: str,
      parkingTypeName: name,
    });
  };

  // To fetch Bike data
  const fetchDashboardData = async () => {
    setLoading(true);
    const url = `${GET_TODAY_DASHBOARD_DETAILS}`;
    const resp = await get(
      url.replace("{tenantId}", tenantId).replace("{locationId}", locationId)
    );
    if (resp) {
      setDashboardData(resp);
      setLoading(false);
    }
  };

  const getMonthlyGraphByCar = async () => {
    let response = await fetchMonthlyGraphByVehicleType(
      tenantId,
      locationId,
      currentYear,
      fourWheelerId
    );
    setMonthlyGraphByCarDetails(response);
    return response;
  };

  const getMonthlyGraphByBike = async () => {
    let response = await fetchMonthlyGraphByVehicleType(
      tenantId,
      locationId,
      currentYear,
      twoWheelerId
    );
    setMonthlyGraphByBikeDetails(response);
    return response;
  };

  const getMonthlyGraphByParkingType = async () => {
    const [id] = parkingType?.parkingTypeId.split(",");
    let response = await fetchMonthlyGraphByParkingType(
      tenantId,
      locationId,
      currentYear,
      id
    );
    setMonthlyGraphByParkingTypeDetails(response);
    return response;
  };

  const formattedFromDate = moment(formValues.fromDate).format("YYYY/MM/DD");
  const formattedToDate = moment(formValues.toDate).format("YYYY/MM/DD");

  const getWeeklyGraphByCar = async () => {
    let response = await fetchWeeklyGraphByVehicleType(
      tenantId,
      locationId,
      fourWheelerId,
      formattedFromDate,
      formattedToDate
    );
    setWeeklyGraphByCarDetails(response);
    return response;
  };

  const getWeeklyGraphByBike = async () => {
    let response = await fetchWeeklyGraphByVehicleType(
      tenantId,
      locationId,
      twoWheelerId,
      formattedFromDate,
      formattedToDate
    );
    setWeeklyGraphByBikeDetails(response);
    return response;
  };

  const getWeeklyGraphByParkingType = async () => {
    const [id] = parkingType.parkingTypeId.split(",");
    let response = await fetchWeeklyGraphByParkingType(
      tenantId,
      locationId,
      id,
      formattedFromDate,
      formattedToDate
    );
    setWeeklyGraphByParkingTypeDetails(response);
    return response;
  };

  const getAllParkingType = async () => {
    let response = await fetchParkingTypes(tenantId, locationId);
    if (response && response?.id !== "") {
      setParkingType({
        ...parkingType,
        parkingTypeId: `${response[0]?.id},${response[0]?.name}`,
        parkingTypeName: response[0]?.name,
      });
      setParkingTypes(response);
    }
  };

  /* eslint-disable */
  useEffect(() => {
    if (twoWheelerId || fourWheelerId) {
      fetchDashboardData();
      getMonthlyGraphByCar();
      getMonthlyGraphByBike();
    }
  }, [twoWheelerId, fourWheelerId]);

  useEffect(() => {
    if (twoWheelerId || fourWheelerId) {
      getWeeklyGraphByCar();
      getWeeklyGraphByBike();
    }
  }, [twoWheelerId, fourWheelerId, formValues.fromDate]);

  useEffect(() => {
    getAllParkingType();
  }, []);

  useEffect(() => {
    if (parkingType.parkingTypeId) {
      getMonthlyGraphByParkingType();
      getWeeklyGraphByParkingType();
    }
  }, [parkingType.parkingTypeId]);
  /* eslint-enable */

  return {
    today,
    parkingType,
    Loading,
    parkingTypes,
    formValues,
    dashboardData,
    sevenDaysAfter,
    weeklyGraphByCarDetails,
    weeklyGraphByBikeDetails,
    monthlyGraphByCarDetails,
    monthlyGraphByBikeDetails,
    weeklyGraphByParkingTypeDetails,
    monthlyGraphByParkingTypeDetails,
    setFormValues,
    handleSelectOnChange,
  };
}
