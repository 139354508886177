import { useState } from "react";

const useChangeDashboardImage = () => {
  const [open, setOpen] = useState(false);

  const [files, setFiles] = useState({
    logo: "",
  });

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };
  return {
    open,
    files,
    setFiles,
    handleOpen,
    handleClose,
  };
};

export default useChangeDashboardImage;
