import EditIcon from "@mui/icons-material/Edit";
import { useEffect, useState } from "react";
import {
  ADD_SHIFT_ASSIGN,
  GET_ALL_ACTIVE_SHIFT,
  GET_BY_SHIFT_ASSIGN,
  GET_ALL_SHIFT_TABLE_DATA,
} from "../../../../../services/apiUrls";
import { Put, get, post } from "../../../../../services/service";
import moment from "moment";
import { fetchActiveEmployees } from "../../../../../api/users";
import axios from "axios";

export function useShiftAssign() {
  const [open, setOpen] = useState(false);
  // const [search, setSearch] = useState("");

  const [value, setValue] = useState([]);

  // const [shiftAssign, setShiftAssign] = useState();

  const [shiftAssignData, setShiftAssignData] = useState([]);

  const [activeShift, setActiveShift] = useState([]);
  const [activeEmployee, setActiveEmployee] = useState([]);

  const date = new Date();
  const currentDate = moment(date.toLocaleDateString()).format("YYYY-MM-DD");

  const [formValue, setFormValue] = useState({
    id: "",
    employeeId: "",
    role: "",
    shiftType: "",
    fromDate: "",
    toDate: "",
    remarks: "",
    searchToDate: currentDate,
    searchFromDate: currentDate,
    employeeName: "",
    userId: "",
  });

  let tenantId = localStorage.getItem("tenantId");

  const ShiftAssigncolumns = [
    {
      field: "No",
      headerName: "NO",
      width: 65,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "id",
      headerName: "EMPLOYEE NAME",
      flex: 1,
      headerClassName: "super-app-theme--header",
      valueGetter: (params) => params?.row?.employee?.name,
    },
    {
      field: "role",
      headerName: "ROLE",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "shiftType",
      headerName: "SHIFT",
      flex: 1,
      headerClassName: "super-app-theme--header",
      valueGetter: (params) =>
        params?.row?.shiftType === null ? "---" : params?.row?.shiftType?.name,
    },
    {
      field: "fromDate",
      headerName: "FROM DATE",
      flex: 1,
      headerClassName: "super-app-theme--header",
      valueGetter: (params) => moment(params.row.fromDate).format("YYYY-MM-DD"),
    },
    {
      field: "toDate",
      headerName: "TO DATE",
      flex: 1,
      headerClassName: "super-app-theme--header",
      valueGetter: (params) =>
        params?.row?.toDate === null
          ? "---"
          : moment(params.row.toDate).format("YYYY-MM-DD"),
    },
    {
      field: "remarks",
      headerName: "REMARKS",
      flex: 1,
      headerClassName: "super-app-theme--header",
      valueGetter: (params) =>
        params.row.remarks === "" ? "---" : params.row.remarks,
      //
    },
    {
      field: "action",
      headerName: "ACTION",
      flex: 1,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <EditIcon
            sx={{ cursor: "pointer" }}
            onClick={() => {
              setOpen(true);
              window.scrollTo(0, 0);
              getByShiftAssign(params.id);
            }}
          />
        );
      },
    },
  ];

  const handleInputChange = (e) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
  };

  const handleSearchChange = (e) => {
    // setSearch(e.target.value);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    handleClear();
  };

  const handleClear = () => {
    setFormValue({
      employeeId: "",
      role: "",
      shiftType: "",
      fromDate: "",
      toDate: "",
      remarks: "",
    });
  };

  const handleOnChange = (event, newValue) => {
    setValue(newValue);
  };

  // GET DATA TABLE BY FROM AND TO DATE OR EMPLOYEE NAME
  const getTableData = async (userId) => {
    const data = {
      fromDate: formValue.searchToDate,
      toDate: formValue.searchFromDate,
      tenantId: tenantId,
      employeeId: userId,
    };
    try {
      const resp = await axios
        .post(GET_ALL_SHIFT_TABLE_DATA.replace("{tenantId}", tenantId), data)
        .then((res) => {
          setShiftAssignData(res.data);
          return res;
        });
      return resp;
    } catch (err) {
      return err;
    }
  };

  useEffect(() => {
    getTableData();
    //eslint-disable-next-line
  }, []);

  // useEffect(() => {
  //   if (formValue.userId) {
  //     getTableData();
  //   }
  // }, [formValue.userId]);

  // const shiftAssignData = shiftAssign?.filter(
  //   (item) =>
  //     item?.remarks?.toLowerCase().includes(search?.toLowerCase()) ||
  //     item?.shiftType?.toLowerCase().includes(search?.toLowerCase()) ||
  //     item?.role?.toLowerCase().includes(search?.toLocaleLowerCase())
  // );

  // const getAllShiftAssignData = async () => {
  //   const url = `${GET_SHIFT_ASSIGN_DATA.replace("{tenantId}", tenantId)}`;
  //   const resp = await get(url);
  //   if (resp && resp.id !== "") {
  //     setShiftAssign(resp);
  //   }
  // };

  const handleShiftAssignSubmit = async () => {
    if (formValue.id) {
      const url = `${ADD_SHIFT_ASSIGN.replace("{tenantId}", tenantId)}`;
      const data = {
        id: formValue.id,
        employeeId: formValue?.employeeId?.[0]?.id,
        role: formValue?.role,
        shiftType: {
          id: formValue?.shiftType,
        },
        fromDate: formValue?.fromDate,
        toDate: formValue?.toDate,
        remarks: formValue?.remarks,
      };
      const resp = await Put(url, data);
      if (resp && resp.id) {
        handleClose();
        getTableData();
      }
    } else {
      const url = `${ADD_SHIFT_ASSIGN.replace("{tenantId}", tenantId)}`;
      const data = [
        {
          employeeId: formValue?.employeeId?.[0]?.id,
          tenantId: tenantId,
          role: formValue?.role,
          shiftType: {
            id: formValue?.shiftType,
          },
          fromDate: formValue?.fromDate,
          toDate: formValue?.toDate,
          remarks: formValue?.remarks,
        },
      ];
      const resp = await post(url, data);
      if (resp && resp[0].id) {
        handleClose();
        getTableData();
      }
    }
  };

  const getByShiftAssign = async (id) => {
    const url = `${GET_BY_SHIFT_ASSIGN.replace("{tenantId}", tenantId)}/${id}`;
    const resp = await get(url);
    if (resp && resp.id) {
      setFormValue({
        ...formValue,
        id: resp.id,
        employeeId: resp?.employeeId,
        role: resp?.role,
        shiftType: resp?.shiftType?.id,
        fromDate: moment(resp?.fromDate).format("YYYY-MM-DD"),
        toDate: moment(resp?.toDate).format("YYYY-MM-DD"),
        remarks: resp?.remarks,
      });
    }
  };

  const getAllActiveEmployeeName = async () => {
    let response = await fetchActiveEmployees(tenantId);
    if (response && response[0]?.id) {
      setActiveEmployee(response);
    }
  };

  const getAllActiveShift = async () => {
    const url = `${GET_ALL_ACTIVE_SHIFT.replace("{tenantId", tenantId)}`;
    const resp = await get(url);
    if (resp && resp[0]?.id) {
      setActiveShift(resp);
    }
  };

  /*eslint-disable */
  useEffect(() => {
    getAllActiveShift();
    // getAllShiftAssignData();
    getAllActiveEmployeeName();
  }, []);
  /*eslint-enable */

  return {
    open,
    value,
    setOpen,
    setValue,
    formValue,
    handleClose,
    activeShift,
    setFormValue,
    handleOnChange,
    activeEmployee,
    shiftAssignData,
    handleClickOpen,
    handleInputChange,
    handleSearchChange,
    ShiftAssigncolumns,
    handleShiftAssignSubmit,
    getTableData,
  };
}
