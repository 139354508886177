import { Box, Dialog, DialogContent, Typography } from "@mui/material";
import React from "react";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";

const PopUp = ({ open, handleClose, popUpTitle, popUpChildren }) => {
  return (
    <>
      <Dialog fullWidth open={open} onClose={handleClose}>
        <Box
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"space-between"}
        >
          <Typography variant="subtitle1" className="!m-2">
            {popUpTitle}
          </Typography>

          <CancelRoundedIcon
            onClick={handleClose}
            className="bg-[white] !text-[#344767] !outline-none m-2 cursor-pointer"
          ></CancelRoundedIcon>
        </Box>

        <DialogContent>{popUpChildren}</DialogContent>
      </Dialog>
    </>
  );
};

export default PopUp;
