import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Search } from "@mui/icons-material";
import MainLayout from "../../../containers/MainLayout";
import {
  Box,
  Card,
  // CircularProgress,
  InputAdornment,
  Table,
  TableContainer,
  tableCellClasses,
  TableHead,
  TableRow,
  TableCell,
  TextField,
  Typography,
  Paper,
  TableBody,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useRefundReports } from "../hooks/useRefundReports";
import { Oval } from "react-loader-spinner";
import moment from "moment";
import useConvertTime from "../../reports/hook/useConvertTime";

const RefundReports = () => {
  const { state } = useLocation();
  const { convertPremiseTimeStamp } = useConvertTime();

  const {
    loading,
    formValues,
    filteredData,
    handleChange,
    handleSearchChange,
  } = useRefundReports();

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#576782",
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  return (
    <MainLayout>
      <Typography className="text-[#252F40] !mb-2">
        <Link to="/dashboard" className="no-underline">
          <span className="text-[#8C8C8C]">PARKezy / </span>
        </Link>
        {state?.vehicle === "car_dsh" ? "Cars" : "Bikes"} Refund Report
      </Typography>

      <Box
        sx={{
          boxShadow: " 0px 5px 15px -2px rgba(0, 0, 0, 0.12)",
          width: "100%",
        }}
      >
        <Box className="flex justify-between px-3 py-2 items-center rounded-t-lg bg-[white]">
          <Box className="flex gap-2">
            <TextField
              id="from-date"
              label="Date from"
              variant="outlined"
              type="date"
              size="small"
              name="fromDate"
              value={formValues.fromDate}
              defaultValue={formValues.fromDate}
              focused
              onChange={handleChange}
            />

            <TextField
              id="to-date"
              label="Date To"
              variant="outlined"
              type="date"
              focused
              name="toDate"
              size="small"
              value={formValues.toDate}
              defaultValue={formValues.toDate}
              onChange={handleChange}
            />
          </Box>

          <Box display="flex" p={1} alignItems="center">
            <TextField
              label="Search"
              onChange={handleSearchChange}
              size="small"
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Box>

        <Card>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 500 }}>
              <TableHead>
                <TableRow>
                  <StyledTableCell>No.</StyledTableCell>
                  <StyledTableCell align="center">Entry by</StyledTableCell>
                  <StyledTableCell align="center">Entry gate</StyledTableCell>
                  <StyledTableCell align="center">Vehicle type</StyledTableCell>
                  <StyledTableCell align="center">VRN No</StyledTableCell>
                  <StyledTableCell align="center">Entry time</StyledTableCell>
                  <StyledTableCell align="center">Exit time</StyledTableCell>
                  <StyledTableCell align="center">Duration</StyledTableCell>
                  <StyledTableCell align="center">Exit by</StyledTableCell>
                  <StyledTableCell align="center">Exit gate</StyledTableCell>
                  <StyledTableCell align="center">Parking type</StyledTableCell>
                  <StyledTableCell align="center">Payment mode</StyledTableCell>
                  <StyledTableCell align="center">Amount</StyledTableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {loading === true ? (
                  <TableCell colSpan={13}>
                    <Box className="flex flex-col items-center justify-center">
                      Loading please wait...
                      <Oval
                        height={20}
                        width={20}
                        color="#4fa94d"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                        ariaLabel="oval-loading"
                        secondaryColor="#4fa94d"
                        strokeWidth={2}
                        strokeWidthSecondary={2}
                      />
                    </Box>
                  </TableCell>
                ) : (
                  <>
                    {filteredData?.length > 0 ? (
                      <>
                        {filteredData?.map((row, id) => (
                          <TableRow key={row.id}>
                            <TableCell component="th" scope="row">
                              {1 + id}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {row?.entryBy?.name}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {row?.entryBooth?.name}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {row?.vehicleType?.name}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {row?.vrn}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {moment(row?.entryTime).format("HH:mm:ss")}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {moment(row?.exitTime).format("HH:mm:ss")}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {convertPremiseTimeStamp(row?.timeOnPremises)}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {row?.exitBy?.name}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {row?.exitBooth?.name}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {row?.parkingType?.name}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {row?.paymentMode}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {row?.parkingAmount}
                            </TableCell>
                          </TableRow>
                        ))}
                      </>
                    ) : (
                      <TableCell colSpan={13}>
                        <Typography className="flex items-center justify-center">
                          No data found!
                        </Typography>
                      </TableCell>
                    )}
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      </Box>
    </MainLayout>
  );
};

export default RefundReports;
