import React from "react";
import { useLottie } from "lottie-react";
import SuccessAnimation from "../../assets/lotties/98260-success.json";
import { Typography } from "@mui/material";

const SuccessPopupChildren = () => {
  const options = {
    animationData: SuccessAnimation,
    loop: false,
  };

  const { View } = useLottie(options);
  return (
    <div className="!flex flex-col !justify-center !items-center">
      <div className="!w-[130px]">{View}</div>
      <Typography variant="h6" className="text-center !mb-10">
        Change password successfully
      </Typography>
    </div>
  );
};

export default SuccessPopupChildren;
