import { useState } from "react";
import { useLocation } from "react-router-dom";

export const useNavSection = () => {
  const location = useLocation();
  const [shiftManagementCollapse, setShiftManagementCollapse] = useState(
    location.pathname === "/shiftmaster/assign" ||
      location.pathname === "/shiftmaster/history" ||
      location.pathname === "/shiftmaster"
      ? true
      : false
  );
  return { shiftManagementCollapse, setShiftManagementCollapse };
};
