import React from "react";
import ShiftTypeForm from "./ShiftTypeForm";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import { Box, Dialog, DialogTitle, DialogContent } from "@mui/material";

const ShiftForm = ({
  open,
  step,
  setStep,
  shiftin,
  shiftout,
  formValue,
  setShiftIn,
  setShiftOut,
  handleClose,
  setFormValue,
  handleCheckBox,
  handleInputChange,
  handleShiftCreateSubmit,
}) => {
  return (
    <>
      <Dialog
        fullWidth
        open={open}
        onClose={handleClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "80%",
            },
          },
        }}
      >
        <Box
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"space-between"}
        >
          <DialogTitle className="text-[#312C41] !text-[16px] !font-semibold">
            Create Shift
          </DialogTitle>

          <CancelRoundedIcon
            onClick={handleClose}
            className="bg-[white] !text-[#344767] !text-[1.7rem] !outline-none p-4"
          />
        </Box>

        <DialogContent className="flex justify-center">
          <ShiftTypeForm
            step={step}
            setStep={setStep}
            shiftin={shiftin}
            shiftout={shiftout}
            formValue={formValue}
            setShiftIn={setShiftIn}
            setShiftOut={setShiftOut}
            setFormValue={setFormValue}
            handleCheckBox={handleCheckBox}
            handleInputChange={handleInputChange}
            handleShiftCreateSubmit={handleShiftCreateSubmit}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ShiftForm;
