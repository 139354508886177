import React from "react";
import {
  Card,
  Paper,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  TableContainer,
  tableCellClasses,
  Typography,
  Pagination,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import moment from "moment";
import useConvertTime from "../hook/useConvertTime";
import { Oval } from "react-loader-spinner";
import { Box } from "@mui/system";

const CashCollectDetailReportTable = ({
  FilterCashCollecDetailtData,
  cashCollectDetailsReportloader,
  setCurrentPage,
  currentPage,
  pageNumbers
}) => {
  const data = FilterCashCollecDetailtData ?? [];
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#576782",
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const { convertPremiseTimeStamp } = useConvertTime();

  return (
    <Card>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 500 }}>
          <TableHead>
            <TableRow>
              <StyledTableCell>No.</StyledTableCell>
              <StyledTableCell align="center">Enter By</StyledTableCell>
              <StyledTableCell align="center">Enter Type</StyledTableCell>
              <StyledTableCell align="right">Gate</StyledTableCell>
              <StyledTableCell align="right">Vehicle Type</StyledTableCell>
              <StyledTableCell align="right">VRN no.</StyledTableCell>
              <StyledTableCell align="right">Entry Time</StyledTableCell>
              <StyledTableCell align="right">Exit Time</StyledTableCell>
              <StyledTableCell align="right">Duration</StyledTableCell>
              <StyledTableCell align="right">Amount</StyledTableCell>
              <StyledTableCell align="right">Status</StyledTableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {cashCollectDetailsReportloader === true ? (
              <TableCell colSpan={11}>
                <Box className="flex flex-col items-center justify-center">
                  Loading please wait...
                  <Oval
                    height={20}
                    width={20}
                    color="#4fa94d"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                    ariaLabel="oval-loading"
                    secondaryColor="#4fa94d"
                    strokeWidth={2}
                    strokeWidthSecondary={2}
                  />
                </Box>
              </TableCell>
            ) : (
              <>
                {data?.length > 0 ? (
                  <>
                    {data?.map((row, id) => (
                      <TableRow key={id}>
                        <TableCell component="th" scope="row">
                          {1 + id}
                        </TableCell>
                        <TableCell align="right">
                          {row?.entryBy?.name}
                        </TableCell>
                        <TableCell align="right">
                          {row?.gate?.gateType}
                        </TableCell>
                        <TableCell align="right">{row?.gate?.name}</TableCell>
                        <TableCell align="right">
                          {row?.parkingDto?.vehicleType?.name}
                        </TableCell>
                        <TableCell align="right">
                          {row?.parkingDto?.vrn}
                        </TableCell>
                        <TableCell align="right">
                          {row?.parkingDto?.entryTime
                            ? moment(row?.parkingDto?.entryTime).format(
                                "hh:mm:ss"
                              )
                            : "--"}
                        </TableCell>
                        <TableCell align="right">
                          {row?.timeOfExit || row?.timeOfExit !== null
                            ? moment(row?.timeOfExit).format("hh:mm:ss")
                            : "--"}
                        </TableCell>
                        <TableCell align="right">
                          {convertPremiseTimeStamp(row?.timeDuration)}
                        </TableCell>
                        <TableCell align="right">{row?.amountPaid}</TableCell>
                        <TableCell align="right">
                          {row?.parkingstatus}
                        </TableCell>
                      </TableRow>
                    ))}
                  </>
                ) : (
                  <TableCell colSpan={11}>
                    <Typography className="flex items-center justify-center">
                      No data found!
                    </Typography>
                  </TableCell>
                )}
              </>
            )}
          </TableBody>
        </Table>
        <Pagination
          className="m-3 flex justify-end"
          count={pageNumbers}
          page={currentPage + 1}
          onChange={(event, page) => setCurrentPage(page - 1)}
        />
      </TableContainer>
    </Card>
  );
};

export default CashCollectDetailReportTable;
