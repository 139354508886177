import React from "react";
import MainLayout from "../../../containers/MainLayout";
import {
  Box,
  Button,
  TextField,
  InputAdornment,
  Typography,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Search } from "@mui/icons-material";
import AddBoxRoundedIcon from "@mui/icons-material/AddBoxRounded";
import { Link } from "react-router-dom";
import { useFloorMaster } from "../hooks/useFloorMaster";
import FloorMasterForm from "../components/FloorMasterForm";

function FloorPage() {
  const {
    open,
    rows,
    areas,
    columns,
    locations,
    formValues,
    searchValue,
    buttonHandle,
    isDisabled,
    handleChange,
    handleClickOpen,
    handleClose,
    handleSearchChange,
    handleSubmit,
    handleClear,
  } = useFloorMaster();

  return (
    <MainLayout>
      <Typography className="text-[#252F40] !mb-2">
        <Link to="/dashboard" className="no-underline">
          <span className="text-[#8C8C8C]">Masters / </span>
        </Link>
        Floor
      </Typography>
      <Box
        sx={{
          boxShadow: " 0px 5px 15px -2px rgba(0, 0, 0, 0.12)",
          borderRadius: "2rem",
          width: "100%",
        }}
      >
        <Box
          backgroundColor={"white"}
          alignItems={"center"}
          className="flex justify-between px-3 py-2 "
        >
          <Box display="flex" p={1} alignItems="center">
            <TextField
              label="Search"
              // value={searchValue}
              onChange={handleSearchChange}
              size="small"
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
          </Box>

          <Button
            variant="contained"
            onClick={handleClickOpen}
            sx={{
              backgroundColor: "#3A416F",
            }}
          >
            Add <AddBoxRoundedIcon />
          </Button>

          <FloorMasterForm
            selectedRow={formValues}
            open={open}
            searchValue={searchValue}
            buttonHandle={buttonHandle}
            isDisabled={isDisabled}
            handleChange={handleChange}
            handleClickOpen={handleClickOpen}
            handleClose={handleClose}
            handleSearchChange={handleSearchChange}
            handleSave={handleSubmit}
            columns={columns}
            rows={rows}
            handleClear={handleClear}
          />
        </Box>

        <Grid container className="!bg-[white] px-5 py-3" gap={2}>
          <Grid item xs={12} sm={5} md={3} lg={3}>
            <FormControl fullWidth size="small">
              <InputLabel size="small" required>
                Locations
              </InputLabel>
              <Select
                labelId="locations"
                label="Locations *"
                name="locationId"
                value={formValues?.locationId}
                onChange={handleChange}
                defaultValue={formValues?.locationId}
              >
                {/* eslint-disable */}
                {locations?.map((obj) => {
                  if (obj.deleted === false)
                    return <MenuItem value={obj.id}>{obj.name}</MenuItem>;
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={5} md={3} lg={3}>
            <FormControl fullWidth size="small">
              <InputLabel size="small" required>
                Areas
              </InputLabel>
              <Select
                labelId="floorId"
                label="Areas *"
                name="areaId"
                value={formValues?.areaId}
                onChange={handleChange}
                defaultValue={formValues?.areaId}
              >
                {/* eslint-disable-next-line */}
                {areas?.map((obj) => {
                  if (obj.deleted === false)
                    return <MenuItem value={obj.id}>{obj.name}</MenuItem>;
                })}
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={10}
          rowsPerPage={[10]}
          sx={{
            height: 400,
            fontSize: "small",
            backgroundColor: "white",
            "& .super-app-theme--header": {
              backgroundColor: "#576782",
              fontWeight: "bold",
              color: "white",
            },
          }}
        />
      </Box>
    </MainLayout>
  );
}

export default FloorPage;
