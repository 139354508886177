import { useEffect, useState } from "react";

const useWorkingDetails = ({ formValue, setFormValue }) => {
  const [isChecked, setIsChecked] = useState(false);

  const [checkedValues, setCheckedValues] = useState([
    { sunday: 0, checked: false, value: 1, name: "sunday" },
    { monday: 0, checked: false, value: 2, name: "monday" },
    { tuesday: 0, checked: false, value: 4, name: "tuesday" },
    { wednesday: 0, checked: false, value: 8, name: "wednesday" },
    { thursday: 0, checked: false, value: 16, name: "thursday" },
    { friday: 0, checked: false, value: 32, name: "friday" },
    { saturday: 0, checked: false, value: 64, name: "saturday" },
  ]);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
    setCheckedValues(
      checkedValues?.map((item) => ({
        ...item,
        checked: !isChecked,
        sunday: !isChecked ? 1 : 0,
        monday: !isChecked ? 2 : 0,
        tuesday: !isChecked ? 4 : 0,
        wednesday: !isChecked ? 8 : 0,
        thursday: !isChecked ? 16 : 0,
        friday: !isChecked ? 32 : 0,
        saturday: !isChecked ? 64 : 0,
      }))
    );
  };

  const handleItemCheckboxChange = (itemId, e) => {
    const updatedItem = checkedValues.map((item, key) => {
      if (itemId === key) {
        return {
          ...item,
          checked: !item.checked,
          [e.target.name]: item.checked ? 0 : parseInt(e.target.value),
        };
      }
      return item;
    });
    setCheckedValues(updatedItem);
    setIsChecked(updatedItem.every((item) => item.checked));
  };

  const convertBinaaryToObject = () => {
    let result;
    if (formValue?.id) {
      const strng = formValue?.binaryForm;

      function ConvertStringToArray(strng) {
        const arr = strng.split("").map((val) => parseInt(val));
        return arr;
      }

      const arrayBeforeReverse = ConvertStringToArray(strng);
      const reverseArray = arrayBeforeReverse.reverse();

      result = reverseArray?.map((val, key) => {
        const result = setCheckedValues((prevState) => {
          const newCheckedValues = [...prevState];
          if (val === 1) {
            newCheckedValues[key] = {
              ...newCheckedValues[key],
              checked: true,
              sunday: key === 0 ? 1 : 0,
              monday: key === 1 ? 2 : 0,
              tuesday: key === 2 ? 4 : 0,
              wednesday: key === 3 ? 8 : 0,
              thursday: key === 4 ? 16 : 0,
              friday: key === 5 ? 32 : 0,
              saturday: key === 6 ? 64 : 0,
            };
          }
          return newCheckedValues;
        });
        return result;
      });
    }
    return result;
  };

  /*eslint-disable */
  useEffect(() => {
    convertBinaaryToObject();
  }, [formValue?.id]);

  useEffect(() => {
    const firstElements = checkedValues?.map((obj) => Object.values(obj)[0]);
    let sum = firstElements.reduce((acc, value) => {
      return acc + value;
    });
    setFormValue({ ...formValue, days: sum });
  }, [checkedValues]);
  /*eslint-disable */

  return {
    isChecked,
    checkedValues,
    handleCheckboxChange,
    handleItemCheckboxChange,
  };
};

export default useWorkingDetails;
