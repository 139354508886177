import React from "react";
import MainLayout from "../../../containers/MainLayout";
import {
  Box,
  Button,
  TextField,
  InputAdornment,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Search } from "@mui/icons-material";
import AddBoxRoundedIcon from "@mui/icons-material/AddBoxRounded";
// import VehicleMasterForm from "../components/VehicleMasterForm";
import { Link } from "react-router-dom";
import { useStaffonBoard } from '../hooks/useStaffonBoard';
import StaffonBoardForm from "../components/StaffonBoardForm";

function StaffOnBoard() {

    const {
        selectedRow,
        open,
        searchValue,
        buttonHandle,
        isDisabled,
        handleChange,
        handleClickOpen,
        handleClose,
        handleSearchChange,
        handleSave,
        handleSaveEdit,
        columns,
        rows,
      } = useStaffonBoard();

  return (
    <MainLayout>
    <Typography className="text-[#252F40] !mb-2">
      <Link to="/dashboard" className="no-underline">
        <span className="text-[#8C8C8C]">Masters / </span>
      </Link>
      Vehicle
    </Typography>
    <Box
      sx={{
        boxShadow: " 0px 5px 15px -2px rgba(0, 0, 0, 0.12)",
        borderRadius: "2rem",
        width: "100%",
      }}
    >
      <Box
        backgroundColor={"white"}
        alignItems={"center"}
        className="flex justify-between px-3 py-2 "
      >
        <Box display="flex" p={1} alignItems="center">
          <TextField
            label="Search"
            value={searchValue}
            onChange={handleSearchChange}
            size="small"
            variant="outlined"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
        </Box>

        <Button
          variant="contained"
          onClick={handleClickOpen}
          sx={{
            backgroundColor: "#3A416F",
          }}
        >
          Add <AddBoxRoundedIcon />
        </Button>

        <StaffonBoardForm
          selectedRow={selectedRow}
          open={open}
          searchValue={searchValue}
          buttonHandle={buttonHandle}
          isDisabled={isDisabled}
          handleChange={handleChange}
          handleClickOpen={handleClickOpen}
          handleClose={handleClose}
          handleSearchChange={handleSearchChange}
          handleSave={handleSave}
          handleSaveEdit={handleSaveEdit}
          columns={columns}
          rows={rows}
        />
      </Box>

      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={10}
        rowsPerPage={[10]}
        sx={{
          height: 400,
          fontSize: "small",
          backgroundColor: "white",
          "& .super-app-theme--header": {
            backgroundColor: "#576782",
            fontWeight: "bold",
            color: "white",
          },
        }}
      />
    </Box>
  </MainLayout>
  )
}

export default StaffOnBoard