import {
  Box,
  Button,
  TextField,
  Typography,
  InputAdornment,
  Snackbar,
  Alert,
} from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { Search } from "@mui/icons-material";
import MainLayout from "../../../../containers/MainLayout";
import { useStaffOnboarding } from "./hooks/useStaffOnboarding";
import AddBoxRoundedIcon from "@mui/icons-material/AddBoxRounded";
import StaffOnboardingForm from "./components/StaffOnboardingForm";
import StaffOnboardingTable from "./components/StaffOnboardingTable";
import PopUp from "../../../../containers/components/PopUp";
import ConfirmationPopupChildren from "../../../../components/ConfirmationPopupChildren";

const StaffOnboardPage = () => {
  const {
    open,
    toast,
    files,
    errors,
    loading,
    columns,
    staffData,
    locations,
    formValues,
    emailError,
    idCardTypes,
    openBackDrop,
    designations,
    idCardSubType,
    idCardSubTypes,
    userIdForDelete,
    showConfirmation,
    userDocumentDetails,
    hanldeStaffCreateSubmit,
    setFiles,
    handleClear,
    handleClose,
    deleteStaff,
    randomString,
    setFormValues,
    validateEmail,
    handleClickOpen,
    handleClosePopup,
    getIdCardSubType,
    handleInputChange,
    handleSearchChange,
    handleCloseBackDrop,
    setUserDocumentDetails,
    staffOnBoardingHandleSubmit,
    updateUserDetails,
  } = useStaffOnboarding();

  const children = (
    <ConfirmationPopupChildren
      params={userIdForDelete}
      message={"Are you sure want to confirm"}
      onConfirm={deleteStaff}
      onCancel={handleClosePopup}
    />
  );

  return (
    <MainLayout>
      <Typography className="text-[#252F40] !mb-2">
        <Link to="/dashboard" className="no-underline">
          <span className="text-[#8C8C8C]">Masters / Staff Management / </span>
        </Link>
        Staff
      </Typography>
      <Box
        sx={{
          boxShadow: " 0px 5px 15px -2px rgba(0, 0, 0, 0.12)",
          borderRadius: "2rem",
          width: "100%",
        }}
      >
        <Box className="flex justify-between px-3 py-2 items-center bg-[white]">
          <Box display="flex" alignItems="center" m={1}>
            <Typography className="text-[#576782]">Staff Management</Typography>
          </Box>
          <Box className="flex gap-6">
            <Box alignItems="center">
              <TextField
                label="Search"
                type="search"
                onChange={handleSearchChange}
                size="small"
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Search />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>

            <Button
              variant="contained"
              size="small"
              onClick={handleClickOpen}
              className="!bg-[#3A416F] gap-2"
              sx={{ textTransform: "none" }}
            >
              Onboard <AddBoxRoundedIcon />
            </Button>
          </Box>

          <StaffOnboardingForm
            open={open}
            files={files}
            errors={errors}
            formValues={formValues}
            emailError={emailError}
            idCardTypes={idCardTypes}
            openBackDrop={openBackDrop}
            designations={designations}
            idCardSubType={idCardSubType}
            idCardSubTypes={idCardSubTypes}
            locations={locations}
            userDocumentDetails={userDocumentDetails}
            hanldeStaffCreateSubmit={hanldeStaffCreateSubmit}
            setFiles={setFiles}
            setFormValues={setFormValues}
            handleClose={handleClose}
            handleClear={handleClear}
            randomString={randomString}
            validateEmail={validateEmail}
            getIdCardSubType={getIdCardSubType}
            handleInputChange={handleInputChange}
            handleCloseBackDrop={handleCloseBackDrop}
            setUserDocumentDetails={setUserDocumentDetails}
            staffOnBoardingHandleSubmit={staffOnBoardingHandleSubmit}
            updateUserDetails={updateUserDetails}
          />
        </Box>

        <StaffOnboardingTable
          staffData={staffData}
          columns={columns}
          loading={loading}
        />
      </Box>

      <PopUp
        open={showConfirmation}
        popUpChildren={children}
        handleClose={handleClosePopup}
      />

      <Snackbar open={toast.show} autoHideDuration={1000}>
        <Alert severity={toast.type} sx={{ width: "100%" }}>
          {toast.message}
        </Alert>
      </Snackbar>
    </MainLayout>
  );
};

export default StaffOnboardPage;
