import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Grid,
  Box,
  Typography,
  IconButton,
  useMediaQuery,
  Popover,
  Avatar,
  Divider,
  MenuItem,
  Drawer,
} from "@mui/material";
import QueryBuilderIcon from "@mui/icons-material/QueryBuilder";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import "../../../assets/css/style.css";
import Header_Parking_logo from "../../../assets/images/Header_Parking_logo.svg";
import hamberger from "../../../assets/images/hamberger.svg";
import { useHeader } from "../../hooks/useHeader";
import { Stack } from "@mui/system";
import { useLocation, useNavigate } from "react-router-dom";
import { useTheme } from "styled-components";
import PopUp from "../PopUp";
import Receipt from "../Receipt";
import ChangePasswordChildren from "../ChangePasswordChildren";
import SuccessPopupChildren from "../SuccessPopupChildren";

const UserHeader = ({ children }) => {
  const {
    currentTime,
    currentDate,
    receiptPopUp,
    receiptHandleClose,
    getUserReceipt,
    receiptData,
    presentDate,
  } = useHeader();

  const theme = useTheme();

  const [open, setOpen] = useState(null);
  const [openChangePassword, setOpenChangePassword] = useState(false);
  const [openSuccessPopup, setOpenSuccessPopup] = useState(false);

  const [drawerOpen, setDrawerOpen] = useState(false);

  const { state } = useLocation();

  const navigate = useNavigate();

  const handleDrawerOpen = () => {
    setDrawerOpen(false);
  };

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleOpenChangePassword = () => {
    setOpenChangePassword(true);
  };

  const handleCloseChangePassword = () => {
    setOpenChangePassword(false);
  };

  const handleOpenSuccessPopup = () => {
    setOpenSuccessPopup(true);
  };

  const handleCloseSuccessPopup = () => {
    setOpenSuccessPopup(false);
  };

  const isSm = useMediaQuery(theme.breakpoints.down("sm"));
  const isMd = useMediaQuery(theme.breakpoints.down("md"));
  const isLg = useMediaQuery(theme.breakpoints.down("lg"));

  const printDailyReportChildren = (
    <Receipt receiptData={receiptData} presentDate={presentDate} />
  );

  const changePasswordChildren = (
    <ChangePasswordChildren
      handleOpenSuccessPopup={handleOpenSuccessPopup}
      handleCloseChangePassword={handleCloseChangePassword}
    />
  );
  const successPopupChildren = <SuccessPopupChildren />;

  return (
    <>
      <AppBar className="!bg-[transparent] !shadow-none">
        <Toolbar className="flex justify-end">
          <Box className="flex !justify-between !w-[100%]">
            <Grid className="flex !gap-4 items-center">
              {isSm ? (
                <Box>
                  <IconButton onClick={() => setDrawerOpen(!drawerOpen)}>
                    <img src={hamberger} alt="logo" className="h-[1.2rem]" />
                    <Drawer
                      anchor="left"
                      open={drawerOpen}
                      onClose={handleDrawerOpen}
                    >
                      <Box
                        sx={{
                          width: 200,
                          p: 2,
                          height: "100vh",
                          bgcolor: "whitesmoke",
                          color: "black",
                        }}
                      >
                        <Box
                          sx={{ display: "flex", alignItems: "center", my: 2 }}
                        >
                          <Avatar />
                          <Box sx={{ ml: 2 }}>
                            <Typography variant="h6">
                              {localStorage.getItem("firstName")}
                            </Typography>
                          </Box>
                        </Box>
                        <Divider sx={{ mb: 2 }} />
                        <MenuItem
                          sx={{ m: 1 }}
                          onClick={handleOpenChangePassword}
                        >
                          Change password
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            localStorage.clear();
                            navigate("/")
                          }}
                          sx={{ m: 1 }}
                        >
                          Logout
                        </MenuItem>
                      </Box>
                    </Drawer>
                  </IconButton>
                </Box>
              ) : (
                ""
              )}
              <div onClick={() => navigate("/home")}>
                <img
                  src={Header_Parking_logo}
                  alt="logo"
                  className="h-[2.2rem] hidden sm:block"
                />
              </div>
            </Grid>
            {!isSm && !isMd && !isLg ? (
              <Stack
                direction="row"
                className="flex justify-center items-center"
                spacing={4}
              >
                <Typography className="!text-[#FFFFFF] opacity-[0.2] !text-[1.5rem]">
                  {state?.vehicleId === 1
                    ? "BIKE PARKING"
                    : state?.vehicleId === 2
                    ? "CAR PARKING"
                    : ""}
                </Typography>
                <Typography className="!text-[#FFFFFF] opacity-[0.5] !text-[1.5rem]">
                  {state?.EntryExit === 1
                    ? "ENTRY "
                    : state?.EntryExit === 2
                    ? "EXIT "
                    : ""}
                  {`${state?.gate ? "-" : ""} ${state?.gate ?? ""}`}
                </Typography>
              </Stack>
            ) : (
              ""
            )}

            {!isSm ? (
              <Grid className="flex items-center gap-4">
                <Grid
                  item
                  className="!h-[2.2rem] flex justify-start items-center px-2 gap-2 !text-[#3A416F] bg-[white] rounded-[5px]"
                >
                  <QueryBuilderIcon />
                  <Typography
                    variant="subtitle"
                    className="!font-bold !font-sans"
                  >
                    {currentTime}
                  </Typography>
                </Grid>

                <Grid
                  item
                  className="!h-[2.2rem] flex justify-start items-center px-2 gap-2 !text-[#3A416F] !font-bold bg-[white] rounded-[5px]"
                >
                  <CalendarMonthIcon />

                  <Typography
                    variant="subtitle"
                    className="!font-bold !font-sans"
                  >
                    {currentDate}
                  </Typography>
                </Grid>

                <Box>
                  <IconButton
                    onClick={handleOpen}
                    sx={{
                      p: 0,
                      ...(open && {
                        "&:before": {
                          zIndex: 1,
                          content: "''",
                          width: "100%",
                          height: "100%",
                          borderRadius: "50%",
                          position: "absolute",
                          bgcolor: "white",
                        },
                      }),
                    }}
                  >
                    <Avatar />
                  </IconButton>
                </Box>

                <Popover
                  open={Boolean(open)}
                  anchorEl={open}
                  onClose={handleClose}
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  transformOrigin={{ vertical: "top", horizontal: "right" }}
                  PaperProps={{
                    sx: {
                      p: 0,
                      mt: 1.5,
                      ml: 0.75,
                      width: 180,
                      "& .MuiMenuItem-root": {
                        typography: "body2",
                        borderRadius: 0.75,
                      },
                    },
                  }}
                >
                  <Box sx={{ my: 1.5, px: 2.5 }}>
                    <Typography
                      variant="body2"
                      sx={{ color: "text.secondary" }}
                      noWrap
                    >
                      {localStorage.getItem("firstName")}
                    </Typography>
                  </Box>

                  <Divider sx={{ borderStyle: "dashed" }} />
                  <MenuItem sx={{ m: 1 }} onClick={getUserReceipt}>
                    Print daily report
                  </MenuItem>
                  <MenuItem sx={{ m: 1 }} onClick={handleOpenChangePassword}>
                    Change password
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      localStorage.clear();
                      navigate("/");
                    }}
                    sx={{ m: 1 }}
                  >
                    Logout
                  </MenuItem>
                </Popover>
              </Grid>
            ) : (
              <Grid className="flex items-center gap-4">
                <Grid
                  item
                  className="!h-[2.2rem] flex justify-start items-center px-2 gap-2 !text-[#3A416F] bg-[white] rounded-[5px]"
                >
                  <QueryBuilderIcon />
                  <Typography
                    variant="subtitle"
                    className="!font-bold !font-sans"
                  >
                    {currentTime}
                  </Typography>
                </Grid>

                <Grid
                  item
                  className="!h-[2.2rem] flex justify-start items-center px-2 gap-2 !text-[#3A416F] !font-bold bg-[white] rounded-[5px]"
                >
                  <CalendarMonthIcon />

                  <Typography
                    variant="subtitle"
                    className="!font-bold !font-sans"
                  >
                    {currentDate}
                  </Typography>
                </Grid>
              </Grid>
            )}
          </Box>
        </Toolbar>
      </AppBar>
      {children}
      <PopUp
        open={receiptPopUp}
        handleClose={receiptHandleClose}
        popUpChildren={printDailyReportChildren}
      />
      <PopUp
        open={openChangePassword}
        handleClose={handleCloseChangePassword}
        popUpChildren={changePasswordChildren}
      />

      <PopUp
        open={openSuccessPopup}
        handleClose={handleCloseSuccessPopup}
        popUpChildren={successPopupChildren}
      />
    </>
  );
};

export default UserHeader;
