import axios from "axios";
import { Initialize } from "./interceptor";

Initialize();

// export const getAuthorization = () => `Bearer ${localStorage.getItem("token")}`;

export const getHeader = (type) => {
  const headers = {
    // "Content-Type": type ? type : "application/json; charset=utf-8",
    // Authorization:""
    // Authorization: getAuthorization(),
  };
  return { headers };
};

export async function post(url, data, params, headers) {
  const response = await axios.post(url, data, {
    params: params,
    headers: headers ? headers : getHeader(),
  });
  if (response && response.data && response.status === 200) {
    return response.data;
  }
  return response;
}

// Get
export async function get(url, params, headers) {
  try {
    const response = await axios
      .get(url, { params }, headers ? { headers } : getHeader())
      .then((res) => {
        return res.data;
      });
    return response;
  } catch (err) {
    return undefined;
  }
}

// Put
export async function Put(url, data, headers) {
  const response = await axios.put(
    url,
    data,
    headers ? { headers } : getHeader()
  );

  if (response && response.data && response.status === 200) {
    return response.data;
  }
  return response;
}

// Delete
export async function Delete(url, headers) {
  const response = await axios.delete(url, headers ? { headers } : getHeader());

  if (response && response.data && response.status === 200) {
    return response.data;
  }

  return response;
}

export async function upload(url, data) {
  try {
    const response = await axios.post(
      url,
      data,
      getHeader("multipart/form-data")
    );
    if (response && response.data && response.status === 200) {
      return response.data;
    } else if (response.status === 201) {
      return true;
    }
  } catch (err) {}
}
