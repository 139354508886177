import React from "react";
import { Link } from "react-router-dom";
import { Search } from "@mui/icons-material";
import MainLayout from "../../../containers/MainLayout";
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  InputAdornment,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useCashCollectReport } from "../hook/useCashCollectReport";
import CashCollectDetailReportTable from "../components/CashCollectDetailReportTable";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const CashCollectDetailReportPage = () => {
  const {
    date,
    week,
    month,
    value,
    params,
    tenDays,
    employees,
    yesterday,
    formValue,
    currentPage,
    activeGates,
    pageNumbers,
    paymentModeData,
    toDateTimeForDetailsReport,
    FilterCashCollecDetailtData,
    fromDateTimeForDetailsReport,
    cashCollectDetailsReportloader,
    setFormValue,
    handleOnChange,
    setCurrentPage,
    handleGateChange,
    handleInputChange,
    handleSearchChange,
    // getCashCollectDetailReport,
    getCashCollectDetailsViewWithPagination,
    handleFromDateTimeChangeForDetailsReport,
    handleToDateTimeChangeForDetailsReport,
  } = useCashCollectReport();

  return (
    <MainLayout>
      <Typography className="text-[#252F40] !mb-2">
        <Link to="/dashboard" className="no-underline">
          <span className="text-[#8C8C8C]">Reports / </span>
        </Link>
        Cash Collect Detail Report
      </Typography>
      <Box
        sx={{
          borderRadius: "10px",
          boxShadow: "0px 3.75px 7.5px rgba(0, 0, 0, 0.12)",
        }}
        className="!bg-[white] !mt-6 !mb-4"
      >
        <Stack direction="row" gap={2} className="!p-4 flex-wrap items-center">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimePicker
              label="Date from"
              value={fromDateTimeForDetailsReport}
              onChange={handleFromDateTimeChangeForDetailsReport}
              slotProps={{ textField: { size: "small" } }}
            />

            <DateTimePicker
              label="Date to"
              value={toDateTimeForDetailsReport}
              onChange={handleToDateTimeChangeForDetailsReport}
              slotProps={{ textField: { size: "small" } }}
            />
          </LocalizationProvider>
          {/* <TextField
            id="from-date"
            label="Date from"
            variant="outlined"
            type="date"
            size="small"
            defaultValue={formValue.fromDate}
            focused
            onChange={(e) => {
              setFormValue({ ...formValue, fromDate: e.target.value });
            }}
          /> */}

          {/* <TextField
            id="to-date"
            label="Date To"
            variant="outlined"
            type="date"
            focused
            size="small"
            defaultValue={formValue.toDate}
            onChange={(e) => {
              setFormValue({ ...formValue, toDate: e.target.value });
            }}
          /> */}

          <FormControl size="small" className="w-[15rem]">
            <InputLabel size="small" required>
              Payment Mode
            </InputLabel>
            <Select
              fullWidth
              name="paymentMode"
              label="Payment Mode"
              labelId="paymentMode"
              // defaultValue={formValue.paymentMode}
              value={formValue.paymentMode}
              onLoad={() => {
                setFormValue({
                  ...formValue,
                  paymentMode: params?.id === "CASH" ? "CASH" : "UPI",
                });
              }}
              onChange={(e) => {
                setFormValue({ ...formValue, paymentMode: e.target.value });
              }}
            >
              {paymentModeData?.map((obj) => {
                return (
                  <MenuItem value={obj?.paymentMode}>
                    {obj?.paymentMode}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <FormControl>
            <RadioGroup
              aria-labelledby="entry-exit-radio-buttons-group-label"
              defaultValue="entry"
              name="gateForDetailReport"
              className="flex !flex-row"
            >
              <FormControlLabel
                control={<Radio onChange={handleGateChange} />}
                checked={formValue?.gateForDetailReport === "entry"}
                value="entry"
                label="Entry"
              />
              <FormControlLabel
                control={<Radio onChange={handleGateChange} />}
                checked={formValue?.gateForDetailReport === "exit"}
                value="exit"
                label="Exit"
              />
            </RadioGroup>
          </FormControl>

          <FormControl size="small">
            <InputLabel>Choose Gate</InputLabel>
            <Select
              labelId="Entity Location"
              label="Vehicle_type*"
              className="!min-w-[300px]"
              name="gateIdForDetailReport"
              value={formValue?.gateIdForDetailReport}
              onChange={handleInputChange}
            >
              {activeGates?.map((obj) => (
                <MenuItem value={obj.id}>{obj.name}</MenuItem>
              ))}
            </Select>
          </FormControl>

          <Autocomplete
            size="small"
            disablePortal
            id="combo-box-demo"
            options={employees ?? []}
            value={value}
            sx={{ width: 250 }}
            onChange={handleOnChange}
            getOptionLabel={(option) => option?.firstname ?? ""}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Employee Name"
                placeholder="Select Name"
              />
            )}
            // renderOption={(props, option) => (
            //   <Box
            //     component="li"
            //     {...props}
            //   >
            //     <AccountCircleIcon className="pr-2"/>
            //     {option.firstname}
            //   </Box>
            // )}
          />
        </Stack>
        <Stack direction={"row"} spacing className="!p-4 !pt-0">
          <Button
            name="today"
            onClick={(e) =>
              getCashCollectDetailsViewWithPagination(date, date, e)
            }
            sx={{
              background: "#576782",
              color: "white",
              "&:hover": { background: "#3e4859" },
            }}
            variant="contained"
            size="small"
          >
            Today
          </Button>
          <Button
            name="yesterday"
            onClick={(e) =>
              getCashCollectDetailsViewWithPagination(yesterday, yesterday, e)
            }
            sx={{
              background: "#576782",
              color: "white",
              "&:hover": { background: "#3e4859" },
            }}
            variant="contained"
            size="small"
          >
            Yesterday
          </Button>
          <Button
            name="week"
            onClick={(e) =>
              getCashCollectDetailsViewWithPagination(week, date, e)
            }
            sx={{
              background: "#576782",
              color: "white",
              "&:hover": { background: "#3e4859" },
            }}
            variant="contained"
            size="small"
          >
            One Week
          </Button>
          <Button
            name="tendays"
            onClick={(e) =>
              getCashCollectDetailsViewWithPagination(tenDays, date, e)
            }
            sx={{
              background: "#576782",
              color: "white",
              "&:hover": { background: "#3e4859" },
            }}
            variant="contained"
            size="small"
          >
            10 Days
          </Button>
          <Button
            name="month"
            onClick={(e) =>
              getCashCollectDetailsViewWithPagination(month, date, e)
            }
            sx={{
              background: "#576782",
              color: "white",
              "&:hover": { background: "#3e4859" },
            }}
            variant="contained"
            size="small"
          >
            One Month
          </Button>
        </Stack>
      </Box>

      <Box
        sx={{
          boxShadow: " 0px 5px 15px -2px rgba(0, 0, 0, 0.12)",
          borderRadius: "2rem",
          width: "100%",
        }}
      >
        <Box className="flex justify-between px-3 py-2 items-center rounded-t-lg bg-[white]">
          <Box display="flex" p={1} alignItems="center">
            <TextField
              label="Search"
              onChange={handleSearchChange}
              size="small"
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Box>
        <CashCollectDetailReportTable
          pageNumbers={pageNumbers}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          FilterCashCollecDetailtData={FilterCashCollecDetailtData}
          cashCollectDetailsReportloader={cashCollectDetailsReportloader}
        />
      </Box>
    </MainLayout>
  );
};

export default CashCollectDetailReportPage;
