import { useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import { Box, Button, Typography } from "@mui/material";
import { Put, get, post } from "../../../services/service";
import {
  GET_BY_EXIT_BOOTH_ID,
  ADD_EXIT_BOOTH_MASTER,
  GET_ALL_EXIT_BOOTH_MASTER_DATA,
  EXIT_BOOTH_ACTIVE,
  EXIT_BOOTH_INACTIVE,
} from "../../../services/apiUrls";
import axios from "axios";

export function useExitBoothMaster() {
  let locationId = localStorage.getItem("locationId");

  const [open, setOpen] = useState(false);

  const [search, setSearch] = useState("");

  const [exitBoothData, setExitBoothData] = useState([]);

  const [formValue, setFormValue] = useState({
    id: "",
    name: "",
    description: "",
    locationId: locationId,
  });

  const columns = [
    {
      field: "No.",
      headerName: "NO.",
      width: 65,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "name",
      headerName: "GATE NAME",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "description",
      headerName: "DESCRIPTION",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "deleted",
      headerName: "STATUS",
      flex: 1,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <Box>
            <Button
              onClick={() => {
                // setStatusUpdateConfirmPopUp(true);
                // exitBoothStatusUpdate(params);
              }}
            >
              {params?.row?.deleted === false ? (
                <Button
                  variant="contained"
                  size="small"
                  color="success"
                  sx={{ width: "2rem" }}
                  onClick={() => {
                    // setStatusUpdateConfirmPopUp(true);
                    exitBoothStatusIncactive(params.row.id);
                  }}
                >
                  <Typography sx={{ fontSize: "10px" }}>Active</Typography>
                </Button>
              ) : (
                <Button
                  variant="contained"
                  size="small"
                  color="error"
                  sx={{ width: "2rem" }}
                  onClick={() => {
                    // setStatusUpdateConfirmPopUp(true);
                    exitBoothStatusActive(params.row.id);
                  }}
                >
                  <Typography sx={{ fontSize: "10px" }}>Delete</Typography>
                </Button>
              )}
            </Button>
          </Box>
        );
      },
    },
    {
      field: "action",
      headerName: "ACTION",
      flex: 1,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <EditIcon
            sx={{ cursor: "pointer" }}
            onClick={() => {
              setOpen(true);
              window.scrollTo(0, 0);
              getByEntryBoothId(params.id);
            }}
          />
        );
      },
    },
  ];

  const handleInputChange = (e) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
  };

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const exitBoothFilterData = exitBoothData.filter(
    (item) =>
      item.name.toLowerCase().includes(search.toLowerCase()) ||
      item.description.toLowerCase().includes(search.toLowerCase())
  );

  const isDisabled = formValue.name !== "" && formValue.description !== "";

  const handleClose = () => {
    setOpen(false);
    handleClear();
  };

  const handleClear = () => {
    setFormValue({ ...formValue, id: "", name: "", description: "" });
  };

  const getExitBoothData = async () => {
    const url = `${GET_ALL_EXIT_BOOTH_MASTER_DATA.replace(
      "{locationId}",
      locationId
    )}`;
    const resp = await get(url);
    if (resp && resp?.id !== "") {
      setExitBoothData(resp);
    }
  };

  const getByEntryBoothId = async (id) => {
    const url = `${GET_BY_EXIT_BOOTH_ID.replace(
      "{locationId}",
      locationId
    )}/${id}`;
    const resp = await get(url);
    if (resp && resp.id) {
      setFormValue({
        ...formValue,
        id: resp?.id,
        name: resp?.name,
        description: resp?.description,
      });
    }
  };

  const handleSubmit = async () => {
    if (!formValue.id) {
      const url = ADD_EXIT_BOOTH_MASTER.replace("{locationId}", locationId);
      const resp = await post(url, formValue);
      if (resp && resp?.id) {
        handleClear();
        handleClose();
        getExitBoothData();
      }
    } else {
      const url = ADD_EXIT_BOOTH_MASTER.replace("{locationId}", locationId);
      const resp = await Put(url, formValue);
      if (resp && resp?.id) {
        handleClear();
        handleClose();
        getExitBoothData();
      }
    }
  };

  // EXIT BOOTH ACTIVE
  const exitBoothStatusActive = async (restoreId) => {
    try {
      const resp = await axios
        .delete(
          EXIT_BOOTH_ACTIVE.replace("{locationId}", locationId).replace(
            "{resotreId}",
            restoreId
          )
        )
        .then((res) => {
          getExitBoothData();
          return res;
        });
      return resp;
    } catch (err) {
      return err;
    }
  };
  

  // EXIT BOOTH INACTIVE
  const exitBoothStatusIncactive = async (restoreId) => {
    try {
      const resp = await axios
        .delete(
          EXIT_BOOTH_INACTIVE.replace("{locationId}", locationId).replace(
            "{resotreId}",
            restoreId
          )
        )
        .then((res) => {
          getExitBoothData();
          return res;
        });
      return resp;
    } catch (err) {
      return err;
    }
  };

  /*eslint-disable */
  useEffect(() => {
    getExitBoothData();
  }, []);
  /*eslint-enable */

  return {
    open,
    setOpen,
    columns,
    formValue,
    isDisabled,
    handleClose,
    handleSubmit,
    handleInputChange,
    handleSearchChange,
    exitBoothFilterData,
  };
}
