import { changePassword } from "../../api/auth";

const useAuth = () => {
  const changePassCode = async (formValues) => {
    let response = await changePassword(formValues);
    return response;
  };

  return { changePassCode };
};

export default useAuth;
