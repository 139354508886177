import React from "react";
import { Link } from "react-router-dom";
import { useShift } from "./hooks/useShift";
import { Search } from "@mui/icons-material";
import MainLayout from "../../../../containers/MainLayout";
import ShiftHistoryTable from "./components/ShiftHistoryTable";
import { Box, InputAdornment, TextField, Typography } from "@mui/material";

const ShiftHistoryPage = () => {
  const { handleSearchChange, shiftHistory, ShiftHistoryColumns } = useShift();
  return (
    <MainLayout>
      <Typography className="text-[#252F40] !mb-2">
        <Link to="/dashboard" className="no-underline">
          <span className="text-[#8C8C8C]">Masters / Shift Management / </span>
        </Link>
        History
      </Typography>
      <Box
        sx={{
          boxShadow: " 0px 5px 15px -2px rgba(0, 0, 0, 0.12)",
          borderRadius: "2rem",
          width: "100%",
        }}
      >
        <Box className="flex justify-between px-3 py-2 items-center bg-[white]">
          <Box display="flex" alignItems="center" m={1}>
            <Typography className="text-[#576782]">Shift History</Typography>
          </Box>
          <Box className="flex gap-6">
            <Box alignItems="center">
              <TextField
                label="Search"
                onChange={handleSearchChange}
                size="small"
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Search />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </Box>
        </Box>
        <ShiftHistoryTable
          shiftHistory={shiftHistory}
          ShiftHistoryColumns={ShiftHistoryColumns}
        />
      </Box>
    </MainLayout>
  );
};

export default ShiftHistoryPage;
