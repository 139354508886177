import React, { useEffect } from "react";
import { Box, Button, Typography, IconButton } from "@mui/material";
import { Edit } from "@mui/icons-material";
import { useState } from "react";
import {
  GET_PARKING_TYPE1,
  PARKING_TYPE_INACTIVE,
  PARKING_TYPE_ACTIVE,
} from "../../../services/apiUrls";
import { get, post, Put } from "../../../services/service";
import axios from "axios";
import useIsEqual from "./useIsEqual";

export function useParkingType() {
  const [parkingData, setParkingdata] = useState([]);
  const [formValues, setFormValues] = useState({
    deleted: false,
    description: "",
    id: null,
    key: "",
    vehicletype: "",
  });

  const [open, setOpen] = useState(false);
  const [searchValue, setSearchvalue] = useState("");

  const [errorMessage, setErrorMessage] = useState("");
  const { isEqual, isSpace } = useIsEqual();

  let tenantId = localStorage.getItem("tenantId");
  let locationId = localStorage.getItem("locationId");

  const isDisabled =
    formValues?.vehicletype !== "" && formValues?.description !== "";

  const fetchParkingReport = async () => {
    const url = `${GET_PARKING_TYPE1.replace("{tenantId}", tenantId).replace(
      "{locationId}",
      locationId
    )}`;
    const resp = await get(url);
    setParkingdata(resp);
  };

  const handleChange = (event) => {
    setErrorMessage("");
    let convertedValue = isSpace(event.target.value);
    setFormValues({ ...formValues, [event.target.name]: convertedValue });
  };

  const handleClickOpen = () => {
    setOpen(true);
    handleClear();
  };

  const handleClose = () => {
    setOpen(false);
    handleClear();
  };

  const handleSearchChange = (e) => {
    setSearchvalue(e.target.value);
  };

  const handleSave = async () => {
    const url = `${GET_PARKING_TYPE1.replace("{tenantId}", tenantId).replace(
      "{locationId}",
      locationId
    )}`;
    const data = {
      name: formValues.vehicletype,
      description: formValues.description,
      tenantId: localStorage.getItem("tenantId"),
      locationId: localStorage.getItem("locationId"),
    };
    const resp = await post(url, data);
    if (resp && resp.id !== "") {
      fetchParkingReport();
    }
    setOpen(false);
  };

  const handleEdit = (row) => {
    setFormValues(row);
    setOpen(true);
  };

  const handleSaveEdit = async () => {
    const url = `${GET_PARKING_TYPE1.replace("{tenantId}", tenantId).replace(
      "{locationId}",
      locationId
    )}`;
    const data = {
      id: formValues.key,
      name: formValues.vehicletype,
      description: formValues.description,
      tenantId: localStorage.getItem("tenantId"),
      locationId: localStorage.getItem("locationId"),
    };

    const resp = await Put(url, data);
    if (resp && resp.id !== "") {
      fetchParkingReport();
    }
    setOpen(false);
  };

  const handleSubmit = async () => {
    if (formValues?.id) {
      handleSaveEdit();
    } else {
      handleSave();
    }
  };

  const findDuplicateParkingType = () => {
    const isDupliacte = parkingData?.map((value) => {
      let isEqualGate = isEqual(value?.name, formValues.vehicletype);
      let isEqualGateType = isEqual(value?.gateType, formValues.gateType);
      return isEqualGate && isEqualGateType;
    });
    let finalResult = isDupliacte.includes(true);
    if (finalResult) {
      setErrorMessage("Parking Type already exists");
    } else {
      handleSubmit();
    }
  };

  const handleClear = () => {
    setFormValues({
      name: "",
      description: "",
    });
  };

  const filteredRows =
    parkingData?.length === undefined || parkingData?.length === 0
      ? []
      : parkingData.filter(
          (row) =>
            row?.description
              ?.toLowerCase()
              .includes(searchValue.toLowerCase()) ||
            row?.name?.toLowerCase().includes(searchValue.toLowerCase())
        );

  const columns = [
    {
      field: "id",
      headerName: "NO",
      headerClassName: "super-app-theme--header",
      width: 80,
    },
    {
      field: "vehicletype",
      headerName: "PARKING TYPE",
      headerClassName: "super-app-theme--header",
      flex: 1,
    },

    {
      field: "description",
      headerName: "DESCRIPTION",
      headerClassName: "super-app-theme--header",
      flex: 1,
    },
    {
      field: "active",
      headerName: "STATUS",
      flex: 1,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <Box display={"flex"} paddingRight={1}>
          {params?.row?.deleted === false ? (
            <Button
              variant="contained"
              size="small"
              color="success"
              sx={{ width: "2rem" }}
              onClick={() => {
                parkingTypeInactive(params.row.key);
              }}
            >
              <Typography sx={{ fontSize: "10px" }}>Active</Typography>
            </Button>
          ) : (
            <Button
              variant="contained"
              size="small"
              color="error"
              sx={{ width: "2rem" }}
              onClick={() => {
                parkingTypeActive(params.row.key);
              }}
            >
              <Typography sx={{ fontSize: "10px" }}>Inactive</Typography>
            </Button>
          )}
        </Box>
      ),
    },
    {
      field: "action",
      headerName: "ACTION",
      headerClassName: "super-app-theme--header",
      flex: 1,
      renderCell: (params) => (
        <Box display="flex" justifyContent="center">
          <IconButton onClick={() => handleEdit(params.row)}>
            <Edit />
          </IconButton>
        </Box>
      ),
    },
  ];

  const rows = filteredRows?.map((rowData, index) => ({
    key: rowData.id,
    id: index + 1,
    vehicletype: rowData.name,
    description: rowData.description,
    deleted: rowData?.deleted,
  }));

  // PARKING TYPE INACTIVE
  const parkingTypeInactive = async (id) => {
    try {
      const resp = await axios
        .delete(
          PARKING_TYPE_INACTIVE.replace("{tenantId}", tenantId)
            .replace("{locationId}", locationId)
            .replace("{id}", id)
        )
        .then((res) => {
          fetchParkingReport();
          return res;
        });
      return resp;
    } catch (err) {
      return err;
    }
  };

  // PARKING TYPE ACTIVE
  const parkingTypeActive = async (id) => {
    try {
      const resp = await axios
        .delete(
          PARKING_TYPE_ACTIVE.replace("{tenantId}", tenantId)
            .replace("{locationId}", locationId)
            .replace("{id}", id)
        )
        .then((res) => {
          fetchParkingReport();
          return res;
        });
      return resp;
    } catch (err) {
      return err;
    }
  };

  /*eslint-disable */
  useEffect(() => {
    fetchParkingReport();
  }, []);
  /*eslint-enable */

  return {
    open,
    rows,
    columns,
    formValues,
    searchValue,
    isDisabled,
    errorMessage,
    handleChange,
    handleClose,
    handleEdit,
    handleClear,
    handleClickOpen,
    handleSearchChange,
    findDuplicateParkingType,
  };
}
