import {
  ADD_SLOT,
  ALLOT_SLOT,
  DELETE_SLOT,
  GET_ACTIVE_SLOT,
  GET_ALL_SLOTS,
  GET_SLOT,
  GET_SLOTS,
  GET_SLOT_COUNT_URL,
  RESTORE_SLOT,
  UNALLOCATE_SLOT,
  UPDATE_SLOT,
} from "../services/apiUrls";
import { Delete, Put, get, post } from "../services/service";

export const fetchAllSlots = async (locationId) => {
  let response = await get(GET_SLOTS.replace("{locationId}", locationId));
  return response;
};

export const fetchAllSlotsWithoutFloor = async (
  tenantId,
  locationId,
  vehicleId,
  parkingTypeId
) => {
  let response = await get(
    GET_ALL_SLOTS.replace("{tenantId}", tenantId)
      .replace("{locationId}", locationId)
      .replace("{vehicleId}", vehicleId)
      .replace("{parkingTypeId}", parkingTypeId)
  );
  return response;
};

export const fetchActiveSlots = async (
  // floorId,
  locationId,
  vehicleId,
  parkingTypeId
) => {
  let response = await get(
    GET_ACTIVE_SLOT.replace("{locationId}", locationId)
      // .replace("{floorId}", floorId)
      .replace("{vehicleId}", vehicleId)
      .replace("{parkingTypeId}", parkingTypeId)
  );
  return response;
};

export const fetchOccupiedAndAvailableSlots = async (
  // floorId,
  locationId,
  vehicleId,
  parkingTypeId
) => {
  let response = await get(
    GET_SLOT_COUNT_URL.replace("{locationId}", locationId)
      // .replace("{floorId}", floorId)
      .replace("{vehicleTypeId}", vehicleId)
      .replace("{parkingTypeId}", parkingTypeId)
  );
  return response;
};

export const allotSlot = async (floorId, locationId, slotId) => {
  let response = await Put(
    ALLOT_SLOT.replace("{floorId}", floorId)
      .replace("{locationId}", locationId)
      .replace("{slotId}", slotId)
  );
  return response;
};

export const unAllocateSlot = async (floorId, locationId, slotId) => {
  let response = await Put(
    UNALLOCATE_SLOT.replace("{floorId}", floorId)
      .replace("{locationId}", locationId)
      .replace("{slotId}", slotId)
  );
  return response;
};

export const fetchSlot = async (locationId, slotId) => {
  let response = await get(
    GET_SLOT.replace("{locationId}", locationId).replace("{slotId}", slotId)
  );
  return response;
};

export const addSlot = async (locationId, formValues) => {
  let response = await post(
    ADD_SLOT.replace("{locationId}", locationId),
    formValues
  );
  return response;
};

export const updateSlot = async (locationId, formValues) => {
  let response = await Put(
    UPDATE_SLOT.replace("{locationId}", locationId),
    formValues
  );
  return response;
};

export const deleteSlot = async (slotId, locationId) => {
  let response = Delete(
    DELETE_SLOT.replace("{slotId}", slotId).replace("{locationId}", locationId)
  );
  return response;
};

export const restoreSlot = async (slotId, locationId) => {
  let response = Delete(
    RESTORE_SLOT.replace("{slotId}", slotId).replace("{locationId}", locationId)
  );
  return response;
};
