import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import React from "react";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  input: {
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
});

const SlotMasterForm = ({
  open,
  toast,
  formValue,
  isDisabled,
  handleClose,
  errorMessage,
  // handleSubmit,
  handleCheckBox,
  parkingTypeData,
  vehicleTypeData,
  handleInputChange,
  findDuplicateSlot,
}) => {
  const classes = useStyles();
  return (
    <>
      <Dialog fullWidth open={open} onClose={handleClose}>
        <Box
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"space-between"}
        >
          <DialogTitle className="text-[#312C41] !text-[16px] !font-semibold">
            Slot Master
          </DialogTitle>

          <CancelRoundedIcon
            onClick={handleClose}
            className="bg-[white] !text-[#344767] !text-[1.7rem] !outline-none p-4"
          />
        </Box>

        <DialogContent>
          <Grid container spacing={2}>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <TextField
                required
                size="small"
                fullWidth
                name="name"
                type="text"
                id="name"
                label="Name"
                className={classes.input}
                InputLabelProps={{ shrink: true }}
                value={formValue.name}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <TextField
                required
                size="small"
                fullWidth
                name="description"
                type="text"
                id="description"
                label="Description"
                className={classes.input}
                InputLabelProps={{ shrink: true }}
                value={formValue.description}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <FormControl fullWidth size="small">
                <InputLabel size="small" required>
                  Vehicle type
                </InputLabel>
                <Select
                  labelId="Vehicle type"
                  label="Vehicle_type*"
                  name="vehicleTypeId"
                  value={formValue?.vehicleTypeId}
                  onChange={handleInputChange}
                >
                  {vehicleTypeData?.map((obj) => {
                    if (obj.deleted === false) {
                      return <MenuItem value={obj.id}>{obj.name}</MenuItem>;
                    } else {
                      return null;
                    }
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <FormControl fullWidth size="small">
                <InputLabel size="small" required>
                  Parking type
                </InputLabel>
                <Select
                  labelId="Parking type"
                  label="Parking type*"
                  name="parkingTypeId"
                  value={formValue?.parkingTypeId}
                  onChange={handleInputChange}
                >
                  {/* eslint-disable */}
                  {parkingTypeData?.map((obj) => {
                    if (obj.deleted === false)
                      return <MenuItem value={obj.id}>{obj.name}</MenuItem>;
                  })}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Typography variant="subtitle2" className="text-[#DD2121]">
            {errorMessage}
          </Typography>

          <Stack direction="row" className="m-1">
            {/* <FormControlLabel
              control={
                <Checkbox
                  defaultChecked
                  sx={{ "& .MuiSvgIcon-root": { color: "#2F2A42" } }}
                />
              }
              onChange={handleCheckBox}
              name="active"
              label="Active"
              checked={formValue?.active === true ? true : false}
            /> */}

            <FormControlLabel
              control={
                <Checkbox
                  defaultChecked
                  sx={{ "& .MuiSvgIcon-root": { color: "#2F2A42" } }}
                />
              }
              onChange={handleCheckBox}
              name="alotted"
              label="Alotted"
              checked={formValue?.alotted === false ? false : true}
            />
          </Stack>

          <Stack direction="row" className="mt-5">
            <Button
              disabled={!isDisabled}
              onClick={findDuplicateSlot}
              fullWidth
              sx={{
                textTransform: "none",
                background: !isDisabled
                  ? "gray"
                  : "linear-gradient(135deg, #3A416F 0%, #141727 100%)",
                color: "white",
              }}
            >
              {formValue.id ? "Update" : "Add"}
            </Button>
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default SlotMasterForm;
