import React from "react";
import { Link } from "react-router-dom";
import { Search } from "@mui/icons-material";
import MainLayout from "../../../containers/MainLayout";
import AddBoxRoundedIcon from "@mui/icons-material/AddBoxRounded";
import {
  Box,
  Button,
  TextField,
  Typography,
  InputAdornment,
} from "@mui/material";
import ExitBoothForm from "../components/ExitBoothForm";
import ExitBoothTable from "../components/ExitBoothTable";
import { useExitBoothMaster } from "../hooks/useExitBoothMaster";

const ExitBoothMasterPage = () => {
  const {
    open,
    setOpen,
    columns,
    formValue,
    isDisabled,
    handleClose,
    handleSubmit,
    handleInputChange,
    handleSearchChange,
    exitBoothFilterData,
  } = useExitBoothMaster();
  return (
    <>
      <MainLayout>
        <Typography className="text-[#252F40] !mb-2">
          <Link to="/dashboard" className="no-underline">
            <span className="text-[#8C8C8C]">Masters / </span>
          </Link>
          Exit Booth
        </Typography>
        <Box
          sx={{
            boxShadow: " 0px 5px 15px -2px rgba(0, 0, 0, 0.12)",
            borderRadius: "2rem",
            width: "100%",
          }}
        >
          <Box className="flex justify-between px-3 py-2 items-center bg-[white]">
            <Box display="flex" p={1} alignItems="center">
              <TextField
                label="Search"
                onChange={handleSearchChange}
                size="small"
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Search />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>

            <Button
              variant="contained"
              onClick={() => setOpen(true)}
              className="!bg-[#3A416F] gap-2"
            >
              Add <AddBoxRoundedIcon />
            </Button>
            <ExitBoothForm
              open={open}
              formValue={formValue}
              isDisabled={isDisabled}
              handleClose={handleClose}
              handleSubmit={handleSubmit}
              handleInputChange={handleInputChange}
            />
          </Box>
          <ExitBoothTable
            columns={columns}
            exitBoothFilterData={exitBoothFilterData}
          />
        </Box>
      </MainLayout>
    </>
  );
};

export default ExitBoothMasterPage;
