import React from "react";
import moment from "moment";
import {
  Card,
  Paper,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  TableContainer,
  tableCellClasses,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/system";
import { Oval } from "react-loader-spinner";

const CashCollectReportTable = ({
  value,
  cashCollectData,
  cashCollectLoader,
  FilterCashCollectData,
}) => {
  const data = FilterCashCollectData ?? [];

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#576782",
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const navigate = useNavigate();

  return (
    <Card>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 500 }}>
          <TableHead>
            <TableRow>
              <StyledTableCell>NO.</StyledTableCell>
              <StyledTableCell align="center">DATE</StyledTableCell>
              <StyledTableCell align="right">PAYMENT MODE</StyledTableCell>
              <StyledTableCell align="right">
                TRANSACTION AMOUNT
              </StyledTableCell>
              <StyledTableCell align="right">REFUND AND COMPLIMENTARY</StyledTableCell>
              <StyledTableCell align="right">NET AMOUNT</StyledTableCell>
              <StyledTableCell align="right">ACTION</StyledTableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {cashCollectLoader === true ? (
              <TableCell colSpan={11}>
                <Box className="flex flex-col items-center justify-center">
                  Loading please wait...
                  <Oval
                    height={20}
                    width={20}
                    color="#4fa94d"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                    ariaLabel="oval-loading"
                    secondaryColor="#4fa94d"
                    strokeWidth={2}
                    strokeWidthSecondary={2}
                  />
                </Box>
              </TableCell>
            ) : (
              <>
                {data?.length > 0 ? (
                  <>
                    {data?.map((row, id) => (
                      <TableRow key={row.id}>
                        <TableCell component="th" scope="row">
                          {1 + id}
                        </TableCell>
                        <TableCell align="right">
                          {moment(row?.fromDate).format("DD/MM/YYYY")}
                        </TableCell>
                        <TableCell align="right">{row.paymentMode}</TableCell>
                        <TableCell align="right">{row.total}</TableCell>
                        <TableCell align="right">{row.refund}</TableCell>
                        <TableCell align="right">{row.netAmount}</TableCell>
                        <TableCell align="right">
                          <VisibilityIcon
                            sx={{ cursor: "pointer" }}
                            onClick={() => {
                              navigate(`/cashcollect/view/${row.paymentMode}`, {
                                state: {
                                  date: row?.fromDate,
                                  userId: value?.userId,
                                },
                              });
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </>
                ) : (
                  <TableCell colSpan={11}>
                    <Typography className="flex items-center justify-center">
                      No data found!
                    </Typography>
                  </TableCell>
                )}
              </>
            )}

            {cashCollectLoader === false ? (
              <>
                {data?.length > 0 ? (
                  <TableRow>
                    <TableCell colSpan={3} align="right">
                      <b className="text-[16px]">Total</b>
                    </TableCell>

                    <TableCell align="right">
                      <span style={{ fontWeight: "bold" }}>
                        {cashCollectData?.totalCashCollect}
                      </span>
                    </TableCell>
                    <TableCell align="right">
                      <span style={{ fontWeight: "bold" }}>
                        {cashCollectData?.totalRefund}
                      </span>
                    </TableCell>
                    <TableCell align="right">
                      <span style={{ fontWeight: "bold" }}>
                        {cashCollectData?.totalNetAmount}
                      </span>
                    </TableCell>
                  </TableRow>
                ) : (
                  ""
                )}
              </>
            ) : (
              ""
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};

export default CashCollectReportTable;
