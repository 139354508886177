import React from "react";
import { Link } from "react-router-dom";
import MainLayout from "../../../containers/MainLayout";
import {
  Box,
  Button,
  Card,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  tableCellClasses,
} from "@mui/material";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useTransactionPage } from "../hook/useTransactionPage";
// import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { styled } from "@mui/material/styles";
import { Oval } from "react-loader-spinner";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import VisibilityIcon from "@mui/icons-material/Visibility";
import moment from "moment";

const TransactionsPage = () => {
  const {
    week,
    month,
    TenDays,
    loading,
    formValues,
    toDateTime,
    fromDateTime,
    totalTransactions,
    transactionDeatils,
    yesterdayConverted,
    currentDateConverted,
    navigate,
    getTransactionReport,
    handleInputChange,
    // handleFromDateTimeChange,
    getTransactionReportByDate,
  } = useTransactionPage();

  console.log('totalTransactions', totalTransactions)

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#576782",
      color: theme.palette.common.white,
      fontSize: 12,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 12,
    },
  }));

  const exportToExcel = (e, data) => {
    const ws = XLSX.utils.json_to_sheet(totalTransactions);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Detail Report");
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, "report.xlsx");
  };

  return (
    <MainLayout>
      <Typography className="text-[#252F40] !mb-2">
        <Link to="/dashboard" className="no-underline">
          <span className="text-[#8C8C8C]">Reports / </span>
        </Link>
        Transactions Report
      </Typography>
      <Box
        sx={{
          background: "white",
          borderRadius: "10px",
          boxShadow: "0px 3.75px 7.5px rgba(0, 0, 0, 0.12)",
        }}
        className="mb-3 p-5"
      >
        <Grid container spacing={1} className="items-center">
          <Grid item lg={8} className="flex gap-2 flex-wrap items-center">
            {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                value={fromDateTime}
                label="Date From"
                slotProps={{ textField: { size: "small" } }}
                onChange={handleFromDateTimeChange}
              />
            </LocalizationProvider> */}

            <TextField
              id="fromDate"
              label="Date from"
              variant="outlined"
              type="date"
              size="small"
              name="fromDate"
              focused
              value={formValues.fromDate}
              onChange={handleInputChange}
            />

            <TextField
              id="toDate"
              label="Date To"
              variant="outlined"
              type="date"
              name="toDate"
              size="small"
              focused
              value={formValues.toDate}
              onChange={handleInputChange}
            />

            {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                value={toDateTime}
                label="Date To"
                slotProps={{ textField: { size: "small" } }}
                onChange={handleToDateTimeChange}
              />
            </LocalizationProvider> */}
            <Box>
              <Button
                name="search"
                onClick={(e) => {
                  if (fromDateTime && toDateTime) {
                    getTransactionReport(e);
                  }
                }}
                sx={{
                  background: "#576782",
                  color: "white",
                  "&:hover": { background: "#3e4859" },
                }}
                variant="contained"
                size="small"
              >
                Search
              </Button>
            </Box>
          </Grid>
        </Grid>

        <Grid container className="items-center" mt={2}>
          <Grid items lg={6}>
            <Stack direction="row" spacing className="flex-wrap gap-1">
              <Button
                name="today"
                onClick={(e) =>
                  getTransactionReportByDate(
                    e,
                    currentDateConverted,
                    currentDateConverted
                  )
                }
                sx={{
                  background: "#576782",
                  color: "white",
                  "&:hover": { background: "#3e4859" },
                }}
                variant="contained"
                size="small"
              >
                Today
              </Button>
              <Button
                name="yesterday"
                onClick={(e) =>
                  getTransactionReportByDate(
                    e,
                    yesterdayConverted,
                    yesterdayConverted
                  )
                }
                sx={{
                  background: "#576782",
                  color: "white",
                  "&:hover": { background: "#3e4859" },
                }}
                variant="contained"
                size="small"
              >
                Yesterday
              </Button>
              <Button
                name="week"
                onClick={(e) => {
                  getTransactionReportByDate(e, week, currentDateConverted);
                }}
                sx={{
                  background: "#576782",
                  color: "white",
                  "&:hover": { background: "#3e4859" },
                }}
                variant="contained"
                size="small"
              >
                One Week
              </Button>
              <Button
                name="tendays"
                onClick={(e) =>
                  getTransactionReportByDate(e, TenDays, currentDateConverted)
                }
                sx={{
                  background: "#576782",
                  color: "white",
                  "&:hover": { background: "#3e4859" },
                }}
                variant="contained"
                size="small"
              >
                10 Days
              </Button>
              <Button
                name="month"
                onClick={(e) =>
                  getTransactionReportByDate(e, month, currentDateConverted)
                }
                sx={{
                  background: "#576782",
                  color: "white",
                  "&:hover": { background: "#3e4859" },
                }}
                variant="contained"
                size="small"
              >
                One Month
              </Button>
            </Stack>
          </Grid>
          <Grid item lg={6} className="flex justify-end pl-2">
            <Button
              variant="outlined"
              onClick={(e) => exportToExcel(e, transactionDeatils)}
            >
              Export to excel
            </Button>
          </Grid>
        </Grid>
      </Box>

      <Box
        sx={{
          height: 475,
          width: "100%",
          "& .super-app-theme--header": {
            backgroundColor: "#576782",
            fontWight: "bold",
            color: "white",
          },
          "& .css-1pe4mpk-MuiButtonBase-root-MuiIconButton-root": {
            color: "white",
          },
          "& .MuiDataGrid-sortIcon": {
            color: "white",
          },
        }}
      >
        <Card>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 500 }}>
              <TableHead>
                <TableRow>
                  <StyledTableCell>NO.</StyledTableCell>
                  <StyledTableCell>DATE</StyledTableCell>
                  <StyledTableCell align="right">TOTAL AMOUNT</StyledTableCell>
                  <StyledTableCell align="right">TOTAL VEHICLE</StyledTableCell>
                  <StyledTableCell align="right">
                    VEHICLE ON PREMISES
                  </StyledTableCell>
                  <StyledTableCell align="right">4W AMOUNT</StyledTableCell>
                  <StyledTableCell align="right">4W COUNT</StyledTableCell>
                  <StyledTableCell align="right">{`4W < 15`}</StyledTableCell>
                  <StyledTableCell align="right">
                    4W REFUND AMOUNT
                  </StyledTableCell>
                  <StyledTableCell align="right">2W AMOUNT</StyledTableCell>
                  <StyledTableCell align="right">2W COUNT</StyledTableCell>
                  <StyledTableCell align="right">{`2W < 15`}</StyledTableCell>
                  <StyledTableCell align="right">
                    2W REFUND AMOUNT
                  </StyledTableCell>
                  <StyledTableCell align="right">ACTION</StyledTableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {loading === true ? (
                  <TableCell colSpan={14}>
                    <Box className="flex flex-col items-center justify-center">
                      Loading please wait...
                      <Oval
                        height={20}
                        width={20}
                        color="#4fa94d"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                        ariaLabel="oval-loading"
                        secondaryColor="#4fa94d"
                        strokeWidth={2}
                        strokeWidthSecondary={2}
                      />
                    </Box>
                  </TableCell>
                ) : (
                  <>
                    {totalTransactions?.length > 0 ? (
                      <>
                        {totalTransactions?.sort((a, b) => new Date(a?.date) - new Date(b?.date)).map((row, id) => {
                          return (
                            <TableRow key={row.id}>
                              <TableCell component="th" scope="row">
                                {1 + id}
                              </TableCell>
                              <TableCell align="right">{row?.date}</TableCell>
                              <TableCell align="right">
                                {row?.totalAmount}
                              </TableCell>
                              <TableCell align="right">
                                {row?.totalVehicle}
                              </TableCell>
                              <TableCell align="right">
                                {row?.vehicleOnPremises}
                              </TableCell>
                              <TableCell align="right">
                                {row?.fourWheelerAmount}
                              </TableCell>
                              <TableCell align="right">
                                {row?.fourWheelerCount}
                              </TableCell>
                              <TableCell align="right">
                                {row?.fourWheelerLessThanFifteen}
                              </TableCell>
                              <TableCell align="right">
                                {row?.fourWheelerRefund}
                              </TableCell>
                              <TableCell align="right">
                                {row?.twoWheelerAmount}
                              </TableCell>
                              <TableCell align="right">
                                {row?.twoWheelerCount}
                              </TableCell>
                              <TableCell align="right">
                                {row?.twoWheelerLessThanFifteen}
                              </TableCell>
                              <TableCell align="right">
                                {row?.twoWheelerRefund}
                              </TableCell>
                              <TableCell
                                align="right"
                                onClick={() => {
                                  navigate("/transaction/view", {
                                    state: {
                                      date: moment(
                                        row?.date,
                                        "DD/MM/YYYY"
                                      ).format("YYYY-MM-DD"),
                                    },
                                  });
                                }}
                              >
                                <VisibilityIcon sx={{ cursor: "pointer" }} />
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </>
                    ) : (
                      <TableCell colSpan={14}>
                        <Typography className="flex items-center justify-center">
                          No data found!
                        </Typography>
                      </TableCell>
                    )}
                  </>
                )}

                {loading === false ? (
                  <>
                    {transactionDeatils?.[0]?.detailReport?.length > 0 ? (
                      <>
                        <TableRow>
                          <TableCell colSpan={2} align="right">
                            <b className="text-[16px]">Total</b>
                          </TableCell>
                          <TableCell align="right">
                            <span style={{ fontWeight: "bold" }}>
                              {transactionDeatils?.[0]?.grandTotalAmount}
                            </span>
                          </TableCell>
                          <TableCell align="right">
                            <span style={{ fontWeight: "bold" }}>
                              {transactionDeatils?.[0]?.grandTotalVehicle}
                            </span>
                          </TableCell>
                          <TableCell align="right">
                            <span style={{ fontWeight: "bold" }}>
                              {transactionDeatils?.[0]?.grandVehicleOnPremises}
                            </span>
                          </TableCell>
                          <TableCell align="right">
                            <span style={{ fontWeight: "bold" }}>
                              {transactionDeatils?.[0]?.grandFourWheelerAmount}
                            </span>
                          </TableCell>
                          <TableCell align="right">
                            <span style={{ fontWeight: "bold" }}>
                              {transactionDeatils?.[0]?.grandFourWheelerCount}
                            </span>
                          </TableCell>
                          <TableCell align="right">
                            <span style={{ fontWeight: "bold" }}>
                              {
                                transactionDeatils?.[0]
                                  ?.grandFourWheelerLessThanFifteen
                              }
                            </span>
                          </TableCell>
                          <TableCell align="right">--</TableCell>
                          <TableCell align="right">
                            <span style={{ fontWeight: "bold" }}>
                              {transactionDeatils?.[0]?.grandTwoWheelerAmount}
                            </span>
                          </TableCell>
                          <TableCell align="right">
                            <span style={{ fontWeight: "bold" }}>
                              {transactionDeatils?.[0]?.grandTwoWheelerCount}
                            </span>
                          </TableCell>
                          <TableCell align="right">
                            <span style={{ fontWeight: "bold" }}>
                              {
                                transactionDeatils?.[0]
                                  ?.grandTwoWheelerLessThanFifteen
                              }
                            </span>
                          </TableCell>
                          <TableCell align="right">--</TableCell>

                          <TableCell align="right">--</TableCell>
                        </TableRow>
                      </>
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  ""
                )}

                {loading === false ? (
                  <>
                    {transactionDeatils?.[0]?.detailReport?.length > 0 ? (
                      <>
                        <TableRow>
                          <TableCell colSpan={12} align="right">
                            <b className="text-[16px]">
                              Total Amount collected
                            </b>
                          </TableCell>
                          <TableCell align="right">
                            <b className="text-[16px] text-[#32ad4b]">
                              {transactionDeatils?.[0]?.grandTotalAmount}
                            </b>
                          </TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      </>
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  ""
                )}

                {loading === false ? (
                  <>
                    {transactionDeatils?.[0]?.detailReport?.length > 0 ? (
                      <>
                        <TableRow>
                          <TableCell colSpan={12} align="right">
                            <b className="text-[16px]">Total Refund</b>
                          </TableCell>
                          <TableCell align="right">
                            <b className="text-[16px] text-[#d33838]">
                              {transactionDeatils?.[0]?.grandTotalRefund}
                            </b>
                          </TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      </>
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  ""
                )}

                {loading === false ? (
                  <>
                    {transactionDeatils?.[0]?.detailReport?.length > 0 ? (
                      <>
                        <TableRow>
                          <TableCell colSpan={12} align="right">
                            <b className="text-[16px]">
                              Total Complimentary amount
                            </b>
                          </TableCell>
                          <TableCell align="right">
                            <b className="text-[16px] text-[#d33838]">
                              {transactionDeatils?.[0]?.grandTotalComplimentary}
                            </b>
                          </TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      </>
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  ""
                )}

                {loading === false ? (
                  <>
                    {transactionDeatils?.[0]?.detailReport?.length > 0 ? (
                      <>
                        <TableRow>
                          <TableCell colSpan={12} align="right">
                            <b className="text-[16px]">Total Cash in hand</b>
                          </TableCell>
                          <TableCell align="right">
                            <b className="text-[16px] text-[#32ad4b]">
                              {transactionDeatils?.[0]?.grandTotalAmount -
                                transactionDeatils?.[0]?.grandTotalRefund -
                                transactionDeatils?.[0]
                                  ?.grandTotalComplimentary}
                            </b>
                          </TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      </>
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  ""
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      </Box>
    </MainLayout>
  );
};

export default TransactionsPage;
