import React from "react";
import { Link } from "react-router-dom";
import { Search } from "@mui/icons-material";
import MainLayout from "../../../containers/MainLayout";
import AddBoxRoundedIcon from "@mui/icons-material/AddBoxRounded";
import { Toaster } from "react-hot-toast";
import {
  Box,
  Button,
  InputAdornment,
  TextField,
  Typography,
  Snackbar,
  Alert,
} from "@mui/material";
import { useExtraCharges } from "../hooks/useExtraCharges";
import ExtraChargesForm from "../components/ExtraChargesForm";
import ExtraChargesTable from "../components/ExtraChargesTable";

const ExtraChargesMasterPage = () => {
  const {
    open,
    columns,
    formValue,
    isDisabled,
    handleClose,
    handleCheckBox,
    handleClickOpen,
    handleInputChange,
    handleSearchChange,
    ExtraChargesFilterDatas,
    extraChargesTableDataList,
    extraChargesHandleSubmit,
    toast,
  } = useExtraCharges();
  return (
    <MainLayout>
      <Typography className="text-[#252F40] !mb-2">
        <Link to="/dashboard" className="no-underline">
          <span className="text-[#8C8C8C]">Masters / </span>
        </Link>
        Extra Charges
      </Typography>
      <Box
        sx={{
          boxShadow: " 0px 5px 15px -2px rgba(0, 0, 0, 0.12)",
          borderRadius: "2rem",
          width: "100%",
        }}
      >
        <Box className="flex justify-between px-3 py-2 items-center bg-[white]">
          <Box display="flex" p={1} alignItems="center">
            <TextField
              label="Search"
              onChange={handleSearchChange}
              size="small"
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
          </Box>

          <Button
            variant="contained"
            onClick={handleClickOpen}
            className="!bg-[#3A416F] gap-2"
          >
            Add <AddBoxRoundedIcon />
            <Toaster position="top-center" />
          </Button>
          <Snackbar open={toast.show} autoHideDuration={1000}>
            <Alert severity={toast.type} sx={{ width: "100%" }}>
              {toast?.messsege}
            </Alert>
          </Snackbar>
          <ExtraChargesForm
            open={open}
            formValue={formValue}
            isDisabled={isDisabled}
            handleClose={handleClose}
            handleCheckBox={handleCheckBox}
            handleInputChange={handleInputChange}
            extraChargesHandleSubmit={extraChargesHandleSubmit}
          />
        </Box>
        <ExtraChargesTable
          columns={columns}
          ExtraChargesFilterDatas={ExtraChargesFilterDatas}
          extraChargesTableDataList={extraChargesTableDataList}
        />
      </Box>
    </MainLayout>
  );
};

export default ExtraChargesMasterPage;
