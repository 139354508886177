import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Button,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import PasswordStrengthBar from "react-password-strength-bar";
import useAuth from "../hooks/useAuth";

const ChangePasswordChildren = ({
  handleCloseChangePassword,
  handleOpenSuccessPopup,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [passwordsMatch, setPasswordsMatch] = useState(true);

  const [formValues, setFormValues] = useState({
    newPassword: "",
    confirmNewPassword: "",
  });

  const handleInputChange = (e) => {
    setFormValues({ ...formValues, [e.target.name]: e.target.value });
  };

  let isButtonDisabled =
    formValues.newPassword !== "" && formValues.confirmNewPassword !== "";

  const { changePassCode } = useAuth();

  const handleShowPassword = () => setShowPassword((show) => !show);

  const handlePasswordChange = async () => {
    let data = {
      newPassword: formValues.newPassword,
      confirmPassword: formValues.confirmNewPassword,
      userId: localStorage.getItem("userId"),
    };
    let response = await changePassCode(data);
    if (response) {
      handleCloseChangePassword();
      handleOpenSuccessPopup();
    }
    return response;
  };

  const validatePasswordsMatch = (passwordValue, confirmPasswordValue) => {
    if (passwordValue === confirmPasswordValue) {
      setPasswordsMatch(true);
    } else {
      setPasswordsMatch(false);
    }
  };

  useEffect(() => {
    if (formValues.newPassword !== "" && formValues.confirmNewPassword) {
      validatePasswordsMatch(
        formValues.newPassword,
        formValues.confirmNewPassword
      );
    }
  }, [formValues.newPassword, formValues.confirmNewPassword]);

  return (
    <>
      <Typography variant="h6" className="!font-bold text-center !mt-[-15px]">
        Change password
      </Typography>
      <Typography variant="subtitle2" className="text-center text-[#8692A6]">
        We are here to help!
      </Typography>
      <Stack spacing={4} mt={4} mx={4} mb={4}>
        <TextField
          required
          fullWidth
          name="newPassword"
          type={showPassword ? "text" : "password"}
          id="new-password"
          label="Enter new password"
          size="small"
          onChange={handleInputChange}
          value={formValues.newPassword}
          InputProps={{
            disableUnderline: true,
            endAdornment: (
              <InputAdornment
                onMouseEnter={(e) => (e.target.style.cursor = "pointer")}
                position="end"
                onClick={handleShowPassword}
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </InputAdornment>
            ),
          }}
        />
        <PasswordStrengthBar password={formValues.newPassword} />
        <TextField
          required
          fullWidth
          name="confirmNewPassword"
          type="password"
          id="confirm-password"
          label="Confirm new password"
          size="small"
          value={formValues.confirmNewPassword}
          onChange={handleInputChange}
        />
        {!passwordsMatch ? (
          <Typography variant="caption" className="text-[#DD2121] !mt-[4px]">
            Passwords do not match
          </Typography>
        ) : (
          ""
        )}
        <Button
          variant="contained"
          disabled={!isButtonDisabled}
          className="h-[2.5rem] !border-[#3A416F] !text-[#ffffff] !bg-[#3A416F] !rounded-md !capitalize"
          onClick={handlePasswordChange}
        >
          Submit
        </Button>
      </Stack>
    </>
  );
};

export default ChangePasswordChildren;
