import React, { useRef } from "react";
import {
  Alert,
  Box,
  Button,
  Card,
  FormControl,
  Grid,
  InputAdornment,
  // MenuItem,
  OutlinedInput,
  Snackbar,
  Stack,
  // TextField,
  Typography,
} from "@mui/material";
import "../../../../assets/css/style.css";
import entry_logo from "../../../../assets/images/entry_logo1.svg";
import car from "../../../../assets/images/car_entry_exit.png";
import bike from "../../../../assets/images/bike_entry_exit.png";
import cashWhite from "../../../../assets/images/CashVectorWhite.svg";
import cashBlue from "../../../../assets/images/Cash_icon_normal.svg";
import upiBlue from "../../../../assets/images/UPI_Og.svg";
import upiWhite from "../../../../assets/images/UPI_Whiteout.svg";
import { useEntryDetails } from "./hooks/useEntryDetails";
import { useLocation } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import ReactToPrint from "react-to-print";
import BlackAndWhiteLogo from "../../../../assets/images/parkezy_black_and_white.png";
import PrintIcon from "@mui/icons-material/Print";
import PopUp from "../../../../containers/components/PopUp";
import moment from "moment";
import { makeStyles } from "@mui/styles";
import { Oval } from "react-loader-spinner";

const useOutlinedTextStyle = makeStyles((theme) => ({
  root: {
    "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    height: "37px",
    width: "100px !important",
    backgroundColor: "#f1f1f1",
    fontSize: "22px !important",
    fontWeight: "800 !important",
    color: "#344767 !important",
    borderRadius: "8px !important",
  },
}));

const EntryDetailsPage = () => {
  const {
    qr,
    toast,
    slotCount,
    showPopUp,
    formValues,
    // activeSlots,
    parkingTypes,
    parkingTypeId,
    entryDetails,
    // activeFloors,
    parkingAmount,
    // activefloorId,
    toastForNoSlot,
    handlePopUp,
    handleChange,
    // handleFloorChange,
    handleEntryClear,
    handlePaymentMode,
    handleParkingType,
    handleEntrySubmit,
    isLoading,
    // getCameraReading,
  } = useEntryDetails();

  const { state } = useLocation();

  const componentRef = useRef();

  const classes = useOutlinedTextStyle();

  const printChildren = (
    <>
      <Box
        className="w-[16rem] m-auto"
        style={{
          boxShadow:
            "rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px",
          borderRadius: 8,
        }}
      >
        <Box ref={componentRef}>
          <Stack className="justify-center items-center">
            <img src={BlackAndWhiteLogo} width={100} alt="logo" />
            ------------------------------------------------
            <Stack direction="row" className="justify-center items-center">
              <Typography
                variant="subtitle2"
                sx={{ fontWeight: "bold", fontSize: "19px" }}
              >
                GOKULAM GALLERIA
              </Typography>
            </Stack>
          </Stack>
          <Stack className="justify-center items-center">
            ------------------------------------------------
            <Box>
              <Typography variant="subtitle2" className="!font-semibold">
                GSTIN 32AAECS2586E1ZI
              </Typography>
            </Box>
            <Box className="flex">
              <Typography variant="subtitle2" className="!font-semibold">
                Date: {moment(entryDetails?.entryTime).format("DD/MM/YYYY")}
              </Typography>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <Typography variant="subtitle2" className="!font-semibold">
                Time: {moment(entryDetails?.entryTime).format("hh:mm A")}
              </Typography>
            </Box>
            <img src={qr} width={200} alt="qr" />
            <Typography
              variant="subtitle2"
              className="!text-[1.5rem] !font-bold !mt-[-1.2rem]"
            >
              {entryDetails?.vrn}
            </Typography>
            <Typography variant="caption" className="!mt-[-0.5rem]">
              {entryDetails?.parkingTypeName?.toUpperCase()} PARKING
            </Typography>
            <Typography variant="h6" className="font-extrabold">
              PAID:{" "}
              <span style={{ fontSize: "27px", fontWeight: "bold" }}>
                ₹{entryDetails?.parkingAmount}
              </span>
            </Typography>
            <Typography variant="caption" className="!mt-[-0.5rem]">
              Fee Rs: 16.95, GST: Rs. 3.05
            </Typography>
            <Typography variant="caption">The base fee for 4 hours</Typography>
            ------------------------------------------------
            <Typography className="!text-[0.7rem]">
              THANK YOU ! PLEASE KEEP THIS SAFELY
            </Typography>
            ------------------------------------------------
          </Stack>
        </Box>
      </Box>
      <ReactToPrint
        trigger={() => (
          <Button className="ms-2">
            <PrintIcon className="!text-[2rem]" />
          </Button>
        )}
        content={() => componentRef.current}
      />
    </>
  );

  const isDisabled =
    formValues?.vrn?.length !== 0 &&
    formValues?.parkingAmount?.length !== 0
    // activeSlots?.length !== 0;

  return (
    <>
      <div className="background-fixed-with-gradiant">
        <Card className="!rounded-xl xs:mx-3 md:mx-6 lg:mx-6 xl:mx-6 mt-16">
          <Grid container className="p-[2%]">
            <Grid item xs={12} sm={12} md={6}>
              <Box className="card-box">
                <img
                  src={
                    formValues.vehicleImgUrl === ""
                      ? entry_logo
                      : formValues.vehicleImgUrl
                  }
                  height={220}
                  alt="logo"
                />
              </Box>
              <Grid>
                <Box
                  xs={12}
                  sm={7}
                  className="flex justify-center flex-col mt-3"
                >
                  <Typography
                    sx={{ color: "#344767" }}
                    className="flex justify-center !mt-2"
                  >
                    Vehicle Number
                  </Typography>

                  <Box className="flex justify-center">
                    <input
                      name="vrn"
                      autoFocus
                      value={formValues.vrn}
                      placeholder="Vehicle Number"
                      className="number-box uppercase placeholder:normal-case"
                      onChange={handleChange}
                      onInput={(e) => {
                        e.target.value = e.target.value.match(/^[A-Za-z0-9]+$/);
                      }}
                    />
                    {/* <Button onClick={getCameraReading}>Read</Button> */}
                  </Box>
                </Box>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={12} md={6} className="lg:!px-[2rem]">
              <Typography
                className="flex justify-evenly !mt-8 text-[#3A416F]"
                variant="subtitle2"
              >
                Parking Type
              </Typography>
              {/* eslint-disable */}
              <Box className="mt-5 flex flex-wrap justify-center gap-5">
                {parkingTypes?.map((value, index) => {
                  if (value?.deleted == false) {
                    return (
                      <Button
                        variant={
                          value.id == parkingTypeId ? "contained" : "outlined"
                        }
                        key={index}
                        id={value?.id}
                        style={{
                          background:
                            value.id == parkingTypeId ? "#3A416F" : "#fff",
                          color: value.id == parkingTypeId ? "#fff" : "#3A416F",
                        }}
                        className="toggle-buttons h-[1rem] !border-[#3A416F] !rounded-xl !capitalize"
                        onClick={(e) => {
                          handleParkingType(e);
                        }}
                      >
                        {value?.name}
                      </Button>
                    );
                  }
                })}
              </Box>
              {/* /*eslint-enable */}

              <Stack
                container
                className="!px-[2rem] justify-center !mt-[1rem]"
                direction={{ xs: "row", sm: "row" }}
                spacing={2}
              >
                <Stack spacing={2} className="justify-center">
                  <Typography
                    className="flex justify-center text-[#3A416F]"
                    variant="subtitle2"
                  >
                    Base Fee
                  </Typography>
                  {parkingAmount == undefined ? (
                    <Oval
                      height={20}
                      width={20}
                      color="#4fa94d"
                      wrapperStyle={{}}
                      wrapperClass=""
                      visible={true}
                      ariaLabel="oval-loading"
                      secondaryColor="#4fa94d"
                      strokeWidth={2}
                      strokeWidthSecondary={2}
                    />
                  ) : (
                    <FormControl variant="outlined">
                      <OutlinedInput
                        id="outlined-adornment-weight"
                        name="parkingAmount"
                        value={parkingAmount}
                        startAdornment={
                          <InputAdornment position="start">₹</InputAdornment>
                        }
                        fullWidth
                        className={classes.root}
                        sx={{ borderBlock: "red" }}
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                          "aria-label": "weight",
                        }}
                        onChange={handleChange}
                      />
                    </FormControl>
                  )}
                </Stack>

                <Stack spacing={2}>
                  <Typography
                    className="flex justify-center text-[#3A416F]"
                    variant="subtitle2"
                  >
                    Payment Mode
                  </Typography>
                  <Stack
                    direction={"row"}
                    spacing={2}
                    className="flex justify-center"
                  >
                    <Button
                      name="UPI"
                      className="button-shadow !rounded-lg gap-1 !capitalize"
                      sx={
                        formValues.paymentMode == "UPI"
                          ? {
                              bgcolor: "#3A416F",
                              color: "#3A416F",
                              "&:hover": {
                                backgroundColor: "#3A416F !important",
                              },
                            }
                          : {
                              bgcolor: "#ffffff",
                              color: "#ffffff",
                              "&:hover": {
                                backgroundColor: "#ffffff !important",
                              },
                            }
                      }
                      onClick={(e) => handlePaymentMode("UPI")}
                    >
                      <img
                        src={
                          formValues.paymentMode == "UPI" ? upiWhite : upiBlue
                        }
                        alt="icon"
                        width="50px"
                      />
                    </Button>

                    <Button
                      name="CASH"
                      className="button-shadow !rounded-lg px-1 !capitalize hover:!bg-[#3A416F]"
                      sx={
                        formValues.paymentMode == "CASH"
                          ? {
                              bgcolor: "#3A416F",
                              color: "#ffffff",
                              "&:hover": {
                                backgroundColor: "#3A416F !important",
                              },
                            }
                          : {
                              bgcolor: "#ffffff",
                              color: "#3A416F",
                              "&:hover": {
                                backgroundColor: "#ffffff !important",
                              },
                            }
                      }
                      onClick={(e) => handlePaymentMode("CASH")}
                    >
                      <div className="w-[1.5rem] flex justify-center gap-1">
                        <img
                          src={
                            formValues.paymentMode == "CASH"
                              ? cashWhite
                              : cashBlue
                          }
                          alt="icon"
                          width="100%"
                          height="auto"
                        />
                        Cash
                      </div>
                    </Button>

                    <Button
                      name="CARD"
                      className="button-shadow !rounded-lg px-1 !capitalize hover:!bg-[#3A416F]"
                      sx={
                        formValues.paymentMode == "CARD"
                          ? {
                              bgcolor: "#3A416F",
                              color: "#ffffff",
                              "&:hover": {
                                backgroundColor: "#3A416F !important",
                              },
                            }
                          : {
                              bgcolor: "#ffffff",
                              color: "#3A416F",
                              "&:hover": {
                                backgroundColor: "#ffffff !important",
                              },
                            }
                      }
                      onClick={(e) => handlePaymentMode("CARD")}
                    >
                      <div className="w-[1.5rem] flex justify-center gap-1">
                        <img
                          src={
                            formValues.paymentMode == "CARD"
                              ? cashWhite
                              : cashBlue
                          }
                          alt="icon"
                          width="100%"
                          height="auto"
                        />
                        Card
                      </div>
                    </Button>
                  </Stack>
                </Stack>
              </Stack>

              <Grid
                container
                gap={2}
                className="flex !flex-col-reverse sm:!flex-row md:!flex-row lg:!flex-row !justify-center !mt-10"
              >
                <Grid item>
                  <Button
                    fullWidth
                    className="!capitalize !text-[#3A416F] md:!w-[150px] lg:!w-[175px]"
                    variant="outlined"
                    onClick={handleEntryClear}
                  >
                    Clear
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    disabled={!isDisabled}
                    fullWidth
                    className="!capitalize disabled:opacity-25 !text-[#FFFFFF] md:!w-[150px] lg:!w-[175px]"
                    sx={{
                      background:
                        "linear-gradient(135deg, #3A416F 0%, #141727 100%), #2F2A42",
                    }}
                    variant="contained"
                    onClick={(e) => {
                      handleEntrySubmit(e);
                    }}
                  >
                    {isLoading ? (
                      <Oval
                        height={20}
                        width={20}
                        color="#4fa94d"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                        ariaLabel="oval-loading"
                        secondaryColor="#4fa94d"
                        strokeWidth={2}
                        strokeWidthSecondary={2}
                      />
                    ) : (
                      "Submit"
                    )}
                  </Button>
                </Grid>
              </Grid>

              {/* {activeSlots?.length === 0 ? (
                <Typography
                  variant="subtitle2"
                  className="text-center text-[#DD2121] !mt-3"
                >
                  No Slot Available
                </Typography>
              ) : (
                ""
              )} */}
            </Grid>
          </Grid>
        </Card>

        <Box className="flex justify-between">
          <Box>
            <Card className="absolute !min-w-[16rem] bg-red-[200] left-6 mt-2 p-3 !rounded-xl">
              <Stack direction="row" spacing={4} className="justify-center">
                <Stack>
                  <Typography variant="subtitle2">Available</Typography>
                  <Typography
                    variant="h5"
                    color="#27803B"
                    className="!font-bold"
                  >
                    {slotCount?.available ?? "--"}
                  </Typography>
                </Stack>
                <Stack>
                  <Typography variant="subtitle2">Occupied</Typography>
                  <Typography
                    variant="h5"
                    color="#C01111"
                    className="!font-bold"
                  >
                    {slotCount?.occuppied ?? "--"}
                  </Typography>
                </Stack>
                <Stack>
                  <Typography variant="subtitle2">Total</Typography>
                  <Typography
                    variant="h5"
                    color="#344767"
                    className="!font-bold"
                  >
                    {slotCount?.totalCount ?? "--"}
                  </Typography>
                </Stack>
              </Stack>
            </Card>
          </Box>

          {/* <Box className="flex justify-center flex-wrap sm:mt-24 xs:mt-24 md:mt-24 lg:mt-2">
            <Box className="mt-2 mr-6">
              <TextField
                className="!w-[200px] sm:!w-[300px] md:!w-[400px] lg:!w-[300px]"
                sx={{
                  "&:after": {
                    borderBottomColor: "#D9D9D9",
                  },
                  "& .MuiSvgIcon-root": {
                    color: "#D9D9D9",
                  },
                }}
                select
                label="Slot"
                name="slotId"
                fullWidth
                disabled={activeSlots?.length === 0}
                InputProps={{
                  sx: { color: "#F8F9FA" },
                }}
                InputLabelProps={{
                  sx: { color: "#F8F9FA" },
                }}
                value={formValues.slotId}
                onChange={handleChange}
              >
                {activeSlots?.map((obj, i) => {
                  return (
                    <MenuItem key={i} value={obj.id}>
                      {obj.name}
                    </MenuItem>
                  );
                })}
              </TextField>
            </Box>
            <Box className="mt-2 mr-6">
              <TextField
                className="!w-[200px] sm:!w-[300px] md:!w-[400px] lg:!w-[300px]"
                sx={{
                  "&:after": {
                    borderBottomColor: "#D9D9D9",
                  },
                  "& .MuiSvgIcon-root": {
                    color: "#D9D9D9",
                  },
                }}
                select
                label="Floor"
                fullWidth
                InputProps={{
                  sx: { color: "#F8F9FA" },
                }}
                InputLabelProps={{
                  sx: { color: "#F8F9FA" },
                }}
                value={activefloorId}
                onChange={handleFloorChange}
              >
                {activeFloors?.map((obj, i) => {
                  return (
                    <MenuItem key={i} value={obj.id}>
                      {obj.name}
                    </MenuItem>
                  );
                })}
              </TextField>
            </Box>
          </Box> */}
        </Box>
        <Box className="flex-col absolute left-5 bottom-30 hidden sm:hidden md:hidden lg:block bottom-14">
          <Typography className="text-gradiant !text-[2rem] !font-black">
            Park with ease !
          </Typography>
          <Typography className="text-[#576782] !text-[17px]">
            PARKezy makes parking hassle-free.
          </Typography>
        </Box>

        <Box className="!absolute !right-0 !bottom-2 ">
          {/* eslint-disable eqeqeq */}
          <img
            src={state?.vehicleId == 1 ? bike : car}
            className="w-200 h-[150px] hidden lg:block"
            alt="logo"
          />
        </Box>
        <Toaster position="top-center" />
      </div>
      <Snackbar open={toast.show} autoHideDuration={1000}>
        <Alert severity={toast.type} sx={{ width: "100%" }}>
          {toast?.messsege}
        </Alert>
      </Snackbar>
      <Snackbar open={toastForNoSlot.show} autoHideDuration={1000}>
        <Alert severity={toastForNoSlot.type} sx={{ width: "100%" }}>
          {toastForNoSlot?.message}
        </Alert>
      </Snackbar>
      activeSlots
      <PopUp
        open={showPopUp}
        handleClose={handlePopUp}
        popUpTitle={"Print receipt"}
        popUpChildren={printChildren}
      />
    </>
  );
};

export default EntryDetailsPage;
