import React from "react";
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";

const ParkingTypeForm = ({
  open,
  formValues,
  isDisabled,
  errorMessage,
  handleChange,
  handleClose,
  findDuplicateParkingType,
}) => {
  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"space-between"}
        >
          <DialogTitle className="text-[#312C41] !text-[16px] !font-semibold">
            {!formValues?.id ? "Add" : "Edit"}
          </DialogTitle>

          <CancelRoundedIcon
            sx={{ cursor: "pointer" }}
            onClick={handleClose}
            className="bg-[white] !text-[#344767] !text-[1.7rem] !outline-none p-4"
          />
        </Box>

        <DialogContent>
          <Stack spacing={2} paddingLeft={2} paddingRight={2} paddingBottom={2}>
            <Stack direction="row" spacing={2}>
              <TextField
                required
                name="vehicletype"
                type="text"
                id="outlined-read-only-input"
                label="Name"
                size="small"
                onChange={(e) => {
                  handleChange(e);
                }}
                value={formValues ? formValues.vehicletype : ""}
              />

              <TextField
                required
                name="description"
                type="text"
                id="outlined-required"
                label="Description"
                size="small"
                value={formValues ? formValues.description : ""}
                onChange={handleChange}
              />
            </Stack>
            <Typography color="error" sx={{ fontSize: "14px" }}>
              {errorMessage}
            </Typography>
            <Button
              disabled={!isDisabled}
              onClick={findDuplicateParkingType}
              sx={{
                textTransform: "none",
                background: !isDisabled
                  ? "gray"
                  : "linear-gradient(135deg, #3A416F 0%, #141727 100%)",
                color: "white",
                "&:hover": {},
              }}
            >
              {!formValues?.id ? "Submit" : "Update"}
            </Button>
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ParkingTypeForm;
