import React, { useEffect } from "react";
import { Box, Typography, IconButton, Button } from "@mui/material";
import { Edit } from "@mui/icons-material";
import { useState } from "react";
import {
  VEHICLE_TYPE,
  PUT_VEHICLE_REPORT,
  VEHICLE_TYPE_INACTIVE,
  VEHICLE_TYPE_ACTIVE,
} from "../../../services/apiUrls";
import { post, Put } from "../../../services/service";
import axios from "axios";
import { fetchVehicleTypes } from "../../../api/vehicleType";
import useIsEqual from "./useIsEqual";

export function useVehicleMaster() {
  const [vehicleData, setVehicleData] = useState([]);
  const [formValues, setFormValues] = useState({
    deleted: false,
    description: "",
    id: null,
    key: "",
    vehicletype: "",
  });

  const [errorMessage, setErrorMessage] = useState("");
  const { isEqual, isSpace } = useIsEqual();

  const [open, setOpen] = useState(false);
  const [searchValue, setSearchvalue] = useState("");
  const [buttonHandle, setButtonHandle] = useState("");

  let tenantId = localStorage.getItem("tenantId");
  let locationId = localStorage.getItem("locationId");

  const isDisabled =
    formValues?.vehicletype !== "" && formValues?.description !== "";

  const getVehicleTypes = async () => {
    let response = await fetchVehicleTypes(tenantId, locationId);
    setVehicleData(response);
  };

  const handleChange = (event) => {
    setErrorMessage("");
    let convertedValue = isSpace(event.target.value);
    setFormValues({ ...formValues, [event.target.name]: convertedValue });
  };

  const handleClickOpen = () => {
    setOpen(true);
    setButtonHandle("Add");
    handleClear();
  };

  const handleClose = () => {
    setOpen(false);
    handleClear();
  };

  const handleSearchChange = (e) => {
    setSearchvalue(e.target.value);
  };

  const handleSave = async () => {
    const url = `${VEHICLE_TYPE.replace("{tenantId}", tenantId).replace(
      "{locationId}",
      locationId
    )}`;
    const data = {
      name: formValues.vehicletype,
      description: formValues.description,
      tenantId: localStorage.getItem("tenantId"),
      locationId: localStorage.getItem("locationId"),
    };
    const resp = await post(url, data);
    if (resp && resp.id !== "") {
      getVehicleTypes();
    }
    setOpen(false);
  };

  const handleEdit = (row) => {
    setFormValues({
      ...formValues,
      deleted: row?.deleted,
      description: row?.description,
      id: row?.id,
      key: row?.id,
      vehicletype: row?.vehicletype,
    });
    localStorage.setItem("description", row?.description);
    setOpen(true);
    setButtonHandle("Edit");
  };

  const handleSaveEdit = async () => {
    const url = `${PUT_VEHICLE_REPORT.replace("{tenantId}", tenantId).replace(
      "{locationId}",
      locationId
    )}`;
    const data = {
      id: formValues.key,
      name: formValues.vehicletype,
      description: formValues.description,
      tenantId: localStorage.getItem("tenantId"),
      locationId: localStorage.getItem("locationId"),
    };

    const resp = await Put(url, data);
    if (resp && resp.id !== "") {
      getVehicleTypes();
    }
    setOpen(false);
  };

  const handleSubmit = () => {
    if (formValues?.id) {
      handleSaveEdit();
    } else {
      handleSave();
    }
  };

  const findDuplicateVehicleType = () => {
    const isDupliacte = vehicleData?.map((value) => {
      let isEqualVehicleType = isEqual(value?.name, formValues.vehicletype);
      return isEqualVehicleType;
    });
    let finalResult = isDupliacte.includes(true);
    let isDescriptionEqual =
    formValues.description === localStorage.getItem("description");
    if (finalResult && isDescriptionEqual) {
      setErrorMessage("Vehicle type already exists");
    } else {
      handleSubmit();
    }
  };

  const handleClear = () => {
    setFormValues({
      vehicletype: "",
      description: "",
    });
  };

  const filteredRows =
    vehicleData?.length === undefined || vehicleData?.length === 0
      ? []
      : vehicleData.filter(
          (row) =>
            row.name.toLowerCase().includes(searchValue.toLowerCase()) ||
            row.description.toLowerCase().includes(searchValue.toLowerCase())
        );

  const columns = [
    {
      field: "id",
      headerName: "NO",
      headerClassName: "super-app-theme--header",
      flex: 1,
    },
    {
      field: "vehicletype",
      headerName: "VEHICLE TYPE",
      headerClassName: "super-app-theme--header",
      flex: 1,
    },
    {
      field: "description",
      headerName: "DESCRIPTION",
      headerClassName: "super-app-theme--header",
      flex: 1,
    },
    {
      field: "active",
      headerName: "STATUS",
      flex: 1,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <>
          {params?.row?.deleted === false ? (
            <Button
              variant="contained"
              size="small"
              color="success"
              sx={{ width: "2rem" }}
              onClick={() => {
                vehicleTypeInactive(params.row.key);
              }}
            >
              <Typography sx={{ fontSize: "10px" }}>Active</Typography>
            </Button>
          ) : (
            <Button
              variant="contained"
              size="small"
              color="error"
              sx={{ width: "2rem" }}
              onClick={() => {
                vehicleTypeActive(params.row.key);
              }}
            >
              <Typography sx={{ fontSize: "10px" }}>Inactive</Typography>
            </Button>
          )}
        </>
      ),
    },
    {
      field: "action",
      headerName: "ACTION",
      headerClassName: "super-app-theme--header",
      flex: 1,
      renderCell: (params) => (
        <Box display="flex" justifyContent="center">
          <IconButton onClick={() => handleEdit(params.row)}>
            <Edit />
          </IconButton>
        </Box>
      ),
    },
  ];

  const rows = filteredRows.map((rowData, index) => ({
    key: rowData.id,
    id: index + 1,
    vehicletype: rowData.name,
    description: rowData.description,
    deleted: rowData.deleted,
  }));

  // VEHICLE TYPE INACTIVE
  const vehicleTypeInactive = async (vehicleId) => {
    try {
      const resp = await axios
        .delete(
          VEHICLE_TYPE_INACTIVE.replace("{tenantId}", tenantId)
            .replace("{locationId}", locationId)
            .replace("{vehicleId}", vehicleId)
        )
        .then((res) => {
          getVehicleTypes();
          return res;
        });
      return resp;
    } catch (err) {
      return err;
    }
  };

  // VEHICLE TYPE ACTIVE
  const vehicleTypeActive = async (restoreId) => {
    try {
      const resp = await axios
        .delete(
          VEHICLE_TYPE_ACTIVE.replace("{tenantId}", tenantId)
            .replace("{locationId}", locationId)
            .replace("{restoreId}", restoreId)
        )
        .then((res) => {
          getVehicleTypes();
          return res;
        });
      return resp;
    } catch (err) {
      return err;
    }
  };

  useEffect(() => {
    getVehicleTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    open,
    rows,
    columns,
    isDisabled,
    formValues,
    searchValue,
    errorMessage,
    buttonHandle,
    handleSave,
    handleClose,
    handleClear,
    handleChange,
    handleSaveEdit,
    handleClickOpen,
    handleSearchChange,
    findDuplicateVehicleType,
  };
}
