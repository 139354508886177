import { Box, InputAdornment, Pagination, TextField } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React from "react";
import MainLayout from "../../../containers/MainLayout";
import { useTransactionPage } from "../hook/useTransactionPage";
import { Search } from "@mui/icons-material";

const DateDetailsTable = () => {
  const {
    loading,
    columns,
    transactionDetailsByDate,
    pageNumbersForDetailReport,
    currentPageForDeatilReport,
    handleSearchChange,
    setCurrentPageForDeatilReport,
  } = useTransactionPage();

  const data = transactionDetailsByDate ?? [];

  return (
    <MainLayout>
      <Box className="flex justify-between px-3 py-2 items-center rounded-t-lg bg-[white]">
        <Box display="flex" p={1} alignItems="center">
          <TextField
            label="Search"
            onChange={handleSearchChange}
            size="small"
            variant="outlined"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </Box>
      <Box
        sx={{
          height: 475,
          width: "100%",
          "& .super-app-theme--header": {
            backgroundColor: "#576782",
            fontWight: "bold",
            color: "white",
          },
          "& .css-1pe4mpk-MuiButtonBase-root-MuiIconButton-root": {
            color: "white",
          },
          "& .MuiDataGrid-sortIcon": {
            color: "white",
          },
        }}
      >
        <DataGrid
          rows={data?.map((val, i) => ({
            No: i + 1,
            ...val,
          }))}
          columns={columns}
          loading={loading}
        />
        <Pagination
          className="m-3 flex justify-end"
          count={pageNumbersForDetailReport}
          page={currentPageForDeatilReport + 1}
          onChange={(event, page) => setCurrentPageForDeatilReport(page - 1)}
        />
      </Box>
    </MainLayout>
  );
};

export default DateDetailsTable;
