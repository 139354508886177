import { useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import { Box, Button, Typography } from "@mui/material";
import {
  ADD_BOOTH_OR_GATE,
  GET_ALL_BOOTH_OR_GATE,
  GET_BOOTH_OR_GATE_BY_ID,
  GATE_MASTER_INACTIVE,
  GATE_MASTER_ACTIVE,
} from "../../../services/apiUrls";
import { get, post, Put } from "../../../services/service";
import axios from "axios";
import useIsEqual from "./useIsEqual";

export function useGateMaster() {
  let locationId = localStorage.getItem("locationId");

  const [open, setOpen] = useState(false);

  const [search, setSearch] = useState("");

  const [loading, setLoading] = useState(true);

  const [gates, setGates] = useState([]);

  const [errorMessage, setErrorMessage] = useState("");

  const [formValues, setFormValues] = useState({
    id: "",
    name: "",
    description: "",
    locationId: locationId,
    gateType: "ENTRY",
  });

  const { isEqual, isSpace } = useIsEqual();

  const columns = [
    {
      field: "No.",
      headerName: "NO.",
      width: 65,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "name",
      headerName: "GATE NAME",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "gateType",
      headerName: "GATE TYPE",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "description",
      headerName: "DESCRIPTION",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "deleted",
      headerName: "STATUS",
      flex: 1,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <Box>
            <Button>
              {params?.row?.deleted === false ? (
                <Button
                  variant="contained"
                  size="small"
                  color="success"
                  sx={{ width: "2rem" }}
                  onClick={() => {
                    // setStatusUpdateConfirmPopUp(true);
                    statusInactive(params.row.id);
                  }}
                >
                  <Typography sx={{ fontSize: "10px" }}>Active</Typography>
                </Button>
              ) : (
                <Button
                  variant="contained"
                  size="small"
                  color="error"
                  sx={{ width: "2rem" }}
                  onClick={() => {
                    // setStatusUpdateConfirmPopUp(true);
                    statusActive(params.row.id);
                  }}
                >
                  <Typography sx={{ fontSize: "10px" }}>delete</Typography>
                </Button>
              )}
            </Button>
          </Box>
        );
      },
    },
    {
      field: "action",
      headerName: "ACTION",
      flex: 1,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <EditIcon
            sx={{ cursor: "pointer" }}
            onClick={() => {
              setOpen(true);
              window.scrollTo(0, 0);
              getGateById(params.id);
            }}
          />
        );
      },
    },
  ];

  const handleInputChange = (e) => {
    setErrorMessage("");
    let convertedValue = isSpace(e.target.value);
    setFormValues({ ...formValues, [e.target.name]: convertedValue });
  };

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const entryBoothFilterData = gates.filter(
    (item) =>
      item?.description?.toLowerCase().includes(search.toLowerCase()) ||
      item?.name?.toLowerCase().includes(search.toLowerCase())
  );

  const isDisabled = formValues.name !== "" && formValues.description !== "";

  const handleClose = () => {
    setOpen(false);
    handleClear();
  };

  const handleOpenForm = () => {
    setOpen(true);
    handleClear();
    setErrorMessage("");
  };

  const handleClear = () => {
    setFormValues({
      ...formValues,
      id: "",
      name: "",
      description: "",
      gateType: "",
    });
  };

  const getGatesOrBooths = async () => {
    setLoading(true);
    const url = `${GET_ALL_BOOTH_OR_GATE.replace("{locationId}", locationId)}`;
    const resp = await get(url);
    if (resp && resp?.id !== "") {
      setLoading(false);
      setGates(resp);
    }
  };

  const getGateById = async (id) => {
    handleClear();
    const url = `${GET_BOOTH_OR_GATE_BY_ID.replace(
      "{locationId}",
      locationId
    )}/${id}`;
    const resp = await get(url);
    if (resp && resp.id) {
      setFormValues({
        ...formValues,
        id: resp?.id,
        name: resp?.name,
        gateType: resp?.gateType,
        description: resp?.description,
      });
      localStorage.setItem("description", resp?.description);
    }
  };

  const findDuplicateGate = () => {
    const isDupliacte = gates?.map((gate) => {
      let isEqualGate = isEqual(gate?.name, formValues.name);
      let isEqualGateType = isEqual(gate?.gateType, formValues.gateType);
      return isEqualGate && isEqualGateType;
    });
    let finalResult = isDupliacte.includes(true);
    let isDescriptionEqual =
      formValues.description === localStorage.getItem("description");
    if (finalResult && isDescriptionEqual) {
      setErrorMessage("Gate already exists");
    } else {
      handleSubmit();
    }
  };

  const handleSubmit = async () => {
    if (!formValues.id) {
      const url = ADD_BOOTH_OR_GATE.replace("{locationId}", locationId);
      const resp = await post(url, formValues);
      if (resp && resp?.id) {
        handleClear();
        handleClose();
        getGatesOrBooths();
      }
    } else {
      const url = ADD_BOOTH_OR_GATE.replace("{locationId}", locationId);
      const resp = await Put(url, formValues);
      if (resp && resp?.id) {
        handleClear();
        handleClose();
        getGatesOrBooths();
      }
    }
  };

  // GATE MASTER STATUS INACTIVE
  const statusInactive = async (id) => {
    try {
      const resp = await axios({
        method: "DELETE",
        url: `${GATE_MASTER_INACTIVE}${locationId}/gate/${id}`,
      }).then((res) => {
        getGatesOrBooths();
        return res;
      });
      return resp;
    } catch (err) {
      return err;
    }
  };

  // GATE MASTER STATUS ACTIVE
  const statusActive = async (id) => {
    try {
      const resp = await axios({
        method: "DELETE",
        url: `${GATE_MASTER_ACTIVE}${locationId}/gate/restore/${id}`,
      }).then((res) => {
        getGatesOrBooths();
        return res;
      });
      return resp;
    } catch (err) {
      return err;
    }
  };

  /*eslint-disable */
  useEffect(() => {
    getGatesOrBooths();
  }, []);
  /*eslint-enable */

  return {
    open,
    columns,
    loading,
    formValues,
    isDisabled,
    errorMessage,
    entryBoothFilterData,
    setOpen,
    handleClose,
    handleOpenForm,
    findDuplicateGate,
    handleInputChange,
    handleSearchChange,
  };
}
