import React from "react";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

const DashBoardBox = ({ image, text, count, url }) => {
  const navigate = useNavigate();

  return (
    <>
      <Box
        sx={{
          background: "#fff",
          borderRadius: "10px",
          color: "#344767",
          boxShadow: "0px 3.75px 7.5px rgba(0, 0, 0, 0.12)",
          cursor: "pointer",
        }}
        className="flex justify-between items-center"
        onClick={
          url
            ? () => {
                navigate(url, {
                  state: { vehicle: image },
                });
                window.scrollTo(0, 0);
              }
            : ""
        }
      >
        <Typography sx={{ fontSize: "small", ml: 2, pt: 1 }}>
          {text}
          <Typography sx={{ fontSize: "35px", fontWeight: "bold" }}>
            {count ?? 0}
          </Typography>
        </Typography>
        <Box
          sx={{
            background: "#344767",
            height: 35,
            width: 35,
            borderRadius: "6px",
            marginRight: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={`/images/${image}.svg`}
            alt="logo"
            style={{ width: "25px", height: "25px" }}
          />
        </Box>
      </Box>
    </>
  );
};

export default DashBoardBox;
