import { GET_ALL_LOCATIONS, GET_USER_LOCATION } from "../services/apiUrls";
import { get, post } from "../services/service";

export const fetchUserLocation = async (formValues) => {
  let response = await post(GET_USER_LOCATION, formValues);
  return response;
};

export const fetchEntityLocation = async (tenantId) => {
  let response = await get(GET_ALL_LOCATIONS.replace("{tenantId}", tenantId));
  return response;
};
