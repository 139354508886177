import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { LOGIN } from "../../../services/apiUrls";

export const useLogin = () => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [loginError, setLoginError] = useState([]);

  const [loginFormValues, setLoginFormValues] = useState({
    email: "",
    password: "",
  });

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const [toast, setToast] = useState({ show: false, type: "", messsege: "" });

  const isDisabled =
    loginFormValues.email.length !== 0 && loginFormValues.password.length !== 0;

  const loginApi = async (e) => {
    e.preventDefault();
    localStorage.clear();
    setIsLoading(true);
    var authHeaders = new Headers();
    authHeaders.append("Authorization", "Basic YXBpOkRKQ29ubmVjdGFwaSEh");
    authHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    var urlencoded = new URLSearchParams();
    urlencoded.append("grant_type", "password");
    urlencoded.append("username", loginFormValues.email);
    urlencoded.append("password", loginFormValues.password);
    var requestOptions = {
      method: "POST",
      headers: authHeaders,
      body: urlencoded,
      redirect: "follow",
    };
    fetch(LOGIN, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        let response = JSON.parse(result);
        if (response?.status.code === 1000) {
          const loginTime = new Date().getTime();
          localStorage.setItem("loginTime", loginTime.toString());
          localStorage.setItem("token", response.access_token);
          localStorage.setItem("userType", response.userType);
          localStorage.setItem("email", response.email);
          localStorage.setItem("tenantId", response.tenantId);
          localStorage.setItem("locationId", response.locationId);
          localStorage.setItem("userId", response.id);
          localStorage.setItem("firstName", response.firstName);
          localStorage.setItem("lastName", response.lastName);
          localStorage.setItem("fullname", response.firstName + ` ${response.lastName}` );
          if (response.userType === "ADMIN") {
            navigate("/dashboard");
          } else {
            navigate("/home");
          }
        } else if (response?.status.code === 1004) {
          setToast({
            ...toast,
            show: true,
            type: "error",
            messsege: "User not found..!",
          });
          setIsLoading(false);
        } else if (response?.status.code === 401) {
          setToast({
            ...toast,
            show: true,
            type: "error",
            messsege: "Invalid credential..!",
          });
          setIsLoading(false);
        } else {
          setToast({
            ...toast,
            show: true,
            type: "error",
            messsege: "Error..!",
          });
          setIsLoading(false);
        }
        setIsLoading(false);
      })
      .catch((error) => console.error("error", error));
  };

  // const loginApi = async () => {
  //   localStorage.clear();
  //   setIsLoading(true);
  //   const urlencoded = new URLSearchParams();
  //   urlencoded.append("grant_type", "password");
  //   urlencoded.append("username", loginFormValues.email);
  //   urlencoded.append("password", loginFormValues.password);
  //   try {
  //     const resp = await axios
  //       .post(`${LOGIN_API}`, urlencoded, {
  //         headers: {
  //           Authorization: "Basic YXBpOkRKQ29ubmVjdGFwaSEh",
  //         },
  //       })
  //       .then((res) => {
  //         localStorage.setItem("token", res.data.access_token);
  //         localStorage.setItem("userType", res.data.userType);
  //         localStorage.setItem("email", res.data.email);
  //         localStorage.setItem("tenantId", res.data.tenantId);
  //         localStorage.setItem("locationId", res.data.locationId);
  //         localStorage.setItem("userId", res.data.id);
  //         if (res?.status === 200) {
  //           navigate("/home");
  //         }
  //         return res;
  //       });
  //     setIsLoading(false);
  //     return resp;
  //   } catch (err) {
  //     setIsLoading(false);
  //     setLoginError(err.response);
  //     return err;
  //   }
  // };

  // LOGIN HANDLE CHANGE
  const loginHandleChange = (e) => {
    if (e.target.name === "email") {
      let value = e.target.value.replace(/\s/g, "");
      setLoginFormValues({
        ...loginFormValues,
        [e.target.name]: value,
      });
    } else {
      setLoginFormValues({
        ...loginFormValues,
        [e.target.name]: e.target.value,
      });
    }
  };

  /* eslint-disable */
  useEffect(() => {
    if (toast.show === true) {
      const interval = setInterval(() => {
        setToast({ ...toast, show: false });
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [toast.show]);

  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
        if (loginFormValues?.password !== "" && loginFormValues?.email !== "") {
          loginApi(event);
        }
      }
    };
    document.addEventListener("keydown", keyDownHandler);
    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, [loginFormValues]);

  // const handleKeyPress = (e) => {
  //   if (e.key === "Enter") {
  //     e.preventDefault();
  //     loginApi();
  //   }
  // };
  /*eslint-enable */

  return {
    toast,
    isLoading,
    loginError,
    isDisabled,
    showPassword,
    loginFormValues,
    loginApi,
    setLoginError,
    loginHandleChange,
    handleClickShowPassword,
  };
};
