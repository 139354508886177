import React from "react";
import ReactDOM from "react-dom/client";
import "./styles/index.css";
import "./styles/app.css";
import reportWebVitals from "./reportWebVitals";
import { HashRouter } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { customTheme } from "./styles/theme";
import App from "./containers/App";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <ThemeProvider theme={customTheme}>
      <HashRouter>
        <App />
      </HashRouter>
    </ThemeProvider>
  </React.StrictMode>
);

reportWebVitals();
