import {
  ADD_BLOCK,
  DELETE_BLOCK,
  GET_BLOCK,
  GET_BLOCKS,
  RESTORE_BLOCK,
} from "../services/apiUrls";
import { Delete, Put, get, post } from "../services/service";

export const fetchAllBlocks = async (floorId, locationId, tenantId) => {
  let response = await get(
    GET_BLOCKS.replace("{floorId}", floorId)
      .replace("{tenantId}", tenantId)
      .replace("{locationId}", locationId)
  );
  return response;
};

export const fetchBlock = async (floorId, locationId, tenantId, blockId) => {
  let response = await get(
    GET_BLOCK.replace("{floorId}", floorId)
      .replace("{tenantId}", tenantId)
      .replace("{locationId}", locationId)
      .replace("{blockId}", blockId)
  );
  return response;
};

export const addBlock = async (floorId, locationId, tenantId, formValues) => {
  let response = await post(
    ADD_BLOCK.replace("{floorId}", floorId)
      .replace("{tenantId}", tenantId)
      .replace("{locationId}", locationId),
    formValues
  );
  return response;
};

export const updateBlock = async (
  floorId,
  locationId,
  tenantId,
  formValues
) => {
  let response = await Put(
    ADD_BLOCK.replace("{floorId}", floorId)
      .replace("{tenantId}", tenantId)
      .replace("{locationId}", locationId),
    formValues
  );
  return response;
};

export const deleteBlock = async (blockId, floorId, locationId, tenantId) => {
  let response = Delete(
    DELETE_BLOCK.replace("{blockId}", blockId)
      .replace("{floorId}", floorId)
      .replace("{tenantId}", tenantId)
      .replace("{locationId}", locationId)
  );
  return response;
};

export const restoreBlock = async (blockId, floorId, locationId, tenantId) => {
  let response = Delete(
    RESTORE_BLOCK.replace("{blockId}", blockId)
      .replace("{floorId}", floorId)
      .replace("{tenantId}", tenantId)
      .replace("{locationId}", locationId)
  );
  return response;
};
