import React from "react";
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
} from "@mui/material";
import { Stack } from "@mui/system";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";

const StaffonBoardForm = ({
  selectedRow,
  open,
  buttonHandle,
  isDisabled,
  handleChange,
  handleClose,
  handleSave,
  handleSaveEdit,
}) => {
  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"space-between"}
        >
          <DialogTitle
            p={3}
            className="text-[#312C41] !text-[16px] !font-semibold"
          >
            {buttonHandle === "Add" ? "Add" : "Edit"}
          </DialogTitle>

          <CancelRoundedIcon
            onClick={handleClose}
            className="bg-[white] !text-[#344767] !text-[1.7rem] !outline-none p-4"
          />
        </Box>

        <DialogContent>
          <Stack spacing={4} paddingLeft={2} paddingRight={2} paddingBottom={2}>
            <Stack direction="row" spacing={2}>
              <TextField
                required
                name="firstname"
                type="text"
                id="outlined-required"
                label="First Name"
                size="small"
                // value={selectedRow ? selectedRow.description : ""}
                // onChange={handleChange}
              />

              <TextField
                name="lastname"
                type="text"
                id="outlined-read-only-input"
                label="Last Name"
                size="small"
                // onChange={handleChange}
                // value={selectedRow ? selectedRow.vehicletype : ""}
              />
            </Stack>

            <Stack direction="row" spacing={2}>
              <TextField
                required
                name="email"
                type="text"
                id="outlined-required"
                label="Email"
                size="small"
                // value={selectedRow ? selectedRow.description : ""}
                // onChange={handleChange}
              />

              <TextField
                required
                name="password"
                type="text"
                id="outlined-required"
                label="Password"
                size="small"
                // value={selectedRow ? selectedRow.description : ""}
                // onChange={handleChange}
              />
            </Stack>

            <Stack direction="row" spacing={2}>
              <TextField
                required
                name="usertype"
                type="text"
                id="outlined-required"
                label="User Type"
                size="small"
                // value={selectedRow ? selectedRow.description : ""}
                // onChange={handleChange}
              />

              <TextField
                required
                name="location"
                type="text"
                id="outlined-required"
                label="Location"
                size="small"
                // value={selectedRow ? selectedRow.description : ""}
                // onChange={handleChange}
              />
            </Stack>

            <Button
              disabled={!isDisabled}
              onClick={buttonHandle === "Add" ? handleSave : handleSaveEdit}
              sx={{
                backgroundColor: !isDisabled ? "#3A416F" : "#3A416F",
                color: "white",
                "&:hover": {
                  color: "white",
                  backgroundColor: "black",
                },
              }}
            >
              {buttonHandle === "Add" ? "Submit" : "Update"}
            </Button>
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default StaffonBoardForm;
