import React from "react";
import { Link } from "react-router-dom";
import { Search } from "@mui/icons-material";
import MainLayout from "../../../containers/MainLayout";
import { useCashCollectReport } from "../hook/useCashCollectReport";
import CashCollectReportTable from "../components/CashCollectReportTable";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import {
  Box,
  Radio,
  Stack,
  Select,
  Button,
  MenuItem,
  TextField,
  InputLabel,
  RadioGroup,
  Typography,
  FormControl,
  InputAdornment,
  FormControlLabel,
  Autocomplete,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const CashCollectReportPage = () => {
  const {
    date,
    week,
    month,
    tenDays,
    value,
    employees,
    yesterday,
    formValue,
    toDateTime,
    activeGates,
    fromDateTime,
    cashCollectData,
    cashCollectLoader,
    FilterCashCollectData,
    handleClear,
    handleOnChange,
    handleGateChange,
    handleInputChange,
    handleSearchChange,
    handleToDateTimeChange,
    handleFromDateTimeChange,
    getCashCollectReportByDateButton,
  } = useCashCollectReport();

  return (
    <MainLayout>
      <Typography className="text-[#252F40] !mb-2">
        <Link to="/dashboard" className="no-underline">
          <span className="text-[#8C8C8C]">Reports / </span>
        </Link>
        Cash collect report
      </Typography>
      <Box
        sx={{
          borderRadius: "10px",
          boxShadow: "0px 3.75px 7.5px rgba(0, 0, 0, 0.12)",
        }}
        className="!bg-[white] !mt-6 !mb-4"
      >
        <Stack direction="row" gap={2} className="!p-4 flex-wrap items-center">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimePicker
              label="Date from"
              value={fromDateTime}
              onChange={handleFromDateTimeChange}
              slotProps={{ textField: { size: "small" } }}
            />

            <DateTimePicker
              label="Date to"
              value={toDateTime}
              onChange={handleToDateTimeChange}
              slotProps={{ textField: { size: "small" } }}
            />
          </LocalizationProvider>

          {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimePicker
              slotProps={{ textField: { size: "small" } }}
              label="Date From"
              value={formValue?.fromDate}
              onChange={(date) => {
                const fromDate = new Date(date);
                setFormValue({ ...formValue, fromDate: fromDate });
              }}
            />
          </LocalizationProvider> */}

          {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimePicker
              slotProps={{ textField: { size: "small" } }}
              label="Date To"
              value={todayFormatted}
              onChange={(date) => {
                const toDate = new Date(date);
                setFormValue({ ...formValue, toDate: toDate });
              }}
            />
          </LocalizationProvider> */}

          {/* <TextField
            id="from-date"
            label="Date from"
            variant="outlined"
            type="date"
            size="small"
            value={formValue.fromDate}
            defaultValue={formValue.fromDate}
            focused
            onChange={(e) => {
              setFormValue({ ...formValue, fromDate: e.target.value });
            }}
          /> */}

          {/* <TextField
            id="to-date"
            label="Date To"
            variant="outlined"
            type="date"
            focused
            size="small"
            value={formValue.toDate}
            defaultValue={formValue.toDate}
            onChange={(e) => {
              setFormValue({ ...formValue, toDate: e.target.value });
            }}
          /> */}

          <FormControl>
            <RadioGroup
              aria-labelledby="entry-exit-radio-buttons-group-label"
              defaultValue="entry"
              name="gate"
              className="flex !flex-row"
            >
              <FormControlLabel
                control={<Radio onChange={handleGateChange} />}
                checked={formValue?.gate === "entry"}
                value="entry"
                label="Entry"
              />
              <FormControlLabel
                control={<Radio onChange={handleGateChange} />}
                checked={formValue?.gate === "exit"}
                value="exit"
                label="Exit"
              />
            </RadioGroup>
          </FormControl>

          <FormControl size="small">
            <InputLabel>Choose Gate</InputLabel>
            <Select
              labelId="getId"
              label="gateId*"
              className="!min-w-[300px]"
              name="gateId"
              value={formValue?.gateId}
              onChange={handleInputChange}
            >
              {activeGates?.map((obj) => (
                <MenuItem value={obj.id}>{obj.name}</MenuItem>
              ))}
            </Select>
          </FormControl>

          <Autocomplete
            size="small"
            disablePortal
            id="combo-box-demo"
            options={employees ?? []}
            value={value}
            sx={{ width: 250 }}
            onChange={handleOnChange}
            getOptionLabel={(option) => option?.firstname ?? ""}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Employee Name"
                placeholder="Select Name"
              />
            )}
            // renderOption={(props, option) => (
            //   <Box
            //     component="li"
            //     {...props}
            //   >
            //     <AccountCircleIcon className="pr-2"/>
            //     {option.firstname}
            //   </Box>
            // )}
          />

          <Button
            variant="contained"
            size="small"
            onClick={handleClear}
            className="!bg-[#3A416F] gap-2"
            sx={{ textTransform: "none" }}
          >
            Clear
          </Button>
        </Stack>

        <Stack direction="row" gap={1} className="!px-4 pb-3 flex-wrap">
          <Button
            name="today"
            onClick={(e) => getCashCollectReportByDateButton(date, date, e)}
            sx={{
              background: "#576782",
              color: "white",
              "&:hover": { background: "#3e4859" },
            }}
            variant="contained"
            size="small"
          >
            Today
          </Button>
          <Button
            name="yesterday"
            onClick={(e) =>
              getCashCollectReportByDateButton(yesterday, yesterday, e)
            }
            sx={{
              background: "#576782",
              color: "white",
              "&:hover": { background: "#3e4859" },
            }}
            variant="contained"
            size="small"
          >
            Yesterday
          </Button>
          <Button
            name="week"
            onClick={(e) => getCashCollectReportByDateButton(week, date, e)}
            sx={{
              background: "#576782",
              color: "white",
              "&:hover": { background: "#3e4859" },
            }}
            variant="contained"
            size="small"
          >
            Week
          </Button>
          <Button
            name="tendays"
            onClick={(e) => getCashCollectReportByDateButton(tenDays, date, e)}
            sx={{
              background: "#576782",
              color: "white",
              "&:hover": { background: "#3e4859" },
            }}
            variant="contained"
            size="small"
          >
            10 Days
          </Button>
          <Button
            name="month"
            onClick={(e) => getCashCollectReportByDateButton(month, date, e)}
            sx={{
              background: "#576782",
              color: "white",
              "&:hover": { background: "#3e4859" },
            }}
            variant="contained"
            size="small"
          >
            Month
          </Button>
        </Stack>
      </Box>

      <Box
        sx={{
          boxShadow: " 0px 5px 15px -2px rgba(0, 0, 0, 0.12)",
          borderRadius: "2rem",
          width: "100%",
        }}
      >
        <Box className="flex justify-between px-3 py-2 items-center rounded-t-lg bg-[white]">
          <Box display="flex" p={1} alignItems="center">
            <TextField
              label="Search"
              onChange={handleSearchChange}
              size="small"
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Box>
        <CashCollectReportTable
          value={value}
          cashCollectData={cashCollectData}
          cashCollectLoader={cashCollectLoader}
          FilterCashCollectData={FilterCashCollectData}
        />
      </Box>
    </MainLayout>
  );
};

export default CashCollectReportPage;
