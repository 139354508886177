import { GET_REFUND_REPORTS } from "../services/apiUrls";
import { post } from "../services/service";

export const fetchRefundReports = async (locationId, tenantId, formValues) => {
  let response = await post(
    GET_REFUND_REPORTS.replace("{tenantId}", tenantId).replace(
      "{locationId}",
      locationId
    ),
    formValues
  );
  return response;
};
