import { useState, useEffect } from "react";
import { fetchAllParkingTypes } from "../../../api/parkingTypes";
import { fetchAllSlotsWithoutFloor } from "../../../api/slots";

const useAvailableSlot = () => {
  let tenantId = localStorage.getItem("tenantId");
  let locationId = localStorage.getItem("locationId");
  let bikeId = "a6584307-f26b-42c5-9653-37f69dea8ee0";
  let carId = "2a60b9af-d7c9-4a06-8ca5-b91625d6d2bb";

  // const [parkingTypeId, setParkingTypeId] = useState("");
  const [bikesAvailbleSlots, setBikesAvailbleSlots] = useState("");
  const [carsAvailbleSlots, setCarsAvailbleSlots] = useState("");

  // Get All parking Type
  const getParkingType = async () => {
    let response = await fetchAllParkingTypes(locationId, tenantId);
    if (response) {
      findRegular(response);
    }
    return response;
  };

  const findRegular = (response) => {
    const selectedParkingType = response?.find((val) => {
      return !val.deleted && val.name.trim().toLowerCase() === "regular";
    });
    // setParkingTypeId(selectedParkingType?.id);
    if (selectedParkingType?.id) {
      getAllSlotesWithoutFloorForCar(selectedParkingType?.id);
      getAllSlotesWithoutFloorForBike(selectedParkingType?.id);
    }
  };

  const getAllSlotesWithoutFloorForBike = async (id) => {
    const response = await fetchAllSlotsWithoutFloor(
      tenantId,
      locationId,
      bikeId,
      id
    );
    setBikesAvailbleSlots({
      ...bikesAvailbleSlots,
      response,
    });
    return response;
  };

  const getAllSlotesWithoutFloorForCar = async (id) => {
    const response = await fetchAllSlotsWithoutFloor(
      tenantId,
      locationId,
      carId,
      id
    );
    setCarsAvailbleSlots({ ...carsAvailbleSlots, response });
    return response;
  };

  useEffect(() => {
    const interval = setInterval(() => {
      getParkingType();
    }, 30000);

    getParkingType();
  
    return () => {
      clearInterval(interval);
    };
  
    // eslint-disable-next-line
  }, [locationId, tenantId]);

  return { carsAvailbleSlots, bikesAvailbleSlots };
};

export default useAvailableSlot;
