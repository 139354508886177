import { Box, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import MainLayout from "../../../containers/MainLayout";

function Collections() {
  return (
    <MainLayout>
      <Typography className="text-[#252F40] !mb-2">
        <Link to="/dashboard" className="no-underline">
          <span className="text-[#8C8C8C]">Reports / </span>
        </Link>
        Collections
      </Typography>
      <Box
        sx={{
          background: "white",
          borderRadius: "10px",
          boxShadow: "0px 3.75px 7.5px rgba(0, 0, 0, 0.12)",
        }}
        className="flex items-center mb-3 p-5"
        gap={2}
      >
        <Typography>Collections</Typography>
      </Box>
    </MainLayout>
  );
}

export default Collections;
