import { Stack, Typography } from "@mui/material";
import React from "react";
import PrimaryButton from "./PrimaryButton";
import SecondaryButton from "./SecondaryButton";

const ConfirmationPopupChildren = ({ params, message, onConfirm, onCancel }) => {
  return (
    <div className="confirmation-popup">
      <Typography
        className="text-center"
        sx={{ fontWeight: 700, fontSize: "20px" }}
      >
        {message}
      </Typography>
      <Stack spacing={2} direction="row" justifyContent="center" mt={3} mb={3}>
        <PrimaryButton
          name={"Confirm"}
          variant={"contained"}
          onClick={(event) => onConfirm(params, event)}
        />
        <SecondaryButton
          name={"Cancel"}
          variant={"contained"}
          onClick={(event) => onCancel(event)}
        />
      </Stack>
    </div>
  );
};

export default ConfirmationPopupChildren;
