import React, { useEffect } from "react";
import { Box, Button, Typography } from "@mui/material";
import { useState } from "react";
import {
  GET_LOCATION_MASTER_DATA,
  PUT_VEHICLE_REPORT,
  LOCATIONS_STATUS_INACTIVE,
  LOCATIONS_STATUS_ACTIVE,
} from "../../../services/apiUrls";
import { get, post, Put } from "../../../services/service";
import axios from "axios";

export function useLocationMaster() {
  const [locationData, setLocationData] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);

  const [open, setOpen] = useState(false);
  const [searchValue, setSearchvalue] = useState("");
  const [buttonHandle, setButtonHandle] = useState("");

  let tenantId = localStorage.getItem("tenantId");
  let locationId = localStorage.getItem("locationId");

  const isDisabled =
    selectedRow?.vehicletype !== "" && selectedRow?.description !== "";

  const fetchLocationMaster = async () => {
    const url = `${GET_LOCATION_MASTER_DATA.replace(
      "{tenantId}",
      tenantId
    ).replace("{locationId}", locationId)}`;
    const resp = await get(url);
    setLocationData(resp);
  };

  const handleChange = (event) => {
    setSelectedRow({ ...selectedRow, [event.target.name]: event.target.value });
  };

  const handleClickOpen = () => {
    setOpen(true);
    setButtonHandle("Add");
    handleClear();
  };

  const handleClose = () => {
    setOpen(false);
    handleClear();
  };

  const handleSearchChange = (e) => {
    setSearchvalue(e.target.value);
  };

  const handleSave = async () => {
    const url = `${GET_LOCATION_MASTER_DATA.replace(
      "{tenantId}",
      tenantId
    ).replace("{locationId}", locationId)}`;
    const data = {
      name: selectedRow.name,
      description: selectedRow.description,
      tenantId: localStorage.getItem("tenantId"),
      addressId: "167124a5-85f9-4423-a701-965e7d78fe6f",
    };
    const resp = await post(url, data);
    if (resp && resp.id !== "") {
      fetchLocationMaster();
    }
    setOpen(false);
  };

  const handleEdit = (row) => {
    setSelectedRow(row);
    setOpen(true);
    setButtonHandle("Edit");
  };

  const handleSaveEdit = async () => {
    const url = `${PUT_VEHICLE_REPORT.replace("{tenantId}", tenantId).replace(
      "{locationId}",
      locationId
    )}`;
    const data = {
      id: selectedRow.key,
      name: selectedRow.vehicletype,
      description: selectedRow.description,
      tenantId: localStorage.getItem("tenantId"),
      locationId: localStorage.getItem("locationId"),
    };

    const resp = await Put(url, data);
    if (resp && resp.id !== "") {
      fetchLocationMaster();
    }
    setOpen(false);
  };

  const handleClear = () => {
    setSelectedRow({
      name: "",
      description: "",
    });
  };

  const toggleStatus = (id) => {
    const updatedData = locationData.map((row) =>
      row.id === id
        ? { ...row, deleted: row.deleted === false ? "ACTIVE" : "INACTIVE" }
        : row
    );
    setLocationData(updatedData);
  };

  const filteredRows =
    locationData?.length === 0 || locationData?.length === undefined
      ? []
      : locationData.filter(
          (row) =>
            row.name.toLowerCase().includes(searchValue.toLowerCase()) ||
            row.description.toLowerCase().includes(searchValue.toLowerCase())
        );

  const columns = [
    {
      field: "id",
      headerName: "NO",
      headerClassName: "super-app-theme--header",
      flex: 1,
    },
    {
      field: "name",
      headerName: "NAME",
      headerClassName: "super-app-theme--header",
      flex: 1,
    },

    {
      field: "description",
      headerName: "DESCRIPTION",
      headerClassName: "super-app-theme--header",
      flex: 1,
    },
    {
      field: "active",
      headerName: "STATUS",
      flex: 1,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <Box display={"flex"} paddingRight={1}>
          <Button
            onClick={() => {
              toggleStatus(params.row.id);
            }}
          >
            {params?.row?.deleted === false ? (
              <Button
                variant="contained"
                size="small"
                color="success"
                sx={{ width: "2rem" }}
                onClick={() => {
                  locationStatusInactive(params.row.key);
                }}
              >
                <Typography sx={{ fontSize: "10px" }}>Active</Typography>
              </Button>
            ) : (
              <Button
                variant="contained"
                size="small"
                color="error"
                sx={{ width: "2rem" }}
                onClick={() => {
                  locationStatusActive(params.row.key);
                }}
              >
                <Typography sx={{ fontSize: "10px" }}>Inactive</Typography>
              </Button>
            )}
          </Button>
        </Box>
      ),
    },
    // {
    //   field: "action",
    //   headerName: "Action",
    //   headerClassName: "super-app-theme--header",
    //   flex: 1,
    //   renderCell: (params) => (
    //     <Box display="flex" justifyContent="center">
    //       <IconButton onClick={() => handleEdit(params.row)}>
    //         <Edit />
    //       </IconButton>
    //     </Box>
    //   ),
    // },
  ];

  // LOCATION STATUS INACTIVE
  const locationStatusInactive = async (id) => {
    try {
      const resp = await axios
        .delete(
          LOCATIONS_STATUS_INACTIVE.replace("{tenantId}", tenantId).replace(
            "{id}",
            id
          )
        )
        .then((res) => {
          fetchLocationMaster();
          return res;
        });
      return resp;
    } catch (err) {
      return err;
    }
  };

  // LOCATION STATUS ACTIVE
  const locationStatusActive = async (id) => {
    try {
      const resp = await axios
        .delete(
          LOCATIONS_STATUS_ACTIVE.replace("{tenantId}", tenantId).replace(
            "{id}",
            id
          )
        )
        .then((res) => {
          fetchLocationMaster();
          return res;
        });
      return resp;
    } catch (err) {
      return err;
    }
  };

  const rows = filteredRows.map((rowData, index) => ({
    key: rowData.id,
    id: index + 1,
    name: rowData.name,
    description: rowData.description,
    status: rowData.status,
    deleted: rowData.deleted,
  }));

  /*eslint-disable */
  useEffect(() => {
    fetchLocationMaster();
  }, []);
  /*eslint-enable */

  return {
    locationData,
    selectedRow,
    open,
    searchValue,
    buttonHandle,
    isDisabled,
    fetchLocationMaster,
    handleChange,
    handleClickOpen,
    handleClose,
    handleSearchChange,
    handleSave,
    handleEdit,
    handleSaveEdit,
    toggleStatus,
    handleClear,
    columns,
    rows,
  };
}
