import {
  Grid,
  Button,
  Checkbox,
  TextField,
  Typography,
  FormControlLabel,
} from "@mui/material";
import React from "react";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  input: {
    marginTop: "0.5rem",
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
});

const LeaveTail = ({
  setStep,
  formValue,
  handleCheckBox,
  handleInputChange,
  isDisabledForLeaveTail,
  handleShiftCreateSubmit,
}) => {
  const classes = useStyles();

  return (
    <>
      <Grid container>
        <Grid item>
          <FormControlLabel
            control={
              <Checkbox sx={{ "& .MuiSvgIcon-root": { color: "#576782" } }} />
            }
            onClick={(e) => handleCheckBox(e)}
            name="calculate"
            label="Check to auto calculate the leave minutes on the basis of Shift In and Shift Out"
          />
        </Grid>
      </Grid>
      <Grid container mt={2} gap={3}>
        <Grid item xs={12} sm={5} md={4} lg={4}>
          <Typography className="!mt-2">Full Day Minutes</Typography>
          <TextField
            fullWidth
            size="small"
            type="number"
            name="fullDayMinutes"
            className={classes.input}
            onChange={handleInputChange}
            value={formValue.fullDayMinutes}
            placeholder="Enter Full Day Minutes"
          />
        </Grid>
        <Grid item xs={12} sm={5} md={4} lg={4}>
          <Typography className="!mt-2">Full Day Value</Typography>
          <TextField
            fullWidth
            size="small"
            type="number"
            name="fullDayValue"
            className={classes.input}
            onChange={handleInputChange}
            value={formValue.fullDayValue}
            placeholder="Enter Full Day Value"
          />
        </Grid>
      </Grid>
      <Grid container gap={3} mt={2}>
        <Grid item xs={12} sm={5} md={4} lg={4}>
          <Typography className="!mt-2">Half Day Minutes</Typography>
          <TextField
            fullWidth
            size="small"
            type="number"
            name="halfDayMinutes"
            className={classes.input}
            onChange={handleInputChange}
            value={formValue.halfDayMinutes}
            placeholder="Enter Half Day Minutes"
          />
        </Grid>
        <Grid item xs={12} sm={5} md={4} lg={4}>
          <Typography className="!mt-2">Half Day Value</Typography>
          <TextField
            fullWidth
            size="small"
            type="number"
            name="halfDayValue"
            className={classes.input}
            onChange={handleInputChange}
            value={formValue.halfDayValue}
            placeholder="Enter Half Day Value"
          />
        </Grid>
      </Grid>

      <Grid container mt={6} className="flex justify-between">
        <Grid item className="w-[20rem]">
          <Button
            variant="outlined"
            onClick={() => setStep(2)}
            sx={{ width: "220px", color: "#344767" }}
          >
            Back
          </Button>
        </Grid>
        <Grid item className="flex justify-center">
          <Button
            disabled={!isDisabledForLeaveTail}
            onClick={handleShiftCreateSubmit}
            sx={{
              width: "220px",
              textTransform: "none",
              background: !isDisabledForLeaveTail
                ? "gray"
                : "linear-gradient(135deg, #3A416F 0%, #141727 100%)",
              color: "white",
              "&:hover": {},
            }}
          >
            {formValue.id ? "Update" : "Save"}
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default LeaveTail;
