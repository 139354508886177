import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import car from "../../../assets/images/car.png";
import bike from "../../../assets/images/bike.png";
import { useLocation, useNavigate } from "react-router";
import useVehicleType from "../hooks/useVehicleType";

const VehicleTypeSelectionPage = () => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const { twoWheelerId, fourWheelerId } = useVehicleType();
  return (
    <>
      <Box className="background-fixed-with-gradiant">
        <Stack
          className="flex justify-center items-center h-[100vh]"
          spacing={3}
        >
          <Typography className="text-center !text-[2.5rem] sm:!text-[3rem] md:!text-[3rem] lg:!text-[3rem] !mt-[1.5rem] sm:!mt-[0rem] md:!mt-[0rem] lg:!mt-[0rem] !font-[600] !text-[#FFFFFF] opacity-[0.1]">
            Choose Vehicle Type
          </Typography>
          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={{ xs: 0, sm: 10 }}
            className="flex justify-center items-center"
          >
            <Box className="!w-[210px]">
              <img
                onClick={() => {
                  navigate(`vehicle/${twoWheelerId}`, {
                    state: { ...state, vehicleId: 1 },
                  });
                }}
                className="transition duration-75 ease-in-out
                    transform hover:-translate-y-1 hover:scale-110 w-full h-auto cursor-pointer"
                height={210}
                src={bike}
                alt="/"
              ></img>
            </Box>
            <Box className="!w-[280px]">
              <img
                onClick={() => {
                  navigate(`vehicle/${fourWheelerId}`, {
                    state: { ...state, vehicleId: 2 },
                  });
                }}
                className="transition duration-75 ease-in-out
                    transform hover:-translate-y-1 hover:scale-110 w-full h-auto cursor-pointer"
                src={car}
                alt="/"
              ></img>
            </Box>
          </Stack>
        </Stack>
      </Box>
    </>
  );
};

export default VehicleTypeSelectionPage;
