import React from "react";
import { Link } from "react-router-dom";
import MainLayout from "../../../containers/MainLayout";
import {
  Box,
  Button,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from "@mui/material";
import { useComplimentaryReports } from "../hook/useComplimentaryReports";
import { DataGrid } from "@mui/x-data-grid";

const ComplimentaryDetails = () => {
  const {
    loading,
    columns,
    parkingTypeList,
    complimentaryFormValues,
    complementaryReportDetails,
    handleInputChange,
    complimentaryHandleSubmit,
    complimentaryReportsHandleChange,
  } = useComplimentaryReports();

  const isDisable =
    complimentaryFormValues?.parkingTypeId &&
    complimentaryFormValues?.fromDate &&
    complimentaryFormValues?.toDate;

  return (
    <MainLayout>
      <Typography className="text-[#252F40] !mb-2">
        <Link to="/dashboard" className="no-underline">
          <span className="text-[#8C8C8C]">Reports / </span>
        </Link>
        Complimentary Report
      </Typography>
      <Box
        sx={{
          background: "white",
          borderRadius: "10px",
          boxShadow: "0px 3.75px 7.5px rgba(0, 0, 0, 0.12)",
        }}
        className="flex items-center mb-3 p-5"
      >
        <Box className="flex flex-wrap gap-2 items-center">
          <div>
            <TextField
              required
              name="fromDate"
              type="date"
              label="Date"
              size="small"
              focused
              onChange={handleInputChange}
              value={complimentaryFormValues.fromDate}
            />
          </div>
          <div>
            <TextField
              required
              name="toDate"
              type="date"
              label="Date"
              size="small"
              focused
              onChange={handleInputChange}
              value={complimentaryFormValues.toDate}
            />
          </div>
          <div>
            <FormControl size="small" variant="outlined">
              <InputLabel htmlFor="parking-type">Parking Type</InputLabel>
              <Select
                required
                labelId="parking-type"
                id="parking-type"
                label="Parking Type*"
                className="!min-w-[300px]"
                name="parkingTypeId"
                value={complimentaryFormValues?.parkingTypeId}
                onChange={complimentaryReportsHandleChange}
              >
                {parkingTypeList?.map((obj) => (
                  <MenuItem value={obj.id}>{obj.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div>
            <Button
              sx={{
                background: "#576782",
                color: "white",
                "&:hover": { background: "#3e4859" },
              }}
              size="small"
              variant="contained"
              onClick={complimentaryHandleSubmit}
              disabled={!isDisable}
            >
              Search
            </Button>
          </div>
        </Box>
      </Box>

      <Box
        sx={{
          height: 475,
          width: "100%",
          "& .super-app-theme--header": {
            backgroundColor: "#576782",
            fontWight: "bold",
            color: "white",
          },
          "& .css-1pe4mpk-MuiButtonBase-root-MuiIconButton-root": {
            color: "white",
          },
          "& .MuiDataGrid-sortIcon": {
            color: "white",
          },
        }}
      >
        <DataGrid
          getRowId={(row) => row.No}
          rows={complementaryReportDetails?.map((val, i) => ({
            No: i + 1,
            ...val,
          }))}
          columns={columns}
          loading={loading}
        />
      </Box>
    </MainLayout>
  );
};

export default ComplimentaryDetails;
