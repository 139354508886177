import React from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import MainLayout from "../../../containers/MainLayout";
import { Search } from "@mui/icons-material";
import AddBoxRoundedIcon from "@mui/icons-material/AddBoxRounded";
import { usePaymentMode } from "../hooks/usePaymentMode";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import { Stack } from "@mui/system";
import PaymentModeTable from "../components/PaymentModeTable";
import { Link } from "react-router-dom";

const PaymentModePage = () => {
  const {
    open,
    columns,
    formValue,
    isDisabled,
    handleClose,
    handleSubmit,
    handleClickOpen,
    handleInputChange,
    handleSearchChange,
    paymentModeFilterData,
  } = usePaymentMode();
  return (
    <MainLayout>
      <Typography className="text-[#252F40] !mb-2">
        <Link to="/dashboard" className="no-underline">
          <span className="text-[#8C8C8C]">Masters / </span>
        </Link>
        Payment Mode
      </Typography>
      <Box
        sx={{
          boxShadow: " 0px 5px 15px -2px rgba(0, 0, 0, 0.12)",
          borderRadius: "2rem",
          width: "100%",
        }}
      >
        <Box className="flex justify-between px-3 py-2 items-center bg-[white]">
          <Box display="flex" p={1} alignItems="center">
            <TextField
              label="Search"
              onChange={handleSearchChange}
              size="small"
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
          </Box>

          <Button
            variant="contained"
            onClick={handleClickOpen}
            className="!bg-[#3A416F] gap-2"
          >
            Add <AddBoxRoundedIcon />
          </Button>

          <Dialog fullWidth open={open} onClose={handleClose}>
            <Box
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"space-between"}
            >
              <DialogTitle className="text-[#312C41] !text-[16px] !font-semibold">
                Payment Mode Master
              </DialogTitle>

              <CancelRoundedIcon
                onClick={handleClose}
                className="bg-[white] !text-[#344767] !text-[1.7rem] !outline-none p-4"
              />
            </Box>

            <DialogContent>
              <Stack
                spacing={4}
                paddingLeft={2}
                paddingRight={2}
                paddingBottom={2}
              >
                <Stack direction="row" spacing={2}>
                  <TextField
                    required
                    fullWidth
                    name="paymentMode"
                    type="text"
                    id="outlined-required"
                    label="Payment Mode"
                    size="small"
                    InputLabelProps={{ shrink: true }}
                    value={formValue.paymentMode}
                    onChange={handleInputChange}
                  />
                </Stack>
                <Button
                  disabled={!isDisabled}
                  onClick={handleSubmit}
                  sx={{
                    background: !isDisabled
                      ? "gray"
                      : "linear-gradient(135deg, #3A416F 0%, #141727 100%)",
                    color: "white",
                    "&:hover": {},
                  }}
                >
                  OK
                </Button>
              </Stack>
            </DialogContent>
          </Dialog>
        </Box>
        <PaymentModeTable
          columns={columns}
          paymentModeFilterData={paymentModeFilterData}
        />
      </Box>
    </MainLayout>
  );
};

export default PaymentModePage;
