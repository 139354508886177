import React from "react";
import LeaveTail from "./LeaveTail";
import { Box, Button } from "@mui/material";
import WorkingDaysTail from "./WorkingDaysTail";
import ShiftTypeFormTail from "./ShiftTypeFormTail";
import LateEarlyRelaxationTail from "./LateEarlyRelaxationTail";
import useWorkingDetails from "../hooks/useWorkingDetails";
import useShiftType from "../hooks/useShiftType";

const ShiftTypeForm = ({
  step,
  setStep,
  shiftin,
  shiftout,
  formValue,
  setShiftIn,
  setShiftOut,
  setFormValue,
  handleCheckBox,
  handleInputChange,
  handleShiftCreateSubmit,
}) => {
  
  const {
    isChecked,
    checkedValues,
    handleCheckboxChange,
    handleItemCheckboxChange,
  } = useWorkingDetails({ formValue, setFormValue });

  const {
    handleTimeChangeIn,
    handleTimeChangeOut,
    handleCheckBoxForShiftType,
  } = useShiftType({
    setShiftIn,
    setShiftOut,
    formValue,
    setFormValue,
  });

  // setFormValue,

  const isDisabledForWorkingDetails = formValue.days <= 0;

  const isDisabledForShiftType =
    formValue.name !== "" &&
    // formValue.description !== "" &&
    formValue.shiftIn !== "" &&
    formValue.shiftOut !== "";

  const isDisabledForRelaxation =
    formValue.lateMinutes !== "" && formValue.earlyMinutes !== "";

  const isDisabledForLeaveTail =
    formValue.fullDayMinutes !== "" &&
    formValue.fullDayValue !== "" &&
    formValue.halfDayMinutes !== "" &&
    formValue.halfDayValue !== "" &&
    formValue.days !== "";
  return (
    <Box className="justify-center w-[100%] px-3">
      <Button
        variant="contained"
        className="!rounded-tl-xl w-[25%] h-[3rem] !rounded-none !shadow-none"
        sx={{
          textTransform: "none",
          color: step === 0 ? "white" : "#576782",
          bgcolor: step === 0 ? "#576782" : "#E9E9E9",
          ":hover": {
            bgcolor: "#576782",
            color: "white",
          },
        }}
        onClick={() => {
          setStep(0);
        }}
      >
        Shift
      </Button>
      <Button
        variant="contained"
        className="w-[25%] h-[3rem] !rounded-none !shadow-none"
        sx={{
          textTransform: "none",
          color: step === 1 ? "white" : "#576782",
          bgcolor: step === 1 ? "#576782" : "#E9E9E9",
          ":hover": {
            bgcolor: "#576782",
            color: "white",
          },
          pointerEvents: !isDisabledForShiftType ? "none" : "auto",
        }}
        onClick={() => {
          setStep(1);
        }}
      >
        Relaxation
      </Button>
      <Button
        variant="contained"
        className="w-[25%] h-[3rem] !rounded-none !shadow-none"
        sx={{
          textTransform: "none",
          color: step === 2 ? "white" : "#576782",
          bgcolor: step === 2 ? "#576782" : "#E9E9E9",
          ":hover": {
            bgcolor: "#576782",
            color: "white",
          },
          pointerEvents: !isDisabledForRelaxation ? "none" : "auto",
        }}
        onClick={() => {
          setStep(2);
        }}
      >
        Working Days
      </Button>
      <Button
        variant="contained"
        className="!rounded-tr-xl w-[25%] h-[3rem] !rounded-none !shadow-none"
        sx={{
          textTransform: "none",
          color: step === 3 ? "white" : "#576782",
          bgcolor: step === 3 ? "#576782" : "#E9E9E9",
          ":hover": {
            bgcolor: "#576782",
            color: "white",
          },
          pointerEvents: isDisabledForWorkingDetails ? "none" : "auto",
        }}
        onClick={() => {
          setStep(3);
        }}
      >
        Leave
      </Button>

      <Box className="mt-8">
        {step === 0 ? (
          <ShiftTypeFormTail
            setStep={setStep}
            shiftin={shiftin}
            shiftout={shiftout}
            formValue={formValue}
            setShiftIn={setShiftIn}
            setShiftOut={setShiftOut}
            setFormValue={setFormValue}
            handleInputChange={handleInputChange}
            handleTimeChangeIn={handleTimeChangeIn}
            handleTimeChangeOut={handleTimeChangeOut}
            isDisabledForShiftType={isDisabledForShiftType}
            handleCheckBoxForShiftType={handleCheckBoxForShiftType}
          />
        ) : step === 1 ? (
          <LateEarlyRelaxationTail
            setStep={setStep}
            formValue={formValue}
            handleInputChange={handleInputChange}
            isDisabledForRelaxation={isDisabledForRelaxation}
          />
        ) : step === 2 ? (
          <WorkingDaysTail
            setStep={setStep}
            formValue={formValue}
            setFormValue={setFormValue}
            isChecked={isChecked}
            checkedValues={checkedValues}
            handleInputChange={handleInputChange}
            handleCheckboxChange={handleCheckboxChange}
            handleItemCheckboxChange={handleItemCheckboxChange}
            isDisabledForWorkingDetails={isDisabledForWorkingDetails}
          />
        ) : step === 3 ? (
          <LeaveTail
            setStep={setStep}
            formValue={formValue}
            handleCheckBox={handleCheckBox}
            handleInputChange={handleInputChange}
            isDisabledForLeaveTail={isDisabledForLeaveTail}
            handleShiftCreateSubmit={handleShiftCreateSubmit}
          />
        ) : (
          ""
        )}
      </Box>
    </Box>
  );
};

export default ShiftTypeForm;
