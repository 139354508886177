import {
  Grid,
  Button,
  Checkbox,
  TextField,
  FormControlLabel,
  Typography,
} from "@mui/material";

import React from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider, MobileTimePicker } from "@mui/x-date-pickers";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";

const ShiftTypeFormTail = ({
  setStep,
  shiftin,
  shiftout,
  formValue,
  handleInputChange,
  handleTimeChangeIn,
  handleTimeChangeOut,
  isDisabledForShiftType,
  handleCheckBoxForShiftType,
}) => {
  return (
    <>
      <Grid container gap={3}>
        <Grid item xs={12} sm={5} md={4} lg={4}>
          <Typography className="!mb-2" variant="subtitle2">
            Shift name
          </Typography>
          <TextField
            fullWidth
            size="small"
            type="text"
            name="name"
            onChange={handleInputChange}
            value={formValue.name}
            placeholder="Shift name"
          />
        </Grid>
        <Grid item xs={12} sm={5} md={4} lg={4}>
          <Typography className="!mb-2" variant="subtitle2">
            Remarks
          </Typography>
          <TextField
            fullWidth
            size="small"
            type="text"
            name="description"
            onChange={handleInputChange}
            value={formValue.description}
            placeholder="Remarks"
          />
        </Grid>
      </Grid>
      <Grid container gap={3} mt={2}>
        <Grid item xs={12} sm={5} md={4} lg={4}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["MobileTimePicker"]}>
              <div className="!w-full">
                <DemoItem label="Shift In">
                  <MobileTimePicker
                    sx={{
                      ".css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input":
                        {
                          padding: "12px",
                          width: "100%",
                          height: "16px",
                        },
                    }}
                    onChange={(time) => handleTimeChangeIn(time)}
                    value={shiftin}
                  />
                </DemoItem>
              </div>
            </DemoContainer>
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} sm={5} md={4} lg={4}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["MobileTimePicker"]}>
              <div className="!w-full">
                <DemoItem label="Shift Out">
                  <MobileTimePicker
                    sx={{
                      ".css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input":
                        {
                          padding: "12px",
                          width: "100%",
                          height: "16px",
                        },
                    }}
                    onChange={(time) => handleTimeChangeOut(time)}
                    value={shiftout}
                  />
                </DemoItem>
              </div>
            </DemoContainer>
          </LocalizationProvider>
        </Grid>
      </Grid>
      <Grid container mt={2} gap={3}>
        <Grid item>
          <FormControlLabel
            control={
              <Checkbox
                defaultChecked
                onChange={(e) => handleCheckBoxForShiftType(e)}
                sx={{ "& .MuiSvgIcon-root": { color: "#576782" } }}
              />
            }
            name="shiftOutNextDay"
            label="Next Day Out"
            checked={formValue?.shiftOutNextDay === true ? true : false}
          />
        </Grid>
      </Grid>
      <Grid container mt={11} className="flex justify-end">
        <Grid item className="flex justify-center">
          <Button
            disabled={!isDisabledForShiftType}
            onClick={() => setStep(1)}
            sx={{
              width: "220px",
              background: !isDisabledForShiftType
                ? "gray"
                : "linear-gradient(135deg, #3A416F 0%, #141727 100%)",
              color: "white",
              "&:hover": {},
            }}
          >
            Next
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default ShiftTypeFormTail;
