import axios from "axios";

export async function Initialize() {
  axios.interceptors.request.use(
    (req) => {
      const token = localStorage.getItem("token");

      if (token) {
        req.headers.Authorization = `Bearer ${token}`;
      }
      return req;
    },
    (err) => {
      return Promise.reject(err);
    }
  );

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        localStorage.clear();
        if (error.request.responseURL.indexOf("/") < 0) {
          setTimeout(() => {
            window.location.href = "/";
          }, 2000);
        }
      } else if (error.response.status === 302) {
        return error.response;
      } else {
        return error;
      }
    }
  );
}
