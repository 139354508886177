import { Box, Button, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import {
  ADD_PAYMENT_MODE,
  GET_ALL_PAYMENT_MODE_DATA,
} from "../../../services/apiUrls";
import { get, post, Put } from "../../../services/service";
import EditIcon from "@mui/icons-material/Edit";

export function usePaymentMode() {
  const [open, setOpen] = useState(false);

  const [formValue, setFormValue] = useState({ id: "", paymentMode: "" });

  const [paymentMode, setPaymentMode] = useState([]);

  const [search, setSearch] = useState("");

  const columns = [
    {
      field: "No.",
      headerName: "NO.",
      width: 65,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "paymentMode",
      headerName: "PAYMENT MODE",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "deleted",
      headerName: "STATUS",
      flex: 1,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <Box>
            <Button
              onClick={() => {
                // setStatusUpdateConfirmPopUp(true);
                // setTableRowDetails(params);
              }}
            >
              {params?.row?.deleted === false ? (
                <Button
                  variant="contained"
                  size="small"
                  color="success"
                  sx={{ width: "2rem" }}
                >
                  <Typography sx={{ fontSize: "10px" }}>Active</Typography>
                </Button>
              ) : (
                <Button
                  variant="contained"
                  size="small"
                  color="error"
                  sx={{ width: "2rem" }}
                >
                  <Typography sx={{ fontSize: "10px" }}>Delete</Typography>
                </Button>
              )}
            </Button>
          </Box>
        );
      },
    },
    {
      field: "action",
      headerName: "ACTION",
      flex: 1,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <EditIcon
            sx={{ cursor: "pointer" }}
            onClick={() => {
              //   setOpen(true);
              window.scrollTo(0, 0);
              //   getFeeMasterById(params.id);
            }}
          />
        );
      },
    },
  ];

  const handleInputChange = (e) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
  };

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const paymentModeFilterData = paymentMode.filter((item) =>
    item.paymentMode.toLowerCase().includes(search.toLowerCase())
  );

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    handleClear();
  };

  const handleClear = () => {
    setFormValue({ ...formValue, paymentMode: "" });
  };

  const isDisabled = formValue?.paymentMode !== "";

  const getAllPaymentModeMasterData = async () => {
    const resp = await get(GET_ALL_PAYMENT_MODE_DATA);
    if (resp && resp?.[0]?.id !== " ") {
      setPaymentMode(resp);
    }
  };

  const handleSubmit = async () => {
    if (!formValue.id) {
      const resp = await post(ADD_PAYMENT_MODE, formValue);
      if (resp?.id !== " ") {
        handleClear();
        handleClose();
        getAllPaymentModeMasterData();
      }
    } else {
      const resp = await Put(ADD_PAYMENT_MODE, formValue);
      if (resp?.id !== " ") {
        handleClear();
        handleClose();
        getAllPaymentModeMasterData();
      }
    }
  };

  /*eslint-disable */
  useEffect(() => {
    getAllPaymentModeMasterData();
  }, []);
  /*eslint-enable */

  return {
    open,
    columns,
    formValue,
    isDisabled,
    handleClose,
    handleSubmit,
    handleClickOpen,
    handleInputChange,
    handleSearchChange,
    paymentModeFilterData,
  };
}
