import { useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import { Box, Button, Typography } from "@mui/material";
import {
  ADD_FEE_MASTER_URL,
  GET_FEE_MASTER_BY_ID,
  GET_ALL_FEE_MASTER_DATA,
  UPDATE_FEE_MASTER_URL,
  FEES_MASTER_INACTIVE,
  FEES_MASTER_ACTIVE,
} from "../../../services/apiUrls";
import { get, post, Put } from "../../../services/service";
import axios from "axios";
import { fetchParkingTypes } from "../../../api/parkingType";
import { fetchVehicleTypes } from "../../../api/vehicleType";

export function useFeeMaster() {
  const [open, setOpen] = useState(false);
  const [feeMaster, setFeeMaster] = useState([]);

  const [parkingTypeData, setParkingTypeData] = useState();
  const [vehicleTypeData, setVehicleTypeData] = useState();

  /*eslint-disable */
  const [tableRowDetails, setTableRowDetails] = useState();
  /*eslint-enable */

  const [search, setSearch] = useState("");

  const [formValue, setFormValue] = useState({
    id: "",
    amount: "",
    toMins: "",
    fromMins: "",
    parkingType: "",
    vehicleType: "",
    additionalMins: "",
    baseFees: false,
    penalty: false,
  });

  let tenantId = localStorage.getItem("tenantId");
  let locationId = localStorage.getItem("locationId");

  const columns = [
    {
      field: "No.",
      headerName: "NO.",
      width: 65,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "vehicleType",
      headerName: "VEHICLE TYPE",
      flex: 1,
      headerClassName: "super-app-theme--header",
      valueGetter: (params) => params.row.vehicleType?.name,
    },
    {
      field: "parkingType",
      headerName: "PARKING TYPE",
      flex: 1,
      headerClassName: "super-app-theme--header",
      valueGetter: (params) => params.row.parkingType?.name,
    },

    {
      field: "fromMins",
      headerName: "FROM TIME",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "toMins",
      headerName: "TO TIME",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "additionalMins",
      headerName: "ADDITIONAL TIME",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "amount",
      headerName: "PARKING FEE",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "deleted",
      headerName: "STATUS",
      flex: 1,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <Box>
            <Button
            // onClick={() => {
            //   setTableRowDetails(params);
            // }}
            >
              {params?.row?.deleted === false ? (
                <Button
                  variant="contained"
                  size="small"
                  color="success"
                  sx={{ width: "2rem" }}
                  onClick={() => {
                    feesMasterInactive(params.row.id);
                  }}
                >
                  <Typography sx={{ fontSize: "10px" }}>Active</Typography>
                </Button>
              ) : (
                <Button
                  variant="contained"
                  size="small"
                  color="error"
                  sx={{ width: "2rem" }}
                  onClick={() => {
                    feesMasterActive(params.row.id);
                  }}
                >
                  <Typography sx={{ fontSize: "10px" }}>Inactive</Typography>
                </Button>
              )}
            </Button>
          </Box>
        );
      },
    },
    {
      field: "action",
      headerName: "ACTION",
      flex: 1,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <EditIcon
            sx={{ cursor: "pointer" }}
            onClick={() => {
              setOpen(true);
              window.scrollTo(0, 0);
              getFeeMasterById(params.id);
            }}
          />
        );
      },
    },
  ];

  const handleInputChange = (e) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
  };

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const handleCheckBox = (e) => {
    if (e.target.checked) {
      setFormValue({
        ...formValue,
        [e.target.name]: true,
      });
    } else {
      setFormValue({
        ...formValue,
        [e.target.name]: false,
      });
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    handleClear();
  };

  const handleClear = () => {
    setFormValue({
      id: "",
      amount: "",
      toMins: "",
      fromMins: "",
      parkingType: "",
      vehicleType: "",
      additionalMins: "",
      baseFees: false,
    });
  };

  const isDisabled =
    formValue?.amount !== "" &&
    formValue?.toMins !== "" &&
    formValue?.parkingType !== "" &&
    formValue?.vehicleType !== "" &&
    formValue?.additionalMins !== "";

  const getFeeMasterById = async (id) => {
    const url = `${GET_FEE_MASTER_BY_ID.replace("{tenantId}", tenantId).replace(
      "{locationId}",
      locationId
    )}/${id}`;
    const resp = await get(url);
    if (resp && resp?.id !== "") {
      setFormValue({
        ...formValue,
        id: resp?.id,
        amount: resp?.amount,
        toMins: resp?.toMins,
        fromMins: resp?.fromMins,
        parkingType: resp?.parkingType?.id,
        vehicleType: resp?.vehicleType?.id,
        additionalMins: resp?.additionalMins,
        baseFees: resp?.baseFees,
        penalty: resp?.penalty,
      });
    }
  };

  const getAllFeeMasterData = async () => {
    const resp = await get(
      GET_ALL_FEE_MASTER_DATA.replace("{tenantId}", tenantId).replace(
        "{locationId}",
        locationId
      )
    );
    if (resp && resp?.[0]?.id !== " ") {
      setFeeMaster(resp);
    }
  };

  const getAllParkingType = async () => {
    let response = await fetchParkingTypes(tenantId, locationId);
    if (response && response?.id !== "") {
      setParkingTypeData(response);
    }
  };

  const getAllVehicleType = async () => {
    let response = await fetchVehicleTypes(tenantId, locationId);
    if (response && response?.id !== " ") {
      setVehicleTypeData(response);
    }
  };

  const handleSubmit = async () => {
    if (!formValue.id) {
      const url = `${ADD_FEE_MASTER_URL.replace("{tenantId}", tenantId).replace(
        "{locationId}",
        locationId
      )}`;
      const data = {
        amount: parseInt(formValue.amount),
        fromMins: parseInt(formValue.fromMins),
        toMins: parseInt(formValue.toMins),
        baseFees: formValue.baseFees,
        additionalMins: parseInt(formValue.additionalMins),
        vehicleType: {
          id: formValue.vehicleType,
        },
        parkingType: {
          id: formValue.parkingType,
        },
        tenantId: localStorage.getItem("tenantId"),
        locationId: localStorage.getItem("locationId"),
        penalty: formValue.penalty,
      };
      const resp = await post(url, data);
      if (resp?.id !== " ") {
        handleClear();
        handleClose();
        getAllFeeMasterData();
      }
    } else {
      const url = `${UPDATE_FEE_MASTER_URL.replace(
        "{tenantId}",
        tenantId
      ).replace("{locationId}", locationId)}`;
      const data = {
        id: formValue.id,
        baseFees: formValue.baseFees,
        toMins: formValue.toMins,
        amount: formValue.amount,
        fromMins: formValue.fromMins,
        additionalMins: formValue.additionalMins,
        vehicleType: {
          id: formValue.vehicleType,
        },
        parkingType: {
          id: formValue.parkingType,
        },
        tenantId: localStorage.getItem("tenantId"),
        locationId: localStorage.getItem("locationId"),
        penalty: formValue.penalty,
      };
      const resp = await Put(url, data);
      if (resp && resp.id !== "") {
        handleClear();
        handleClose();
        getAllFeeMasterData();
      }
    }
  };

  const feeMasterFilterData = feeMaster?.filter(
    (item) =>
      item.toMins === parseInt(search) ||
      item.fromMins === parseInt(search) ||
      item.amount === parseInt(search) ||
      item.additionalMins === parseInt(search) ||
      item.parkingType.name.toLowerCase().includes(search.toLowerCase()) ||
      item.vehicleType.name.toLowerCase().includes(search.toLowerCase())
  );

  // FEES MASTER INACTIVE
  const feesMasterInactive = async (id) => {
    try {
      const resp = await axios
        .delete(
          FEES_MASTER_INACTIVE.replace("{tenantId}", tenantId)
            .replace("{locationId}", locationId)
            .replace("{id}", id)
        )
        .then((res) => {
          getAllFeeMasterData();
          return res;
        });
      return resp;
    } catch (err) {
      return err;
    }
  };

  // FEES MASTER ACTIVE
  const feesMasterActive = async (restoreId) => {
    try {
      const resp = await axios
        .delete(
          FEES_MASTER_ACTIVE.replace("{tenantId}", tenantId)
            .replace("{locationId}", locationId)
            .replace("{restoreId}", restoreId)
        )
        .then((res) => {
          getAllFeeMasterData();
          return res;
        });
      return resp;
    } catch (err) {
      return err;
    }
  };

  /*eslint-disable */
  useEffect(() => {
    getAllParkingType();
    getAllVehicleType();
    getAllFeeMasterData();
  }, []);
  /*eslint-disable */

  return {
    open,
    setOpen,
    columns,
    formValue,
    isDisabled,
    handleClose,
    handleSubmit,
    handleCheckBox,
    tableRowDetails,
    handleClickOpen,
    parkingTypeData,
    vehicleTypeData,
    handleInputChange,
    handleSearchChange,
    feeMasterFilterData,
  };
}
