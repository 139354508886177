import { DELETE_USER, GET_ALL_ACTIVE_EMPLOYEES } from "../services/apiUrls";
import { Delete, get } from "../services/service";

export const fetchActiveEmployees = async (tenantId) => {
  let response = await get(
    GET_ALL_ACTIVE_EMPLOYEES.replace("{tenantId}", tenantId)
  );
  return response;
};

export const deleteUser = async (userId) => {
  try {
    let resposne = await Delete(DELETE_USER.replace("{userId}", userId));
    return resposne;
  } catch (error) {
    return error;
  }
};
