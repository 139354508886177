import {
  Box,
  Grid,
  Button,
  Dialog,
  Select,
  Checkbox,
  MenuItem,
  TextField,
  InputLabel,
  DialogTitle,
  FormControl,
  Autocomplete,
  DialogContent,
} from "@mui/material";
import React, { useEffect } from "react";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";

const ShiftAssignForm = ({
  open,
  value,
  formValue,
  activeShift,
  handleClose,
  setFormValue,
  handleOnChange,
  activeEmployee,
  handleInputChange,
  handleShiftAssignSubmit,
}) => {
  const isDisabled = true;

  /*eslint-disable */
  useEffect(() => {
    setFormValue({ ...formValue, employeeId: value });
  }, [value]);
  /*eslint-enable */

  return (
    <>
      <Dialog
        fullWidth
        open={open}
        onClose={handleClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "80%",
            },
          },
        }}
      >
        <Box
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"space-between"}
        >
          <DialogTitle className="text-[#312C41] !text-[16px] !font-semibold">
            Shift Assign
          </DialogTitle>

          <CancelRoundedIcon
            onClick={handleClose}
            className="bg-[white] !text-[#344767] !text-[1.7rem] !outline-none p-4"
          />
        </Box>
        
        <DialogContent className="!p-7">
          <Grid container gap={3}>
            <Grid item xs={12} sm={12} md={3.5} lg={3.5}>
              <FormControl size="small" fullWidth>
                <InputLabel id="demo-select-small">Role</InputLabel>
                <Select
                  labelId="role"
                  id="role"
                  name="role"
                  label="role"
                  value={formValue?.role}
                  onChange={handleInputChange}
                >
                  <MenuItem value={"ROLE_USER"}>User</MenuItem>
                  <MenuItem value={"ROLE_STAFF"}>Staff</MenuItem>
                  <MenuItem value={"ROLE_ADMIN"}>Admin</MenuItem>
                  <MenuItem value={"ROLE_BUSINESS"}>Business</MenuItem>
                  <MenuItem value={"ROLE_SUPERVISOR"}>Supervisor</MenuItem>
                  <MenuItem value={"ROLE_SUPERADMIN"}>Super Admin</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={3.5} lg={3.5}>
              <Autocomplete
                size="small"
                multiple
                fullWidth
                name="employeeId"
                id="checkboxes-tags-demo"
                options={activeEmployee}
                value={value}
                onChange={handleOnChange}
                disableCloseOnSelect
                getOptionLabel={(option) => option?.name}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      style={{ marginRight: 8 }}
                      checked={selected}
                      sx={{
                        "& .MuiSvgIcon-root": { color: "#576782" },
                      }}
                    />
                    {option?.firstname}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Employee Name"
                    placeholder="Select Name"
                    sx={{
                      "& .MuiAutocomplete-tag": {
                        background: "#3A416F",
                        color: "white",
                      },
                      "& .MuiChip-deleteIcon": {
                        color: "white !important",
                      },
                      "& .css-1kp1dnc-MuiButtonBase-root-MuiChip-root": {
                        color: "white !important",
                      },
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>

          <Grid container gap={3} mt={{ xs: 3, sm: 3, md: 5, lg: 5 }}>
            <Grid item xs={12} sm={12} md={3.5} lg={3.5}>
              <FormControl size="small" fullWidth>
                <InputLabel id="demo-select-small">Shift</InputLabel>
                <Select
                  labelId="demo-select-small"
                  id="demo-select-small"
                  value={formValue?.shiftType}
                  label="shift"
                  name="shiftType"
                  onChange={handleInputChange}
                >
                  {activeShift?.map((obj) => {
                    return <MenuItem value={obj.id}>{obj.name}</MenuItem>;
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={3.5} lg={3.5}>
              <TextField
                focused
                fullWidth
                id="to-date"
                type="date"
                size="small"
                name="fromDate"
                label="Date From"
                variant="outlined"
                value={formValue.fromDate}
                defaultValue={formValue.fromDate}
                onChange={(e) => {
                  setFormValue({ ...formValue, fromDate: e.target.value });
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3.5} lg={3.5}>
              <TextField
                focused
                fullWidth
                id="to-date"
                type="date"
                size="small"
                name="toDate"
                label="Date To"
                variant="outlined"
                value={formValue.toDate}
                defaultValue={formValue.toDate}
                onChange={(e) => {
                  setFormValue({ ...formValue, toDate: e.target.value });
                }}
              />
            </Grid>
          </Grid>

          <Grid container gap={3} mt={{ xs: 3, sm: 3, md: 5, lg: 5 }}>
            <Grid item xs={12} sm={12} md={3.5} lg={3.5}>
              <TextField
                fullWidth
                size="small"
                type="text"
                placeholder="Enter Remarks"
                name="remarks"
                value={formValue?.remarks}
                onChange={handleInputChange}
              />
            </Grid>
          </Grid>

          <Grid container mt={8}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Button
                fullWidth
                onClick={handleShiftAssignSubmit}
                sx={{
                  textTransform: "none",
                  background: !isDisabled
                    ? "gray"
                    : "linear-gradient(135deg, #3A416F 0%, #141727 100%)",
                  color: "white",
                  "&:hover": {},
                }}
              >
                {formValue?.id ? "Update" : "Submit"}
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ShiftAssignForm;
