import React, { useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import {
  ADD_NEW_SHIFT,
  GET_BY_SHIFT_ID,
  GET_ALL_SHIFT_DATA,
} from "../../../../../services/apiUrls";
import { Put, get, post } from "../../../../../services/service";
import dayjs from "dayjs";

export function useShift() {
  const [step, setStep] = useState(0);
  const [open, setOpen] = useState(false);

  const [shiftType, setShiftType] = useState();

  const [shiftin, setShiftIn] = useState(dayjs(new Date()));
  const [shiftout, setShiftOut] = useState(dayjs(new Date()));

  const [formValue, setFormValue] = useState({
    name: "",
    description: "",
    shiftIn: "",
    shiftOut: "",
    shiftOutNextDay: "false",
    days: "",
    lateMinutes: "",
    earlyMinutes: "",
    fullDayMinutes: "",
    fullDayValue: "",
    halfDayMinutes: "",
    halfDayValue: "",
  });
  /*eslint-disable */
  const [search, setSearch] = useState("");

  let tenantId = localStorage.getItem("tenantId");

  const handleInputChange = (e) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
  };

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    handleClear();
  };

  const handleClear = () => {
    setFormValue({
      name: "",
      description: "",
      shiftIn: "",
      shiftOut: "",
      shiftOutNextDay: "false",
      days: "",
      lateMinutes: "",
      earlyMinutes: "",
      fullDayMinutes: "",
      fullDayValue: "",
      halfDayMinutes: "",
      halfDayValue: "",
    });
  };

  const shiftIn = `${shiftin?.$H}:${shiftin?.$m}:${shiftin?.$s}`;
  const shiftOut = `${shiftout?.$H}:${shiftout?.$m}:${shiftout?.$s}`;

  const timeIn = new Date(shiftin);
  const timeOut = new Date(shiftout);

  const handleCheckBox = (e) => {
    if (e.target.checked === true) {
      const timeDiff = timeOut.getTime() - timeIn.getTime();
      const diffInHours = timeDiff / (1000 * 60);

      setFormValue({
        ...formValue,
        fullDayMinutes: diffInHours,
        halfDayMinutes: diffInHours / 2,
        fullDayValue: 1,
        halfDayValue: 0.5,
      });
    } else {
      setFormValue({
        ...formValue,
        fullDayMinutes: "",
        halfDayMinutes: "",
        fullDayValue: "",
        halfDayValue: "",
      });
    }
  };

  const isDisabled =
    formValue?.amount !== "" &&
    formValue?.toMins !== "" &&
    formValue?.parkingType !== "" &&
    formValue?.vehicleType !== "" &&
    formValue?.additionalMins !== "";

  const days = [
    "Saturday",
    "Friday",
    "Thursday",
    "Wednesday",
    "Tuesday",
    "Monday",
    "Sunday",
  ];

  const convertedWorkingDays = (params) => {
    const binary = (params >>> 0).toString(2);

    function zeroFill(number, length) {
      return number.toString().padStart(length, "0");
    }

    const binaryValue = zeroFill(binary, 7);

    function ConvertStringToArray(binaryValue) {
      const arr = binaryValue.split("").map((val) => parseInt(val));
      return arr;
    }

    const BinaryArray = ConvertStringToArray(binaryValue);

    function FilterArray(array) {
      const indices = array.reduce((acc, val, index) => {
        if (val === 1) {
          acc.push(index);
        }
        return acc;
      }, []);
      return indices;
    }

    const arryIndex = FilterArray(BinaryArray);

    const workingDay = arryIndex.map((index) => days[index]);
    const workingDays = workingDay.reverse();
    return workingDays;
  };

  const ShiftTypeColumns = [
    {
      field: "No",
      headerName: "No",
      width: 65,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "name",
      headerName: "NAME",
      flex: 1,
      headerClassName: "super-app-theme--header",
      //   valueGetter: (params) => params.row.vehicleType?.name,
    },
    {
      field: "shiftIn",
      headerName: "SHIFT IN",
      flex: 1,
      headerClassName: "super-app-theme--header",
      //   valueGetter: (params) => params.row.parkingType?.name,
    },
    {
      field: "shiftOut",
      headerName: "SHIFT OUT",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "days",
      headerName: "WORKING DAYS",
      flex: 1,
      headerClassName: "super-app-theme--header",
      valueGetter: ({ value }) => convertedWorkingDays(value),
    },
    {
      field: "lateMinutes",
      headerName: "LATE DAILY",
      flex: 1,
      align: "right",
      headerAlign: "right",
      headerClassName: "super-app-theme--header",
    },
    {
      field: "earlyMinutes",
      headerName: "EARLY DAILY",
      flex: 1,
      align: "right",
      headerAlign: "right",
      headerClassName: "super-app-theme--header",
    },
    // {
    //   field: "leave",
    //   headerName: "LEAVE",
    //   flex: 1,
    //   headerClassName: "super-app-theme--header",
    // },
    {
      field: "action",
      headerName: "ACTION",
      flex: 1,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <EditIcon
            sx={{ cursor: "pointer" }}
            onClick={() => {
              setOpen(true);
              window.scrollTo(0, 0);
              getByShiftid(params.id);
            }}
          />
        );
      },
    },
  ];

  const ShiftHistoryColumns = [
    {
      field: "No",
      headerName: "NO",
      width: 65,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "date",
      headerName: "DATE",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "username",
      headerName: "EMPLOYEE NAME",
      flex: 1,
      headerClassName: "super-app-theme--header",
      // valueGetter: (params) => console.log('params', params),
    },
    {
      field: "role",
      headerName: "ROLE",
      flex: 1,
      headerClassName: "super-app-theme--header",
      //   valueGetter: (params) => params.row.parkingType?.name,
    },
    {
      field: "shift",
      headerName: "SHIFT",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "shiftIn",
      headerName: "SHIFT IN",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "shiftOut",
      headerName: "SHIFT OUT",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "gate",
      headerName: "GATE",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "remarks",
      headerName: "REMARKS",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
  ];

  const shiftHistory = [
    {
      id: 1,
      No: 1,
      date: "11-04-2023",
      username: "Messi",
      role: "Supervisor",
      shift: "Shift-1",
      shiftIn: "09:00AM",
      shiftOut: "12:00PM",
      gate: "Gate A",
      remarks: "remarks",
    },
    {
      id: 2,
      No: 2,
      date: "11-04-2023",
      username: "CR7",
      role: "staff",
      shift: "Shift-1",
      shiftIn: "09:00AM",
      shiftOut: "12:00PM",
      gate: "Gate B",
      remarks: "remarks",
    },
  ];

  const shiftTypeData = shiftType?.filter(
    (item) =>
      item.lateMinutes === parseInt(search) ||
      item.earlyMinutes === parseInt(search) ||
      item.name.toLowerCase().includes(search.toLowerCase())
  );

  const getAllshiftData = async () => {
    const url = `${GET_ALL_SHIFT_DATA.replace("{tenantId}", tenantId)}`;
    const resp = await get(url);
    if (resp && resp?.id !== "") {
      setShiftType(resp);
    }
  };

  const handleShiftCreateSubmit = async () => {
    if (formValue.id) {
      const url = `${ADD_NEW_SHIFT.replace("{tenantId}", tenantId)}`;
      const data = {
        id: formValue.id,
        name: formValue.name,
        description: formValue.description,
        shiftIn: formValue.shiftIn,
        shiftOut: formValue.shiftOut,
        shiftOutNextDay: formValue.shiftOutNextDay,
        days: formValue.days,
        lateMinutes: parseInt(formValue.lateMinutes),
        earlyMinutes: parseInt(formValue.earlyMinutes),
        fullDayMinutes: parseInt(formValue.fullDayMinutes),
        fullDayValue: parseInt(formValue.fullDayValue),
        halfDayMinutes: parseInt(formValue.halfDayMinutes),
        halfDayValue: parseInt(formValue.halfDayValue),
        tenantId: tenantId,
      };
      const resp = await Put(url, data);
      if (resp && resp.id) {
        handleClose();
        getAllshiftData();
      }
    } else {
      const url = `${ADD_NEW_SHIFT.replace("{tenantId}", tenantId)}`;
      const data = {
        name: formValue.name,
        description: formValue.description,
        shiftIn: formValue.shiftIn,
        shiftOut: formValue.shiftOut,
        shiftOutNextDay: formValue.shiftOutNextDay,
        days: formValue.days,
        lateMinutes: parseInt(formValue.lateMinutes),
        earlyMinutes: parseInt(formValue.earlyMinutes),
        fullDayMinutes: parseInt(formValue.fullDayMinutes),
        fullDayValue: parseInt(formValue.fullDayValue),
        halfDayMinutes: parseInt(formValue.halfDayMinutes),
        halfDayValue: parseInt(formValue.halfDayValue),
        tenantId: tenantId,
      };
      const resp = await post(url, data);
      if (resp && resp.id) {
        handleClose();
        getAllshiftData();
      }
    }
  };

  const getByShiftid = async (id) => {
    const url = `${GET_BY_SHIFT_ID.replace("{tenantId}", tenantId)}/${id}`;
    const resp = await get(url);
    if (resp && resp.id) {
      setFormValue({
        ...formValue,
        id: resp.id,
        name: resp.name,
        description: resp.description,
        shiftIn: resp.shiftIn,
        shiftOut: resp.shiftOut,
        shiftOutNextDay: resp.shiftOutNextDay,
        days: resp.days,
        binaryForm: resp.binaryForm,
        lateMinutes: parseInt(resp.lateMinutes),
        earlyMinutes: parseInt(resp.earlyMinutes),
        fullDayMinutes: parseInt(resp.fullDayMinutes),
        fullDayValue: parseInt(resp.fullDayValue),
        halfDayMinutes: parseInt(resp.halfDayMinutes),
        halfDayValue: parseInt(resp.halfDayValue),
      });
    }
  };

  /* eslint-disable */
  useEffect(() => {
    if (shiftin && shiftout) {
      setFormValue({ ...formValue, shiftIn: shiftIn, shiftOut: shiftOut });
    }
  }, [shiftin, shiftout]);

  useEffect(() => {
    getAllshiftData();
  }, []);
  /*eslint-enable */

  return {
    open,
    step,
    setStep,
    shiftin,
    shiftout,
    formValue,
    isDisabled,
    setShiftIn,
    setShiftOut,
    handleClose,
    shiftHistory,
    setFormValue,
    shiftTypeData,
    handleCheckBox,
    handleClickOpen,
    ShiftTypeColumns,
    handleInputChange,
    handleSearchChange,
    ShiftHistoryColumns,
    handleShiftCreateSubmit,
  };
}
