import moment from "moment";
import {
  VEHICLE_EXIT,
  GET_VEHICLE_NUMBER_SUGGESTION,
  GET_EXTRA_CHARGE_REASONS,
  GET_ENTRY_DETAILS_NEW,
} from "../../../../../services/apiUrls";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { get, Put } from "../../../../../services/service";
// import { unAllocateSlot } from "../../../../../api/slots";
import { fetchActiveFloors } from "../../../../../api/floors";

export function useExitDetails() {
  const params = useParams();

  const userId = localStorage.getItem("userId");
  const tenantId = localStorage.getItem("tenantId");
  const locationId = localStorage.getItem("locationId");
  const fullName = localStorage.getItem("fullname");

  const [activeVehicleNumber, setActiveVehicleNumber] = useState([]);
  const [extraCharges, setExtraCharges] = useState([]);

  const [value, setValue] = useState([]);
  const [inputValue, setInputValue] = useState([]);

  const [toast, setToast] = useState({ show: false, type: "", messsege: "" });
  const [loading, setLoading] = useState(false);

  const [activeFloors, setActiveFloors] = useState([]);

  const [formValues, setFormValues] = useState({
    id: "XXXX-XXXX-XXXX",
    vrn: inputValue,
    baseFee: "",
    tenantId: tenantId,
    entryTime: "",
    locationId: locationId,
    extrafee: "0",
    extrafeeReasonId: "",
    paymentStatus: "COMPLETED",
    parkingAmount: "",
    paymentMode: "",
    gateId: params.gateId,
    exitById: userId,
    parkingType: "",
    timeOnPremises: "",
    vehicleTypeId: params.vehicleId,
    parkingTypeId: "",
    refund: 0,
    penalty: 0,
    slotId: "",
    fullParkingAmount: "",
    complimentary: ""
  });

  const [parkingAmount, setParkingAmount] = useState("");

  const [penaltyAndRefund, setPenaltyAndRefund] = useState({
    penalty: 0,
    refund: 0,
  });

  const handleInputChange = (event, value) => {
    if (!value) {
      setActiveVehicleNumber([]);
    }
  };

  const handleChange = (e) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });

    if (e.target.name === "extrafee") {
      setFormValues({
        ...formValues,
        [e.target.name]: e.target.value.replace(/^0+/, ""),
      });
    }
  };

  const handleAmountChange = (e) => {
    setParkingAmount({
      ...parkingAmount,
      [e.target.name]: e.target.value,
    });
  };

  const handleExitClear = () => {
    setFormValues({
      ...formValues,
      id: "XXXX-XXXX-XXXX",
      vrn: "",
      baseFee: "",
      extrafee: "0",
      entryTime: "",
      parkingType: "",
      timeOnPremises: "",
      extrafeeReasonId: "",
    });
    setPenaltyAndRefund({ ...penaltyAndRefund, penalty: 0, refund: 0 });
    setInputValue([]);
    setValue([]);
    setParkingAmount("");
  };

  const [activefloorId, setActivefloorId] = useState("");

  const getActiveFloors = async () => {
    let response = await fetchActiveFloors(locationId, tenantId);
    if (response) {
      setActivefloorId(response[0]?.id);
    }
    setActiveFloors(response);
    return response;
  };

  // const handleSlotAllot = async () => {
  //   let response = await unAllocateSlot(
  //     activefloorId,
  //     locationId,
  //     formValues.slotId
  //   );
  //   return response;
  // };

  const [paymentModes, setPymentModes] = useState({
    cash: true,
    upi: false,
  });

  // const today = new Date();
  // const originalDate = moment(today);
  // const formattedDate = originalDate.format("YYYY-MM-DD");

  /*eslint-disable eqeqeq*/
  const handlePaymentMode = async (name) => {
    if (name == "UPI") {
      setPymentModes({ ...paymentModes, cash: false, upi: true });
      setFormValues({ ...formValues, paymentMode: name });
    } else if (name == "CASH") {
      setPymentModes({ ...paymentModes, cash: true, upi: false });
      setFormValues({ ...formValues, paymentMode: name });
    }
  };

  const handleCheckBox = (e) => {
    if (e.target.checked) {
      setFormValues({
        ...formValues,
        complimentary: true,
      });
    } else {
      setFormValues({
        ...formValues,
        complimentary: false,
      });
    }
  };

  // const addDataToCashCollectiontable = async (parkingId) => {
  //   const data = {
  //     vrn: formValues.vrn.toUpperCase(),
  //     parking: {
  //       id: parkingId,
  //     },
  //     parkingAmount: totalSeconds < 900 ? formValues?.baseFee : parkingAmount,
  //     date: formattedDate,
  //     paymentMode: totalSeconds < 900 ? "CASH" : formValues.paymentMode,
  //     tenantId: formValues.tenantId,
  //     locationId: formValues.locationId,
  //     entryById: userId,
  //     transactionType:
  //       totalSeconds < 900
  //         ? "DEBIT"
  //         : (totalSeconds < 900 && formValues.extrafee !== "") ||
  //           (totalSeconds < 900 && formValues.extrafee !== 0)
  //         ? "CREDIT"
  //         : "CREDIT",
  //     gateId: params.gateId,
  //     gateType: "EXIT",
  //     extraCharge: {
  //       id: formValues.extrafeeReasonId,
  //     },
  //     extraChargeAmount: formValues.extrafee,
  //     vehicleType: {
  //       id: params.vehicleId,
  //     },
  //     complimentary: false,
  //     mobileNo: "",
  //   };
  //   const response = await post(
  //     ADD_CASH_COLLECT_DATA.replace("{tenantId}", tenantId).replace(
  //       "{locationId}",
  //       locationId
  //     ),
  //     data
  //   );
  //   return response;
  // };

  const getVehicleSuggestions = async (vrn) => {
    const url = `${GET_VEHICLE_NUMBER_SUGGESTION.replace("{tenantId}", tenantId)
      .replace("{locationId}", locationId)
      .replace("{vrn}", vrn)
      .replace("{vehicleTypeId}", params.vehicleId)}`;
    let response = await get(url);
    setActiveVehicleNumber(response);
    return response;
  };

  const handleAutoCompleteChange = (event, newValue) => {
    setFormValues({
      ...formValues,
      vrn: newValue?.vrn.toUpperCase(),
    });
    setValue(newValue);
  };

  const getExtraChargeReasons = async () => {
    const response = await get(GET_EXTRA_CHARGE_REASONS);
    setExtraCharges(response);
  };

  const isDisabled = formValues?.vrn !== "" && formValues?.baseFee !== "";

  const getEntryDetails = async (parkingId) => {
    setLoading(true);
    const url = `${GET_ENTRY_DETAILS_NEW.replace(
      "{tenantId}",
      tenantId
    ).replace("{locationId}", locationId)}/${parkingId}`;
    const resp = await get(url);
    if (resp && resp?.id !== " ") {
      setLoading(false);
      setFormValues({
        ...formValues,
        id: resp?.id,
        tenantId: tenantId,
        locationId: locationId,
        entryTime: resp?.entryTime,
        baseFee: resp?.parkingAmount,
        paymentMode: resp?.paymentMode,
        parkingType: resp?.parkingType?.name,
        timeOnPremises: resp?.timeOnPremises,
        parkingTypeId: resp?.parkingType?.id,
        slotId: resp?.slotId,
        refund: resp?.refund,
        fullParkingAmount: resp?.fullParkingAmount,
        parkingAmount: resp?.fullParkingAmount - resp?.parkingAmount,
      });
      setParkingAmount(resp?.fullParkingAmount - resp?.parkingAmount);
      // getParkingAmount(resp?.parkingAmount, parkingId);
    } else {
      setLoading(false);
      setToast({
        ...toast,
        show: true,
        type: "error",
        messsege: "Vehicle not found",
      });
    }
  };

  // const getParkingAmount = async (baseFee, parkingId) => {
  //   const url = `${PARKING_AMOUNT.replace("{tenantId}", tenantId).replace(
  //     "{locationId}",
  //     locationId
  //   )}/${parkingId}/parking-amount`;
  //   const response = await get(url);
  //   if (response.length !== 0) {
  //     let parkingAmountValue =
  //       response?.parkingAmount == 0 ? 0 : response?.parkingAmount - baseFee;
  //     setParkingAmount(parkingAmountValue);
  //   }
  //   setPenaltyAndRefund({
  //     ...penaltyAndRefund,
  //     refund: response?.refund,
  //     penalty: response?.penalty,
  //   });
  // };

  var entryTimeStamp = formValues?.entryTime;
  const entryDate = moment(entryTimeStamp);
  const entryTime = entryDate.format("h:mm A");

  var premisesTimeStamp = formValues?.timeOnPremises;

  let totalSeconds = premisesTimeStamp / 1000;
  let totalMinutes = totalSeconds / 60;

  let totalHour = totalMinutes / 60;
  let totalHourIntValue = Math.floor(totalHour);

  // let totalHourLength = ("" + totalHourIntValue).length;

  let premiseMinutes = (totalMinutes % 60).toFixed(0);

  // let premiseMinutesLength = ("" + premiseMinutes).length;

  let premiseSeconds = (totalSeconds % 60).toFixed(0);

  // const roundedDecimalForSeconds = Math.floor(decimalPart * 100) / 100;
  // let premiseSecondsLength = ("" + premiseSeconds).length;

  const premisesDate = `${totalHourIntValue} : ${premiseMinutes} : ${premiseSeconds}`;

  const handleExitSubmit = async () => {
    setLoading(true);
    const url = `${VEHICLE_EXIT.replace("{tenantId}", tenantId).replace(
      "{locationId}",
      locationId
    )}/${formValues?.id}/exit`;

    const data = {
      id: formValues.id,
      vrn: formValues.vrn,
      vehicleType: {
        id: formValues.vehicleTypeId,
      },
      parkingType: {
        id: formValues.parkingTypeId,
      },
      // vehicleTypeId: formValues.vehicleTypeId,
      // parkingTypeId: formValues.parkingTypeId,
      parkingAmount:
        formValues?.refund > 0
          ? formValues.refund
          : formValues?.fullParkingAmount - formValues?.baseFee,
      exitBoothId: formValues.gateId,
      exitById: localStorage.getItem("userId"),
      paymentMode: formValues.paymentMode,
      paymentStatus: formValues.paymentStatus,
      tenantId: formValues.tenantId,
      locationId: formValues.locationId,
      extraCharge: {
        id: formValues.extrafeeReasonId,
      },
      extraChargeAmount: formValues.extrafee,
      slotId: formValues.slotId,
      complimentary: false,
      mobileNo: "",
      transactionType:
        totalSeconds < 900
          ? "DEBIT"
          : (totalSeconds < 900 && formValues.extrafee !== "") ||
            (totalSeconds < 900 && formValues.extrafee !== 0)
          ? "CREDIT"
          : "CREDIT",
          name: fullName,
    };

    const resp = await Put(url, data);
    if (
      resp?.request?.status === 400 ||
      resp?.request?.status === 404 ||
      resp?.request?.status === 500
    ) {
      setLoading(false);
      setToast({
        ...toast,
        show: true,
        type: "error",
        messsege: "Error",
      });
      handleExitClear();
    } else if (resp && resp?.id !== "") {
      // addDataToCashCollectiontable(resp?.id);
      setLoading(false);
      setToast({
        ...toast,
        show: true,
        type: "success",
        messsege: "Vehicle Exit Successfull",
      });
      handleExitClear();
      if (formValues.slotId) {
        setLoading(false);
        // handleSlotAllot();
      }
    }
  };

  /* eslint-disable */
  useEffect(() => {
    if (toast.show === true) {
      const interval = setInterval(() => {
        setToast({ ...toast, show: false });
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [toast.show]);

  useEffect(() => {
    if (value?.parkingId) {
      let data = setTimeout(() => {
        getEntryDetails(value?.parkingId);
      }, 1000);
      return () => clearTimeout(data);
    }
  }, [value]);

  useEffect(() => {
    getExtraChargeReasons();
    getActiveFloors();
  }, []);
  /*eslint-enable */

  return {
    toast,
    value,
    loading,
    entryTime,
    formValues,
    isDisabled,
    totalSeconds,
    premisesDate,
    paymentModes,
    handleChange,
    parkingAmount,
    handleExitClear,
    inputValue,
    activeFloors,
    activefloorId,
    setInputValue,
    extraCharges,
    setFormValues,
    handleExitSubmit,
    handleInputChange,
    penaltyAndRefund,
    handlePaymentMode,
    handleAmountChange,
    activeVehicleNumber,
    getVehicleSuggestions,
    handleAutoCompleteChange,
    handleCheckBox
  };
}
