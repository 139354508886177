const useShiftType = ({ setShiftIn, setShiftOut, formValue, setFormValue }) => {
  const handleTimeChangeIn = (time) => {
    setShiftIn(time);
  };
  const handleTimeChangeOut = (time) => {
    setShiftOut(time);
  };

  const handleCheckBoxForShiftType = (e) => {
    if (e.target.checked) {
      setFormValue({
        ...formValue,
        shiftOutNextDay: true,
      });
    } else {
      setFormValue({
        ...formValue,
        shiftOutNextDay: false,
      });
    }
  };
  return {
    handleCheckBoxForShiftType,
    handleTimeChangeIn,
    handleTimeChangeOut,
  };
};

export default useShiftType;
