import { useEffect } from "react";
import { fetchVehicleTypes } from "../../../api/vehicleType";
import { useState } from "react";
import { fetchAllSettlementDetails } from "../../../api/settlement";
import moment from "moment";

const useSettlementReport = () => {
  const tenantId = localStorage.getItem("tenantId");
  const locationId = localStorage.getItem("locationId");

  const [vehileTypes, setVehileTypes] = useState();
  const [settleReportDetails, setSettleReportDetails] = useState([]);
  const [loading, setLoading] = useState(false);

  const [formValues, setFormValues] = useState({
    date: moment().format("YYYY-MM-DD"),
    vehicleTypeId: "",
    tenantId,
    locationId,
  });

  const handleInputChange = (e) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
  };

  const handleClear = () => {
    setFormValues({
      date: moment().format("YYYY-MM-DD"),
      vehicleTypeId: "",
      tenantId,
      locationId,
    });
  };

  const getVehicleType = async () => {
    let response = await fetchVehicleTypes(tenantId, locationId);
    setVehileTypes(response);
    return response;
  };

  const getSettlementDetails = async () => {
    setLoading(true);
    let response = await fetchAllSettlementDetails(
      tenantId,
      locationId,
      formValues
    );
    setLoading(false);
    setSettleReportDetails(response);
    return response;
  };

  useEffect(() => {
    getVehicleType();
    //eslint-disable-next-line
  }, []);

  return {
    loading,
    formValues,
    vehileTypes,
    settleReportDetails,
    handleClear,
    handleInputChange,
    getSettlementDetails,
  };
};

export default useSettlementReport;
