import {
  Box,
  Button,
  InputAdornment,
  TextField,
  Typography,
  Autocomplete,
} from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { Search } from "@mui/icons-material";
import { useShiftAssign } from "./hooks/useShiftAssign";
import MainLayout from "../../../../containers/MainLayout";
import ShiftAssignForm from "./components/ShiftAssignForm";
import ShiftAssignTable from "./components/ShiftAssignTable";
import AddBoxRoundedIcon from "@mui/icons-material/AddBoxRounded";

const ShiftAssignPage = () => {
  const {
    open,
    value,
    formValue,
    activeShift,
    activeEmployee,
    shiftAssignData,
    ShiftAssigncolumns,
    handleClose,
    setFormValue,
    getTableData,
    handleOnChange,
    handleClickOpen,
    handleInputChange,
    handleSearchChange,
    handleShiftAssignSubmit,
  } = useShiftAssign();

  return (
    <MainLayout>
      <Typography className="text-[#252F40] !mb-2">
        <Link to="/dashboard" className="no-underline">
          <span className="text-[#8C8C8C]">Masters / Shift Manangement / </span>
        </Link>
        Assign Shift
      </Typography>

      <Box
        sx={{
          boxShadow: " 0px 5px 15px -2px rgba(0, 0, 0, 0.12)",
          borderRadius: "2rem",
          width: "100%",
        }}
      >
        <Box className="flex flex-wrap justify-between px-3 py-3 items-center bg-[white]">
          <Box display="flex" alignItems="center" m={1}>
            <Typography className="text-[#576782]">Assign Shift</Typography>
          </Box>

          <Box className="flex gap-6">
            <Box alignItems="center">
              <TextField
                label="Search"
                onChange={handleSearchChange}
                size="small"
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Search />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>

            <Button
              variant="contained"
              size="small"
              onClick={handleClickOpen}
              className="!bg-[#3A416F] gap-2"
              sx={{ textTransform: "none" }}
            >
              Assign <AddBoxRoundedIcon />
            </Button>
          </Box>
        </Box>

        <Box className="flex flex-wrap gap-6 px-3 py-4 bg-[white]">
          <Box alignItems="center">
            <TextField
              label="From Date"
              type="date"
              name="searchToDate"
              InputLabelProps={{ shrink: true }}
              value={formValue.searchToDate}
              onChange={handleInputChange}
              size="small"
              variant="outlined"
            />
          </Box>
          <Box alignItems="center">
            <TextField
              label="To Date"
              name="searchFromDate"
              type="date"
              InputLabelProps={{ shrink: true }}
              value={formValue.searchFromDate}
              onChange={(e) => {
                getTableData();
                handleInputChange(e);
              }}
              size="small"
              variant="outlined"
            />
          </Box>
          <Box alignItems="center">
            <Autocomplete
              size="small"
              disablePortal
              id="combo-box-demo"
              options={activeEmployee.map((option) => ({
                label: option.name,
                userId: option.id,
              }))}
              onChange={(e, value) => {
                setFormValue({
                  ...formValue,
                  employeeName: value.label,
                  userId: value.userId,
                });
                getTableData(value.userId);
              }}
              sx={{ width: 300 }}
              renderInput={(params) => (
                <TextField {...params} label="Employee Name" />
              )}
            />
          </Box>
        </Box>
        <ShiftAssignForm
          open={open}
          value={value}
          formValue={formValue}
          activeShift={activeShift}
          handleClose={handleClose}
          setFormValue={setFormValue}
          handleOnChange={handleOnChange}
          activeEmployee={activeEmployee}
          handleInputChange={handleInputChange}
          handleShiftAssignSubmit={handleShiftAssignSubmit}
        />
        <ShiftAssignTable
          shiftAssignData={shiftAssignData}
          ShiftAssigncolumns={ShiftAssigncolumns}
        />
      </Box>
    </MainLayout>
  );
};

export default ShiftAssignPage;
