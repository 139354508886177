import React from "react";
import { Link } from "react-router-dom";
import { Search } from "@mui/icons-material";
import MainLayout from "../../../containers/MainLayout";
import AddBoxRoundedIcon from "@mui/icons-material/AddBoxRounded";
import {
  Box,
  Button,
  TextField,
  Typography,
  InputAdornment,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { useBlockMaster } from "../hooks/useBlockMaster";
import BlockMasterFrom from "../components/BlockMasterFrom";
import BlockMasterTable from "../components/BlockMasterTable";

const BlockMasterPage = () => {
  const {
    open,
    floors,
    setOpen,
    columns,
    formValues,
    isDisabled,
    handleClose,
    handleSubmit,
    handleInputChange,
    handleSearchChange,
    blockMasterFilterData,
  } = useBlockMaster();
  return (
    <MainLayout>
      <Typography className="text-[#252F40] !mb-2">
        <Link to="/dashboard" className="no-underline">
          <span className="text-[#8C8C8C]">Masters / </span>
        </Link>
        Block
      </Typography>
      <Box
        sx={{
          boxShadow: " 0px 5px 15px -2px rgba(0, 0, 0, 0.12)",
          borderRadius: "2rem",
          width: "100%",
        }}
      >
        <Box className="flex justify-between px-3 py-2 items-center bg-[white]">
          <Box display="flex" p={1} alignItems="center">
            <TextField
              label="Search"
              onChange={handleSearchChange}
              size="small"
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment className="cursor-pointer" position="end">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
          </Box>

          <Box className="min-w-[310px] flex gap-2">
            <FormControl fullWidth size="small">
              <InputLabel size="small" required>
                Floors
              </InputLabel>
              <Select
                labelId="floorId"
                label="floorId *"
                name="floorId"
                value={formValues?.floorId}
                onChange={handleInputChange}
                defaultValue={formValues?.floorId}
              >
                {/* eslint-disable */}
                {floors?.map((obj) => {
                  if (obj.deleted === false)
                    return <MenuItem value={obj.id}>{obj.name}</MenuItem>;
                })}
              </Select>
            </FormControl>
            <Box>
              <Button
                variant="contained"
                onClick={() => setOpen(true)}
                className="!bg-[#3A416F] gap-2"
              >
                Add <AddBoxRoundedIcon />
              </Button>
            </Box>
          </Box>

          <BlockMasterFrom
            open={open}
            formValue={formValues}
            isDisabled={isDisabled}
            handleClose={handleClose}
            handleSubmit={handleSubmit}
            handleInputChange={handleInputChange}
          />
        </Box>
        <BlockMasterTable
          columns={columns}
          blockMasterFilterData={blockMasterFilterData}
        />
      </Box>
    </MainLayout>
  );
};

export default BlockMasterPage;
