import { GET_PARKING_TYPES } from "../services/apiUrls";
import { get } from "../services/service";

export const fetchParkingTypes = async (tenantId, locationId) => {
  let response = await get(
    GET_PARKING_TYPES.replace("{tenantId}", tenantId).replace(
      "{locationId}",
      locationId
    )
  );
  return response;
};
