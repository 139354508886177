import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { GET_ACTIVE_GATES } from "../../../services/apiUrls";
import { get } from "../../../services/service";

export const useGateSelection = () => {
  const params = useParams();

  let locationId = localStorage.getItem("locationId");

  const [activeBoothList, setActiveBoothList] = useState([]);

  /*eslint-disable */
  const GetActiveBoothList = async () => {
    let booth;
    if (params.gateName == "entry-gate") {
      booth = await get(
        GET_ACTIVE_GATES.replace("{locationId}", locationId).replace(
          "{type}",
          "ENTRY"
        )
      );
    } else if (params.gateName == "exit-gate") {
      booth = await get(
        GET_ACTIVE_GATES.replace("{locationId}", locationId).replace(
          "{type}",
          "EXIT"
        )
      );
    }
    setActiveBoothList(booth);
  };
  useEffect(() => {
    GetActiveBoothList();
  }, []);
  /*eslint-enable */

  return { activeBoothList };
};
