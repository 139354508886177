import { Search } from "@mui/icons-material";
import {
  Box,
  Button,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import AddBoxRoundedIcon from "@mui/icons-material/AddBoxRounded";
import { useShiftType } from "../hooks/useShiftType";
import ShiftTypeForm from "../components/ShiftTypeForm";
import ShiftTypeTable from "../components/ShiftTypeTable";

const ShiftTypePage = () => {
  const { showForm, handleFormPopup, columns } = useShiftType();
  return (
    <>
      <Typography className="text-[#252F40] !mb-2">
        <Link to="/dashboard" className="no-underline">
          <span className="text-[#8C8C8C]">Masters / </span>
        </Link>
        Shift Type
      </Typography>
      <Box
        sx={{
          boxShadow: " 0px 5px 15px -2px rgba(0, 0, 0, 0.12)",
          borderRadius: "2rem",
          width: "100%",
        }}
      >
        <Box className="flex justify-between px-3 py-2 items-center bg-[white]">
          <Box display="flex" p={1} alignItems="center">
            <TextField
              label="Search"
              // value={searchValue}
              // onChange={handleSearchChange}
              size="small"
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
          </Box>

          <Button
            variant="contained"
            onClick={() => {
              handleFormPopup();
            }}
            className="!bg-[#3A416F] gap-2"
          >
            Add <AddBoxRoundedIcon />
          </Button>

          <ShiftTypeForm
            showForm={showForm}
            handleFormPopup={handleFormPopup}
          />
        </Box>
        <ShiftTypeTable columns={columns} />
      </Box>
    </>
  );
};

export default ShiftTypePage;
