import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import PopUp from "../../../containers/components/PopUp";

const useStyles = makeStyles({
  input: {
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
});

const ShiftMasterForm = ({ showForm, handleFormPopup }) => {
  const classes = useStyles();
  const formChildren = (
    <>
      <Stack spacing={4} paddingLeft={4} paddingRight={4} paddingBottom={2}>
        <Stack direction="row" spacing={2}>
          <FormControl fullWidth size="small">
            <InputLabel size="small" required>
              User Name
            </InputLabel>
            <Select
              labelId="User Name"
              label="User Name*"
              name="username"
              //   value={formValue?.vehicleType}
              //   onChange={handleInputChange}
            >
              {/* {vehicleTypeData?.map((obj) => {
                  return <MenuItem value={obj.id}>{obj.name}</MenuItem>;
                })} */}
            </Select>
          </FormControl>
          <FormControl fullWidth size="small">
            <InputLabel size="small" required>
              Shift Name
            </InputLabel>
            <Select
              labelId="Shift Name"
              label="Shift Name*"
              name="shiftname"
              //   value={formValue?.parkingType}
              //   onChange={handleInputChange}
            >
              {/* {parkingTypeData?.map((obj) => {
                  return <MenuItem value={obj.id}>{obj.name}</MenuItem>;
                })} */}
            </Select>
          </FormControl>
        </Stack>
        <Stack direction="row" spacing={2}>
          <TextField
            required
            fullWidth
            name="description"
            type="text"
            id="outlined-required"
            label="Description"
            size="small"
            className={classes.input}
            InputLabelProps={{ shrink: true }}
            // value={formValue.fromMins}
            // onChange={handleInputChange}
          />
        </Stack>
        <Stack>
          <FormControlLabel
            control={
              <Checkbox
                defaultChecked
                sx={{ "& .MuiSvgIcon-root": { color: "#2F2A42" } }}
              />
            }
            // onChange={handleCheckBox}
            name="active"
            label="Base Fee"
            // checked={formValue?.active === "true" ? true : false}
          />
        </Stack>
        <Button
        //   disabled={!isDisabled}
        //   onClick={handleSubmit}
        //   sx={{
        //     textTransform: "none",
        //     background: !isDisabled
        //       ? "gray"
        //       : "linear-gradient(135deg, #3A416F 0%, #141727 100%)",
        //     color: "white",
        //     "&:hover": {},
        //   }}
        >
          {/* {formValue.id ? "Update" : "Ok"} */}
          OK
        </Button>
      </Stack>
    </>
  );
  return (
    <>
      <PopUp
        open={showForm}
        handleClose={handleFormPopup}
        popUpTitle="Shift Master"
        popUpChildren={formChildren}
      />
    </>
  );
};

export default ShiftMasterForm;
