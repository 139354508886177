import React from "react";
import {
  Box,
  Stack,
  Dialog,
  Button,
  TextField,
  DialogTitle,
  DialogContent,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Grid,
  Typography,
} from "@mui/material";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";

const GateForm = ({
  open,
  formValue,
  isDisabled,
  errorMessage,
  handleClose,
  handleInputChange,
  findDuplicateGate,
}) => {
  return (
    <>
      <Dialog fullWidth open={open} onClose={handleClose}>
        <Box className="flex justify-between">
          <DialogTitle className="text-[#312C41] !text-[16px] !font-semibold">
            Gate/Booth
          </DialogTitle>

          <CancelRoundedIcon
            onClick={handleClose}
            className="bg-[white] !text-[#344767] !text-[1.7rem] !outline-none p-4 cursor-pointer"
          />
        </Box>

        <DialogContent>
          <Stack spacing={4}>
            <Grid container spacing={2}>
              <Grid item lg={6} md={6} sm={12}>
                <TextField
                  required
                  fullWidth
                  name="name"
                  type="text"
                  id="outlined-required"
                  label="Gate Name"
                  size="small"
                  value={formValue.name}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12}>
                <FormControl fullWidth size="small">
                  <InputLabel required>Gate type</InputLabel>
                  <Select
                    label="Gate_type*"
                    name="gateType"
                    value={formValue.geteType}
                    onChange={handleInputChange}
                    defaultValue={
                      formValue.gateType === "EXIT" ? "EXIT" : "ENTRY"
                    }
                  >
                    <MenuItem value="ENTRY">Entry</MenuItem>
                    <MenuItem value="EXIT">Exit</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item lg={6} md={6} sm={12}>
                <TextField
                  required
                  fullWidth
                  name="description"
                  type="text"
                  id="outlined-required"
                  label="Description"
                  size="small"
                  value={formValue.description}
                  onChange={handleInputChange}
                />
              </Grid>
            </Grid>

            <Typography variant="subtitle2" className="text-[#DD2121]">
              {errorMessage}
            </Typography>

            <Button
              disabled={!isDisabled}
              onClick={findDuplicateGate}
              sx={{
                textTransform: "none",
                background: !isDisabled
                  ? "gray"
                  : "linear-gradient(135deg, #3A416F 0%, #141727 100%)",
                color: "white",
                "&:hover": {},
              }}
            >
              {formValue?.id ? "Update" : "Submit"}
            </Button>
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default GateForm;
