import {
  ADD_FLOOR,
  DELETE_FLOOR,
  GET_ACTIVE_FLOORS,
  GET_FLOOR,
  GET_FLOORS,
  RESTORE_FLOOR,
  UPDATE_FLOOR,
} from "../services/apiUrls";
import { Delete, Put, get, post } from "../services/service";

export const fetchAllFloors = async (locationId, tenantId) => {
  let response = await get(
    GET_FLOORS.replace("{tenantId}", tenantId).replace(
      "{locationId}",
      locationId
    )
  );
  return response;
};

export const fetchActiveFloors = async (locationId, tenantId) => {
  let response = await get(
    GET_ACTIVE_FLOORS.replace("{tenantId}", tenantId).replace(
      "{locationId}",
      locationId
    )
  );
  return response;
};

export const fetchFloor = async (floorId, locationId, tenantId) => {
  let response = await get(
    GET_FLOOR.replace("{floorId}", floorId)
      .replace("{tenantId}", tenantId)
      .replace("{locationId}", locationId)
  );
  return response;
};

export const addFloor = async (locationId, tenantId, formValues) => {
  let response = await post(
    ADD_FLOOR.replace("{tenantId}", tenantId).replace(
      "{locationId}",
      locationId
    ),
    formValues
  );
  return response;
};

export const updateFloor = async (locationId, tenantId, formValues) => {
  let response = await Put(
    UPDATE_FLOOR.replace("{tenantId}", tenantId).replace(
      "{locationId}",
      locationId
    ),
    formValues
  );
  return response;
};

export const deleteFloor = async (floorId, locationId, tenantId) => {
  let response = Delete(
    DELETE_FLOOR.replace("{floorId}", floorId)
      .replace("{tenantId}", tenantId)
      .replace("{locationId}", locationId)
  );
  return response;
};

export const restoreFloor = async (floorId, locationId, tenantId) => {
  let response = Delete(
    RESTORE_FLOOR.replace("{floorId}", floorId)
      .replace("{tenantId}", tenantId)
      .replace("{locationId}", locationId)
  );
  return response;
};
