import { Button } from "@mui/material";
import React from "react";

const PrimaryButton = ({ disabled, onClick, name, variant }) => {
  return (
    <Button
      disabled={disabled}
      onClick={onClick}
      variant={variant}
      sx={{
        textTransform: "none",
        background: disabled
          ? "gray"
          : "linear-gradient(135deg, #3A416F 0%, #141727 100%)",
        color: "white",
        "&:hover": {},
      }}
    >
      {name}
    </Button>
  );
};

export default PrimaryButton;
