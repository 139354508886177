import { createTheme } from "@mui/material/styles";

export const customTheme = createTheme({
  breakpoints: {
    keys: ["xs", "sm", "md", "lg", "xl", "2xl"],
    values: {
      xs: 0,
      sm: 640,
      md: 768,
      lg: 1024,
      xl: 1280,
      "2xl": 1536,
    },
  },
  palette: {
    neutral: {
      main: "#2F2A42",
      secondaryHover: "#222436",
    },
  },
});

export const primaryColor = "#2F2A42";
export const SecondaryColor = "#222436";
export const inputFieledbgColor = "#F7F7FB";
