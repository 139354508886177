import React from "react";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  input: {
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
});

const FeeMasterForm = ({
  open,
  formValue,
  isDisabled,
  handleClose,
  handleSubmit,
  handleCheckBox,
  parkingTypeData,
  vehicleTypeData,
  handleInputChange,
}) => {
  const classes = useStyles();
  return (
    <>
      <Dialog fullWidth open={open} onClose={handleClose}>
        <Box
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"space-between"}
        >
          <DialogTitle className="text-[#312C41] !text-[16px] !font-semibold">
            Fee Master
          </DialogTitle>

          <CancelRoundedIcon
            onClick={handleClose}
            className="bg-[white] !text-[#344767] !text-[1.7rem] !outline-none p-4"
          />
        </Box>

        <DialogContent>
          <Stack spacing={4} paddingLeft={2} paddingRight={2} paddingBottom={2}>
            <Stack direction="row" spacing={2}>
              <FormControl fullWidth size="small">
                <InputLabel size="small" required>
                  Vehicle type
                </InputLabel>
                <Select
                  labelId="Vehicle type"
                  label="Vehicle_type*"
                  name="vehicleType"
                  value={formValue?.vehicleType}
                  onChange={handleInputChange}
                >
                  {vehicleTypeData?.map((obj) => {
                    if (obj.deleted === false) {
                      return <MenuItem value={obj.id}>{obj.name}</MenuItem>;
                    } else {
                      return null;
                    }
                  })}
                </Select>
              </FormControl>
              <FormControl fullWidth size="small">
                <InputLabel size="small" required>
                  Parking type
                </InputLabel>
                <Select
                  labelId="Parking type"
                  label="Parking type*"
                  name="parkingType"
                  value={formValue?.parkingType}
                  onChange={handleInputChange}
                >
                  {/* eslint-disable */}
                  {parkingTypeData?.map((obj) => {
                    if (obj.deleted === false)
                      return <MenuItem value={obj.id}>{obj.name}</MenuItem>;
                  })}
                </Select>
              </FormControl>
            </Stack>
            <Stack direction="row" spacing={2}>
              <TextField
                required
                fullWidth
                name="fromMins"
                type="number"
                id="outlined-required"
                label="From Time"
                size="small"
                className={classes.input}
                InputLabelProps={{ shrink: true }}
                value={formValue.fromMins}
                onChange={handleInputChange}
              />
              <TextField
                required
                fullWidth
                name="toMins"
                type="number"
                id="outlined-required"
                label="To Time"
                size="small"
                className={classes.input}
                InputLabelProps={{ shrink: true }}
                value={formValue.toMins}
                onChange={handleInputChange}
              />
            </Stack>
            <Stack direction="row" spacing={2}>
              <TextField
                required
                fullWidth
                name="additionalMins"
                type="number"
                id="outlined-required"
                label="Additional Time"
                size="small"
                className={classes.input}
                InputLabelProps={{ shrink: true }}
                value={formValue.additionalMins}
                onChange={handleInputChange}
              />
              <TextField
                required
                fullWidth
                name="amount"
                type="number"
                id="outlined-required"
                label="Amount"
                size="small"
                className={classes.input}
                value={formValue.amount}
                onChange={handleInputChange}
              />
            </Stack>
            <Stack direction="row">
              <FormControlLabel
                control={
                  <Checkbox
                    defaultChecked
                    sx={{ "& .MuiSvgIcon-root": { color: "#2F2A42" } }}
                  />
                }
                onChange={handleCheckBox}
                name="baseFees"
                label="Base Fee"
                checked={formValue?.baseFees === true ? true : false}
              />

              <FormControlLabel
                control={
                  <Checkbox
                    defaultChecked
                    sx={{ "& .MuiSvgIcon-root": { color: "#2F2A42" } }}
                  />
                }
                onChange={handleCheckBox}
                name="penalty"
                label="Penalty"
                checked={formValue?.penalty === true ? true : false}
              />
            </Stack>
            <Button
              disabled={!isDisabled}
              onClick={handleSubmit}
              sx={{
                textTransform: "none",
                background: !isDisabled
                  ? "gray"
                  : "linear-gradient(135deg, #3A416F 0%, #141727 100%)",
                color: "white",
                "&:hover": {},
              }}
            >
              {formValue.id ? "Update" : "Ok"}
            </Button>
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default FeeMasterForm;
