import { makeStyles } from "@mui/styles";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
} from "@mui/material";

const useStyles = makeStyles({
  input: {
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
});

const ExitBoothForm = ({
  open,
  formValue,
  isDisabled,
  handleClose,
  handleSubmit,
  handleInputChange,
}) => {
  const classes = useStyles();
  return (
    <>
      <Dialog fullWidth open={open} onClose={handleClose}>
        <Box
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"space-between"}
        >
          <DialogTitle className="text-[#312C41] !text-[16px] !font-semibold">
            Entry Booth
          </DialogTitle>

          <CancelRoundedIcon
            onClick={handleClose}
            className="bg-[white] !text-[#344767] !text-[1.7rem] !outline-none p-4"
          />
        </Box>

        <DialogContent>
          <Stack spacing={4} paddingLeft={2} paddingRight={2} paddingBottom={2}>
            <Stack direction="row" spacing={2}>
              <TextField
                required
                fullWidth
                name="name"
                type="text"
                id="outlined-required"
                label="Gate Name"
                size="small"
                className={classes.input}
                value={formValue.name}
                onChange={handleInputChange}
              />
              <TextField
                required
                fullWidth
                name="description"
                type="text"
                id="outlined-required"
                label="Description"
                size="small"
                className={classes.input}
                value={formValue.description}
                onChange={handleInputChange}
              />
            </Stack>
            <Button
              disabled={!isDisabled}
              onClick={handleSubmit}
              sx={{
                textTransform: "none",
                background: !isDisabled
                  ? "gray"
                  : "linear-gradient(135deg, #3A416F 0%, #141727 100%)",
                color: "white",
                "&:hover": {},
              }}
            >
              {formValue?.id ? "Update" : "Submit"}
            </Button>
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ExitBoothForm;
