import { useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  POST_STAFF_ONBOARDING,
  EMPLOYEE_SAVE_STAFF_ONBOARDING,
  USER_ADDRESS_SAVE_STAFF_ONBOARDING,
  GET_IDCARD_SUB_TYPES,
  UPLOAD_FILE,
  SAVE_DOCUMENT,
  SAVE_USER_DOCUMENT,
  GET_ALL_STAFF_DATA,
  GET_USER_DETAILS,
  GET_USER_LOCATION_DETAILS,
  GET_USER_ADDRESS_DETAILS,
  GET_USER_DOCUMENT_DETAILS,
  GET_USER_ATTACHMENT_DETAILS,
  GET_ID_CARD_SUBTYPE,
  GET_DESIGNATION_DETAILS,
  UPDATE_USER_DETAILS,
  UPDATE_EMPLOYEE_DETAILS,
  UPDATE_DOCUMENT_DETAILS,
  UPDATE_ADDRESS_DETAILS,
  UPDATE_DOCUMENT_SUBTYPE,
  UPDATE_LOCATION_DETAILS,
  UPDATE_DOCUMENT_DETAILS_UPDATE,
  UPDATE_DOCUMENT_UPDATE,
  ADD_SUPERVISOR,
} from "../../../../../services/apiUrls";
import axios from "axios";
import { get, post } from "../../../../../services/service";
import { GET_IDCARD_TYPES } from "./../../../../../services/apiUrls";
import {
  fetchEntityLocation,
  fetchUserLocation,
} from "./../../../../../api/locations";
import { fetchAllDesignation } from "../../../../../api/designation";
import { deleteUser } from "../../../../../api/users";

export function useStaffOnboarding() {
  const [open, setOpen] = useState(false);

  const [userDocumentDetails, setUserDocumentDetails] = useState([]);

  const userId = localStorage.getItem("userId");

  const [loading, setLoading] = useState(true);

  const [formValues, setFormValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    token: "",
    locationId: "",
    designationId: "",
    userType: "",
    addressLine1: "",
    addressLine2: "",
    country: "",
    city: "",
    state: "",
    pinCode: "",
    whatsappNumber: "",
    idCardType: "",
    idCardSubType: "",
    idCardNumber: "",
    status: "",
    id: "",
    name: "",
    fileType: "",
    fileUrl: "",
    attachmentId: "",
    documenttId: "",
  });

  const [errors, setErrors] = useState({});

  const [staffDatas, setStaffDatas] = useState();

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [userIdForDelete, setUserIdForDelete] = useState("");

  const handleOpenPopup = () => {
    setShowConfirmation(true);
  };

  const handleClosePopup = (event) => {
    event.preventDefault();
    setShowConfirmation(false);
  };

  const [openBackDrop, setOpenBackDrop] = useState(false);

  const handleCloseBackDrop = () => {
    setOpenBackDrop(false);
  };

  const handleOpenBackDrop = () => {
    setOpenBackDrop(true);
  };

  const [designations, setDesignations] = useState([]);

  const [toast, setToast] = useState({ show: false, type: "", message: "" });

  const [locations, setLocations] = useState([]);

  const [idCardSubType, setIdCardSubType] = useState([]);

  const [idCardTypes, setIdCardTypes] = useState([]);
  const [idCardSubTypes, setIdCardSubTypes] = useState([]);

  const [files, setFiles] = useState({
    userImg: "",
    idCard: "",
  });

  // EMAIL VALIDATION
  const emailRegex = new RegExp(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );

  const [emailError, setEmailError] = useState();

  const isEmailValid = emailRegex.test(formValues.email);

  const validateEmail = () => {
    setEmailError(isEmailValid);
  };

  /*eslint-disable */
  const [search, setSearch] = useState("");

  let tenantId = localStorage.getItem("tenantId");
  let locationId = localStorage.getItem("locationId");
  let userIdFromLocalStorage = localStorage.getItem("userId");
  /*eslint-enable */

  const handleInputChange = (e) => {
    if (e.target.name === "firstName") {
      setErrors({ ...errors, name: "" });
    }
    if (e.target.name === "email") {
      setErrors({ ...errors, email: "" });
    }
    if (e.target.name === "addressLine1") {
      setErrors({ ...errors, addressLine1: "" });
    }
    if (e.target.name === "city") {
      setErrors({ ...errors, city: "" });
    }
    if (e.target.name === "designationId") {
      setErrors({ ...errors, designationId: "" });
    }
    if (e.target.name === "userType") {
      setErrors({ ...errors, userType: "" });
    }
    if (e.target.name === "locationId") {
      setErrors({ ...errors, locationId: "" });
    }
    if (e.target.name === "state") {
      setErrors({ ...errors, state: "" });
    }
    if (e.target.name === "token") {
      setErrors({ ...errors, token: "" });
    }
    if (e.target.name === "whatsappNumber") {
      setErrors({ ...errors, whatsappNumber: "" });
    }
    setFormValues({ ...formValues, [e.target.name]: e.target.value });
  };

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const handleClickOpen = () => {
    setFiles({ ...files, userImg: "", idCard: "" });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    handleClear();
  };

  const handleClear = () => {
    setFormValues({
      firstName: "",
      lastName: "",
      email: "",
      token: "",
      locationId: "",
      designationId: "",
      userType: "",
      addressLine1: "",
      addressLine2: "",
      country: "",
      city: "",
      state: "",
      pinCode: "",
      whatsappNumber: "",
      idCardType: "",
      idCardSubType: "",
      idCardNumber: "",
      status: "",
      id: "",
      name: "",
      fileType: "",
      fileUrl: "",
      attachmentId: "",
      documenttId: "",
    });
    setFiles({ ...files, userImg: "", idCard: "" });
    setUserDocumentDetails([]);
  };

  const staffData = staffDatas?.filter(
    (item) =>
      item?.name?.toLowerCase().includes(search.toLowerCase()) ||
      item?.email?.toLowerCase().includes(search.toLowerCase())
  );

  const randomString = () => {
    var chars = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz";
    var string_length = 6;
    var randomstr = "";
    for (var i = 0; i < string_length; i++) {
      var rnum = Math.floor(Math.random() * chars.length);
      randomstr += chars.substring(rnum, rnum + 1);
    }
    document.value = randomstr;
    setFormValues({ ...formValues, token: randomstr });
  };

  const getEntityLocations = async () => {
    let response = await fetchEntityLocation(tenantId);
    setLocations(response);
    return response;
  };

  const deleteStaff = async (userId, event) => {
    event.preventDefault();
    let response = await deleteUser(userId);
    if (response) {
      setShowConfirmation(false);
    }
    return response;
  };

  const columns = [
    {
      field: "No",
      headerName: "NO",
      width: 65,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "name",
      headerName: "NAME",
      flex: 1,
      headerClassName: "super-app-theme--header",
      valueGetter: (params) =>
        params.row.name === "" || params.row.name === " "
          ? "---"
          : params.row.name,
    },
    {
      field: "id",
      headerName: "ID",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    // {
    //   field: "location",
    //   headerName: "LOCATION",
    //   flex: 1,
    //   headerClassName: "super-app-theme--header",
    // },
    {
      field: "email",
      headerName: "EMAIL",
      flex: 1,
      headerClassName: "super-app-theme--header",
      valueGetter: (params) =>
        params.row.email === "" || !params.row.email ? "---" : params.row.email,
    },
    {
      field: "action",
      headerName: "ACTION",
      flex: 1,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <EditIcon
            sx={{ cursor: "pointer" }}
            onClick={() => {
              setErrors({
                ...errors,
                name: "",
                email: "",
                addressLine1: "",
                city: "",
                designationId: "",
                userType: "",
                locationId: "",
                state: "",
                token: "",
                whatsappNumber: "",
              });
              setOpen(true);
              window.scrollTo(0, 0);
              getUserDetails(params.row.id);
            }}
          />
        );
      },
    },
    {
      // field: "action",
      headerName: "DELETE",
      flex: 1,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <DeleteIcon
            sx={{ cursor: "pointer" }}
            onClick={(e) => {
              e.preventDefault();
              handleOpenPopup();
              setUserIdForDelete(params.row.id);
            }}
          />
        );
      },
    },
  ];

  //Get All Staff Data
  const getStaffData = async () => {
    setLoading(true);
    const url = GET_ALL_STAFF_DATA;
    const resp = await get(url);
    if (resp) {
      setLoading(false);
      setStaffDatas(resp.filter((val) => val?.deleted === false));
    }
  };

  // GET LOCATIONS
  const getAllLocations = async () => {
    try {
      let response = await fetchEntityLocation(tenantId);
      return response;
    } catch (err) {
      return err;
    }
  };

  //Get id card types
  const getIdCardTypes = async () => {
    const response = await get(GET_IDCARD_TYPES);
    setIdCardTypes(response);
    return response;
  };

  //Get id card sub types
  const getIdCardSubTypes = async () => {
    const response = await get(GET_IDCARD_SUB_TYPES);
    setIdCardSubTypes(response);
    return response;
  };

  // Upload idCard
  const uploadIdCard = async (userId, formData) => {
    formData.append("file", files.idCard[0]);
    formData.append("userId", userId);
    const response = await post(UPLOAD_FILE, formData);
    return response;
  };

  // Upload shopImage
  // const uploadUserImage = async (userId, formData) => {
  //   formData.append("file", files.userImg[0]);
  //   formData.append("userId", userId);
  //   const response = await post(UPLOAD_FILE, formData);
  //   return response;
  // };

  /*eslint-disable */
  const saveDocument = async (id, url, name) => {
    const data = {
      name: name,
      attachmentId: id,
      fileUrl: url,
      type: {
        id: formValues.idCardType,
      },
      subType: {
        id: formValues.idCardSubType,
      },
      fileType: "image",
      documentNo: formValues.idCardNumber,
    };
    const response = await post(SAVE_DOCUMENT, data);
    return response;
  };
  /*eslint-enable */

  /*eslint-disable */
  const saveUserDocument = async (documentId, userId) => {
    try {
      const data = {
        userId: userId,
        document: {
          id: documentId,
        },
      };
      const response = await post(
        SAVE_USER_DOCUMENT.replace("{userId}", userId),
        data
      ).then((res) => {
        return res;
      });
      return response;
    } catch (error) {
      return error;
    }
  };
  /*eslint-enable */

  const getAllDesignation = async () => {
    let response = await fetchAllDesignation(tenantId);
    setDesignations(response);
    return response;
  };

  // GET ID CARD SUBTYPE BY ID CARD ID
  const getIdCardSubType = async (id) => {
    try {
      const resp = await axios
        .get(`${GET_ID_CARD_SUBTYPE}${id}`)
        .then((res) => {
          setIdCardSubType(res.data);
          return res;
        });
      return resp;
    } catch (err) {
      return err;
    }
  };

  // STAFF ONBOARAING POST
  const saveUser = async (bodyData) => {
    try {
      const resp = await axios
        .post(POST_STAFF_ONBOARDING, bodyData)
        .then((res) => {
          return res;
        });
      return resp;
    } catch (err) {
      return err;
    }
  };

  // STAFF ONBOARAING POST
  const saveSupervisor = async (bodyData) => {
    try {
      const resp = await axios
        .post(ADD_SUPERVISOR.replace("{userId}", userId), bodyData)
        .then((res) => {
          return res;
        });
      return resp;
    } catch (err) {
      return err;
    }
  };

  // EMPLOYEE SAVE - STAFF ONBOARDING
  const saveEmployee = async (id) => {
    const employee_data = {
      tenentId: tenantId,
      userId: id,
      firstname: formValues.firstName,
      lastname: formValues.lastName,
      status: "permanent",
      locationId: locationId,
      designationId: formValues?.designationId,
    };
    try {
      const resp = await axios
        .post(
          EMPLOYEE_SAVE_STAFF_ONBOARDING.replace("{tenantId}", tenantId),
          employee_data
        )
        .then((res) => {
          return res;
        });
      return resp;
    } catch (err) {
      return err;
    }
  };

  // USER ADDRESS SAVE
  const saveUserAddress = async (userId) => {
    const data = {
      addressLine1: formValues.addressLine1,
      addressLine2: formValues.addressLine2,
      country: formValues.country,
      state: formValues.state,
      city: formValues.city,
      pinCode: formValues.pinCode,
      type: "OFFICE",
      defaultAddress: false,
    };
    try {
      const resp = await axios
        .post(
          USER_ADDRESS_SAVE_STAFF_ONBOARDING.replace("{userId}", userId),
          data
        )
        .then((res) => {
          return res;
        });
      return resp;
    } catch (err) {
      return err;
    }
  };

  /*eslint-disable */
  useEffect(() => {
    getAllLocations();
  }, [tenantId]);
  /*eslint-enable */

  // GET USER DETAILS
  const getUserDetails = async (id) => {
    try {
      const resp = await axios.get(`${GET_USER_DETAILS}${id}`).then((res) => {
        getUserLocationDetails(id, res.data);
        return res;
      });
      return resp;
    } catch (err) {
      return err;
    }
  };

  // GET USER LOCATION DETAILS
  const getUserLocationDetails = async (id, userDetails) => {
    try {
      const resp = await axios
        .get(`${GET_USER_LOCATION_DETAILS}${id}`)
        .then((res) => {
          getUserAddressDetails(id, res?.data[0], userDetails);
          return res;
        });
      return resp;
    } catch (err) {
      return err;
    }
  };

  // GET USER ADDRESS DETAILS
  const getUserAddressDetails = async (id, location, userDetails) => {
    try {
      const resp = await axios
        .get(GET_USER_ADDRESS_DETAILS.replace("{id}", id))
        .then((res) => {
          getDesignationDetails(id, res?.data[0], location, userDetails);
          return res;
        });
      return resp;
    } catch (err) {
      return err;
    }
  };

  // GET DESIGNATION DETAILS
  const getDesignationDetails = async (id, address, location, userDetails) => {
    try {
      const resp = await axios
        .get(
          GET_DESIGNATION_DETAILS.replace("{tenantId}", tenantId).replace(
            "{id}",
            id
          )
        )
        .then((res) => {
          getUserDocumentDetails(id, address, location, userDetails, res?.data);
          return res;
        });
      return resp;
    } catch (err) {
      return err;
    }
  };

  // GET USER DOCUMENT DETAILS
  const getUserDocumentDetails = async (
    id,
    address,
    location,
    userDetails,
    designation
  ) => {
    try {
      const resp = await axios
        .get(GET_USER_DOCUMENT_DETAILS.replace("{id}", id))
        .then((res) => {
          if (res?.data[0]?.document?.type?.id) {
            getIdCardSubType(res?.data[0]?.document?.type?.id);
          }
          setFormValues({
            ...formValues,
            addressLine1: address?.addressLine1,
            addressLine2: address?.addressLine2,
            pinCode: address?.pinCode,
            country: address?.country,
            state: address?.state,
            city: address?.city,
            id: id,
            locationId: location?.locationId,
            firstName: userDetails?.firstName,
            lastName: userDetails?.lastName,
            userType: userDetails?.userType,
            email: userDetails?.email,
            whatsappNumber: userDetails?.phoneNumber,
            designationId: designation?.designationId,
            status: designation?.status,
            idCardType: res?.data[0]?.document?.type?.id,
            idCardSubType: res?.data[0]?.document?.subType?.id,
            idCardNumber: res?.data[0]?.document?.documentNo,
            name: res?.data[0]?.document?.name,
            fileType: res?.data[0]?.document?.fileType,
            fileUrl: res?.data[0]?.document?.fileUrl,
            attachmentId: res?.data[0]?.document?.attachmentId,
            documenttId: res?.data[0]?.document?.id,
          });
          setUserDocumentDetails(res?.data[1]);
          getUserAttachmentDetails(id);
          return res;
        });
      return resp;
    } catch (err) {
      setFormValues({
        ...formValues,
        addressLine1: address?.addressLine1,
        addressLine2: address?.addressLine2,
        pinCode: address?.pinCode,
        country: address?.country,
        state: address?.state,
        city: address?.city,
        id: address?.id,
        locationId: location?.locationId,
        firstName: userDetails?.firstName,
        lastName: userDetails?.lastName,
        userType: userDetails?.userType,
        email: userDetails?.email,
        whatsappNumber: userDetails?.phoneNumber,
        designationId: designation?.designationId,
        status: designation?.status,
      });
      return err;
    }
  };

  // GET USER ATTACHMENT DETAILS
  const getUserAttachmentDetails = async (id) => {
    try {
      const resp = await axios
        .get(`${GET_USER_ATTACHMENT_DETAILS}${id}`)
        .then((res) => {
          return res;
        });
      return resp;
    } catch (err) {
      return err;
    }
  };

  const isValidEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  const validateForm = () => {
    let errors = {};
    if (!formValues.firstName.trim()) {
      errors.name = "Firstname is required";
    }
    if (!formValues.email.trim()) {
      errors.email = "Email is required";
    } else if (!isValidEmail(formValues.email)) {
      errors.email = "Invalid email address";
    }
    if (!formValues.token.trim()) {
      errors.token = "Password is required";
    }
    if (!formValues.locationId) {
      errors.locationId = "Location is required";
    }
    if (!formValues.addressLine1.trim()) {
      errors.addressLine1 = "Address is required";
    }
    if (!formValues.city.trim()) {
      errors.city = "District is required";
    }
    if (!formValues.state.trim()) {
      errors.state = "State is required";
    }
    if (!formValues.whatsappNumber.trim()) {
      errors.whatsappNumber = "Whatsapp number is required";
    }
    if (!formValues.designationId) {
      errors.designationId = "Designation is required";
    }
    if (!formValues.userType) {
      errors.userType = "User Type is required";
    }

    return errors;
  };

  // UPDATE STAFF DETAILS

  // UPDATE USER DETAILS
  const updateUserDetails = async () => {
    try {
      const resp = await axios({
        method: "PUT",
        url: UPDATE_USER_DETAILS,
        data: {
          id: formValues.id,
          firstName: formValues.firstName,
          lastName: formValues.lastName,
          userType: "USER",
          phoneNumber: formValues.whatsappNumber,
        },
      }).then((res) => {
        updateEmployeeDetails();
        return res;
      });
      return resp;
    } catch (err) {
      return err;
    }
  };

  // UPDATE EMPLOYEE DETAILS
  const updateEmployeeDetails = async () => {
    try {
      const resp = await axios({
        method: "PUT",
        url: UPDATE_EMPLOYEE_DETAILS.replace("{tenantId}", tenantId),
        data: {
          id: formValues.id,
          tenentId: tenantId,
          userId: userIdFromLocalStorage,
          firstname: formValues.firstName,
          lastname: formValues.lastName,
          status: formValues.status,
          locationId: formValues.locationId,
          designationId: formValues.designationId,
        },
      }).then((res) => {
        updateDocumentDetails();
        return res;
      });
      return resp;
    } catch (err) {
      return err;
    }
  };

  // UPDATE DOCUMENT DETAILS
  const updateDocumentDetails = async () => {
    try {
      const resp = await axios({
        method: "PUT",
        url: UPDATE_DOCUMENT_DETAILS,
        data: {
          id: formValues.id,
          name: "image",
          description: "for saving ID proof",
        },
      }).then((res) => {
        updateUserAddress();
        return res;
      });
      return resp;
    } catch (err) {
      return err;
    }
  };

  // UPDATE USER ADDRESS
  const updateUserAddress = async () => {
    try {
      const resp = await axios({
        method: "PUT",
        url: UPDATE_ADDRESS_DETAILS.replace("{tenantId}", tenantId),
        data: {
          id: formValues.id,
          addressLine1: formValues.addressLine1,
          addressLine2: formValues.addressLine2,
          country: formValues.country,
          city: formValues.city,
          state: formValues.state,
          pinCode: formValues.pinCode,
        },
      }).then((res) => {
        updateDocumentSubType();
        return res;
      });
      return resp;
    } catch (err) {
      return err;
    }
  };

  // UPDATE DOCUMENT SUBTYPE
  const updateDocumentSubType = async () => {
    try {
      const resp = await axios({
        method: "PUT",
        url: UPDATE_DOCUMENT_SUBTYPE,
        data: {
          id: formValues.id,
          typeId: formValues.idCardType,
          name: "driving",
          description: "for saving PAN",
        },
      }).then((res) => {
        updateLocationDetails();
        return res;
      });
      return resp;
    } catch (err) {
      return err;
    }
  };

  // UPDATE LOCATION DETAILS
  const updateLocationDetails = async () => {
    try {
      const resp = await axios({
        method: "PUT",
        url: UPDATE_LOCATION_DETAILS,
        data: {
          id: formValues.id,
          typeId: userIdFromLocalStorage,
          tenantId: tenantId,
          locationId: formValues.locationId,
        },
      }).then((res) => {
        updateUserDocumentDetails();
        return res;
      });
      return resp;
    } catch (err) {
      return err;
    }
  };

  // UPDATE USER DOCUMENT DETAILS
  const updateUserDocumentDetails = async () => {
    try {
      const resp = await axios({
        method: "PUT",
        url: UPDATE_DOCUMENT_DETAILS_UPDATE.replace("{tenantId}", tenantId),
        data: {
          userId: userIdFromLocalStorage,
          id: formValues.id,
          document: {
            id: formValues.documenttId,
          },
        },
      }).then((res) => {
        if (files?.idCard?.length === 0) {
          updateDocument();
        } else {
          let profilePictureFormData = new FormData();
          uploadIdCard(formValues.id, profilePictureFormData);
        }
        return res;
      });
      return resp;
    } catch (err) {
      return err;
    }
  };

  // DOCUMENT UPDATE
  const updateDocument = async () => {
    try {
      const resp = await axios({
        method: "PUT",
        url: UPDATE_DOCUMENT_UPDATE,
        data: {
          id: formValues.id,
          name: formValues.name,
          attachmentId: formValues.attachmentId,
          fileUrl: formValues.fileUrl,
          type: {
            id: formValues.idCardType,
          },
          subType: {
            id: formValues.subType,
          },
          fileType: formValues.fileType,
          documentNo: formValues.idCardNumber,
        },
      }).then((res) => {
        setToast({
          ...toast,
          show: true,
          type: "success",
          message: "User Updated successfully",
        });
        setOpen(false);
        return res;
      });
      return resp;
    } catch (err) {
      return err;
    }
  };

  // UPDATE STAFF DETAILS

  // HandleSubmit for staff onboard
  const staffOnBoardingHandleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length === 0) {
      handleOpenBackDrop();
      const bodyData = {
        firstName: formValues.firstName,
        lastName: formValues.lastName,
        email: formValues.email,
        token: formValues.token,
        userType: formValues.userType,
        phoneNumber: formValues.whatsappNumber,
      };

      let responseFromEmployeeSave;
      let responseFromAddressSave;

      let responseFromSuperVisorSave;
      let responseFromUserSave;

      if (formValues.userType === "SUPERVISOR") {
        responseFromSuperVisorSave = await saveSupervisor(bodyData);
      } else {
        responseFromUserSave = await saveUser(bodyData);
      }

      let userId = responseFromUserSave?.data?.id;

      if (responseFromUserSave?.status === 200) {
        let userLocationDate = {
          userId: userId,
          tenantId: tenantId,
          locationId: locationId,
        };
        await fetchUserLocation(userLocationDate);
        responseFromEmployeeSave = await saveEmployee(userId);
        /*eslint-disable */
        if (responseFromEmployeeSave.status === 200) {
          responseFromAddressSave = await saveUserAddress(userId);
        }
        /*eslint-enable */

        let idCardFormData = new FormData();
        // let profilePictureFormData = new FormData();

        let saveUserDocumentForIdCardResponse;
        // let saveUserDocumentForProfilePictureResponse;

        // let responseFromProfilePictureAttachemnt = await uploadUserImage(
        //   userId,
        //   profilePictureFormData
        // );

        // if (responseFromProfilePictureAttachemnt?.mediaUrl !== "") {
        //   let responseFromSaveDocumentForProfilePicture = await saveDocument(
        //     responseFromProfilePictureAttachemnt?.id,
        //     responseFromProfilePictureAttachemnt?.mediaUrl,
        //     "image"
        //   );

        //   if (responseFromSaveDocumentForProfilePicture?.id !== "") {
        //     saveUserDocumentForProfilePictureResponse = saveUserDocument(
        //       responseFromSaveDocumentForProfilePicture?.id,
        //       userId
        //     ).then((res) => {
        //       return res;
        //     });
        //   }
        // }

        let responseFromIdCardAttachemnt = await uploadIdCard(
          userId,
          idCardFormData
        );

        if (responseFromIdCardAttachemnt?.mediaUrl !== "") {
          let responseFromSaveDocumentForIdCard = await saveDocument(
            responseFromIdCardAttachemnt?.id,
            responseFromIdCardAttachemnt?.mediaUrl,
            "image"
          );

          if (responseFromSaveDocumentForIdCard?.id !== "") {
            saveUserDocumentForIdCardResponse = saveUserDocument(
              responseFromSaveDocumentForIdCard?.id,
              userId
            );
          }
          handleCloseBackDrop();
        }
        if (saveUserDocumentForIdCardResponse?.id !== "") {
          setToast({
            ...toast,
            show: true,
            type: "success",
            message: "User saved successfully",
          });
          handleClose();
          getStaffData();
        } else {
          setToast({
            ...toast,
            show: true,
            type: "error",
            message: "User not saved",
          });
        }
      } else if (responseFromSuperVisorSave?.status === 200) {
        let userId = responseFromSuperVisorSave?.data?.id;

        let userLocationDate = {
          userId: userId,
          tenantId: tenantId,
          locationId: locationId,
        };
        await fetchUserLocation(userLocationDate);
        responseFromEmployeeSave = await saveEmployee(userId);
        /*eslint-disable */
        if (responseFromEmployeeSave.status === 200) {
          responseFromAddressSave = await saveUserAddress(userId);
        }
        /*eslint-enable */

        let idCardFormData = new FormData();
        // let profilePictureFormData = new FormData();

        let saveUserDocumentForIdCardResponse;
        // let saveUserDocumentForProfilePictureResponse;

        // let responseFromProfilePictureAttachemnt = await uploadUserImage(
        //   userId,
        //   profilePictureFormData
        // );

        // if (responseFromProfilePictureAttachemnt?.mediaUrl !== "") {
        //   let responseFromSaveDocumentForProfilePicture = await saveDocument(
        //     responseFromProfilePictureAttachemnt?.id,
        //     responseFromProfilePictureAttachemnt?.mediaUrl,
        //     "image"
        //   );

        //   if (responseFromSaveDocumentForProfilePicture?.id !== "") {
        //     saveUserDocumentForProfilePictureResponse = saveUserDocument(
        //       responseFromSaveDocumentForProfilePicture?.id,
        //       userId
        //     ).then((res) => {
        //       return res;
        //     });
        //   }
        // }

        let responseFromIdCardAttachemnt = await uploadIdCard(
          userId,
          idCardFormData
        );

        if (responseFromIdCardAttachemnt?.mediaUrl !== "") {
          let responseFromSaveDocumentForIdCard = await saveDocument(
            responseFromIdCardAttachemnt?.id,
            responseFromIdCardAttachemnt?.mediaUrl,
            "image"
          );

          if (responseFromSaveDocumentForIdCard?.id !== "") {
            saveUserDocumentForIdCardResponse = saveUserDocument(
              responseFromSaveDocumentForIdCard?.id,
              userId
            );
          }
          handleCloseBackDrop();
        }
        if (saveUserDocumentForIdCardResponse?.id !== "") {
          setToast({
            ...toast,
            show: true,
            type: "success",
            message: "User saved successfully",
          });
          handleClose();
          getStaffData();
        } else {
          setToast({
            ...toast,
            show: true,
            type: "error",
            message: "User not saved",
          });
        }
      }
    } else {
      setErrors(validationErrors);
    }
  };

  /*eslint-disable */
  useEffect(() => {
    getStaffData();
    getIdCardTypes();
    getIdCardSubTypes();
    getAllDesignation();
    getEntityLocations();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setToast({ show: false, type: "", message: "" });
    }, 5000);
  }, [toast]);
  /*eslint-enable */

  return {
    open,
    toast,
    files,
    errors,
    columns,
    loading,
    staffData,
    locations,
    emailError,
    formValues,
    idCardTypes,
    openBackDrop,
    designations,
    idCardSubType,
    idCardSubTypes,
    userIdForDelete,
    showConfirmation,
    userDocumentDetails,
    setOpen,
    setFiles,
    handleClose,
    handleClear,
    deleteStaff,
    randomString,
    validateEmail,
    setFormValues,
    handleClickOpen,
    handleClosePopup,
    getIdCardSubType,
    handleInputChange,
    updateUserDetails,
    handleSearchChange,
    handleCloseBackDrop,
    setUserDocumentDetails,
    staffOnBoardingHandleSubmit,
  };
}
