import {
  Alert,
  Box,
  Button,
  Card,
  // Checkbox,
  // FormControlLabel,
  InputAdornment,
  Link,
  Snackbar,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
// import CarImage from "../../assets/images/mercedes-eqc.png";
import CarImage from "../../assets/images/porsche_car.png";
import Parkezylogo from "../../assets/images/parkezy-logo.svg";
import { useTheme } from "styled-components";
import { inputFieledbgColor } from "../../styles/theme";
import { useLogin } from "./hooks/useLogin";
import { Oval } from "react-loader-spinner";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFilledInput-root": {
      backgroundColor: inputFieledbgColor,
    },
    "& .MuiFilledInput-root:hover": {
      backgroundColor: inputFieledbgColor,
      "@media (hover: none)": {
        backgroundColor: inputFieledbgColor,
      },
    },
    "& .MuiFilledInput-root.Mui-focused": {
      backgroundColor: inputFieledbgColor,
    },
    "& .css-1mog9k0-MuiFormLabel-root-MuiInputLabel-root": {
      fontSize: "14px",
    },
  },
}));

const LandingPage = () => {
  const {
    toast,
    loginApi,
    isLoading,
    isDisabled,
    loginError,
    showPassword,
    setLoginError,
    loginFormValues,
    loginHandleChange,
    handleClickShowPassword,
  } = useLogin();

  const classes = useStyles();
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));
  const isMd = useMediaQuery(theme.breakpoints.down("md"));
  const isLg = useMediaQuery(theme.breakpoints.down("lg"));

  return (
    <Box className="background-fixed-with-gradiant h-[100vh] w-100 relative flex justify-center items-center overflow-hidden">
      <Stack
        sx={isSm ? { width: "100%", height: "100%" } : ""}
        direction="row"
        className="justify-center items-center"
        spacing={6}
      >
        {!isLg && !isMd && !isSm ? (
          <Box className="h-[28rem]">
            <img src={CarImage} alt="" className="h-full w-auto" />
          </Box>
        ) : (
          ""
        )}

        {isSm ? (
          <Box className="w-full absolute top-0">
            <Box className="mt-[4rem] flex justify-center items-center">
              <img
                src={CarImage}
                alt=""
                className="h-[12rem] w-auto transform -scale-x-100"
              />
            </Box>
            <Card className="w-100 h-[80vh] !rounded-t-[1rem] ">
              <form>
                <Stack
                  spacing={2}
                  className="p-[26px] ml-0 justify-center items-center"
                >
                  <Stack
                    spacing={1}
                    className="justify-between items-center mb-3"
                  >
                    <img
                      src={Parkezylogo}
                      alt=""
                      className="w-[14rem] h-auto"
                    />
                    <Typography variant="h6">Welcome to Parkezy!</Typography>
                    <Typography variant="subtitle2">
                      Sign in into your account
                    </Typography>
                  </Stack>
                  <TextField
                    label="Email"
                    className={classes.root}
                    size="small"
                    fullWidth
                    variant="filled"
                    name="email"
                    value={loginFormValues.email}
                    InputProps={{ disableUnderline: true }}
                    onChange={(e) => {
                      loginHandleChange(e);
                      setLoginError([]);
                    }}
                  />
                  <TextField
                    id=""
                    size="small"
                    fullWidth
                    label="password"
                    type={showPassword ? "text" : "Password"}
                    name="password"
                    value={loginFormValues.password}
                    onChange={(e) => {
                      loginHandleChange(e);
                      setLoginError([]);
                    }}
                    autoComplete="current-password"
                    variant="filled"
                    className={classes.root}
                    InputProps={{
                      disableUnderline: true,
                      endAdornment: (
                        <InputAdornment
                          onMouseEnter={(e) =>
                            (e.target.style.cursor = "pointer")
                          }
                          position="end"
                          onClick={handleClickShowPassword}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </InputAdornment>
                      ),
                    }}
                  />
                  {loginError?.status === 401 && (
                    <Typography color="error" className="text-[13px]">
                      Invalid credentials
                    </Typography>
                  )}
                  <Box className="!w-full flex gap-6 justify-between items-center">
                    {/* <FormControlLabel
                      control={<Checkbox value="remember" color="primary" />}
                      sx={{
                        "& .css-ahj2mt-MuiTypography-root": { fontSize: 13 },
                        "& .css-9l3uo3": { fontSize: 13 },
                      }}
                      label="Remember me"
                      color="#1D1E2C"
                    /> */}
                    <Link href="#">Forgot password?</Link>
                  </Box>
                  <Button
                    onClick={(e) => {
                      loginApi(e);
                    }}
                    type="submit"
                    sx={{
                      width: "100%",
                      borderRadius: "20px",
                      background: theme.palette.neutral.main,
                      "&:hover": {
                        background: theme.palette.neutral.secondaryHover,
                      },
                    }}
                    variant="contained"
                  >
                    {isLoading === true ? (
                      <Oval
                        height={20}
                        width={20}
                        color="#4fa94d"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                        ariaLabel="oval-loading"
                        secondaryColor="#4fa94d"
                        strokeWidth={2}
                        strokeWidthSecondary={2}
                      />
                    ) : (
                      "Sign In"
                    )}
                  </Button>
                  {/* <Typography variant="subtitle2">
                  Don't have an account?{" "}
                  <Link href="#" className="mt-1 decoration-0 !no-underline">
                    Sign up
                  </Link>
                </Typography> */}
                </Stack>
              </form>
            </Card>
          </Box>
        ) : (
          <Box>
            <Card className="w-100 h-auto !rounded-[1rem]">
              <form>
                <Stack
                  spacing={2}
                  className="p-[26px] ml-0 justify-center items-center"
                >
                  <Stack
                    spacing={1}
                    className="justify-between items-center mb-3"
                  >
                    <img
                      src={Parkezylogo}
                      alt=""
                      className="w-[14rem] h-auto"
                    />
                    <Typography variant="h6">Welcome to Parkezy!</Typography>
                    <Typography variant="subtitle2">
                      Sign in into your account
                    </Typography>
                  </Stack>
                  <TextField
                    label="Email"
                    className={classes.root}
                    size="small"
                    fullWidth
                    variant="filled"
                    name="email"
                    value={loginFormValues.email}
                    InputProps={{ disableUnderline: true }}
                    onChange={(e) => {
                      loginHandleChange(e);
                      setLoginError([]);
                    }}
                  />
                  <TextField
                    id=""
                    size="small"
                    fullWidth
                    label="Password"
                    type={showPassword ? "text" : "Password"}
                    name="password"
                    value={loginFormValues.password}
                    onChange={(e) => {
                      loginHandleChange(e);
                      setLoginError([]);
                    }}
                    autoComplete="current-password"
                    variant="filled"
                    className={classes.root}
                    InputProps={{
                      disableUnderline: true,
                      endAdornment: (
                        <InputAdornment
                          onMouseEnter={(e) =>
                            (e.target.style.cursor = "pointer")
                          }
                          position="end"
                          onClick={handleClickShowPassword}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </InputAdornment>
                      ),
                    }}
                  />
                  {loginError?.status === 401 && (
                    <Typography color="error" sx={{ fontSize: "13px" }}>
                      Invalid credentials
                    </Typography>
                  )}

                  <Box className="!w-full flex gap-6 justify-between items-center">
                    {/* <FormControlLabel
                      sx={{
                        "& .css-ahj2mt-MuiTypography-root": { fontSize: 13 },
                        "& .css-9l3uo3": { fontSize: 14 },
                      }}
                      className={classes.root}
                      control={
                        <Checkbox
                          value="remember"
                          size="small"
                          color="primary"
                        />
                      }
                      label="Remember me"
                      color="#1D1E2C"
                    /> */}
                    <Link
                      href="#"
                      variant="subtitle2"
                      className="mt-1 decoration-0 no-underline"
                    >
                      Forgot password?
                    </Link>
                  </Box>
                  <Button
                    onClick={(e) => {
                      loginApi(e);
                    }}
                    disabled={!isDisabled}
                    className="disabled:opacity-85 !text-[#FEFEF]"
                    sx={{
                      width: "100%",
                      borderRadius: "20px",
                      background: theme.palette.neutral.main,
                      "&:hover": {
                        background: theme.palette.neutral.secondaryHover,
                      },
                    }}
                    variant="contained"
                  >
                    {isLoading === true ? (
                      <Oval
                        height={20}
                        width={20}
                        color="#4fa94d"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                        ariaLabel="oval-loading"
                        secondaryColor="#4fa94d"
                        strokeWidth={2}
                        strokeWidthSecondary={2}
                      />
                    ) : (
                      "Sign In"
                    )}
                  </Button>
                  {/* <Typography variant="subtitle2">
                  Don't have an account?{" "}
                  <Link href="#" className="mt-1 decoration-0 !no-underline">
                    Sign up
                  </Link>
                </Typography> */}
                </Stack>
              </form>
            </Card>
          </Box>
        )}
      </Stack>
      <Snackbar open={toast.show} autoHideDuration={1000}>
        <Alert severity={toast.type} sx={{ width: "100%" }}>
          {toast.messsege}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default LandingPage;
