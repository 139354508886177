import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Nav from "./components/Nav/Nav";
import Header from "./components/Header/Header";


const APP_BAR_MOBILE = 64;
// const APP_BAR_DESKTOP = 92;

const StyledRoot = styled("div")({
  display: "flex",
  minHeight: "100%",
  overflow: "hidden",
});

const Main = styled("div")(({ theme }) => ({
  flexGrow: 1,
  overflow: "auto",
  minHeight: "100%",
  paddingTop: APP_BAR_MOBILE + 24,
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  [theme.breakpoints.up("lg")]: {
  },
}));

function MainLayout({ children }) {
  // const [open, setOpen] = useState(false);

  const [isTrue, setIsTrue] = useState(true);

  return (
    <StyledRoot>
  
      <Header
        onOpenNav={() => setIsTrue(true)}
        // setOpen={setOpen}
        open={isTrue}
        setIsTrue={setIsTrue}
        isTrue={isTrue}
      />
      <Nav
        openNav={isTrue}
        onCloseNav={() => setIsTrue(false)}
        setIsTrue={setIsTrue}
        isTrue={isTrue}
        open={isTrue}
        // setOpen={setOpen}
      />
      <Main sx={{ bgcolor: "#F2F2F2", height: "100vh" }}>
        <Outlet />
        {children}
      </Main>
    </StyledRoot>
  );
}

export default MainLayout;
