import React from "react";
import MainLayout from "../../../containers/MainLayout";
import { Box, Button, Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import useChangeDashboardImage from "../hooks/useChangeDashboardImage";
import CancelIcon from "@mui/icons-material/Cancel";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import Dropzone from "react-dropzone";

const ChangeLogoInDashbaordPage = () => {
  const { files, setFiles } = useChangeDashboardImage();

  const thumb = {
    width: "250px",
    minHieght: "300px !important",
    height: "auto",
  };

  const img = {
    objectFit: "contain",
    width: "100%",
    height: "auto",
    margin: "0px",
  };

  const companyLogo = (
    <div style={thumb} key={files.logo[0]?.preview}>
      <img alt="user" src={files.logo[0]?.preview} style={img} />
      <span
        onClick={() => {
          setFiles({ ...files, logo: "" });
        }}
        className="cursor-pointer"
      >
        <CancelIcon className="absolute top-0 right-0 m-1" />
      </span>
    </div>
  );
  return (
    <MainLayout>
      <Typography className="text-[#252F40] !mb-2">
        <Link to="/dashboard" className="no-underline">
          <span className="text-[#8C8C8C]">Settings / </span>
        </Link>
        Dashboard Image Change
      </Typography>

      <Box
        sx={{
          boxShadow: " 0px 5px 15px -2px rgba(0, 0, 0, 0.12)",
          borderRadius: "2rem",
          width: "100%",
        }}
      >
        <Box className="flex justify-between px-3 py-2 items-center bg-[white]">
          <Grid container className="justify-center items-center">
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              lg={3}
              direction="row"
              spacing={2}
              className="items-center"
            >
              <Dropzone
                onDrop={(acceptedFiles) =>
                  setFiles({
                    ...files,
                    logo: acceptedFiles.map((file) =>
                      Object.assign(file, {
                        preview: URL.createObjectURL(file),
                      })
                    ),
                  })
                }
              >
                {({ getRootProps, getInputProps }) => (
                  <>
                    <Typography className="text-center" color="#576782" mb={1}>
                      Upload logo
                    </Typography>
                    <section className="containers-for-dropzone">
                      <section className=" text-center mb-0">
                        {files.logo === "" ? (
                          <div {...getRootProps({ className: "dropzone" })}>
                            <input {...getInputProps()} />
                            <section className="card-text mt-2">
                              <CloudUploadOutlinedIcon
                                sx={{ color: "#576782" }}
                              />
                              <Typography color="#576782">
                                Drag and Drop here
                              </Typography>
                            </section>
                            <Typography color="#576782">or</Typography>
                            <Button
                              variant="contained"
                              className="btn btn-primary mt-3"
                              sx={{
                                background:
                                  "linear-gradient(310deg, #141727 0%, #3A416F 100%);",
                                mb: 2,
                              }}
                            >
                              Select File
                            </Button>
                          </div>
                        ) : (
                          ""
                        )}
                        {files.logo[0]?.preview && (
                          <aside className="absolute">{companyLogo}</aside>
                        )}
                      </section>
                    </section>
                  </>
                )}
              </Dropzone>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </MainLayout>
  );
};

export default ChangeLogoInDashbaordPage;
