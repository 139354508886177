import { useEffect, useState } from "react";
import { fetchRefundReports } from "../../../api/general";
import moment from "moment";
import useVehicleType from "../../entry-exit/hooks/useVehicleType";
import useConvertTime from "../../reports/hook/useConvertTime";
import { useParams } from "react-router";

export function useRefundReports() {
  const [search, setSearch] = useState("");

  let tenantId = localStorage.getItem("tenantId");
  let locationId = localStorage.getItem("locationId");

  const [loading, setLoading] = useState(true);

  const [carReundDetails, setCarRefundDetails] = useState([]);
  const [bikeReundDetails, setBikeReundDetails] = useState([]);

  let today = new Date();
  let convertedDate = moment(today).format("YYYY-MM-DD");

  const [formValues, setFormValues] = useState({
    fromDate: convertedDate,
    toDate: convertedDate,
  });

  const params = useParams();

  const { twoWheelerId, fourWheelerId } = useVehicleType();

  const { convertPremiseTimeStamp } = useConvertTime();

  const columns = [
    {
      field: "No",
      headerName: "NO.",
      width: 65,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "entryBy",
      headerName: "ENTRY BY",
      flex: 1,
      headerClassName: "super-app-theme--header",
      valueGetter: (params) => params?.row?.entryBy?.name,
    },
    {
      field: "entry",
      headerName: "ENTRY GATE",
      flex: 1,
      headerClassName: "super-app-theme--header",
      valueGetter: (params) => params?.row?.entryBooth?.name,
    },
    {
      field: "vehicletype",
      headerName: "VEHICLE TYPE",
      flex: 1,
      headerClassName: "super-app-theme--header",
      valueGetter: (params) => params.row.vehicleType?.name,
    },
    {
      field: "vrn",
      headerName: "VRN NO",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "entryTime",
      headerName: "ENTRY TIME",
      flex: 1,
      headerClassName: "super-app-theme--header",
      valueGetter: (params) =>
        moment(params?.row?.entryTime).format("HH:mm:ss"),
    },
    {
      field: "exitTime",
      headerName: "EXIT TIME",
      flex: 1,
      headerClassName: "super-app-theme--header",
      valueGetter: (params) =>
        params?.row?.exitTime
          ? moment(params?.row?.exitTime).format("HH:mm:ss")
          : " --",
    },
    {
      field: "timeOnPremises",
      headerName: "DURATION",
      flex: 1,
      headerClassName: "super-app-theme--header",
      valueGetter: ({ value }) => convertPremiseTimeStamp(value),
    },
    {
      field: "exitBy",
      headerName: "EXIT BY",
      flex: 1,
      headerClassName: "super-app-theme--header",
      valueGetter: (params) => params?.row?.exitBy?.name,
    },
    {
      field: "exit",
      headerName: "EXIT GATE",
      flex: 1,
      headerClassName: "super-app-theme--header",
      valueGetter: (params) => params?.row?.exitBooth?.name,
    },
    {
      field: "parkingType",
      headerName: "PARKING TYPE",
      flex: 1,
      headerClassName: "super-app-theme--header",
      valueGetter: (params) => params?.row?.parkingType?.name,
    },
    {
      field: "paymentMode",
      headerName: "PAYMENT MODE",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "parkingAmount",
      headerName: "AMOUNT",
      flex: 1,
      align: "right",
      headerAlign: "right",
      headerClassName: "super-app-theme--header",
    },
  ];

  const getRefunReport = async () => {
    let data = {
      fromDate: formValues.fromDate,
      toDate: formValues.toDate,
      tenantId: tenantId,
      locationId: locationId,
    };
    let response = await fetchRefundReports(locationId, tenantId, data);
    if (response) {
      getCarRefundReports(response);
      getBikeRefundReports(response);
    }
    return response;
  };

  const getCarRefundReports = async (response) => {
    setLoading(true);
    let result = await response.filter((val) => {
      return val?.vehicleType?.id === fourWheelerId;
    });
    setLoading(false);
    setCarRefundDetails(result);
    return result;
  };

  const getBikeRefundReports = async (response) => {
    setLoading(true);
    let result = await response.filter((val) => {
      return val?.vehicleType?.id === twoWheelerId;
    });
    setLoading(false);
    setBikeReundDetails(result);
    return result;
  };

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const handleChange = (e) => {
    setFormValues({ ...formValues, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    getRefunReport();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [twoWheelerId, fourWheelerId, formValues.fromDate, formValues.toDate]);

  const filteredData =
    twoWheelerId === params.id
      ? carReundDetails?.filter((item) =>
          item.vrn.toLowerCase().includes(search.toLowerCase())
        )
      : bikeReundDetails?.filter((item) =>
          item.vrn.toLowerCase().includes(search.toLowerCase())
        );

  return {
    loading,
    columns,
    formValues,
    filteredData,
    setLoading,
    handleChange,
    handleSearchChange,
  };
}
