import { Box, Card } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React from "react";

const StaffOnboardingTable = ({ staffData, columns, loading }) => {
  const data =  staffData ??[];
  return (
    <Card>
      <Box
        sx={{
          height: 475,
          width: "100%",
          "& .super-app-theme--header": {
            backgroundColor: "#576782",
            fontWight: "bold",
            color: "white",
          },
          "& .css-1pe4mpk-MuiButtonBase-root-MuiIconButton-root": {
            color: "white",
          },
          "& .MuiDataGrid-sortIcon": {
            color: "white",
          },
        }}
      >
        <DataGrid
          rows={data?.map((menu, i) => ({
            No: i + 1,
            ...menu,
          }))}
          columns={columns}
          loading={loading}
        />
      </Box>
    </Card>
  );
};

export default StaffOnboardingTable;
