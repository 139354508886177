import { useEffect, useState } from "react";
import { VEHICLE_TYPE } from "../../../services/apiUrls";
import { get } from "../../../services/service";

const useVehicleType = () => {
  let tenantId = localStorage.getItem("tenantId");
  let locationId = localStorage.getItem("locationId");

  const [vehicleTypeData, setVehicleTypeData] = useState([]);

  const [twoWheelerId, setTwoWheelerId] = useState(null);
  const [fourWheelerId, setFourWheelerId] = useState(null);

  const getAllVehicleType = async () => {
    const resp = await get(
      VEHICLE_TYPE.replace("{tenantId}", tenantId).replace(
        "{locationId}",
        locationId
      )
    );
    if (resp && resp?.id !== " ") {
      setVehicleTypeData(resp);
    }
  };

  const findTwoWheelerId = () => {
    const result = vehicleTypeData?.filter((val) => {
      return (
        val.description.toLowerCase() === "bike" ||
        val.name.toLowerCase() === "two wheeler" ||
        val.name.toLowerCase() === "2 wheeler" ||
        val.name.toLowerCase() === "bike"
      );
    });
    setTwoWheelerId(result?.[0]?.id);
  };

  const findFourWheelerId = () => {
    const result = vehicleTypeData?.filter((val) => {
      return (
        val.description.toLowerCase() === "car" ||
        val.name.toLowerCase() === "four wheeler" ||
        val.name.toLowerCase() === "4 wheeler" ||
        val.name.toLowerCase() === "car"
      );
    });
    setFourWheelerId(result?.[0]?.id);
  };

  /*eslint-disable */
  useEffect(() => {
    getAllVehicleType();
  }, []);

  useEffect(() => {
    findFourWheelerId();
    findTwoWheelerId();
  }, [vehicleTypeData]);
  /*eslint-enable */

  return { getAllVehicleType, vehicleTypeData, twoWheelerId, fourWheelerId };
};

export default useVehicleType;
