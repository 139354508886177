import {
  GET_MONTHLY_GRAPH_BY_PARKING_TYPE,
  GET_MONTHLY_GRAPH_BY_VEHICLE_TYPE,
  GET_WEEK_GRAPH_BY_PARKING_TYPE,
  GET_WEEK_GRAPH_BY_VEHICLE_TYPE,
} from "../services/apiUrls";
import { get } from "../services/service";

export const fetchMonthlyGraphByVehicleType = async (
  tenantId,
  locationId,
  year,
  vehicleType
) => {
  let response = await get(
    GET_MONTHLY_GRAPH_BY_VEHICLE_TYPE.replace("{tenantId}", tenantId)
      .replace("{locationId}", locationId)
      .replace("{year}", year)
      .replace("{vehicleType}", vehicleType)
  );
  return response;
};

export const fetchMonthlyGraphByParkingType = async (
  tenantId,
  locationId,
  year,
  parkingType
) => {
  let response = await get(
    GET_MONTHLY_GRAPH_BY_PARKING_TYPE.replace("{tenantId}", tenantId)
      .replace("{locationId}", locationId)
      .replace("{year}", year)
      .replace("{parkingType}", parkingType)
  );
  return response;
};

export const fetchWeeklyGraphByVehicleType = async (
  tenantId,
  locationId,
  vehicleType,
  fromDate,
  toDate
) => {
  let params = {
    fromDate: `${fromDate} 00:00:00`,
    toDate: `${toDate} 00:00:00`,
  };
  let URL = `${GET_WEEK_GRAPH_BY_VEHICLE_TYPE.replace("{tenantId}", tenantId)
    .replace("{locationId}", locationId)
    .replace("{vehicleType}", vehicleType)}`;
  let response = await get(URL, params);
  return response;
};

export const fetchWeeklyGraphByParkingType = async (
  tenantId,
  locationId,
  parkingType,
  fromDate,
  toDate
) => {
  let params = {
    fromDate: `${fromDate} 00:00:00`,
    toDate: `${toDate} 00:00:00`,
  };
  let URL = `${GET_WEEK_GRAPH_BY_PARKING_TYPE.replace("{tenantId}", tenantId)
    .replace("{locationId}", locationId)
    .replace("{parkingType}", parkingType)}`;
  let response = await get(URL, params);
  return response;
};
