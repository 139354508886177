const useConvertTime = () => {
  const convertPremiseTimeStamp = (premisesTimeStamp) => {
    let totalSeconds = premisesTimeStamp / 1000;
    let totalMinutes = totalSeconds / 60;

    let totalHour = totalMinutes / 60;
    let totalHourIntValue = Math.floor(totalHour);
    let totalHourLength = ("" + totalHourIntValue).length;

    let premiseMinutes = (totalMinutes % 60).toFixed();
    let premiseMinutesLength = ("" + premiseMinutes).length;

    let premiseSeconds = (totalSeconds % 60).toFixed(0);
    let premiseSecondsLength = ("" + premiseSeconds).length;

    const premisesDate = `${
      totalHourLength > 1 ? totalHourIntValue : `0${totalHourIntValue}`
    } : ${premiseMinutesLength > 1 ? premiseMinutes : `0${premiseMinutes}`} : ${
      premiseSecondsLength > 1 ? premiseSeconds : `0${premiseSeconds}`
    }`;

    return premisesDate;
  };
  return {
    convertPremiseTimeStamp,
  };
};

export default useConvertTime;
