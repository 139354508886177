// tails-report?page=0&size=100
import { GET_CASH_COLLECT_DETAIL_URL } from "../services/apiUrls";
import { post } from "../services/service";

export const fetchCashCollectDetailsViewWithPagination = async (
  locationId,
  tenantId,
  currentPage,
  data,
) => {
  try {
    let params = {
      page: currentPage,
      size: 100,
    };
    let response = await post(
      GET_CASH_COLLECT_DETAIL_URL.replace(
        "{tenantId}",
        tenantId
      ).replace("{locationId}", locationId),
      data,
      params
    );
    return response;
  } catch (error) {
    return error;
  }
};
