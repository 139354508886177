import React from "react";
import {
  Box,
  Grid,
  Select,
  Dialog,
  TextField,
  InputLabel,
  FormControl,
  DialogContent,
  InputAdornment,
  Button,
  Typography,
  MenuItem,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import Dropzone from "react-dropzone";
import "../../../../../assets/css/style.css";
import { makeStyles } from "@mui/styles";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import CancelIcon from "@mui/icons-material/Cancel";

const useStyles = makeStyles({
  input: {
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
});

const StaffOnboardingForm = ({
  open,
  files,
  errors,
  setFiles,
  formValues,
  handleClear,
  idCardTypes,
  designations,
  handleClose,
  openBackDrop,
  randomString,
  handleInputChange,
  handleCloseBackDrop,
  locations,
  validateEmail,
  getIdCardSubType,
  idCardSubType,
  // userDocumentDetails,
  // setUserDocumentDetails,
  updateUserDetails,
  setFormValues,
  staffOnBoardingHandleSubmit,
}) => {
  const classes = useStyles();

  const userTypes = [
    {
      id: "ADMIN",
      name: "ADMIN",
    },
    {
      id: "USER",
      name: "USER",
    },
    {
      id: "SUPERVISOR",
      name: "SUPERVISOR",
    },
  ];

  const thumb = {
    width: "200px",
    height: "auto",
  };

  const img = {
    objectFit: "contain",
    width: "100%",
    height: "auto",
    margin: "0px",
  };

  // const userimage = (
  //   <div style={thumb} key={files.userImg[0]?.preview}>
  //     <img alt="user" src={files.userImg[0]?.preview} style={img} />
  //     <span
  //       onClick={() => {
  //         setFiles({ ...files, userImg: "" });
  //       }}
  //       className="cursor-pointer"
  //     >
  //       <CancelIcon className="absolute top-0 right-0 m-1" />
  //     </span>
  //   </div>
  // );

  const idcard = (
    <div style={thumb} key={files.idCard[0]?.preview}>
      <img
        alt="ID card"
        src={
          files.idCard[0]?.preview
            ? files.idCard[0]?.preview
            : formValues?.fileUrl
        }
        style={img}
      />
      <span
        onClick={() => {
          setFiles({ ...files, idCard: "" });
          setFormValues({ ...formValues, fileUrl: "" });
        }}
        className="cursor-pointer"
      >
        <CancelIcon className="absolute top-0 right-0 m-1" />
      </span>
    </div>
  );

  return (
    <>
      <Dialog
        fullWidth
        open={open}
        onClose={handleClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "80%",
            },
          },
        }}
      >
        <Box display={"flex"} flexDirection={"row"} justifyContent={"end"}>
          <CancelRoundedIcon
            onClick={handleClose}
            className="bg-[white] !text-[#344767] !text-[1.7rem] !outline-none p-4 cursor-pointer"
          />
        </Box>

        <DialogContent className="flex justify-center">
          <form onSubmit={staffOnBoardingHandleSubmit}>
            <Box className="w-[100%]">
              <Grid container gap={4} className="flex justify-center">
                <Grid item xs={12} md={5} lg={3.5}>
                  <TextField
                    // required
                    fullWidth
                    type="text"
                    size="small"
                    name="firstName"
                    label="First Name*"
                    id="outlined-required"
                    value={formValues.firstName}
                    onChange={handleInputChange}
                  />
                  <Typography color="error" sx={{ fontSize: "12px", mt: 0.5 }}>
                    {errors.name}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={5} lg={3.5}>
                  <TextField
                    fullWidth
                    type="text"
                    size="small"
                    name="lastName"
                    label="Last Name"
                    id="outlined-required"
                    value={formValues.lastName}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12} md={5} lg={3.5}>
                  <FormControl fullWidth size="small">
                    <InputLabel size="small" required>
                      User types
                    </InputLabel>
                    <Select
                      labelId="userType"
                      label="User type *"
                      name="userType"
                      value={formValues?.userType}
                      onChange={handleInputChange}
                    >
                      {userTypes?.map((obj) => (
                        <MenuItem value={obj.id}>{obj.name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <Typography color="error" sx={{ fontSize: "12px", mt: 0.5 }}>
                    {errors.userType}
                  </Typography>
                </Grid>

                <Grid item xs={12} md={5} lg={3.5}>
                  <FormControl fullWidth size="small">
                    <InputLabel size="small" required>
                      Entity Location
                    </InputLabel>
                    <Select
                      labelId="Entity Location"
                      label="Vehicle_type*"
                      name="locationId"
                      value={formValues?.locationId}
                      onChange={handleInputChange}
                    >
                      {locations?.map((obj) => (
                        <MenuItem value={obj.id}>{obj.name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <Typography color="error" sx={{ fontSize: "12px", mt: 0.5 }}>
                    {errors.locationId}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={5} lg={3.5}>
                  <FormControl fullWidth size="small">
                    <InputLabel size="small" required>
                      Designation
                    </InputLabel>
                    <Select
                      labelId="Designation"
                      label="Designation*"
                      name="designationId"
                      value={formValues?.designationId}
                      onChange={handleInputChange}
                    >
                      {designations?.map((obj) => (
                        <MenuItem value={obj.id}>{obj.name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <Typography color="error" sx={{ fontSize: "12px", mt: 0.5 }}>
                    {errors.designationId}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={5} lg={3.5}>
                  <TextField
                    // required
                    fullWidth
                    type="text"
                    size="small"
                    name="addressLine1"
                    label="Address 1*"
                    id="outlined-required"
                    value={formValues.addressLine1}
                    onChange={handleInputChange}
                  />
                  <Typography color="error" sx={{ fontSize: "12px", mt: 0.5 }}>
                    {errors.addressLine1}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={5} lg={3.5}>
                  <TextField
                    fullWidth
                    type="text"
                    size="small"
                    name="addressLine2"
                    label="Address 2"
                    id="outlined-required"
                    value={formValues.addressLine2}
                    onChange={handleInputChange}
                  />
                </Grid>

                <Grid item xs={12} md={5} lg={3.5}>
                  <TextField
                    fullWidth
                    type="number"
                    size="small"
                    name="pinCode"
                    label="Pincode"
                    //   className="inputRounded"
                    id="outlined-required"
                    onInput={(e) =>
                      (e.target.value = e.target.value.slice(0, 6))
                    }
                    className={classes.input}
                    value={formValues.pinCode}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12} md={5} lg={3.5}>
                  {/* <FormControl fullWidth size="small">
                  <InputLabel size="small" required>
                    Country
                  </InputLabel>
                  <Select
                    name="country"
                    label="Country"
                    labelId="State"
                    value={formValues?.country}
                    onChange={handleInputChange}
                  >
                    {vehicleTypeData?.map((obj) => {
                      return <MenuItem value={obj.id}>{obj.name}</MenuItem>;
                    })}
                  </Select>
                </FormControl> */}
                  <TextField
                    // required
                    fullWidth
                    type="text"
                    size="small"
                    name="country"
                    label="Country"
                    id="outlined-required"
                    value={formValues.country}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12} md={5} lg={3.5}>
                  {/* <FormControl fullWidth size="small">
                  <InputLabel size="small" required>
                    State
                  </InputLabel>
                  <Select
                    name="state"
                    label="State"
                    labelId="State"
                    value={formValues?.state}
                    onChange={handleInputChange}
                  >
                    {vehicleTypeData?.map((obj) => {
                      return <MenuItem value={obj.id}>{obj.name}</MenuItem>;
                    })}
                  </Select>
                </FormControl> */}
                  <TextField
                    // required
                    fullWidth
                    type="text"
                    size="small"
                    name="state"
                    label="State*"
                    id="outlined-required"
                    value={formValues.state}
                    onChange={handleInputChange}
                  />
                  <Typography color="error" sx={{ fontSize: "12px", mt: 0.5 }}>
                    {errors.state}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={5} lg={3.5}>
                  {/* <FormControl fullWidth size="small">
                  <InputLabel size="small" required>
                    District
                  </InputLabel>
                  <Select
                    name="city"
                    label="District"
                    labelId="District"
                    value={formValues?.city}
                    onChange={handleInputChange}
                  >
                    {vehicleTypeData?.map((obj) => {
                      return <MenuItem value={obj.id}>{obj.name}</MenuItem>;
                    })}
                  </Select>
                </FormControl> */}
                  <TextField
                    // required
                    fullWidth
                    type="text"
                    size="small"
                    name="city"
                    label="District*"
                    id="outlined-required"
                    value={formValues.city}
                    onChange={handleInputChange}
                  />
                  <Typography color="error" sx={{ fontSize: "12px", mt: 0.5 }}>
                    {errors.city}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={5} lg={3.5}>
                  <TextField
                    // required
                    fullWidth
                    type="text"
                    size="small"
                    name="email"
                    label="Email *"
                    id="outlined-required"
                    value={formValues.email}
                    onChange={(e) => {
                      validateEmail();
                      handleInputChange(e);
                    }}
                  />
                  <Typography color="error" sx={{ fontSize: "12px", mt: 0.5 }}>
                    {errors.email}
                  </Typography>
                </Grid>
                {!formValues?.id && (
                  <Grid item xs={12} md={5} lg={3.5}>
                    <TextField
                      // required
                      fullWidth
                      type="text"
                      size="small"
                      name="token"
                      label="Password*"
                      id="outlined-required"
                      value={formValues.token}
                      onChange={handleInputChange}
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Button
                              variant="contaiend"
                              onClick={randomString}
                              sx={{
                                py: "1px",
                                textTransform: "none",
                                color: "white",
                                fontSize: "12px",
                                background:
                                  "linear-gradient(310deg, #141727 0%, #3A416F 100%)",
                              }}
                            >
                              Generate
                            </Button>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <Typography
                      color="error"
                      sx={{ fontSize: "12px", mt: 0.5 }}
                    >
                      {errors.token}
                    </Typography>
                  </Grid>
                )}
                <Grid item xs={12} md={5} lg={3.5}>
                  <TextField
                    // required
                    fullWidth
                    type="number"
                    size="small"
                    name="whatsappNumber"
                    label="Whatsapp Number*"
                    id="outlined-required"
                    onInput={(e) =>
                      (e.target.value = e.target.value.slice(0, 10))
                    }
                    className={classes.input}
                    value={formValues.whatsappNumber}
                    onChange={handleInputChange}
                  />
                  <Typography color="error" sx={{ fontSize: "12px", mt: 0.5 }}>
                    {errors.whatsappNumber}
                  </Typography>
                </Grid>

                <Grid item xs={12} md={5} lg={3.5}>
                  <FormControl fullWidth size="small">
                    <InputLabel size="small" required>
                      ID Card Type
                    </InputLabel>
                    <Select
                      name="idCardType"
                      label="ID Card Type"
                      labelId="ID Card Type"
                      value={formValues?.idCardType}
                      onChange={(e) => {
                        getIdCardSubType(e.target.value);
                        handleInputChange(e);
                      }}
                    >
                      {idCardTypes?.map((obj) => {
                        return <MenuItem value={obj.id}>{obj.name}</MenuItem>;
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={5} lg={3.5}>
                  <FormControl fullWidth size="small">
                    <InputLabel size="small" required>
                      ID Card Sub Type
                    </InputLabel>
                    <Select
                      name="idCardSubType"
                      label="ID Card Sub Type"
                      labelId="ID Card Type"
                      value={formValues?.idCardSubType}
                      onChange={handleInputChange}
                    >
                      {idCardSubType?.map((obj) => {
                        return <MenuItem value={obj.id}>{obj.name}</MenuItem>;
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={5} lg={3.5}>
                  <TextField
                    // required
                    fullWidth
                    type="number"
                    size="small"
                    name="idCardNumber"
                    label="ID Card Number"
                    id="outlined-required"
                    className={classes.input}
                    value={formValues.idCardNumber}
                    onChange={handleInputChange}
                    onWheel={(e) => e.preventDefault}
                  />
                </Grid>

                <Grid item xs={12} md={5} lg={3.5}></Grid>
                <Grid item xs={12} md={5} lg={3.5}></Grid>
              </Grid>
              {/*eslint-disable */}
              <Grid container gap={4} mt={4} className="flex justify-center">
                <Grid item xs={12} md={5} lg={3.5}>
                  <Dropzone
                    onDrop={(acceptedFiles) =>
                      setFiles({
                        ...files,
                        idCard: acceptedFiles.map((file) =>
                          Object.assign(file, {
                            preview: URL.createObjectURL(file),
                          })
                        ),
                      })
                    }
                  >
                    {({ getRootProps, getInputProps }) => (
                      <>
                        <Typography
                          className="text-center"
                          color="#576782"
                          mb={1}
                        >
                          Upload ID card image{" "}
                          <span style={{ color: "#C01111" }}>*</span>
                        </Typography>
                        <section className="containers">
                          <section className="text-center mb-0">
                            {files.idCard == "" &&
                            formValues?.fileUrl === "" ? (
                              <div {...getRootProps({ className: "dropzone" })}>
                                <input {...getInputProps()} />
                                <section className="card-text mt-2">
                                  <CloudUploadOutlinedIcon
                                    sx={{ color: "#576782" }}
                                  />
                                  <Typography color="#576782">
                                    Drag and Drop here
                                  </Typography>
                                </section>
                                <Typography color="#576782">or</Typography>
                                <Button
                                  variant="contained"
                                  className="btn btn-primary mt-2"
                                  sx={{
                                    background:
                                      "linear-gradient(310deg, #141727 0%, #3A416F 100%);",
                                    mb: 2,
                                  }}
                                >
                                  Select File
                                </Button>
                              </div>
                            ) : (
                              ""
                            )}
                            {(files.idCard[0]?.preview ||
                              formValues?.fileUrl !== "") && (
                              <aside className="absolute">{idcard}</aside>
                            )}
                          </section>
                        </section>
                      </>
                    )}
                  </Dropzone>
                </Grid>
                {/* <Grid item xs={12} md={5} lg={3.5}>
                <Dropzone
                  onDrop={(acceptedFiles) =>
                    setFiles({
                      ...files,
                      userImg: acceptedFiles.map((file) =>
                        Object.assign(file, {
                          preview: URL.createObjectURL(file),
                        })
                      ),
                    })
                  }
                >
                  {({ getRootProps, getInputProps }) => (
                    <>
                      <Typography
                        className="text-center"
                        color="#576782"
                        mb={1}
                      >
                        Upload user image{" "}
                        <span style={{ color: "#C01111" }}>*</span>
                      </Typography>
                      <section className="containers">
                        <section className=" text-center mb-0">
                          {files.userImg == "" && !userDocumentDetails ? (
                            <div {...getRootProps({ className: "dropzone" })}>
                              <input {...getInputProps()} />
                              <section className="card-text mt-2">
                                <CloudUploadOutlinedIcon
                                  sx={{ color: "#576782" }}
                                />
                                <Typography color="#576782">
                                  Drag and Drop here
                                </Typography>
                              </section>
                              <Typography color="#576782">or</Typography>
                              <Button
                                variant="contained"
                                className="btn btn-primary mt-3"
                                sx={{
                                  background:
                                    "linear-gradient(310deg, #141727 0%, #3A416F 100%);",
                                  mb: 2,
                                }}
                              >
                                Select File
                              </Button>
                            </div>
                          ) : (
                            ""
                          )}
                          {userDocumentDetails && (
                            <aside className="absolute">{userimage}</aside>
                          )}
                        </section>
                      </section>
                    </>
                  )}
                </Dropzone>
              </Grid> */}

                <Grid item xs={12} md={5} lg={3.5}></Grid>
              </Grid>
              {/*eslint-enable */}
              <Grid container>
                <Grid item xs={12} md={12} lg={12} className="flex justify-end">
                  <Box sx={{ mr: 5, mb: 2 }}>
                    <Button
                      variant="outlined"
                      sx={{ mr: 3 }}
                      size="small"
                      onClick={handleClear}
                    >
                      CLEAR
                    </Button>
                    <Button
                      size="small"
                      type="submit"
                      variant="contained"
                      sx={{
                        background:
                          "linear-gradient(310deg, #141727 0%, #3A416F 100%);",
                      }}
                      onClick={() => {
                        if (formValues.id === "") {
                          staffOnBoardingHandleSubmit();
                        } else {
                          updateUserDetails();
                        }
                      }}
                    >
                      SUBMIT
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={openBackDrop}
              onClick={handleCloseBackDrop}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default StaffOnboardingForm;
