import {
  Button,
  Card,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  tableCellClasses,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import React from "react";
import { Link } from "react-router-dom";
import useSettlementReport from "../hook/useSettlementReport";
import { styled } from "@mui/material/styles";
import { Oval } from "react-loader-spinner";
import moment from "moment";

const SettlementReport = () => {
  const {
    loading,
    formValues,
    vehileTypes,
    settleReportDetails,
    handleClear,
    handleInputChange,
    getSettlementDetails,
  } = useSettlementReport();

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#576782",
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  return (
    <>
      <Typography className="text-[#252F40] !mb-2">
        <Link to="/dashboard" className="no-underline">
          <span className="text-[#8C8C8C]">Reports / </span>
        </Link>
        Settlement report
      </Typography>

      <Box
        sx={{
          borderRadius: "10px",
          boxShadow: "0px 3.75px 7.5px rgba(0, 0, 0, 0.12)",
        }}
        className="!bg-[white] !mt-6 !mb-4"
      >
        <Stack direction="row" gap={2} className="!p-4 flex-wrap items-center">
          <TextField
            required
            name="date"
            type="date"
            id="outlined-required"
            label="Date"
            size="small"
            focused
            onChange={handleInputChange}
            value={formValues.date ? formValues.date : ""}
          />

          <FormControl size="small" variant="outlined">
            <InputLabel htmlFor="vehicle-type">Vehicle type</InputLabel>
            <Select
              required
              labelId="vehicle-type"
              id="vehicle-type"
              label="vehicle-type*"
              className="!min-w-[300px]"
              name="vehicleTypeId"
              value={formValues?.vehicleTypeId}
              onChange={handleInputChange}
            >
              {vehileTypes?.map((obj) => (
                <MenuItem value={obj.id}>{obj.name}</MenuItem>
              ))}
            </Select>
          </FormControl>

          <Button
            variant="contained"
            size="small"
            onClick={handleClear}
            className="!border !border-solid !border-[#3A416F] !bg-[#fff] !text-[#3A416F] gap-2"
            sx={{ textTransform: "none" }}
          >
            Clear
          </Button>
          <Button
            variant="contained"
            size="small"
            onClick={getSettlementDetails}
            disabled={!formValues.vehicleTypeId}
            className="!bg-[#3A416F] gap-2"
            sx={{ textTransform: "none" }}
          >
            {loading ? (
              <Oval
                height={20}
                width={20}
                color="#4fa94d"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                ariaLabel="oval-loading"
                secondaryColor="#4fa94d"
                strokeWidth={2}
                strokeWidthSecondary={2}
              />
            ) : (
              "Search"
            )}
          </Button>
        </Stack>
      </Box>

      <Card>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 500 }}>
            <TableHead>
              <TableRow>
                <StyledTableCell>No.</StyledTableCell>
                <StyledTableCell align="center">Date</StyledTableCell>
                <StyledTableCell align="left">Name</StyledTableCell>
                <StyledTableCell align="center">Start Time</StyledTableCell>
                <StyledTableCell align="center">End Time</StyledTableCell>
                <StyledTableCell align="right">
                  Total Entry Count
                </StyledTableCell>
                <StyledTableCell align="right">
                  Total Exit Count
                </StyledTableCell>
                <StyledTableCell align="right">Total Refund</StyledTableCell>
                <StyledTableCell align="right">
                  Total Entry Cash
                </StyledTableCell>
                <StyledTableCell align="right">Total Exit Cash</StyledTableCell>
                <StyledTableCell align="right">
                  Total Refund and complimentary Cash
                </StyledTableCell>
                <StyledTableCell align="right">Cash Settlement</StyledTableCell>
                <StyledTableCell align="right">Gate Type</StyledTableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {loading === true ? (
                <TableCell colSpan={13}>
                  <Box className="flex flex-col items-center justify-center">
                    Loading please wait...
                    <Oval
                      height={20}
                      width={20}
                      color="#4fa94d"
                      wrapperStyle={{}}
                      wrapperClass=""
                      visible={true}
                      ariaLabel="oval-loading"
                      secondaryColor="#4fa94d"
                      strokeWidth={2}
                      strokeWidthSecondary={2}
                    />
                  </Box>
                </TableCell>
              ) : (
                <>
                  {settleReportDetails?.settlementDetails?.length > 0 ? (
                    <>
                      {settleReportDetails?.settlementDetails?.map(
                        (row, id) => {
                          return (
                            <TableRow key={row.id}>
                              <TableCell component="th" scope="row">
                                {1 + id}
                              </TableCell>
                              <TableCell align="right">
                                {moment(row?.fromDate).format("DD/MM/YYYY")}
                              </TableCell>
                              <TableCell align="left">
                                <span
                                  style={{ fontWeight: "bold", color: "green" }}
                                >
                                  {row?.name}
                                  {console.log('rowddd', row)}
                                </span>
                              </TableCell>
                              <TableCell align="right">
                                {moment(row?.startTime).format("hh:mm:ss A")}
                              </TableCell>
                              <TableCell align="right">
                                {moment(row?.endTime).format("hh:mm:ss A")}
                              </TableCell>
                              <TableCell align="right">
                                {row?.totalEntryCount}
                              </TableCell>
                              <TableCell align="right">
                                {row?.totalExitCount}
                              </TableCell>
                              <TableCell align="right">
                                {row?.totalRefund}
                              </TableCell>
                              <TableCell align="right">
                                {row?.totalEntryCash}
                              </TableCell>
                              <TableCell align="right">
                                {row?.totalExitCash}
                              </TableCell>
                              <TableCell align="right">
                                {row?.totalRefundCash}
                              </TableCell>
                              <TableCell align="right">
                                {row?.cashSettlement}
                              </TableCell>
                              <TableCell align="right">
                                {row?.gateType}
                              </TableCell>
                            </TableRow>
                          );
                        }
                      )}
                    </>
                  ) : (
                    <TableCell colSpan={13}>
                      <Typography className="flex items-center justify-center">
                        No data found!
                      </Typography>
                    </TableCell>
                  )}
                </>
              )}

              {loading === false ? (
                <>
                  {settleReportDetails?.settlementDetails?.length > 0 ? (
                    <>
                      <TableRow>
                        <TableCell colSpan={12} align="right">
                          <b className="text-[16px]">Total cash collect</b>
                        </TableCell>

                        <TableCell align="right">
                          <span style={{ fontWeight: "bold" }}>
                            {settleReportDetails?.totalCashCollect}
                          </span>
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell colSpan={12} align="right">
                          <b className="text-[16px]">Total refund and complimentary</b>
                        </TableCell>

                        <TableCell align="right">
                          <span
                            style={{
                              fontWeight: "bold",
                              color:
                                settleReportDetails?.totalCashInHand > 0
                                  ? "red"
                                  : "",
                            }}
                          >
                            {settleReportDetails?.totalRefund}
                          </span>
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell colSpan={12} align="right">
                          <b className="text-[16px]">Total cash in hand</b>
                        </TableCell>

                        <TableCell align="right">
                          <span
                            style={{
                              fontWeight: "bold",
                            }}
                          >
                            {settleReportDetails?.totalCashInHand}
                          </span>
                        </TableCell>
                      </TableRow>
                    </>
                  ) : (
                    ""
                  )}
                </>
              ) : (
                ""
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
    </>
  );
};

export default SettlementReport;
