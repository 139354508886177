import React from "react";
import { Typography, Box, Stack, Button } from "@mui/material";
import BlackAndWhiteLogo from "../../assets/images/parkezy_black_and_white.png";
import PrintIcon from "@mui/icons-material/Print";
import moment from "moment";
import ReactToPrint from "react-to-print";

const componentRef = React.createRef();

const timestamp = parseInt(localStorage.getItem("loginTime"), 10);
const isValidTimestamp = !isNaN(timestamp);
const formattedInTime = isValidTimestamp
  ? moment(timestamp).format("hh:mm:ss A")
  : "";
const formattedOutTime = moment().format("hh:mm:ss A");

const Receipt = ({ receiptData, presentDate }) => {
  return (
    <>
      <Box
        className="w-[16rem] m-auto"
        style={{
          boxShadow:
            "rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px",
          borderRadius: 8,
        }}
      >
        <Box ref={componentRef} className="!mt-3">
          <Stack className="justify-center items-center">
            <img src={BlackAndWhiteLogo} width={100} alt="logo" />
            ------------------------------------------------
            <Stack direction="row" className="justify-center items-center">
              <Typography
                variant="subtitle2"
                sx={{ fontWeight: "bold", fontSize: "19px" }}
              >
                GOKULAM GALLERIA
              </Typography>
            </Stack>
          </Stack>
          <Stack className="justify-center items-center">
            ------------------------------------------------
            <Box>
              <Typography sx={{ textAlign: "center" }} variant="subtitle2">
                DATE : <strong>{presentDate}</strong>
              </Typography>
              <Box sx={{ display: "flex", justifyContent: "center", my: 1 }}>
                <Typography sx={{ textAlign: "center" }} variant="subtitle2">
                  IN TIME : <strong>{formattedInTime}</strong>
                </Typography>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <Typography sx={{ textAlign: "center" }} variant="subtitle2">
                  OUT TIME : <strong>{formattedOutTime}</strong>
                </Typography>
              </Box>
              {/* <Typography sx={{ textAlign: "center", my: 1 }} variant="subtitle2">
              VEHICLE TYPE : <strong></strong>
            </Typography>
            <Typography sx={{ textAlign: "center" }} variant="subtitle2">
              GATE : <strong></strong>
            </Typography> */}
            </Box>
          </Stack>
          <Stack className="justify-center items-center">
            ------------------------------------------------
            <Box>
              <Typography
                sx={{ textAlign: "center" }}
                variant="subtitle2"
                className="!font-semibold"
              >
                TOTAL ENTRY : <strong>{receiptData?.totalVehicleEntryCount}</strong>
              </Typography>

              <Typography
                sx={{ textAlign: "center" }}
                variant="subtitle2"
                className="!font-semibold"
              >
                TOTAL EXIT : <strong>{receiptData?.totalVehicleExitCount}</strong>
              </Typography>
              <Typography
                sx={{ textAlign: "center", my: 1 }}
                variant="subtitle2"
                className="!font-semibold"
              >
                CASH IN HAND : <strong>{receiptData?.cashInHand}</strong>
              </Typography>
              <Typography
                sx={{ textAlign: "center" }}
                variant="subtitle2"
                className="!font-semibold"
              >
                CASH : <strong>{receiptData?.totalCashCollect}</strong>
              </Typography>
              <Typography
                sx={{ textAlign: "center" }}
                variant="subtitle2"
                className="!font-semibold"
              >
                UPI : <strong>{receiptData?.totalAmountCollectInUpi}</strong>
              </Typography>
              <Typography
                sx={{ textAlign: "center", mt: 1 }}
                variant="subtitle2"
                className="!font-semibold"
              >
                REFUND :{" "}
                <strong>{receiptData?.refund}</strong>
              </Typography>
            </Box>
            ------------------------------------------------
          </Stack>
        </Box>
      </Box>
      <ReactToPrint
        trigger={() => (
          <Button className="ms-2">
            <PrintIcon className="!text-[2rem]" />
          </Button>
        )}
        content={() => componentRef.current}
      />
    </>
  );
};

export default Receipt;
