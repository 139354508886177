import { MenuItem, Stack, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useLocation, useNavigate } from "react-router";
import { useGateSelection } from "../hooks/useGateSelection";

const GateSelectionPage = () => {
  const { activeBoothList } = useGateSelection();

  const navigate = useNavigate();
  const { state } = useLocation();
  return (
    <>
      <Box className="background-fixed-with-gradiant">
        <Stack className="flex justify-center items-center h-[100vh] font-[550]">
          <Typography className="!text-[50px] sm:!text-[72px] md:!text-[72px] lg:!text-[72px] !font-[600] !text-[#FFFFFF] opacity-[0.1]">
            Choose Gate
          </Typography>
          <Box>
            <TextField
              onChange={(e) =>
                navigate(`${e.target.value.split("_")[0]}`, {
                  state: { ...state, gate: e.target.value.split("_")[1] },
                })
              }
              className="!w-[250px] sm:!w-[450px] md:!w-[450px] lg:!w-[450px]"
              sx={{
                "&:after": {
                  borderBottomColor: "#D9D9D9",
                },
                "& .MuiSvgIcon-root": {
                  color: "#D9D9D9",
                },
              }}
              select
              label="Gate"
              fullWidth
              InputProps={{
                sx: { color: "#F8F9FA" },
              }}
              InputLabelProps={{
                sx: { color: "#F8F9FA" },
              }}
            >
              {activeBoothList?.map((obj, i) => {
                const str = obj.name;
                const str2 = str.charAt(0).toUpperCase() + str.slice(1);
                return (
                  <MenuItem key={i} value={obj.id + "_" + obj.name}>
                    {str2}
                  </MenuItem>
                );
              })}
            </TextField>
          </Box>
        </Stack>
      </Box>
    </>
  );
};

export default GateSelectionPage;
