import { Avatar, Box, Stack, Typography } from "@mui/material";
import React from "react";
import ArrowUpwardIcon from "../../assets/images/UpArrowVector.svg";
import ArrowDownwardIcon from "../../assets/images/DownArrowVector.svg";
import { useNavigate } from "react-router";

const UserHomePage = () => {
  const navigate = useNavigate();
  return (
    <>
      <Box className="background-fixed-with-gradiant">
        <Stack
          className="flex justify-center items-center h-[100vh] font-[550]"
          direction={{ xs: "column", sm: "row" }}
          spacing={7}
        >
          <Avatar
            onClick={() => {
              navigate("entry-gate", { state: { EntryExit: 1 } });
            }}
            className="!w-[170px] !h-[170px] bg-gradient-to-r from-[#A8B8D8] to-[#576782] hover:from-[#A8B8D8] hover:to-[#3A416F] cursor-pointer"
          >
            <Stack spacing={1} className="flex justify-center items-center">
              <img height={40} src={ArrowUpwardIcon} alt="arrow-up-icon" />
              <Typography variant="subtitle1" className="!font-bold">
                Entry
              </Typography>
            </Stack>
          </Avatar>
          <Avatar
            onClick={() => {
              navigate("exit-gate", { state: { EntryExit: 2 } });
            }}
            className="!w-[170px] !h-[170px] bg-gradient-to-r from-[#A8B8D8] to-[#576782] hover:from-[#A8B8D8] hover:to-[#3A416F] cursor-pointer"
          >
            <Stack spacing={1} className="flex justify-center items-center">
              <img height={40} src={ArrowDownwardIcon} alt="arrow-down-icon" />
              <Typography variant="subtitle1" className="!font-bold">
                Exit
              </Typography>
            </Stack>
          </Avatar>
        </Stack>
      </Box>
    </>
  );
};

export default UserHomePage;
