import React, { useEffect } from "react";
import { Box, Button, IconButton, Typography } from "@mui/material";
import { useState } from "react";
import {
  addFloor,
  deleteFloor,
  fetchAllFloors,
  restoreFloor,
  updateFloor,
} from "../../../api/floors";
import EditIcon from "@mui/icons-material/Edit";
import { fetchActiveAreas } from "../../../api/areas";
import { fetchEntityLocation } from "../../../api/locations";

export function useFloorMaster() {
  const [floorData, setFloorData] = useState([]);

  const [formValues, setFormValues] = useState({
    name: "",
    description: "",
    key: "",
    locationId: localStorage.getItem("locationId"),
    areaId: "",
  });

  const [locations, setLocation] = useState([]);
  const [areas, setAreas] = useState([]);

  const [open, setOpen] = useState(false);
  const [searchValue, setSearchvalue] = useState("");
  const [buttonHandle, setButtonHandle] = useState("");

  let tenantId = localStorage.getItem("tenantId");
  let floorId = "0bb13dcb-a309-4a8d-901d-b481dd006e63";
  let areaId = "ced4ddb3-16f5-4a51-a7c0-f8da3231619f";

  const isDisabled =
    formValues?.vehicletype !== "" && formValues?.description !== "";

  const getAllFloors = async () => {
    let response = await fetchAllFloors(formValues.locationId, tenantId);
    setFloorData(response);
  };

  const handleChange = (event) => {
    setFormValues({ ...formValues, [event.target.name]: event.target.value });
  };

  const handleClickOpen = () => {
    setOpen(true);
    setButtonHandle("Add");
    handleClear();
  };

  const handleClose = () => {
    setOpen(false);
    handleClear();
  };

  const handleSearchChange = (e) => {
    setSearchvalue(e.target.value);
  };

  const handleEdit = (row) => {
    setFormValues(row);
    setOpen(true);
    setButtonHandle("Edit");
  };

  const getActiveAreas = async () => {
    let response = await fetchActiveAreas(formValues.locationId, tenantId);
    if (response) {
      setFormValues({ ...formValues, areaId: response[0]?.id });
    }
    setAreas(response);
    return response;
  };

  const getEntityLocations = async () => {
    let response = await fetchEntityLocation(tenantId);
    setLocation(response);
    return response;
  };

  const handleSubmit = async () => {
    // eslint-disable-next-line
    if (formValues?.key == "" || formValues?.key == undefined) {
      const data = {
        name: formValues.name,
        description: formValues.description,
        locationId: formValues.locationId,
        areaId: areaId,
      };

      let response = addFloor(formValues.locationId, tenantId, data);
      if (response && response.id !== "") {
        getAllFloors();
      }
      setOpen(false);
    } else {
      const data = {
        id: formValues.key,
        name: formValues.name,
        description: formValues.description,
        locationId: formValues.locationId,
        areaId: areaId,
      };

      let response = updateFloor(formValues.locationId, tenantId, data);
      if (response && response.id !== "") {
        getAllFloors();
      }
      setOpen(false);
    }
  };

  const deleteFloorDetails = async (id) => {
    let response = await deleteFloor(
      id,
      floorId,
      formValues.locationId,
      tenantId
    );
    if (response) {
      getAllFloors();
    }
  };

  const restoreBlockDetails = async (id) => {
    let response = await restoreFloor(
      id,
      floorId,
      formValues.locationId,
      tenantId
    );
    if (response) {
      getAllFloors();
    }
  };

  const handleClear = () => {
    setFormValues({
      name: "",
      description: "",
    });
  };

  const filteredRows = floorData?.filter(
    (row) =>
      row.name.toLowerCase().includes(searchValue.toLowerCase()) ||
      row.description.toLowerCase().includes(searchValue.toLowerCase())
  );

  const columns = [
    {
      field: "id",
      headerName: "NO",
      headerClassName: "super-app-theme--header",
      flex: 1,
    },
    {
      field: "name",
      headerName: "NAME",
      headerClassName: "super-app-theme--header",
      flex: 1,
    },

    {
      field: "description",
      headerName: "DESCRIPTION",
      headerClassName: "super-app-theme--header",
      flex: 1,
    },
    {
      field: "active",
      headerName: "STATUS",
      flex: 1,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <Box display={"flex"} paddingRight={1}>
          {params?.row?.deleted === false ? (
            <Button
              variant="contained"
              size="small"
              color="success"
              sx={{ width: "2rem" }}
              onClick={() => deleteFloorDetails(params?.row?.key)}
            >
              <Typography sx={{ fontSize: "10px" }}>Active</Typography>
            </Button>
          ) : (
            <Button
              variant="contained"
              size="small"
              color="error"
              sx={{ width: "2rem" }}
              onClick={() => restoreBlockDetails(params?.row?.key)}
            >
              <Typography sx={{ fontSize: "10px" }}>Inactive</Typography>
            </Button>
          )}
        </Box>
      ),
    },
    {
      field: "action",
      headerName: "ACTION",
      headerClassName: "super-app-theme--header",
      flex: 1,
      renderCell: (params) => (
        <Box display="flex" justifyContent="center">
          <IconButton onClick={() => handleEdit(params.row)}>
            <EditIcon />
          </IconButton>
        </Box>
      ),
    },
  ];

  const rows =
    filteredRows?.length === 0 || filteredRows?.length === undefined
      ? []
      : filteredRows?.map((rowData, index) => ({
          key: rowData.id,
          id: index + 1,
          name: rowData.name,
          description: rowData.description,
          status: rowData.status,
          deleted: rowData.deleted,
        }));

  /*eslint-disable */
  useEffect(() => {
    getAllFloors();
  }, [formValues.locationId, formValues.areaId]);

  useEffect(() => {
    getEntityLocations();
    getActiveAreas();
  }, []);
  /*eslint-enable */

  return {
    open,
    rows,
    areas,
    columns,
    locations,
    formValues,
    searchValue,
    buttonHandle,
    isDisabled,
    handleChange,
    handleClickOpen,
    handleClose,
    handleSearchChange,
    handleSubmit,
    handleClear,
  };
}
