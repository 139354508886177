import { useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import { Box, Button, IconButton, Typography } from "@mui/material";
import {
  addBlock,
  deleteBlock,
  fetchAllBlocks,
  fetchBlock,
  restoreBlock,
  updateBlock,
} from "../../../api/blocks";
import { fetchActiveFloors } from "../../../api/floors";

export function useBlockMaster() {
  const [open, setOpen] = useState(false);

  const [floors, setFloors] = useState([]);
  const [blocks, setBlocks] = useState([]);

  const [search, setSearch] = useState("");

  const [formValues, setFormValues] = useState({
    id: "",
    name: "",
    floorId: "",
    description: "",
  });

  let tenantId = localStorage.getItem("tenantId");
  let locationId = localStorage.getItem("locationId");

  const columns = [
    {
      field: "No.",
      headerName: "NO.",
      width: 65,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "name",
      headerName: "BLOCK NAME",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "description",
      headerName: "DESCRIPTION",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "deleted",
      headerName: "STATUS",
      flex: 1,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <Box>
            {params?.row?.deleted === false ? (
              <Button
                variant="contained"
                size="small"
                color="success"
                sx={{ width: "2rem" }}
                onClick={() => deleteBlockDetails(params.id)}
              >
                <Typography sx={{ fontSize: "10px" }}>Active</Typography>
              </Button>
            ) : (
              <Button
                variant="contained"
                size="small"
                color="error"
                sx={{ width: "2rem" }}
                onClick={() => {
                  restoreBlockDetails(params.id);
                }}
              >
                <Typography sx={{ fontSize: "10px" }}>In Active</Typography>
              </Button>
            )}
          </Box>
        );
      },
    },
    {
      field: "action",
      headerName: "ACTION",
      flex: 1,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <IconButton
            onClick={() => {
              setOpen(true);
              window.scrollTo(0, 0);
              getBlock(params.id);
            }}
          >
            <EditIcon />
          </IconButton>
        );
      },
    },
  ];

  const handleInputChange = (e) => {
    setFormValues({ ...formValues, [e.target.name]: e.target.value });
  };

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const blockMasterFilterData = blocks?.filter(
    (item) =>
      item.name.toLowerCase().includes(search.toLowerCase()) ||
      item.description.toLowerCase().includes(search.toLowerCase())
  );

  const getActiveFloors = async () => {
    let response = await fetchActiveFloors(locationId, tenantId);
    if (response) {
      setFormValues({ ...formValues, floorId: response[0]?.id });
    }
    setFloors(response);
    return response;
  };

  const handleClose = () => {
    setOpen(false);
    handleClear();
  };

  const handleClear = () => {
    setFormValues({
      id: "",
      name: "",
      description: "",
    });
  };

  const isDisabled = formValues?.name !== "" && formValues.description !== "";

  const getAllBlocks = async () => {
    const response = await fetchAllBlocks(
      formValues.floorId,
      tenantId,
      locationId
    );
    if (response && response.id !== "") {
      setBlocks(response);
    }
  };

  const getBlock = async (id) => {
    let response = await fetchBlock(
      formValues.floorId,
      tenantId,
      locationId,
      id
    );
    if (response && response.id !== "") {
      setFormValues({
        ...formValues,
        id: response.id,
        name: response.name,
        description: response.description,
      });
    }
  };

  const deleteBlockDetails = async (id) => {
    let response = await deleteBlock(
      id,
      formValues.floorId,
      locationId,
      tenantId
    );
    if (response) {
      getAllBlocks();
    }
  };

  const restoreBlockDetails = async (id) => {
    let response = await restoreBlock(
      id,
      formValues.floorId,
      locationId,
      tenantId
    );
    if (response) {
      getAllBlocks();
    }
  };

  const handleSubmit = async () => {
    if (formValues.id !== "") {
      const response = await updateBlock(
        formValues.floorId,
        locationId,
        tenantId,
        formValues
      );
      if (response && response.id !== "") {
        handleClear();
        handleClose();
        getAllBlocks();
      }
    } else {
      const response = await addBlock(
        formValues.floorId,
        locationId,
        tenantId,
        formValues
      );
      if (response && response.id !== "") {
        handleClear();
        handleClose();
        getAllBlocks();
      }
    }
  };

  /*eslint-disable */
  useEffect(() => {
    getActiveFloors();
  }, []);

  useEffect(() => {
    if (formValues?.floorId) {
      getAllBlocks();
    }
  }, [formValues?.floorId]);
  /*eslint-enable */

  return {
    open,
    setOpen,
    columns,
    floors,
    formValues,
    isDisabled,
    handleClose,
    handleSubmit,
    handleInputChange,
    handleSearchChange,
    blockMasterFilterData,
  };
}
